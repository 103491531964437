<template>
  <div class="statistics-page">
    <div class="date-box">
      <el-date-picker
        v-model="dates"
        type="daterange"
        @change="init"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期">
      </el-date-picker>
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '名称'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <i class="el-icon-folder" v-if="scope.row.fileType === 1" @dblclick="nextLevel(scope.row,scope.$index)" style="color:#f2d67e;font-size: 20px;margin-right: 5px"></i>
              <span style="cursor: pointer;" @dblclick="nextLevel(scope.row,scope.$index)">{{scope.row.name}}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          ></el-table-column>
        </template>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getHrppInitial, getHrppWeekgroup, getHrppBetween } from "@/api/knowledgeData.js";
export default {
  name: "statisticsPage",
  components: {
  },
  data() {
    return {
      loading: false,
      headData: [
        { align: "center", prop: "name", label: "", width: "" },
        { align: "center", prop: "initData", label: "初始数据", width: "" },
      ],
      tableData: [],
      initData: {},
      dates: null,
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
      this.tableData = [];
      this.headData = [
        { align: "center", prop: "name", label: "", width: "" },
        { align: "center", prop: "initData", label: "初始数据", width: "" },
      ]
      this.getInitial();
    },
    setDate() {
      if (this.dates != null) {
      // console.log(this.dates);
      }
    },
    async getInitial() {
      let res = await getHrppInitial();
      if (res.code == 0) {
        this.initData = res.result;
        if (this.dates === null) {
          this.getWeekgroup();
        } else {
          this.getBetween();
        }
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    async getWeekgroup() {
      this.loading = true;
      let res = await getHrppWeekgroup();
      this.loading = false;
      if (res.code == 0) {
        // let data = res.result;
        this.setData(res.result);
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    async getBetween() {
      let startDate = this.$getDate(1,this.dates[0]);
      let endDate = this.$getDate(1,this.dates[1]);
      let params = `${startDate}/${endDate}`;
      this.loading = true;
      let res = await getHrppBetween(params);
      this.loading = false;
      if (res.code == 0) {
        this.setData([res.result]);
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    setData(data) {
      let arr = [
          { name: "文件夹总数", key: "folderCount", initData: this.initData.folderCount },
          { name: "文件总数", key: "fileCount", initData: this.initData.fileCount },
          { name: "手册总数", key: "manualCount", initData: this.initData.manualCount },
          { name: "制度总数", key: "regimeCount", initData: this.initData.regimeCount },
          { name: "流程总数", key: "processCount", initData: this.initData.processCount },
          { name: "表单总数", key: "formCount", initData: this.initData.formCount },
          { name: "其他类", key: "otherCount", initData: this.initData.otherCount },
        ]
        data.forEach(item => {
          this.headData.push(
            {
              align: "center",
              prop: item.groupName,
              label: item.groupName,
              width: "",
            }
          )
          arr.forEach(item2 => {
            item2[`${item.groupName}`] = item.data[`${item2.key}`];
          })
        });
        this.tableData = arr;
    },
  }
}
</script>

<style lang="scss" scoped>
  .statistics-page {
    .date-box {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
</style>

