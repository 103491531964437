<template>
  <div class="table-container statistics-page">
    <el-table
      v-loading="loading"
      border
      :data="tableData"
      style="width: 100%"
      fit
      row-key="id"
      lazy
      :load="load"
      :tree-props="{ children: 'children', hasChildren: 'isHasChild' }"
    >
      <template v-for="(item, index) in headData">
        <!-- <el-table-column
          :align="item.align"
          v-if="item.label === '地区'"
          :key="index"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <p>{{ scope.row.regionName }}</p>
          </template>
        </el-table-column> -->
        <el-table-column
          :align="item.align"
          :key="index"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
        ></el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import { getLaborLawsListDashboard } from "@/api/knowledgeData";
export default {
  name: "statisticsPage",
  components: {},
  data() {
    return {
      headData: [
        { align: "left", prop: "regionName", label: "地区", width: "300px" },
        { align: "center", prop: "count", label: "效力数量", width: "" },
        { align: "center", prop: "totalCount", label: "区域数量", width: "" },
      ],
      tableData: [],
      allData: [],
      parentId: 0,
      loading: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    async init() {
      this.loading = true;
      let res = await getLaborLawsListDashboard(this.parentId);
      this.loading = false;
      if (res.code == 0) {
        if (this.parentId === 0) {
          this.allData = res.result.concat([]);
        }
        this.tableData = res.result;
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
    async load(tree, treeNode, resolve) {
      // console.log(tree, treeNode);
      let res = await getLaborLawsListDashboard(tree.id);
      let arr = [];
      if (res.code == 0) {
        let data = res.result;
        data.map((item) => {
          return (item.totalCount = "");
        });
        arr = data;
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
      resolve(arr);
    },
    setAllData() {
      this.tableData = this.allData.concat([]);
    },
    getData(item) {
      this.parentId = item.id;
      this.$emit("showBtn");
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics-page {
  ::v-deep .el-table__row--level-1 {
    background-color: rgba(230, 244, 236, 0.6);
  }
}
</style>
