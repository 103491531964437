<template>
  <div class="compliance-user-control">
    <router-view class="children-view"></router-view>
  </div>
</template>

<script>
export default {
  name: "complianceUserControl",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .compliance-user-control {
  }
</style>

