<template>
  <!-- <div class="com-doc">
    <h1>ComDoc</h1>
  </div> -->

  <div class="compliance-user-control">
    <router-view class="children-view"></router-view>
  </div>
</template>

<script>
export default {
  name: "comDoc",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.com-doc {
}
</style>
