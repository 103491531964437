import { render, staticRenderFns } from "./addLabourHarmoniousDialog.vue?vue&type=template&id=57d1ac80&scoped=true"
import script from "./addLabourHarmoniousDialog.vue?vue&type=script&lang=js"
export * from "./addLabourHarmoniousDialog.vue?vue&type=script&lang=js"
import style0 from "./addLabourHarmoniousDialog.vue?vue&type=style&index=0&id=57d1ac80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57d1ac80",
  null
  
)

export default component.exports