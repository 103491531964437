<template>
<!-- 所有订单 -->
  <div class="iLaaS-all-order-form">
    <div class="head-box">
        <div class="head-left">
        <el-date-picker
          v-model="filterInfo.dates"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="init">
        </el-date-picker>
        <el-select
          v-model="filterInfo.orderFormStatus"
          @change="init"
          clearable
          placeholder="请选择订单状态"
        >
          <el-option
            v-for="item in orderFormStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="filterInfo.payStatus"
          @change="init"
          clearable
          placeholder="请选择支付状态"
        >
          <el-option
            v-for="item in payStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input placeholder="请输入搜索内容" v-model="filterInfo.keyVal">
          <i slot="suffix" @click="init" class="el-input__icon el-icon-search"></i>
        </el-input>
        <!-- <el-button class="btn" @click="exportData">导出</el-button> -->
      </div>
      <div class="head-right">
        <div class="popover-box">
          <el-popover
            popper-class="item-popover"
            placement="bottom"
            width="416"
            trigger="click"
          >
            <el-checkbox-group v-model="headFilter">
              <el-checkbox
                v-for="(item, index) in headFilterList"
                :label="index"
                :key="index"
                @change="filtrate"
                class="checkbox-line">
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
            <el-button type="text" slot="reference">
              <img class="icon-img" src="@/assets/img/hmc_shaixuan.png" />
              <span class="c-span">筛选</span></el-button>
          </el-popover>
        </div>
       </div>
      </div>
      <div class="table-container">
        <el-table
          :height="tableHeight"
          border
          :data="tableData"
          style="width: 100%"
          fit
        >
          <template v-for="(head, index) in headData">
            <el-table-column
              :align="head.align"
              v-if="head.label === '是否支付'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
            >
              <template slot-scope="scope">
                {{ setPaymentStatus(scope.row.paymentStatus) }}
              </template>
            </el-table-column>
            <el-table-column
              :align="head.align"
              v-else-if="head.label === '是否完成'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
            >
              <template slot-scope="scope">
                {{ setStatus(scope.row.status) }}
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :align="head.align"
              :key="index"
              :prop="head.prop"
              :label="head.label"
            ></el-table-column>
          </template>
        </el-table>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="interPage"
            layout="total, sizes, prev, pager, next, jumper"
            background
            :total="dataSize"
          >
          </el-pagination>
        </div>
      </div>
  </div>
</template>

<script>
import {  exportUser } from "@/api/index";
import { getBestComOrder,  } from "@/api/clientOperation";
import { Loading } from "element-ui";
export default {
  name: "iLaaSAllOrderForm",
  components: {
  },
  data() {
    return {
      filterInfo: {
        dates: null,
        orderFormStatus: null,
        payStatus: null,
        keyVal: "",
      },
      orderFormStatusList: [
        {value: 1, label: "待付款"},
        {value: 2, label: "待发货"},
        {value: 3, label: "待收货"},
        {value: 4, label: "取消（客户）"},
        {value: 5, label: "作废（管理员）"},
        {value: 6, label: "完成"},
        {value: 7, label: "全部退款"},
        {value: 8, label: "部分退款"},
      ],
      payStatusList: [
        {value: 0, label: "未支付"},
        {value: 1, label: "已支付"},
        {value: 2, label: "取消支付"},
        {value: 3, label: "支付失败"},
      ],
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      headFilter: [0,1,2,3,4,5,6,7,8,9],
      headFilterList: [
        { align: "center", prop: "orderNo", label: "订单号" },
        { align: "center", prop: "companyName", label: "公司名称" },
        { align: "center", prop: "goodsName", label: "产品名称" },
        { align: "center", prop: "name", label: "姓名" },
        { align: "center", prop: "mobile", label: "手机" },
        { align: "center", prop: "email", label: "邮箱" },
        // { align: "center", prop: "enterprise", label: "公司" },
        { align: "center", prop: "orderAmount", label: "费用" },
        { align: "center", prop: "paymentStatus", label: "是否支付" },
        { align: "center", prop: "status", label: "是否完成" },
        { align: "center", prop: "addTime", label: "下单时间" },
      ],
      tableData: [],
      headData: [],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      this.filtrate();
    },
    // 过滤表头
    filtrate() {
      this.headData = [];
      let newArr = [];
      if (this.headFilter.length > 0) {
        this.headFilter.sort(function (a,b) {
            return a-b;
        })
        this.headFilter.forEach(item => {
          newArr.push(this.headFilterList[item])
        })
      }
      this.$nextTick(()=> {
        this.headData = newArr;
        this.getUserInfo();
      })
    },
    async getUserInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = {
        info: {
          queryString: this.filterInfo.keyVal,
          startDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[0]):null,
          endDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[1]):null,
          status: this.filterInfo.orderFormStatus == "" ? null:this.filterInfo.orderFormStatus,
          payStatus: this.filterInfo.payStatus == "" ? null:this.filterInfo.payStatus,
        },
        params: `${this.currentPage}/${this.interPage}`,
      }
      let res = await getBestComOrder(params);
      loadingInstance.close();
      if (res.code == 0) {
        this.tableData = res.result.items;
        this.dataSize = res.result.totalCount;
        this.tableData.map((item) => { 
          item.addTime = item.addTime != null ? item.addTime.split("T")[0]:"";
          return item;
        });
        // console.log(this.tableData);
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 是否支付
    setPaymentStatus(val) {
      let str = "";
      if (val === 0) {
        str = "未支付";
      } else if (val === 1) {
        str = "已支付";
      } else if (val === 2) {
        str = "取消支付";
      } else if (val === 3) {
        str = "支付失败";
      }
      return str;
    },
    // 是否完成
    setStatus(val) {
      let str = "";
      if (val === 1) {
        str = "待付款";
      } else if (val === 2) {
        str = "待发货";
      } else if (val === 3) {
        str = "待收货";
      } else if (val === 4) {
        str = "取消（客户）";
      } else if (val === 5) {
        str = "作废（管理员）";
      } else if (val === 6) {
        str = "完成";
      } else if (val === 7) {
        str = "全部退款";
      } else if (val === 8) {
        str = "部分退款";
      }
      return str;
    },
    // 导出 exportUser
    async exportData() {
      let params = {
        queryString: this.filterInfo.keyVal,
        startDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[0]):null,
        endDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[1]):null,
        status: this.filterInfo.orderFormStatus,
      };
      let res = await exportUser(params);
      // console.log(res.status);
      if (res.status != undefined) {

      } else {
        let data = res;
        let fileName = "登录记录.xlsx";
        const blob =
          data instanceof Blob
            ? data
            : new Blob([data], {
                type: "application/vnd.ms-excel;charset=utf-8",
              });
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getUserInfo();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
  .iLaaS-all-order-form {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  .head-box {
    display: flex;
    justify-content: space-between;
    height: 7%;
    margin-bottom: 1%;
    .head-left {
      display: flex;
      align-items: center;
      .el-date-editor {
        width: 300px;
        margin-right: 10px;
      }
      .el-select {
        width: 200px;
        margin-right: 20px;
      }
      .el-input {
        width: 330px;
        margin-right: 10px;
      }
      .btn {
        height: 30px;
        border-radius: 15px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
      }
    }
    .head-right {
      .popover-box {
        margin-left: 30px;
        .icon-img {
          width: 14px !important;
          height: 14px !important;
          margin-right: 8px;
        }
        span {
          color: #333;
        }
      }
    }
  }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  img {
    width: 140px;
  }
  }
</style>

