<template>
  <div class="add-labour-harmonious-dialog">
    <el-dialog
      title="劳动和谐关系标准"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="460px"
    >
      <div class="row-box">
        <p class="label">
          <span>* </span>
          标准名称：
        </p>
        <el-input placeholder="请输入标准名称" v-model="form.title"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          城市：
        </p>
        <el-cascader
          v-model="form.city"
          :options="cityList"
          :props="props"
          filterable
        >
        </el-cascader>
      </div>
      <!-- <div class="row-box">
        <p class="label">
          <span>* </span>
          排序：
        </p>
        <el-input placeholder="请输入排序" v-model="form.sort"></el-input>
      </div> -->
      <div class="row-box">
        <p class="label">关联法规：</p>
        <div class="law-div">
          <p class="law-p" :title="form.lawName" @click="seleteLaw">
            {{ form.lawName }}
          </p>
          <div class="del" @click="delLawName" v-if="form.lawName!='关联'">删除</div>
        </div>
      </div>
      <div class="row-box">
        <p class="label">启用状态：</p>
        <el-switch
          v-model="form.isEnable"
          active-color="#13ce66"
          inactive-color="#aaaaaa"
        >
        </el-switch>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
      <labourHarmoniousLawDialog
        v-if="showDialog"
        :info="detailsInfo"
        @cancelLaw="cancelLaw"
        @selectLaw="selectLaw"
      ></labourHarmoniousLawDialog>
    </el-dialog>
  </div>
</template>

<script>
import labourHarmoniousLawDialog from "./labourHarmoniousLawDialog.vue";
import { addHarmonious, editHarmonious } from "@/api/knowledgeData";
import { getAddress } from "@/api/index";
export default {
  name: "addLabourHarmoniousDialog",
  components: {
    labourHarmoniousLawDialog,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        title: "", // 标准名称
        city: [], // 城市
        sort: null, // 排序
        lawName: "关联", // 法规
        lawId: null,
        isEnable: true, // 启用状态
      },
      submitStatus: false,
      cityList: [], // 城市数组
      props: {
        label: "name",
        value: "id",
        children: "cities",
        expandTrigger: "hover",
      },
      showDialog: false,
      detailsInfo: {},
    };
  },
  created() {
    this.getRegion();
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      console.log(this.info);
      if (this.info.type == "edit") {
        let info = this.info.item;
        this.form = {
          id: info.id,
          title: info.title,
          city: [info.provinceId, info.cityId],
          lawName: info.lawName || "关联",
          lawId: info.lawId,
          isEnable: info.isEnable,
        };
      }
    },
    // 获取范围
    async getRegion() {
      this.cityList = [];
      let res = await getAddress();
      if (res.code === 0) {
        this.cityList = res.result;
        this.estimateArr(this.cityList);
      }
    },
    estimateArr(arr) {
      // console.log(arr);
      if (arr.length < 1) return;
      arr.forEach((item) => {
        let str = JSON.stringify(item.cities);
        // console.log(str,'str');
        if (str === "[]") {
          item.cities = null;
        } else if (str != "null" && str != "[]" && str != undefined) {
          // console.log(item.cities);
          this.estimateArr(item.cities);
        }
      });
    },
    // 法规弹框
    seleteLaw() {
      this.showDialog = true;
      this.detailsInfo = {
        lawId: this.form.lawId,
        lawName: this.form.lawName,
      };
    },
    // 取消弹框
    cancelLaw() {
      this.showDialog = false;
    },
    // 选择法规
    selectLaw(item) {
      // console.log(item);
      this.form.lawId = item.id;
      this.form.lawName = item.title;
      this.cancelLaw();
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    // 删除法规名称
    delLawName(){
      this.form.lawName = '关联';
      this.form.lawId=null;
    },
    async submit() {
      if (!this.form.title) {
        this.$message.warning(`标准名称不能为空！`);
        return;
      }
      if (!this.form.city.length) {
        this.$message.warning(`请选择省市！`);
        return;
      }
      let params = {
        title: this.form.title,
        provinceId: this.form.city[0],
        cityId: this.form.city[1],
        isEnable: this.form.isEnable,
        lawId: this.form.lawId,
      };
      // console.log(params);return
      this.submitStatus = true;
      let res = {};
      if (this.info.type == "add") {
        res = await addHarmonious(params);
      } else {
        params.id = this.form.id;
        res = await editHarmonious(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-labour-harmonious-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
          .law-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .law-p {
              color: #0094ff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              cursor: pointer;
              width: 300px;
            }
            .del {
              width: 60px;
              height: 26px;
              font-size: 12px;
              font-weight: 600;
              color: #bbb;
              background: #f1f2f1;
              border-radius: 12px;
              border: none;
              cursor: pointer;
              text-align: center;
              line-height: 26px;
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
