<template>
  <div class="data-maintain">
    <allParameter v-show="showTable"></allParameter>
    <parameterType v-if="!showTable"></parameterType>
  </div>
</template>

<script>
import allParameter from './modules/allParameter.vue';
import parameterType from './modules/parameterType.vue';
export default {
  name: "dataMaintain",
  components: {
    allParameter,
    parameterType,
  },
  data() {
    return {
      showTable: true,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .data-maintain {
    // width: 100%;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    .head-box{
      display: flex;
      justify-content: space-between;
      height: 7%;
      margin-bottom: 1%;
      .el-button {
        height: 40px;
        border-radius: 20px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
      }
      .head-right {
        display: flex;
        .el-select {
          margin-right: 20px;
        }
      }
    }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  }
</style>

