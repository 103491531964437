<template>
  <div class="table-page">
    <div class="head-box">
      <div class="level-label">
        <span @click="goBack('首页')">首页</span>
        <span v-if="levelList.length > 0">></span>
        <div v-for="(item,index) in levelList" :key="index">
          <span @click="goBack(index)">{{item.name}}</span>
          <span v-if="index != levelList.length -1">></span>
        </div>
      </div>
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '名称'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <i class="el-icon-folder" v-if="scope.row.fileType === 1" @click="nextLevel(scope.row,scope.$index)" style="cursor: pointer;color:#f2d67e;font-size: 20px;margin-right: 5px"></i>
              <i class="el-icon-tickets" v-else style="color:#7fbce5;font-size: 20px;margin-right: 5px"></i>
              <span style="cursor: pointer;" @click="nextLevel(scope.row,scope.$index)">{{scope.row.name}}</span>
              <i v-if="scope.row.hasChild && scope.row.fileType === 1" class="el-icon-bottom" style="color:green;"></i>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '格式'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <span v-if="scope.row.fileType == 2">PDF</span>
              <span v-else>文件夹</span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '类型'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <span v-if="scope.row.fileType == 2">{{levelList.length > 0 ? levelList[levelList.length -1].name:''}}</span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <div class="popover-box">
                <el-popover
                  popper-class="item-popover"
                  placement="bottom"
                  trigger="hover">
                  <p 
                    class="operation-p"
                    v-for="item in setOperationList(scope.row)"
                    :key="item.id"
                    style="width: 100%;padding: 3px 8px;box-sizing: border-box;cursor: pointer;"
                    @click="isOperation(scope.row,item.label)">
                    {{item.label}}
                  </p>
                  <el-button type="text" slot="reference" style="padding: 0;">
                    <i class="el-icon-more" style="font-size: 20px;"></i>
                  </el-button>
                </el-popover>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          ></el-table-column>
        </template>
      </el-table>
    </div>
    <addJDDialog v-if="showDialog" :info="dialogInfo"></addJDDialog>
    <shiftJDDialog v-if="showDialog3" :info="dialogInfo3"></shiftJDDialog>
    <setJDFileDialog v-if="showDialog4" :info="dialogInfo4"></setJDFileDialog>
  </div>
</template>

<script>
import addJDDialog from '@/views/Dialog/addJDDialog.vue'
import shiftJDDialog from '@/views/Dialog/shiftJDDialog.vue'
import setJDFileDialog from '@/views/Dialog/setJDFileDialog.vue'
import { getFileUrl } from "@/api/index.js";
import { getJD, delJDfolder,  } from "@/api/knowledgeData.js";
export default {
  name: "tablePage",
  components: {
    addJDDialog,
    shiftJDDialog,
    setJDFileDialog
  },
  data() {
    return {
      showDialog: false,
      showDialog3: false,
      showDialog4: false,
      dialogInfo: {
        type: 'add',
        item: {},
      },
      dialogInfo2: {},
      dialogInfo3: {},
      dialogInfo4: {},
      levelList: [],
      loading: false,
      headData: [],
      tableData: [],
      allTableData: [],
      operationList: [
        {id: 1, label: "新建子级文件夹"},
        {id: 2, label: "上传文件"},
        {id: 3, label: "编辑"},
        {id: 4, label: "删除"},
        {id: 5, label: "转移"},
      ],
    }
  },
  created() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // this.levelList = [];
      this.getData();
    },
    async getData() {
      let params = "/0";
      if (this.levelList.length > 0) {
        params = `/${this.levelList[this.levelList.length - 1].id}`
      }
      this.loading = true;
      let res = await getJD(params);
      this.loading = false;
      if (res.code == 0) {
        let data = res.result;
        data.map(item => {
          if (item.fileType === 2) {
            let nameArr = item.name.split(".");
            if (nameArr.length > 1) {
              nameArr.pop();
              item.name = nameArr.join('');
            }
          }
          item.createTime = item.createTime.substring(0,10);
          return item
        })
        this.tableData = data;
        if (data.length > 0) {
          if (data[0].fileType === 1) {
            this.headData = [
              { align: "left", prop: "name", label: "名称", width: "" },
              { align: "center", prop: "fileCount", label: "文件数量", width: "" },
              { align: "center", prop: "createTime", label: "添加日期", width: "" },
              { align: "center", prop: "操作", label: "操作", width: "" },
            ]
          } else {
            this.headData = [
              { align: "left", prop: "name", label: "名称", width: "" },
              { align: "center", prop: "格式", label: "格式", width: "" },
              { align: "center", prop: "类型", label: "类型", width: "" },
              { align: "center", prop: "createTime", label: "添加日期", width: "" },
              { align: "center", prop: "操作", label: "操作", width: "" },
            ]
          }
        }
      }
    },
    // setData() {
    //   let data = [];
    //   if (this.levelList.length > 0) {
    //     let newArr = [].concat(this.allTableData);
    //     this.levelList.forEach(item => {
    //       if (newArr[item.idx].childs === null) {
    //         newArr = [];
    //       } else {
    //         newArr = newArr[item.idx].childs;
    //       }
    //     })
    //     data = [].concat(newArr);
    //   } else {
    //     data = [].concat(this.allTableData);
    //   }
    //   if (data.length > 0) {
    //     data.map(item => {
    //       if (item.fileType === 2) {
    //         let nameArr = item.name.split(".");
    //         if (nameArr.length > 1) {
    //           nameArr.pop();
    //           item.name = nameArr.join('');
    //         }
    //       }
    //       item.createTime = item.createTime.substring(0,10);
    //       return item
    //     })
    //   }
    //   if (data.length > 0) {
    //     let item = data[0];
    //     if (item.fileType === 1) {
    //       this.headData = [
    //         { align: "left", prop: "name", label: "名称", width: "" },
    //         { align: "center", prop: "fileCount", label: "文件数量", width: "" },
    //         { align: "center", prop: "createTime", label: "添加日期", width: "" },
    //         { align: "center", prop: "操作", label: "操作", width: "" },
    //       ]
    //     } else {
    //       this.headData = [
    //         { align: "left", prop: "name", label: "名称", width: "" },
    //         { align: "center", prop: "格式", label: "格式", width: "" },
    //         { align: "center", prop: "类型", label: "类型", width: "" },
    //         { align: "center", prop: "createTime", label: "添加日期", width: "" },
    //         { align: "center", prop: "操作", label: "操作", width: "" },
    //       ]
    //     }
    //   } else {
    //     this.headData = [
    //       { align: "left", prop: "name", label: "名称", width: "" },
    //       { align: "center", prop: "fileCount", label: "文件数量", width: "" },
    //       { align: "center", prop: "createTime", label: "添加日期", width: "" },
    //       { align: "center", prop: "操作", label: "操作", width: "" },
    //     ]
    //   }
    //   this.tableData = [].concat(data);
    // },
    setOperationList(row) {
      let arr = [];
      if (row.fileType === 2) { // 当前格式为文件
        arr = [
          // {id: 3, label: "编辑"},
          {id: 6, label: "下载"},
          {id: 4, label: "删除"},
          {id: 5, label: "转移"},
        ]
      } else { // 当前格式为文件夹
        if (!row.hasChild) {
          arr = [
            {id: 1, label: "新建子级文件夹"},
            {id: 2, label: "上传文件"},
            {id: 3, label: "编辑"},
            {id: 4, label: "删除"},
            {id: 5, label: "转移"},
          ]
        } else {  // 文件夹不为空
          if (row.childFileType === 1) { // 子级类型为文件夹
            arr = [
            {id: 1, label: "新建子级文件夹"},
            {id: 3, label: "编辑"},
            {id: 4, label: "删除"},
            {id: 5, label: "转移"},
            ]
          } else { // 子级类型为文件
            arr = [
              {id: 2, label: "上传文件"},
              {id: 3, label: "编辑"},
              {id: 4, label: "删除"},
              {id: 5, label: "转移"},
            ]
          }
        }
      }
      return arr;
    },
    add() {
      this.dialogInfo = {
        type: "add",
        item: {},
        status: 2, // 弹框显示新增 1 新增文件夹或者文件二选一 2 新增文件夹 3新增文件
      }
      if (this.levelList.length > 0) {
        let info = this.levelList[this.levelList.length -1];
        // console.log(info);
        this.dialogInfo.item = JSON.parse(JSON.stringify(info));
        if (info.hasChild) {
          if (info.childFileType === 1) {
            this.dialogInfo.status = 2;
          } else if (info.childFileType === 2) {
            this.dialogInfo.status = 3;
          }
        } else {
          this.dialogInfo.status = 1;
        }
      }
      this.showDialog = true;
    },
    // 点击下级
    nextLevel(row, idx) {
      // console.log(row);
      if (row.fileType === 1) {
        // if (row.childs != null) {
          this.levelList.push(
            {
              name: row.name,
              id: row.id,
              idx: idx,
              hasChild: row.hasChild,
              childFileType: row.childFileType,
            }
          )
          this.getData();
        // }
      } else {
        this.dialogInfo4 = row;
        this.showDialog4 = true;
      }
    },
    // 返回
    goBack(idx) {
      if (idx != "首页") {
        let len = this.levelList.length -1;
        // console.log(len,idx);
        if (idx != len) {
          this.levelList.splice(idx+1,len-idx);
          this.getData(); 
        }
      } else {
        this.levelList = [];
        this.getData();
      }
    },
    async isOperation(row,label) {
      if (label == "新建子级文件夹") {
        this.dialogInfo = {
          type: "add",
          item: row,
          status: 2,
        }
        this.showDialog = true;
      } else if (label == "上传文件") {
        this.dialogInfo = {
          type: "add",
          item: row,
          status: 3,
        }
        this.showDialog = true;
      } else if (label == "编辑") {
        this.dialogInfo = {
          type: "edit",
          item: row,
          status: row.fileType === 1 ? 2:3,
        }
        this.showDialog = true;
      } else if (label == "删除") {
        this.$confirm(`请确认是否删【 ${row.name} 】？`)
        .then((_) => {
          this.delData({ids: [row.id]});
        })
        .catch((_) => {});
      } else if (label == "转移") {
        this.dialogInfo3 = JSON.parse(JSON.stringify(row));
        this.dialogInfo3.lists = this.allTableData;
        this.showDialog3 = true;
      } else if (label == "下载") {
        let params = {
          expireTime: 3600,
          objectName: row.handledFilePath,
          BucketName: row.buketName,
          EndPoint: 'https://oss-cn-shenzhen.aliyuncs.com',
        };
        let ress = await getFileUrl(params);
        if (ress.code == 200) {
          fetch(ress.result).then(res => res.blob()).then(blob => {
            const a = document.createElement('a');
            document.body.appendChild(a)
            a.style.display = 'none'
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${row.name}`;
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
          })
        }
      }
    },
    async delData(id) {
      let res = await delJDfolder(id);
      if (res.code === 200) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        // let len = this.levelList.length;
        // if (len > 1) { 
        //   let newList = [].concat(this.allTableData);
        //   let status = false;
        //   for (let i = 0; i < len; i++) {
        //     newList = newList[this.levelList[i].idx].childs;
        //     if (Number(i) == len -1) {
        //       if (status) {
        //         this.levelList.splice(len-1,1);
        //       }
        //     } else if (Number(i) == len -2) {
        //       if (newList[this.levelList[len-1].idx].childs.length == 1) {
        //         status = true;
        //       }
        //     }
        //   }
        // } else if (len == 1) {
        //   if (this.allTableData[this.levelList[0].idx].childs.length == 1) {
        //     this.levelList.splice(0,1)
        //   }
        // }
        this.init();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .table-page {
    padding-bottom: 10px;
    .head-box {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .btn {
        height: 30px;
        border-radius: 20px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
        margin-right: 20px;
      }
      .level-label {
        display: flex;
        align-items: center;
        span {
          font-size: 14px;
          margin: 0 5px;
          color: #409eff;
          cursor: pointer;
        }
      }
    }
    ::v-deep .table-container {
      .el-table__header-wrapper {
        th {
          padding: 5px 0;
        }
      }
      .el-table__body-wrapper {
        td {
          // padding: 5px 0;
        }
      }
    }
  }
  .popover-box {
    display: flex;
    justify-content: center;
  }
  .operation-p:hover {
    background-color: rgba(127, 188, 229,.3);
  }
</style>

