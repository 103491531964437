<template>
  <div class="ComDoc-publicity">
    <div class="tabs-box">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="自定义菜单" name="1">
          <div class="content-box">
            <div class="left-box">
              <p>一级菜单</p>
            </div>
            <div class="right-box">
              <div class="column-box" v-for="(item,index) in myList" :key="index">
                <p class="title-p">{{item.title}}</p>
                <div class="card" v-for="(item2,index2) in item.list" :key="index2">
                  <div class="row">
                    <p class="label-p">名称：</p>
                    <p class="val-p">{{item2.name}}</p>
                  </div>
                  <div class="row">
                    <p class="label-p">URL：</p>
                    <p class="val-p">{{item2.url}}</p>
                  </div>
                  <div class="btn-box">
                    <el-button class="btn" @click="edit(index,index2,item2)">修改</el-button>
                    <el-button class="del-btn btn" v-if="index2 !== 0" @click="del(index,index2)">删除</el-button>
                  </div>
                </div>
                <i class="el-icon-circle-plus-outline icon" @click="add(index)"></i>
              </div>
            </div>
          </div>
          <el-button v-if="variationStatus" class="addbtn" @click="save">保存修改</el-button>
          <addComDocPublicityDialog v-if="showDialog" :info="dialogInfo" @closeDialog="closeDialog" @saveData="saveData"></addComDocPublicityDialog>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import addComDocPublicityDialog from '@/views/Dialog/addComDocPublicityDialog.vue'
import { addWechatComDoc, getWechatComDoc,  } from "@/api/knowledgeData.js";
export default {
  name: "ComDocPublicity",
  components: {
    addComDocPublicityDialog,
  },
  data() {
    return {
      activeName: "1",
      myList: [
        {
          title: "第一列",
          list: [
            {
              name: "",
            },
          ],
        },
        {
          title: "第二列",
          list: [
            {
              name: "",
            },
          ],
        },
        {
          title: "第三列",
          list: [
            {
              name: "",
            },
          ],
        },
      ],
      isIdx: 0,
      isIdx2: 0,
      showDialog: false,
      dialogInfo: {
        type: 'add',
        item: {},
      },
      variationStatus: false, // 页面是否更改过
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    async init() {
      let res = await getWechatComDoc();
      if (res.code == 200) {
        let data = res.result.button;
        data.forEach((item,index) => {
          let arr = [];
          if (item.sub_button != null) {
            arr.push({name: item.name});
            item.sub_button.list.forEach(item2 => {
              arr.push({name: item2.name, url: item2.url});
            })
          } else {
            arr.push({name: item.name, url: item.url});
          }
          this.myList[index].list = arr;
        })
        console.log(this.myList);
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    add(idx) {
      if (!this.myList[idx].list[0].name) {
        this.$message.warning(`请先设定一级菜单名称！`);
        return;
      }
      this.isIdx = idx;
      this.dialogInfo = {
        type: 'add',
        item: {},
      }
      this.showDialog = true;
    },
    edit(idx,idx2,info) {
      this.isIdx = idx;
      this.isIdx2 = idx2;
      this.dialogInfo = {
        type: 'edit',
        item: info,
      }
      this.showDialog = true;
    },
    del(idx,idx2) {
      this.myList[idx].list.splice(idx2,1);
      this.variationStatus = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    saveData(data) {
      if (data.type == "add") {
        this.myList[this.isIdx].list.push(data.info);
      } else {
        this.myList[this.isIdx].list[this.isIdx2] = data.info;
      }
      this.variationStatus = true;
    },
    async save() {
      let list = [];
      this.myList.forEach(item => {
        if (item.list[0].name) {
        let info = {};
        let arr = item.list;
        arr.forEach((item2,index2) => {
          if (index2 == 0) {
            info = {
              name: item2.name,
              subButton: [],
            }
          } else {
            info.subButton.push(
              {
                type: "view",
                name: item2.name,
                url: item2.url,
              }
            )
          }
        })
        list.push(info);
        }
      })
      let res = await addWechatComDoc({buttons: list});
      if (res.code == 200) {
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    visibleAreaHeights() {
      console.log(window.innerHeight);
    },
  },
  beforeDestroy() {
  }
}
</script>

<style lang="scss" scoped>
  .ComDoc-publicity {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 20px;
  height: 100%;
  box-sizing: border-box;
  .tabs-box {
    position: relative;
    width: 100%;
    ::v-deep .el-tabs__item {
      padding: 0 30px !important;
    }
    .content-box {
      display: flex;
      .left-box {
        width: 100px;
        p {
          text-align: right;
          font-size: 14px;
          line-height: 40px;
          color: #a7b1b6;
          padding-right: 20px;
        }
      }
      .right-box {
        flex: 1;
        display: flex;
        justify-content: space-around;
        .column-box {
          width: 33.33%;
          .title-p {
            line-height: 40px;
            background-color: #f9f9f9;
            border: 1px solid #e4e7ed;
            border-bottom: none;
          }
          .card {
            border: 1px solid #e4e7ed;
            margin-bottom: 20px;
            .row {
              display: flex;
              border-bottom: 1px solid #e4e7ed;
              .label-p {
                width: 10%;
                border-right: 1px solid #e4e7ed;
                text-align: center;
              }
              .val-p {
                display: inline-block;
                width: 90%;
                min-height: 50px;
                line-height: 25px;
              }
            }
            .btn-box {
              display: flex;
              justify-content: flex-end;
              .btn {
                font-size: 12px;
                margin: 6px;
                padding: 6px 7px;
                box-sizing: border-box;
                border-radius: 26px;
                color: rgba(0, 148, 255, 0.7);
                border: 1px solid rgba(0, 148, 255, 0.7);
              }
              .del-btn {
                color: red;
                border: 1px solid red;
              }
            }
          }
          .icon {
            font-size: 30px;
            color: green;
            // margin-top: 30px;
            cursor: pointer;
          }
        }
      }
    }
    .addbtn {
      color: rgba(0, 148, 255,.7);
      border: 1px solid rgba(0, 148, 255,.7);
      cursor: pointer;
    }
    // .btn {
    //   position: absolute;
    //   top: 2px;
    //   right: 0;
    //   height: 30px;
    //   border-radius: 20px;
    //   color: rgba(0, 148, 255,.7);
    //   border: 1px solid rgba(0, 148, 255,.7);
    //   margin-right: 20px;
    // }
  }
  }
</style>

