<template>
  <div class="personnel-regulations">
    <h1>民主规章</h1> 
  </div>
</template>

<script>
export default {
  name: "personnelRegulations",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .personnel-regulations {
  }
</style>

