<template>
  <div class="set-jurisdiction-dialog">
    <el-dialog
      title="权限设置"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="70%">
      <div class="table-container">
        <el-table
          v-loading="loading"
          ref="multipleTable"
          @select-all="selectAll"
          @select="selectTr"
          :data="tableData"
          style="width: 100%"
          row-key="id"
          border
          default-expand-all
          :tree-props="{ children: 'childs', hasChildren: 'hasChildren' }">
          <el-table-column prop="name" label="菜单名称" align="left" width="500px"> </el-table-column>
          <el-table-column prop="actionIds" label="权限配置" align="left">
            <template slot-scope="scope">
              <!-- <span style="color: green; cursor: pointer;">{{scope.row.id}}</span> -->
              <template v-if="scope.row.childs.length == 0">
                <el-checkbox-group v-model="scope.row.actionIds" @change="handleCheckedCitiesChange(scope.row)">
                  <el-checkbox v-for="city in cities" :label="city.id" :key="city.id">{{city.label}}</el-checkbox>
                </el-checkbox-group>
              </template>
              <template v-else>
                <el-checkbox-group v-model="scope.row.actionIds" @change="handleCheckedCitiesChange(scope.row)">
                  <el-checkbox v-for="city in cities2" :label="city.id" :key="city.id">{{city.label}}</el-checkbox>
                </el-checkbox-group>
              </template>
            </template>
          </el-table-column>
          <el-table-column prop="全选" label="全选" align="center" width="100px">
            <template slot-scope="scope" v-if="scope.row.childs.length == 0">
              <el-checkbox :indeterminate="scope.row.isIndeterminate" v-model="scope.row.checkAll" @change="handleCheckAllChange(scope.row)">全选</el-checkbox>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getAllocationList, setAllocation } from "@/api/setting.js"
export default {
  name: "setJurisdictionDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        name: '',
        phone: '',
        email: '',
        job: '',
      },
      loading: false,
      tableData: [],
      cities: [
        {label: "显示", id: 1},
        {label: "添加", id: 2},
        {label: "修改", id: 3},
        {label: "删除", id: 4},
        {label: "查看", id: 5},
        {label: "导出", id: 6},
      ],
      cities2: [
        {label: "显示", id: 1},
      ],
      paramsList: [],
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      console.log(this.info);
      this.getData();
    },
    async getData() {
      this.loading = true;
      let res = await getAllocationList(this.info.id);
      this.loading = false;
      // console.log(res,'res');
      if (res.code == 0) {
        let data = res.result;
        this.tableData = this.setData(data);
        // this.tableData = res.result;
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    setData(data) {
      data.forEach(item => {
        // item.actionIds = [];
        item.isIndeterminate = false;
        if (item.actionIds.length > 0) {
          if (item.actionIds.length > 1) {
            item.checkAll = "";
          } else {
            item.checkAll = false;
          }
          let len = 6;
          let total = 0;
          item.actionIds = item.actionIds.map(item2 => {
            total ++;
            item2 = Number(item2);
            return item2
          })
          if (len > 1) {
            if (len === total) {
              item.checkAll = true;
            } else if (item.actionIds.length > 0 && len > total) {
              item.isIndeterminate = true;
              item.checkAll = true;
            }
          }
        }
        if (item.childs.length > 0) {
          this.setData(item.childs);
        } 
      })
      return data
    },
    handleCheckAllChange(row) {
      let arr = [1,2,3,4,5,6];
      row.actionIds = row.checkAll ? arr:[];
      row.isIndeterminate = false;
    },
    handleCheckedCitiesChange(row) {
      let len = row.actionIds.length;
      row.checkAll = len === this.cities.length;
      row.isIndeterminate = len > 0 && len < this.cities.length;
    },
    cancel() {
      this.$parent.showDialog2 = false;
    },
    selectAll() {
      this.isAllSelect = !this.isAllSelect;
      let data = this.tableData;
      this.toggleSelect(data, this.isAllSelect, "all");
    },
    //选择某行
    selectTr(selection, row) {
      this.$set(row, "isChecked", !row.isChecked);
      this.$nextTick(() => {
        this.isAllSelect = row.isChecked;
        this.toggleSelect(row, row.isChecked, "tr");
      });
    },
    //递归子级
    toggleSelect(data, flag, type) {
      if (type === "all") {
        if (data.length > 0) {
          data.forEach((item) => {
            this.toggleSelection(item, flag);
            if (item.children && item.children.length > 0) {
              this.toggleSelect(item.children, flag, type);
            }
          });
        }
      } else {
        if (data.children && data.children.length > 0) {
          data.children.forEach((item) => {
            item.isChecked = flag;
            this.$refs.multipleTable.toggleRowSelection(item, flag);
            this.toggleSelect(item, flag, type);
          });
        }
      }
    },
    //改变选中
    toggleSelection(row, flag) {
      this.$set(row, "isChecked", flag);
      this.$nextTick(() => {
        if (flag) {
          this.$refs.multipleTable.toggleRowSelection(row, flag);
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    setParams(arr) {
      arr.forEach(item => {
        this.paramsList.push(
          {
            menuIdentity: item.identity,
            actionIds: item.actionIds,
          }
        )
        if (item.childs.length > 0) {
          this.setParams(item.childs);
        }
      })
    },
    async submit() {
      if (this.submitStatus) return;
      this.paramsList = [];
      let data = this.tableData;
      await this.setParams(data);
      let params = {
        accountId: this.info.id,
        permissions: this.paramsList,
      }
      // console.log(params);
      this.submitStatus = true;
      let res = await setAllocation(params);
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.set-jurisdiction-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      ::v-deep .el-dialog__body {
        padding: 30px;
        .table-container {
          th,
          td {
            padding: 5px 0 !important;
          }
          .el-table__cell {
            padding: 5px 0 !important;
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
