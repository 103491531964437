<template>
  <div class="add-labor-laws-dialog">
    <el-dialog
      :title="myTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      :show-close="false"
      width="50%"
    >
    <div class="head-box">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabsList" :key="item.id" :label="item.label" :name="item.name"></el-tab-pane>
      </el-tabs>
    </div>
      <div class="content-box">
        <div class="card-box" v-if="activeName === '基本信息'">
          <div class="row-box">
            <p class="label">
              <span class="red-span">*</span>
              范围：
            </p>
            <el-cascader
              v-model="myInfo.regionId"
              :options="scopeList"
              :props="props"
              filterable>
            </el-cascader>
          </div>
          <div class="row-box">
            <span class="label">显示状态：</span>
            <el-select
              v-model="myInfo.displayStatus"
              filterable
              placeholder="选择显示状态"
            >
              <el-option
                v-for="item in showList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row-box">
            <p class="label">
              <span class="red-span">*</span>
              标题：
            </p>
            <el-input v-model="myInfo.title" placeholder="输入标题"></el-input>
          </div>
          <div class="row-box">
            <span class="label">发文字号：</span>
            <el-input v-model="myInfo.publicationNumber" placeholder="输入发文字号"></el-input>
          </div>
          <div class="row-box">
            <span class="label">索引号：</span>
            <el-input v-model="myInfo.indexNumber" placeholder="输入索引号"></el-input>
          </div>
          <div class="row-box">
            <span class="label">URL链接：</span>
            <el-input v-model="myInfo.url" placeholder="输入URL链接"></el-input>
          </div>
          <div class="row-box">
            <span class="label">法规类型：</span>
            <el-select
              v-model="myInfo.type"
              filterable
              placeholder="选择法规类型"
              clearable
            >
              <el-option
                v-for="item in typeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="card-box card-box2" v-if="activeName === '效力/关键词'">
          <div class="row-box">
            <p class="label">
              关键词：
              <span>多个词语时用逗号“,”隔开</span>
            </p>
            <el-input
            type="textarea"
            v-model="myInfo.keyword"
            placeholder="输入关键词"
            :autosize="{ minRows: 3}"
            >
            </el-input>
          </div>
          <div class="row-box">
            <p class="label">
              近义词：
              <span>多个词语时用逗号“,”隔开</span>
            </p>
            <el-input
            type="textarea"
            v-model="myInfo.synonyms"
            placeholder="输入近义词"
            :autosize="{ minRows: 3}"
            >
            </el-input>
          </div>
          <div class="row-box">
            <p class="label">
              同义词：
              <span>多个词语时用逗号“,”隔开</span>
            </p>
            <el-input
            type="textarea"
            v-model="myInfo.synonym"
            placeholder="输入同义词"
            :autosize="{ minRows: 3}"
            >
            </el-input>
          </div>
          <div class="row-box">
            <p class="label">
              <span class="red-span">*</span>
              法律效力位级：
            </p>
            <el-select
              v-model="myInfo.legalEffect"
              filterable
              placeholder="选择法律效力位级"
            >
              <el-option
                v-for="item in legalEffectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row-box">
            <p class="label">
              <span class="red-span">*</span>
              时效性：
            </p>
            <el-select
              v-model="myInfo.timeliness"
              filterable
              placeholder="选择时效性"
            >
              <el-option
                v-for="item in timelinessList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="card-box" v-if="activeName === '详细描述'">
          <div class="row-box">
            <span class="label">信息来源：</span>
            <el-input v-model="myInfo.souce" placeholder="输入信息来源"></el-input>
          </div>
          <div class="row-box">
            <span class="label">颁布机构1：</span>
            <el-input v-model="myInfo.promulgateMechanism1" placeholder="输入颁布机构1"></el-input>
          </div>
          <div class="row-box">
            <span class="label">颁布机构2：</span>
            <el-input v-model="myInfo.promulgateMechanism2" placeholder="输入颁布机构2"></el-input>
          </div>
          <div class="row-box" style="flex-direction: column; align-items: flex-start;">
            <p class="label">
              <span class="red-span">*</span>
              文件内容：
            </p><br/>
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 500px; overflow-y: hidden"
                v-model="myInfo.content"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              />
            </div>
          </div>
        </div>
        <div class="card-box" v-if="activeName === '日期/分类'">
          <div class="row-box">
            <span class="label">颁布日期：</span>
            <el-date-picker
              v-model="myInfo.promulgateDate"
              type="date"
              placeholder="选择颁布日期">
            </el-date-picker>
          </div>
          <div class="row-box">
            <span class="label">施行日期：</span>
            <el-date-picker
              v-model="myInfo.executeDate"
              type="date"
              placeholder="选择施行日期">
            </el-date-picker>
          </div>
          <div class="row-box">
            <span class="label">废止日期：</span>
            <el-date-picker
              v-model="myInfo.abolishDate"
              type="date"
              placeholder="选择废止日期">
            </el-date-picker>
          </div>
          <div class="row-box">
            <span class="label">修订日期：</span>
            <el-date-picker
              v-model="myInfo.reviseDate"
              type="date"
              placeholder="选择修订日期">
            </el-date-picker>
          </div>
        </div>
        <!-- <div class="row-box">
          <div style="border: 1px solid #ccc">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden"
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </div> -->
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getLaborLawsRegion, getLaborLawsDetail, addLaborLaws, editLaborLaws } from '@/api/knowledgeData'
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "addLaborLawsDialog",
  components: {
    Editor,
    Toolbar,
  },
  props: {
    info: {
      type: Object,
    }
  },
  data() {
    return {
      myTitle: "劳动法规",
      dialogVisible: true,

      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default", // or 'simple'

      activeName: "基本信息", // tabs
      tabsList: [
        {id: 1, label: "基本信息", name: "基本信息"},
        {id: 2, label: "效力/关键词", name: "效力/关键词"},
        {id: 3, label: "详细描述", name: "详细描述"},
        {id: 4, label: "日期/分类", name: "日期/分类"},
      ],
      // showVal: "正常",
      scopeList: [], // 范围数组
      props: {
	      label: 'name',
        value: 'id',
        children: 'childs',
	      expandTrigger: 'hover'
      },
      myInfo: {
        regionId: "", // 范围
        displayStatus: null, // 显示状态
        title: "", // 标题
        publicationNumber: "", // 发文字号
        indexNumber: "", // 索引号
        url: "", // URL链接
        type: null, // 法规类型
        keyword: "", // 关键词
        synonyms: "", // 近义词
        synonym: "", // 同义词
        legalEffect: null, // 法律效力位级
        timeliness: null, // 时效性
        souce: "", // 信息来源
        promulgateMechanism1: "", // 颁布机构1
        promulgateMechanism2: "", // 颁布机构2
        content: "", //文件内容
        promulgateDate: null, // 颁布日期
        executeDate: null, // 施行日期
        abolishDate: null, // 废止日期
        reviseDate: null, //修订日期
        id: "",
      },
      showList: [ // 显示状态数组
        {value: 1, label: "正常"},
        {value: 2, label: "待审核"},
        {value: 3, label: "不显示"},
      ],
      typeList: [ // 法规类型数组
        {value: 1, label: "ISO"},
        {value: 2, label: "GBT"},
        {value: 3, label: "中央企业合规管理办法"},
        {value: 4, label: "和谐关系"},
      ],
      legalEffectList: [ // 法律效力位级数组
        {value: 1, label: "国家法律"},
        {value: 2, label: "国家行政法规"},
        {value: 3, label: "国家部门规章"},
        {value: 4, label: "国家规范性文件"},
        {value: 5, label: "地方行政法规"},
        {value: 6, label: "地方部门规章"},
        {value: 7, label: "地方规范性文件"},
      ],
      timelinessList: [ // 时效性数组
        {value: 1, label: "尚未生效"},
        {value: 2, label: "有效"},
        {value: 3, label: "废止"},
        {value: 4, label: "修订"},
      ],
      submitStatus: false,
    };
  },
  created() {},
  mounted() {
    this.init();
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = "<p>模拟 Ajax 异步设置内容 HTML</p>";
    // }, 1500);
  },
  methods: {
    init() {
      // console.log(this.info);
      this.getRegion();
      if (this.info.type === "edit") {
        this.getDetails();
      }
    },
    // 切换tabs
    handleClick() {},
    // 获取范围
    async getRegion() {
      this.scopeList = [];
      let res = await getLaborLawsRegion();
      if (res.code === 0) {
        // let data = res.result[0];
        // this.scopeList.push(
        //   {
        //     childs: null,
        //     id: data.id,
        //     name: data.name,
        //     order: data.order,
        //     parentId: data.parentId,
        //   }
        // );
        // let childs = data.childs;
        // childs.forEach(item => {
        //   this.scopeList.push(item);
        // });
        this.scopeList = res.result;
        this.estimateArr(this.scopeList);
        // console.log(this.scopeList);
      }
    },
    estimateArr(arr) {
      // console.log(arr,'arr');
      if (arr.length < 1)return
      arr.forEach(item => {
        let str = JSON.stringify(item.childs)
        // console.log(str,'strstrstrs');
        if (str === "[]") {
          item.childs = null;
        } else if (str != "null" && str != "[]") {
          this.estimateArr(item.childs)
        }
      })
    },
    // 获取详情
    async getDetails() {
      let res = await getLaborLawsDetail(this.info.id);
      // console.log(res,'详情返回');
      if (res.code === 0) {
        let data = res.result;
        this.myInfo = {
          regionId: data.regionId,
          displayStatus: data.displayStatus || null,
          title: data.title,
          publicationNumber: data.publicationNumber,
          indexNumber: data.indexNumber,
          url: data.url,
          type: data.type || null,
          keyword: data.keyword,
          synonyms: data.synonyms,
          synonym: data.synonym,
          legalEffect: data.legalEffect || null,
          timeliness: data.timeliness || null,
          souce: data.souce,
          promulgateMechanism1: data.promulgateMechanism1,
          promulgateMechanism2: data.promulgateMechanism2,
          content: data.content,
          promulgateDate: data.promulgateDate,
          executeDate: data.executeDate,
          abolishDate: data.abolishDate,
          reviseDate: data.reviseDate,
          id: data.id,
        };
      }
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    uploadVideoProcess() {},
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      let newArr = file.name.split(".");
      let fileType = newArr[newArr.length - 1];
      // console.log(fileType);
      // console.log(file,file.type);return
      let imgType = [
        "jpg",
        "jpeg",
        "dds",
        "psd",
        "pdt",
        "webp",
        "xmp",
        "gif",
        "bmp",
        "svg",
        "tiff",
        "ico", // 图片
      ];
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!imgType.includes(fileType)) {
        this.$message.warning("上传文件只能是图片!");
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 10MB!");
        return;
      }
      let res3 = await getPolicyToken(this.ossInfo);
      if (res3.code == 0) {
        // console.log(res3);
        let data = JSON.parse(res3.content);
        this.objData = {
          OSSAccessKeyId: data.accessid,
          policy: data.policy,
          signature: data.signature,
          expire: data.expire,
          host: data.host,
          dir: data.dir,
          key: `project/client/file/${
            this.userInfo.userId
          }/${new Date().getTime()}-${file.name}`,
        };
        this.filePathList.push(this.objData.key);
        // console.log(this.objData);
        this.fileList.push({
          name: file.name,
          filePath: this.objData.key,
        });
      } else {
        return;
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    cancel() {
      this.$parent.showDialog = false;
      // this.$parent.init();
    },
    // 弹框保存
    async submit() {
      if (this.submitStatus) return;
      let verifyObj = {
        regionId: "基本信息【范围】",
        title: "基本信息【标题】",
        legalEffect: "效力/关键词【法律效力位级】",
        timeliness: "效力/关键词【时效性】",
      };
      for (let key in verifyObj) {
        if (!this.myInfo[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      if (this.myInfo.content === "<p><br></p>") {
        this.$message.warning(`详细描述【文件内容】不能为空！`);
        return;
      }
      let region = '';
      let params = JSON.parse(JSON.stringify(this.myInfo));
      if (typeof params.regionId == 'number') {
        region = params.regionId
      } else {
        region = params.regionId[params.regionId.length - 1]
      }
      params.type = params.type || null;
      params.regionId = region;
      params.promulgateDate = params.promulgateDate ? this.$getDate(1,params.promulgateDate):null;
      if (params.executeDate) params.executeDate = this.$getDate(1,params.executeDate);
      if (params.reviseDate) params.reviseDate = this.$getDate(1,params.reviseDate);
      if (params.abolishDate) params.abolishDate = this.$getDate(1,params.abolishDate);
      // console.log(params);return
      this.submitStatus = true;
      let res = {};
      if (this.info.type == "add") {
        delete params.id;
        res = await addLaborLaws(params);
      } else {
        res = await editLaborLaws(params);
      }
      this.submitStatus = false;
      // console.log(res,'提交返回');
      if (res.code === 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.init();
        this.$parent.showDialog = false;
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style>
  @import url("@wangeditor/editor/dist/css/style.css");
</style>
<style lang="scss" scoped>
.add-labor-laws-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__body {
        padding: 10px;
        .content-box {
          width: 100%;
          min-height: 400px;
          border-radius: 12px;
          padding: 10px;
          box-sizing: border-box;
          border: 1px solid #e4e7ed;
          .card-box {
            .row-box {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              .label {
                display: block;
                min-width: 80px;
                font-size: 14px;
                color: #333;
                .red-span {
                  font-size: 20px;
                  color: red;
                }
              }
              .val-box {
              }
            }
            }
            .card-box2 {
              .row-box {
                flex-direction: column;
                align-items: flex-start;
                .label {
                  font-size: 12px;
                  margin-bottom: 10px;
                  span {
                    margin-left: 10px;
                    color: #bbbbbb;
                  }
                } 
              }
            }
          }
      }
    }
  }
}
</style>
