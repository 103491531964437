<template>
  <div class="add-contacts-dialog">
    <el-dialog
      title="风险点"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      width="80%"
    >
      <div class="head-box">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane
            v-for="item in tabsList"
            :key="item.id"
            :label="item.label"
            :name="item.name"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <template v-if="activeName === '风险识别'">
        <div class="row-box">
          <p class="label">
            <span>* </span>
            风险点编码：
          </p>
          <el-input
            placeholder="自动生成"
            v-model="form.code"
            disabled
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            风险点名称：
          </p>
          <el-input
            placeholder="请输入风险点名称"
            v-model="form.name"
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            风险源：
          </p>
          <div class="vals-box">
            <div class="icon-box">
              <i
                v-if="form.behaviourDescriptions.length < 1"
                class="el-icon-circle-plus-outline"
                @click="addRiskPointBehavior"
              ></i>
            </div>
            <div
              class="border-box"
              v-if="form.behaviourDescriptions.length > 0"
            >
              <div
                class="card-box card-box2"
                v-for="(item, index) in form.behaviourDescriptions"
                :key="index"
              >
                <div class="vals-box">
                  <el-input
                    type="textarea"
                    class="short-ipt"
                    placeholder="请输入关键句"
                    autosize
                    v-model="item.keySentence"
                  ></el-input>
                  <el-input
                    type="textarea"
                    placeholder="请输入描述"
                    autosize
                    v-model="item.content"
                  ></el-input>
                </div>
                <i
                  class="el-icon-remove-outline"
                  @click="delRiskPointBehavior(index)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            违规后果：
          </p>
          <div class="vals-box">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addViolateConsequence"
              ></i>
            </div>
            <div
              class="border-box"
              v-if="form.infractionConsequences.length > 0"
            >
              <div
                class="card-box"
                v-for="(item, index) in form.infractionConsequences"
                :key="index"
              >
                <el-input
                  type="textarea"
                  placeholder="请输入违规后果"
                  autosize
                  v-model="item.content"
                ></el-input>
                <i
                  class="el-icon-remove-outline"
                  @click="delViolateConsequence(index)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            风险责任：
          </p>
          <el-select v-model="form.type" multiple placeholder="请选择风险责任">
            <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <p class="label">
            风险值：
          </p>
          <el-input
            placeholder="请输入风险值"
            v-model="form.riskValue"
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            风险等级：
          </p>
          <el-select v-model="form.rank" placeholder="请选择风险类型">
            <el-option
              v-for="item in rankList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box" v-if="form.status !== undefined">
          <p class="label">风险点状态：</p>
          <el-select v-model="form.status">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </template>
      <template v-if="activeName === '类别'">
        <div class="row-box">
          <p class="label">
            <span>* </span>
            职业阶段：
          </p>
          <el-select v-model="form.postCycle" placeholder="请选择职业阶段">
            <el-option
              v-for="item in postCycleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            专项类：
          </p>
          <el-select
            class="min-select"
            v-model="form.businessModule"
            placeholder="请选择专项类"
          >
            <el-option
              v-for="item in businessModuleList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            组织类型：
          </p>
          <el-select v-model="form.enterpriseNature" multiple placeholder="请选择组织类型">
            <el-option
              v-for="item in suitOrgList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            组织规模：
          </p>
          <el-select v-model="form.enterpriseScale" multiple placeholder="请选择组织规模">
            <el-option
              v-for="item in scaleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <p class="label">匹配报告：</p>
          <el-select
            v-model="form.matchingReports"
            multiple
            placeholder="请选择匹配报告"
          >
            <el-option
              v-for="item in matchingReportsList"
              :disabled="item.status"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </template>
      <template v-if="activeName === '劳动法规'">
        <div class="row-box">
          <p class="label" style="width: 14%">
            <span>* </span>
            劳动法规：
          </p>
          <div class="vals-box" style="width: 85%">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addUpervisePursuant(1)"
              ></i>
            </div>
            <div class="col-boxs" v-if="form.lawBasis.length > 0">
              <el-row class="head-row">
                <el-col :span="6">法规名称</el-col>
                <el-col :span="4">条款</el-col>
                <el-col :span="11">条款内容</el-col>
                <el-col :span="3">操作</el-col>
              </el-row>
              <template>
                <el-row
                  class="row-son"
                  type="flex"
                  v-for="(item, index) in form.lawBasis"
                  :key="index"
                >
                  <el-col :span="6" :title="item.name">
                    <p class="span-val">{{ item.name }}</p>
                  </el-col>
                  <el-col :span="4" :title="item.clause">
                    <p class="span-val">{{ item.clause }}</p>
                  </el-col>
                  <el-col :span="11" :title="item.content">
                    <p class="span-val">{{ item.content }}</p>
                  </el-col>
                  <el-col :span="3">
                    <span @click="lookLawBasis(item, index, 1)">修改</span>
                    <i
                      class="el-icon-remove-outline"
                      @click.stop="delLawBasis(index, 1)"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeName === 'ISO37301'">
        <div class="row-box">
          <p class="label" style="width: 14%">
            <!-- <span>* </span> -->
            ISO37301：
          </p>
          <div class="vals-box" style="width: 85%">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addUpervisePursuant(2)"
              ></i>
            </div>
            <div class="col-boxs" v-if="form.standardISO.length > 0">
              <el-row class="head-row">
                <el-col :span="6">法规名称</el-col>
                <el-col :span="4">条款</el-col>
                <el-col :span="11">条款内容</el-col>
                <el-col :span="3">操作</el-col>
              </el-row>
              <template>
                <el-row
                  class="row-son"
                  type="flex"
                  v-for="(item, index) in form.standardISO"
                  :key="index"
                >
                  <el-col :span="6" :title="item.name">
                    <p class="span-val">{{ item.name }}</p>
                  </el-col>
                  <el-col :span="4" :title="item.clause">
                    <p class="span-val">{{ item.clause }}</p>
                  </el-col>
                  <el-col :span="11" :title="item.content">
                    <p class="span-val">{{ item.content }}</p>
                  </el-col>
                  <el-col :span="3">
                    <span @click="lookLawBasis(item, index, 2)">修改</span>
                    <i
                      class="el-icon-remove-outline"
                      @click.stop="delLawBasis(index, 2)"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeName === 'GBT/T'">
        <div class="row-box">
          <p class="label" style="width: 14%">
            <!-- <span>* </span> -->
            GBT/T：
          </p>
          <div class="vals-box" style="width: 85%">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addUpervisePursuant(3)"
              ></i>
            </div>
            <div class="col-boxs" v-if="form.standardGBT.length > 0">
              <el-row class="head-row">
                <el-col :span="6">法规名称</el-col>
                <el-col :span="4">条款</el-col>
                <el-col :span="11">条款内容</el-col>
                <el-col :span="3">操作</el-col>
              </el-row>
              <template>
                <el-row
                  class="row-son"
                  type="flex"
                  v-for="(item, index) in form.standardGBT"
                  :key="index"
                >
                  <el-col :span="6" :title="item.name">
                    <p class="span-val">{{ item.name }}</p>
                  </el-col>
                  <el-col :span="4" :title="item.clause">
                    <p class="span-val">{{ item.clause }}</p>
                  </el-col>
                  <el-col :span="11" :title="item.content">
                    <p class="span-val">{{ item.content }}</p>
                  </el-col>
                  <el-col :span="3">
                    <span @click="lookLawBasis(item, index, 3)">修改</span>
                    <i
                      class="el-icon-remove-outline"
                      @click.stop="delLawBasis(index, 3)"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeName === '中央企业合规管理办法'">
        <div class="row-box">
          <p class="label" style="width: 14%">
            <!-- <span>* </span> -->
            中央企业合规管理办法：
          </p>
          <div class="vals-box" style="width: 85%">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addUpervisePursuant(4)"
              ></i>
            </div>
            <div class="col-boxs" v-if="form.centralEnterprises.length > 0">
              <el-row class="head-row">
                <el-col :span="6">法规名称</el-col>
                <el-col :span="4">条款</el-col>
                <el-col :span="11">条款内容</el-col>
                <el-col :span="3">操作</el-col>
              </el-row>
              <template>
                <el-row
                  class="row-son"
                  type="flex"
                  v-for="(item, index) in form.centralEnterprises"
                  :key="index"
                >
                  <el-col :span="6" :title="item.name">
                    <p class="span-val">{{ item.name }}</p>
                  </el-col>
                  <el-col :span="4" :title="item.clause">
                    <p class="span-val">{{ item.clause }}</p>
                  </el-col>
                  <el-col :span="11" :title="item.content">
                    <p class="span-val">{{ item.content }}</p>
                  </el-col>
                  <el-col :span="3">
                    <span @click="lookLawBasis(item, index, 4)">修改</span>
                    <i
                      class="el-icon-remove-outline"
                      @click.stop="delLawBasis(index, 4)"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeName === '和谐关系标准'">
        <div class="row-box">
          <p class="label" style="width: 14%">
            <!-- <span>* </span> -->
            和谐关系标准：
          </p>
          <div class="vals-box" style="width: 85%">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addUpervisePursuant(5)"
              ></i>
            </div>
            <div class="col-boxs" v-if="form.harmoniousRelationships.length > 0">
              <el-row class="head-row">
                <el-col :span="6">法规名称</el-col>
                <el-col :span="4">条款</el-col>
                <el-col :span="11">条款内容</el-col>
                <el-col :span="3">操作</el-col>
              </el-row>
              <template>
                <el-row
                  class="row-son"
                  type="flex"
                  v-for="(item, index) in form.harmoniousRelationships"
                  :key="index"
                >
                  <el-col :span="6" :title="item.name">
                    <p class="span-val">{{ item.name }}</p>
                  </el-col>
                  <el-col :span="4" :title="item.clause">
                    <p class="span-val">{{ item.clause }}</p>
                  </el-col>
                  <el-col :span="11" :title="item.content">
                    <p class="span-val">{{ item.content }}</p>
                  </el-col>
                  <el-col :span="3">
                    <span @click="lookLawBasis(item, index, 5)">修改</span>
                    <i
                      class="el-icon-remove-outline"
                      @click.stop="delLawBasis(index, 5)"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeName === '合规义务'">
        <div class="row-box">
          <p class="label">
            <span>* </span>
            合规措施：
          </p>
          <div class="vals-box">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addRiskPointMeasure"
              ></i>
            </div>
            <div class="border-box" v-if="form.riskMeasures.length > 0">
              <div
                class="card-box"
                v-for="(item, index) in form.riskMeasures"
                :key="index"
              >
                <p class="label-p">标题：</p>
                <div class="main">
                  <div class="title">{{ item.title }}</div>
                  <div class="tool">
                    <span @click="editTask(item, index)">修改</span>
                    <i
                      class="el-icon-remove-outline"
                      @click.stop="delTask(index)"
                    ></i>
                  </div>
                </div>
                <p class="label-p">描述：</p>
                <div class="title">{{item.description}}</div>
                <div class="col-boxs" v-if="item.subs && item.subs.length > 0">
                  <el-row class="head-row">
                    <el-col :span="12">任务内容</el-col>
                    <el-col :span="12">合规工具</el-col>
                  </el-row>
                  <template>
                    <el-row
                      class="row-son"
                      type="flex"
                      v-for="(it, ix) in item.subs"
                      :key="ix"
                    >
                      <el-col :span="12" :title="it.content">
                        <span class="span-val">{{ it.content }} </span>
                      </el-col>
                      <el-col :span="12" :title="it.tools">
                        <span
                          class="span-val"
                          v-for="(ie, inx) in it.tools"
                          :key="inx"
                        >
                          {{ ie.title }}&nbsp;&nbsp;
                        </span>
                      </el-col>
                    </el-row>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row-box row-box2">
          <p class="label">合规文件：</p>
          <div class="vals-box2">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addcomplianceDocClick"
              ></i>
            </div>
            <div
              class="col-boxs"
              v-if="form.riskTexts && form.riskTexts.length > 0"
            >
              <el-row class="head-row">
                <el-col :span="10">标题</el-col>
                <el-col :span="10">文件名称</el-col>
                <!-- <el-col :span="10">地址链接</el-col> -->
                <el-col :span="4">操作</el-col>
              </el-row>
              <template>
                <el-row
                  class="row-son"
                  type="flex"
                  v-for="(item, index) in form.riskTexts"
                  :key="index"
                >
                  <el-col :span="10" :title="item.title">
                    <div class="span-val">{{ item.name }}</div>
                  </el-col>

                  <el-col :span="10" title="">
                    <div
                      class="subClass"
                      v-for="(it, ix) in item.subs"
                      :key="ix"
                    >
                      <div class="span-val">{{ it.originFileName }}</div>
                    </div>
                  </el-col>
                  <!-- <el-col :span="10" title="">
                    <div
                      class="subClass"
                      v-for="(it, ix) in item.subs"
                      :key="ix"
                    >
                      <div class="span-val">{{ it.filePath }}</div>
                    </div>
                  </el-col> -->

                  <el-col :span="4">
                    <span @click="lookcomplianceDocList(item, index)"
                      >修改</span
                    >
                    <i
                      class="el-icon-remove-outline"
                      @click.stop="delcomplianceDocList(index)"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>

          <!-- <div class="vals-box">
            <div class="file-box">
              <div
                class="file-card"
                :class="isFileIdx === index ? 'is-file-card' : ''"
                @mouseenter="mouseOver(index, 1)"
                @mouseleave="mouseLeave(1)"
                v-for="(item, index) in fileList"
                :key="item.fileName"
              >
                <img :src="selectImg(item.fileName)" alt="" />
                <p :title="item.fileName">{{ item.fileName }}</p>
                <el-progress
                  v-if="progressFlag && index == fileList.length - 1"
                  :percentage="loadProgress"
                ></el-progress>
                <div class="shadow-box" v-else>
                  <button @click="download(item)">下载</button>
                  <button @click="delFile(item, index, 1)">删除</button>
                  <button @click="editFile(item, index, 1)">修改文件名</button>
                </div>
              </div>
              <div class="add-box" v-if="!progressFlag" @click="clickUp(1)">
                <el-upload
                  class="avatar-uploader"
                  :action="objData.host"
                  :data="objData"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :on-progress="uploadVideoProcess"
                  :on-success="upSuccess"
                  :on-error="upError"
                >
                  <img src="@/assets/img/add-icon.png" alt="" />
                  <p>上传文件</p>
                </el-upload>
                <span>（ 上传文件格式：Word、Excel ）</span>
              </div>
            </div>
          </div> -->
          <!-- 合规文件修改文件名 -->
          <editFilename
            :RenameDialogVisible="RenameDialogVisibleV1"
            @Reback="RebackV1"
            @filenameConfirm="filenameConfirmV1"
            :Filename="FilenameV1"
            :fileId="fileIdV1"
          ></editFilename>
        </div>
        <div class="row-box row-box3">
          <p class="label">合规制度：</p>
          <div class="vals-box3">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addComplianceSystemClick"
              ></i>
            </div>
            <div
              class="col-boxs"
              v-if="form.institutions && form.institutions.length > 0"
            >
              <el-row class="head-row">
                <el-col :span="10">标题</el-col>
                <el-col :span="10">文件名称</el-col>
                <!-- <el-col :span="10">地址链接</el-col> -->
                <!-- <el-col :span="4">文件</el-col> -->
                <el-col :span="4">操作</el-col>
              </el-row>
              <template>
                <el-row
                  class="row-son"
                  type="flex"
                  v-for="(item, index) in form.institutions"
                  :key="index"
                >
                  <el-col :span="10" :title="item.title">
                    <div class="span-val">{{ item.name }}</div>
                  </el-col>

                  <el-col :span="10" title="">
                    <div
                      class="subClass"
                      v-for="(it, ix) in item.subs"
                      :key="ix"
                    >
                      <div class="span-val">{{ it.originFileName }}</div>
                    </div>
                  </el-col>
                  <!-- <el-col :span="10" title="">
                    <div
                      class="subClass"
                      v-for="(it, ix) in item.subs"
                      :key="ix"
                    >
                      <div class="span-val">{{ it.filePath }}</div>
                    </div>
                  </el-col> -->

                  <el-col :span="4">
                    <span @click="lookComplianceSystemArr(item, index)"
                      >修改</span
                    >
                    <i
                      class="el-icon-remove-outline"
                      @click.stop="delComplianceSystemArr(index)"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>
          <!-- <div class="vals-box">
            <div class="file-box">
              <div
                class="file-card"
                :class="isFileIdx2 === index ? 'is-file-card' : ''"
                @mouseenter="mouseOver(index, 2)"
                @mouseleave="mouseLeave(2)"
                v-for="(item, index) in fileList2"
                :key="item.fileName"
              >
                <img :src="selectImg(item.fileName)" alt="" />
                <p :title="item.fileName">{{ item.fileName }}</p>
                <el-progress
                  v-if="progressFlag2 && index == fileList2.length - 1"
                  :percentage="loadProgress"
                ></el-progress>
                <div class="shadow-box" v-else>
                  <button @click="download(item)">下载</button>
                  <button @click="delFile(item, index, 2)">删除</button>
                  <button @click="editFile(item, index)">修改文件名</button>
                </div>
              </div>
              <div class="add-box" v-if="!progressFlag2" @click="clickUp(2)">
                <el-upload
                  class="avatar-uploader"
                  :action="objData.host"
                  :data="objData"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :on-progress="uploadVideoProcess"
                  :on-success="upSuccess"
                  :on-error="upError"
                >
                  <img src="@/assets/img/add-icon.png" alt="" />
                  <p>上传文件</p>
                </el-upload>
                <span>（ 上传文件格式：Word、Excel ）</span>
              </div>
            </div>
          </div> -->
          <!-- 合规制度修改文件名 -->
          <editFilename
            :RenameDialogVisible="RenameDialogVisible"
            @Reback="Reback"
            @filenameConfirm="filenameConfirm"
            :Filename="Filename"
            :fileId="fileId"
          ></editFilename>
        </div>
        <div class="row-box">
          <p class="label">合规义务数量：</p>
          <el-input
            placeholder="保存后自动生成"
            v-model="form.dutyAmount"
            disabled
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">合规工具：</p>
          <el-select v-model="form.tools" multiple placeholder="请选择合规工具">
            <el-option
              v-for="item in ToolList"
              :disabled="item.status"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            合规义务种类：
          </p>
          <el-select v-model="form.dutyType" placeholder="请选择合规义务种类">
            <el-option
              v-for="item in dutyTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <p class="label">责任人：</p>
          <el-input
            placeholder="请输入责任人"
            v-model="form.responsiblePerson"
          ></el-input>
        </div>
      </template>
      <template v-if="activeName === '案例'">
        <div class="row-box">
          <p class="label">案例：</p>
          <div class="vals-box">
            <div class="icon-box">
              <i
                class="el-icon-circle-plus-outline"
                @click="addTypicalCases"
              ></i>
            </div>
            <div class="col-boxs" v-if="form.typicalCases.length > 0">
              <el-row class="head-row">
                <el-col :span="7">标题</el-col>
                <el-col :span="10">地址链接</el-col>
                <el-col :span="4">文件</el-col>
                <el-col :span="3">操作</el-col>
              </el-row>
              <template>
                <el-row
                  class="row-son"
                  type="flex"
                  v-for="(item, index) in form.typicalCases"
                  :key="index"
                >
                  <el-col :span="7" :title="item.title">
                    <span class="span-val">{{ item.title }} </span>
                  </el-col>
                  <el-col :span="10" :title="item.url">
                    <span class="span-val">{{ item.url }} </span>
                  </el-col>
                  <el-col :span="4" :title="item.fileName">
                    <span class="span-val">{{ item.fileName }} </span>
                  </el-col>
                  <el-col :span="3">
                    <span @click="lookTypicalCases(item, index)">修改</span>
                    <i
                      class="el-icon-remove-outline"
                      @click.stop="delTypicalCases(index)"
                    ></i>
                  </el-col>
                </el-row>
              </template>
            </div>
          </div>
        </div>
      </template>
      <template v-if="activeName === '其他'">
        <div class="row-box">
          <p class="label">整改建议：</p>
          <el-input
            type="textarea"
            placeholder="请输入整改建议"
            autosize
            v-model="form.rectificationSuggestion"
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">问题：</p>
          <div class="vals-box">
            <div class="icon-box">
              <i class="el-icon-circle-plus-outline" @click="addQuestions"></i>
            </div>
            <div class="border-box" v-if="form.questions.length > 0">
              <div
                class="card-box"
                v-for="(item, index) in form.questions"
                :key="index"
              >
                <el-input
                  type="textarea"
                  placeholder="请输入问题"
                  autosize
                  v-model="item.content"
                ></el-input>
                <i
                  class="el-icon-remove-outline"
                  @click="delQuestions(index)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="row-box">
          <p class="label">是否启用：</p>
          <el-select v-model="form.isEnable">
            <el-option
              v-for="item in enableList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </template>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
    <upervisePursuantDialog
      v-if="showDialog"
      :info="detailsInfo"
      @setLawBasis="setLawBasis"
    ></upervisePursuantDialog>
    <modelCaseDialog
      v-if="showDialog2"
      :info="detailsInfo2"
      @setTypicalCases="setTypicalCases"
    ></modelCaseDialog>
    <addcomplianceDoc
      v-if="showDialog3"
      :info="detailsInfo3"
      @setTypicalCases3="setTypicalCases3"
    ></addcomplianceDoc>
    <addComplianceSystem
      v-if="showDialog4"
      :info="detailsInfo4"
      @setComplianceSystem="setComplianceSystem"
    ></addComplianceSystem>
    <addComplianceMeasures
      :complianceMeasuresDialog="complianceMeasuresDialog"
      :ToolList="ToolList"
      @closecomplianceMeasures="closecomplianceMeasures"
      @confirmcomplianceMeasures="confirmcomplianceMeasures"
      :info="MeasuresInfo"
    ></addComplianceMeasures>
  </div>
</template>

<script>
import addComplianceSystem from "./addComplianceSystem.vue";
import addcomplianceDoc from "./addcomplianceDoc.vue";
import addComplianceMeasures from "./addComplianceMeasures.vue";
import upervisePursuantDialog from "./upervisePursuantDialog.vue";
import modelCaseDialog from "./modelCaseDialog.vue";
import { getPolicyToken } from "@/api/index.js";
import {
  getBusinessModuleList,
  addRiskPlace,
  editRiskPlace,
  getRiskPlaceDetail,
  getRiskTool,
  getRiskMatchingReport,
  getRiskBusinessModule,
} from "@/api/knowledgeData.js";
import EditFilename from "./editFilename.vue";
var OSS = require("ali-oss");
export default {
  name: "addRiskPoint",
  components: {
    upervisePursuantDialog,
    modelCaseDialog,
    EditFilename,
    addComplianceMeasures,
    addcomplianceDoc,
    addComplianceSystem,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      showDialog4: false, //合规制度
      detailsInfo4: {
        //合规制度
        type: "add",
      },
      showDialog3: false, //合规文件弹窗
      detailsInfo3: {
        //合规文件
        type: "add",
      },
      MeasuresInfo: {
        type: "add",
      },
      complianceMeasuresDialog: false, //新增合规措施
      fileIdV1: 0, //修改合规文件源文件ID
      FilenameV1: "", //修改合规文件原文件名
      RenameDialogVisibleV1: false, //修改合规文件文件名
      fileId: 0, //合规制度源文件ID
      Filename: "", //合规制度原文件名
      RenameDialogVisible: false, //合规制度修改文件名
      dialogVisible: true,
      activeName: "风险识别", // tabs
      tabsList: [
        { id: 1, label: "风险识别", name: "风险识别" },
        { id: 2, label: "类别", name: "类别" },
        {
          id: 3,
          label: "劳动法规",
          name: "劳动法规",
        },
        {
          id: 4,
          label: "ISO37301",
          name: "ISO37301",
        },
        { id: 5, label: "GBT/T", name: "GBT/T" },
        { id: 6, label: "中央企业合规管理办法", name: "中央企业合规管理办法" },
        { id: 7, label: "和谐关系标准", name: "和谐关系标准" },
        { id: 8, label: "合规义务", name: "合规义务" },
        { id: 9, label: "案例", name: "案例" },
        { id: 10, label: "其他", name: "其他" },
      ],
      form: {
        code: "", // 风险点编码
        name: "", // 风险点名称
        behaviourDescriptions: [], // 风险行为
        infractionConsequences: [], // 违规后果
        type: [], // 风险责任
        riskValue: "", // 风险值
        rank: null, // 风险等级
        postCycle: null, // 职业阶段
        businessModule: null, // 专项类
        enterpriseNature: [], // 适合组织
        enterpriseScale: [], // 组织规模
        matchingReports: [], // 匹配报告
        lawBasis: [], // 劳动法规
        standardISO: [], // ISO37301
        standardGBT: [], // GBT/T
        centralEnterprises: [], // 中央企业合规管理办法
        harmoniousRelationships: [], // 和谐关系标准标准
        riskMeasures: [], // 合规措施
        riskTexts: [], // 合规文件
        institutions: [], // 合规制度
        dutyAmount: null, // 合规义务数量
        tools: [], // 合规工具
        dutyType: 0, // 合规义务种类
        responsiblePerson: "", // 责任人
        typicalCases: [], // 案例
        rectificationSuggestion: "", // 整改建议
        questions: [], // 问题点
        isEnable: true, // 启用状态
        taskList: [], //合规任务
      },
      newriskMeasures: [], //传给后端的riskMeasures
      postCycleList: [
        // 职业阶段数组
        { value: 1, label: "职前" },
        { value: 2, label: "入职" },
        { value: 3, label: "在职" },
        { value: 4, label: "离职" },
        { value: 5, label: "职后" },
      ],
      businessModuleInfo: {},
      businessModuleList: [
        // HR业务模块数组
        { value: 1, label: "招聘甄选" },
        { value: 2, label: "培训开发" },
        { value: 3, label: "绩效管理" },
        { value: 4, label: "薪酬管理" },
        { value: 5, label: "人事规章" },
        { value: 6, label: "劳动关系" },
        { value: 7, label: "组织发展" },
      ],
      typeList: [
        // 风险类型数组
        { value: "行政责任", label: "行政责任" },
        { value: "民事责任", label: "民事责任" },
        { value: "刑事责任", label: "刑事责任" },
      ],
      suitOrgList: [ // 企业类型
        { value: 1, label: "国企" },
        { value: 2, label: "民企" },
        { value: 3, label: "劳务公司" },
        { value: 4, label: "其他组织" },
      ],
      scaleList: [ // 企业规模
        { value: 1, label: "50人以下" },
        { value: 2, label: "50-200人" },
        { value: 3, label: "201-500人" },
        { value: 4, label: "501-1000人" },
        { value: 5, label: "1000人以上" },
      ],
      matchingReportsList: [],
      rankList: [
        // 风险等级数组
        { value: 1, label: "低" },
        { value: 2, label: "中" },
        { value: 3, label: "高" },
      ],
      statusList: [
        // 风险点状态
        { value: 0, label: "有效" },
        { value: 1, label: "失效" },
      ],
      dutyTypeList: [
        { value: 0, label: "强制性" },
        { value: 1, label: "自愿性" },
      ],
      enableList: [
        // 启用状态
        { value: true, label: "是" },
        { value: false, label: "否" },
      ],
      ToolList: [], // 风控数字工具数组
      ossInfo: {
        // oss参数
        BucketName: "ilaas-files",
        expireTime: 120000,
        uploadDir: "ilaas_cms/conformGuide/file/",
      },
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "*",
        dir: "",
      },
      imageUrl: "",
      isFileIdx: "", // 鼠标悬浮哪个文件上
      isFileIdx2: "", // 鼠标悬浮哪个文件上
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 是否显示进度条
      progressFlag2: false, // 是否显示进度条
      upNum: null, // 上传得弹框
      isFile: {}, // 上传中的文件
      fileList: [], // 上传的文件
      filePathList: [], // 文件上传成功的路径
      delFileList: [], // 删除的文件暂存数组
      fileList2: [], // 上传的文件
      filePathList2: [], // 文件上传成功的路径
      delFileList2: [], // 删除的文件暂存数组
      ossSignature: {}, //oss签名
      showDialog: false, // 显示法律监管依据弹框
      detailsInfo: {
        type: "add",
      },
      showDialog2: false, // 显示典型案例弹框
      detailsInfo2: {
        type: "add",
      },
      submitStatus: false,
      showDialog5: false, // 整改措施弹框
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    //打开合规制度
    addComplianceSystemClick() {
      this.detailsInfo4 = {
        type: "add",
      };
      this.showDialog4 = true;
    },
    //提交新增合规制度
    setComplianceSystem(info, idx) {
      this.showDialog4 = false;
      if (idx === "") {
        this.form.institutions.push(info);
      } else {
        this.form.institutions[idx] = info;
      }
    },
    //编辑合规制度
    lookComplianceSystemArr(row, idx) {
      console.log("编辑", row, idx);
      this.detailsInfo4 = {
        type: "edit",
        row: row,
        idx: idx,
      };
      this.showDialog4 = true;
    },
    // 删除合规制度
    delComplianceSystemArr(idx) {
      let that = this;
      let item = that.form.institutions[idx];
      // console.log("this.form.ComplianceSystemArr[idx]", item);
      if (item.subs && item.subs.length > 0) {
        item.subs.forEach((ele) => {
          console.log("ele", ele);
          that.ossDelFile({ filePath: ele.filePath });
        });
      }
      that.form.institutions.splice(idx, 1);
      that.$forceUpdate();
    },
    //打开合规文件
    addcomplianceDocClick() {
      this.detailsInfo3 = {
        type: "add",
      };
      this.showDialog3 = true;
    },
    //提交新增合规文件
    setTypicalCases3(info, idx) {
      console.log("setTypicalCases3", info);
      this.showDialog3 = false;
      if (idx !== "") {
        this.form.riskTexts[idx] = info;
      } else {
        this.form.riskTexts.push(info);
      }
    },
    //编辑合规文件
    lookcomplianceDocList(row, idx) {
      console.log("编辑", row, idx);
      this.detailsInfo3 = {
        type: "edit",
        row: row,
        idx: idx,
      };
      this.showDialog3 = true;
    },
    // 删除合规文件
    delcomplianceDocList(idx) {
      let that = this;
      // console.log(that.form.riskTexts);
      let item = that.form.riskTexts[idx];
      // console.log("this.form.riskTexts[idx]", item);
      if (item.subs && item.subs.length > 0) {
        item.subs.forEach((ele) => {
          // console.log("ele", ele);
          that.ossDelFile({ filePath: ele.filePath });
        });
      }
      that.form.riskTexts.splice(idx, 1);
      that.$forceUpdate();
      // that.$set(that.form, "complianceDocList", newArr);
    },
    // //编辑合规措施
    // editTask(item, index) {
    //   this.MeasuresInfo = {
    //     type: "edit",
    //     row: item,
    //     id: index,
    //   };
    //   this.showDialog5 = true;
    // },
    //删除合规措施
    delTask(idx) {
      this.form.riskMeasures.splice(idx, 1);
    },
    //取消新增合规措施
    closecomplianceMeasures(e) {
      this.complianceMeasuresDialog = e;
    },
    //确定新增合规措施
    confirmcomplianceMeasures(e, data, id) {
      this.complianceMeasuresDialog = e;
      console.log("新增措施", data, id);

      if (id !== "") {
        // console.log("编辑");
        // this.form.riskMeasures[id] = JSON.parse(JSON.stringify(data));
        this.$set(this.form.riskMeasures, id, JSON.parse(JSON.stringify(data)));
        // console.log("编辑结果", this.form.riskMeasures);
        // this.$forceUpdate();
      } else {
        // console.log("新增");
        this.form.riskMeasures.push(data);
      }
    },
    //取消合规文件重命名
    RebackV1(e) {
      this.RenameDialogVisibleV1 = e;
    },
    //确认修改合规文件名
    filenameConfirmV1(e, data) {
      this.RenameDialogVisibleV1 = e;
      console.log("data", data);
      this.fileList[data.id].fileName = data.FileName;
    },
    //取消合规制度文件重命名
    Reback(e) {
      this.RenameDialogVisible = e;
    },
    //确认修改合规制度文件名
    filenameConfirm(e, data) {
      this.RenameDialogVisible = e;
      console.log("data", data);
      this.fileList2[data.id].fileName = data.FileName;
    },

    init() {
      // console.log(this.info);
      this.getTool();
      this.getBusinessModule();
      this.getMatchingReports();
      this.getOss();
    },
    // 获取工具列表
    async getTool() {
      let params = `${1}/${99}`;
      let res = await getRiskTool(params);
      if (res.code == 0) {
        let data = res.result.items;
        console.log(data);
        let list = [];
        data.forEach((item) => {
          let label = item.status ? `${item.title}（未启用）` : item.title;
          list.push({
            id: item.id,
            title: label,
            status: item.status ? true : false,
          });
        });
        this.ToolList = list;
      }
    },
    // 获取业务类型 getRiskBusinessModule
    async getBusinessModule() {
      this.businessModuleList = [];
      // let res = await getBusinessModuleList();
      let res = await getRiskBusinessModule();
      if (res.code == 0) {
        let data = res.result;
        data.forEach((item) => {
          this.businessModuleList.push({
            name: item.name,
            id: item.id,
          });
        });
        if (this.info.type2 == "edit") {
          this.getData();
        }
      }
    },
    // 获取匹配报告
    async getMatchingReports() {
      let params = `1/99`;
      let res = await getRiskMatchingReport(params);
      if (res.code == 0) {
        let data = res.result.items;
        data.map((item) => {
          item.title = item.status ? `${item.title}（未启用）` : item.title;
          item.status = item.status ? true : false;
          return item;
        });
        this.matchingReportsList = data;
      }
    },
    // 获取风险点详情
    async getData() {
      let res = await getRiskPlaceDetail(this.info.id);
      if (res.code == 0) {
        let data = res.result;
        if (data.tools.length > 0) {
          let arr = [];
          data.tools.forEach((item) => {
            arr.push(item.id);
          });
          data.tools = arr;
        }
        if (data.matchingReports.length > 0) {
          let arr = [];
          data.matchingReports.forEach((item) => {
            arr.push(item.id);
          });
          data.matchingReports = arr;
        }
        let newSuitOrg = [];
        data.enterpriseScale.forEach(item => {
          newSuitOrg.push(
            item.id
          )
        })
        data.enterpriseScale = newSuitOrg;
        let newSuitOrg2 = [];
        data.enterpriseNature.forEach(item => {
          newSuitOrg2.push(
            item.id
          )
        })
        data.enterpriseNature = newSuitOrg2;
        if (data.suitOrg) {
          delete data.suitOrg
        }
        this.form = data;
        this.fileList = data.riskTexts;
        this.fileList2 = data.institutions;
        // this.form.complianceDocList=data.riskTexts;//回显
        // this.form.ComplianceSystemArr=data.institutions;//回显
        console.log("fileList2", this.form);
      }
    },
    // 新增风险行为
    addRiskPointBehavior() {
      this.form.behaviourDescriptions.push({
        keySentence: "",
        content: "",
        id: null,
      });
    },
    // 删除风险行为
    delRiskPointBehavior(idx) {
      this.form.behaviourDescriptions.splice(idx, 1);
    },
    // 新增违规后果
    addViolateConsequence() {
      this.form.infractionConsequences.push({ content: "", id: null });
    },
    // 删除违规后果
    delViolateConsequence(idx) {
      this.form.infractionConsequences.splice(idx, 1);
    },
    // 新增合规措施
    addRiskPointMeasure() {
      // this.form.riskMeasures.push({ content: "", id: null });
      // this.complianceMeasuresDialog = true;
      // this.showDialog5 = true;
      this.MeasuresInfo = {
        type: "add",
      };
      this.complianceMeasuresDialog = true;
    },
    //编辑合规措施
    editTask(item, index) {
      this.MeasuresInfo = {
        type: "edit",
        row: item,
        id: index,
      };
      this.complianceMeasuresDialog = true;
    },
    // 删除合规措施
    delRiskPointMeasure(idx) {
      this.form.riskMeasures.splice(idx, 1);
    },
    // 新增问题点
    addQuestions() {
      this.form.questions.push({ content: "", id: null });
    },
    // 删除问题点
    delQuestions(idx) {
      this.form.questions.splice(idx, 1);
    },
    // 新增典型案例
    addTypicalCases() {
      // this.form.typicalCases.push(
      //   {title: "", url: ""}
      // )
      this.detailsInfo2 = {
        type: "add",
      };
      this.showDialog2 = true;
    },
    setTypicalCases(info, idx) {
      // console.log(info);
      this.showDialog2 = false;
      if (idx === "") {
        this.form.typicalCases.push(info);
      } else {
        this.form.typicalCases[idx] = info;
      }
    },
    lookTypicalCases(row, idx) {
      this.detailsInfo2 = {
        type: "edit",
        row: row,
        idx: idx,
      };
      this.showDialog2 = true;
    },
    // 删除典型案例
    delTypicalCases(idx) {
      let item = this.form.typicalCases[idx];
      if (item.filePath) {
        this.ossDelFile({ filePath: item.filePath });
      }
      this.form.typicalCases.splice(idx, 1);
    },
    // 新增法律依据
    addUpervisePursuant(num) {
      let name = "";
      if (num == 2) {
        name = "ISO37301";
      } else if (num == 3) {
        name = "GBT/T";
      } else if (num == 4) {
        name = "中央企业合规管理办法";
      } else if (num == 5) {
        name = "和谐关系标准";
      } else {
        name = "劳动法规";
      }
      this.detailsInfo = {
        type: "add",
        name: name,
        num: num,
      };
      this.showDialog = true;
    },
    setLawBasis(info, idx) {
      this.showDialog = false;
      if (this.detailsInfo.num == 2) {
        if (idx === "") {
          this.form.standardISO.push(info);
        } else {
          this.form.standardISO[idx] = info;
        }
      } else if (this.detailsInfo.num == 3) {
        if (idx === "") {
          this.form.standardGBT.push(info);
        } else {
          this.form.standardGBT[idx] = info;
        }
      } else if (this.detailsInfo.num == 4) {
        if (idx === "") {
          this.form.centralEnterprises.push(info);
        } else {
          this.form.centralEnterprises[idx] = info;
        }
      } else if (this.detailsInfo.num == 5) {
        if (idx === "") {
          this.form.harmoniousRelationships.push(info);
        } else {
          this.form.harmoniousRelationships[idx] = info;
        }
      } else {
        if (idx === "") {
          this.form.lawBasis.push(info);
        } else {
          this.form.lawBasis[idx] = info;
        }
      }
    },
    lookLawBasis(row, idx, num) {
      let name = "";
      if (num == 2) {
        name = "ISO37301";
      } else if (num == 3) {
        name = "GBT/T";
      } else if (num == 4) {
        name = "中央企业合规管理办法";
      } else if (num == 5) {
        name = "和谐关系标准";
      } else {
        name = "劳动法规";
      }
      this.detailsInfo = {
        type: "edit",
        row: row,
        idx: idx,
        name: name,
        num: num,
      };
      this.showDialog = true;
    },
    // 删除法律依据
    delLawBasis(idx) {
      if (this.detailsInfo.num == 2) {
        this.form.standardISO.splice(idx, 1);
      } else if (this.detailsInfo.num == 3) {
        this.form.standardGBT.splice(idx, 1);
      } else if (this.detailsInfo.num == 4) {
        this.form.centralEnterprises.splice(idx, 1);
      } else if (this.detailsInfo.num == 5) {
        this.form.harmoniousRelationships.splice(idx, 1);
      } else {
        this.form.lawBasis.splice(idx, 1);
      }
    },
    // 获取oss签名
    async getOss() {
      let res = await getPolicyToken(this.ossInfo);
      if (res.code === 200) {
        let data = JSON.parse(res.result);
        this.ossSignature = data;
      }
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      // let newArr = file.name.split(".");
      // let fileType = newArr[newArr.length - 1];
      let imgType = [
        "doc",
        "docx", // Word
        "xls",
        "xlsx", // Excel
      ];
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!imgType.includes(fileType)) {
        this.$message.warning("上传文件只能是 Word、Excel 格式文件!");
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 50MB!");
        return;
      }
      this.objData = {
        OSSAccessKeyId: this.ossSignature.accessid,
        policy: this.ossSignature.policy,
        signature: this.ossSignature.signature,
        expire: this.ossSignature.expire,
        host: this.ossSignature.host,
        dir: this.ossSignature.dir,
        key: `ilaas_cms/conformGuide/file/yw-0/${new Date().getTime()}-${
          file.name
        }`,
      };
      // this.filePathList.push(this.objData.key);
      this.isFile = {
        fileName: file.name,
        filePath: this.objData.key,
        bucketName: "ilaas-files",
      };
    },
    // 点击得哪个上传按钮
    clickUp(num) {
      this.upNum = num;
    },
    // 上传文件进度
    uploadVideoProcess(event, file, fileList) {
      if (this.upNum == 2) {
        this.progressFlag2 = true; // 显示进度条
      } else {
        this.progressFlag = true; // 显示进度条
      }
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          if (this.upNum == 2) {
            this.progressFlag2 = false;
          } else {
            this.progressFlag = false;
          }
        }, 1000); // 一秒后关闭进度条
      }
    },
    // 上传成功事件
    upSuccess() {
      if (this.upNum == 2) {
        this.fileList2.push(this.isFile);
        this.filePathList2.push(this.isFile.filePath);
      } else {
        this.fileList.push(this.isFile);
        this.filePathList.push(this.isFile.filePath);
      }
    },
    handleClick() {
      // console.log(this.fileList.length);
      // console.log(this.activeName,'activeName');
    },
    // 上传失败事件
    upError() {
      // this.$message.warning(`文件上传失败，请尝试重新上传！`);
    },
    // 鼠标悬浮文件上
    mouseOver(idx, num) {
      if (num == 2) {
        this.isFileIdx2 = idx;
      } else {
        this.isFileIdx = idx;
      }
    },
    // 离开文件
    mouseLeave(num) {
      if (num == 2) {
        this.isFileIdx2 = "";
      } else {
        this.isFileIdx = "";
      }
    },
    // 下载客户文件
    download(item) {
      let client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossSignature.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-files",
      });
      let filename = item.fileName;
      let response = {
        "content-disposition": `attachment; filename=${encodeURIComponent(
          filename
        )};expires: 7200`,
      };
      let url = client.signatureUrl(item.filePath, {
        response,
      });
      window.location.href = url;
    },
    // 删除客户文件
    delFile(item, idx, num) {
      this.$confirm(
        `删除文件不可修复，请确认是否删除文件【 ${item.fileName} 】`
      )
        .then((_) => {
          if (num == 2) {
            this.fileList2.splice(idx, 1);
          } else {
            this.fileList.splice(idx, 1);
          }
          this.ossDelFile(item);
        })
        .catch((_) => {});
    },
    ossDelFile(item) {
      let client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossSignature.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-files",
      });
      client.delete(item.filePath);
    },
    //修改文件名
    editFile(item, idx, num) {
      console.log("修改文件", item, idx);
      if (num == 1) {
        this.FilenameV1 = item.fileName;
        this.fileIdV1 = idx;
        this.RenameDialogVisibleV1 = true;
      } else {
        this.Filename = item.fileName;
        this.fileId = idx;
        this.RenameDialogVisible = true;
      }
    },

    // 设置文件图片
    selectImg(info) {
      console.log("selectImg", info);
      let imgUrl = "";
      // let valList = info.split(".");
      // let fileType = valList[valList.length - 1];
      // if (fileType == "doc" || fileType == "docx") {
      //   imgUrl = require("@/assets/img/file-word.png");
      // } else if (fileType == "xls" || fileType == "xlsx") {
      //   imgUrl = require("@/assets/img/file-excel.png");
      // }
      return imgUrl;
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    submit() {
      let verifyStatus = false;
      let verifyObj = {
        name: "风险点名称",
        // riskValue: "风险值",
        postCycle: "职业阶段",
        businessModule: "专项类",
        rank: "风险等级",
        // suitOrg: "适合组织",
      };
      for (let key in verifyObj) {
        if (!this.form[key]) {
          verifyStatus = true;
          // this.$message.warning(`${verifyObj[key]} 不能为空！`);  
          // return;
        }
      }
      if (this.form.type.length < 1 || this.form.enterpriseNature.length < 1 || this.form.enterpriseScale.length < 1) {
        verifyStatus = true;
        // this.$message.warning(`风险类型 不能为空！`);
        // return;
      }
      if (this.form.riskMeasures.length < 1) {
        verifyStatus = true;
      }
      let verifyObj2 = {
        behaviourDescriptions: "风险源",
        infractionConsequences: "违规后果",
        // riskMeasures: "合规措施",
      };
      for (let key in verifyObj2) {
        if (this.form[key].length > 0) {
          let arr = this.form[key];
          for (let i = 0; i < arr.length; i++) {
            let item = arr[i];
            if (!item.content) {
              verifyStatus = true;
              // this.$message.warning(`${verifyObj2[key]} 第  ${i + 1}  条内容不能为空！`);
              // return;
            }
          }
        } else {
          verifyStatus = true;
          // this.$message.warning(`至少增加1条 ${verifyObj2[key]}！`);
          // return;
        }
      }
      if (this.form.lawBasis.length < 1) {
        verifyStatus = true;
        // this.$message.warning(`至少增加1条法律监管依据！`);
        // return;
      }
      if (verifyStatus) {
        this.$confirm(
          `部分必填项为空，是否继续保存？保存后启用状态将被设定为不启用！`
        )
          .then((_) => {
            this.form.isEnable = false;
            this.confirmSubmit();
          })
          .catch((_) => {});
      } else {
        this.confirmSubmit();
      }
      // this.form.riskTexts = this.fileList;
      // let params = JSON.parse(JSON.stringify(this.form));
      // // console.log(params);return
      // let res = {};
      // if (this.info.type2 == 'add') {
      //   res = await addRiskPlace(params);
      // } else {
      //   res = await editRiskPlace(params);
      // }
      // if (res.code == 200) {
      //   this.$message({
      //     message: '保存成功',
      //     type: 'success'
      //   });
      //   this.$parent.init();
      //   this.cancel();
      // }
    },
    async confirmSubmit() {
      if (this.submitStatus) return;
      this.form.riskMeasures.forEach((item) => {
        item.subs.forEach((It) => {
          let toolArr = [];
          It.tools.forEach((ele) => {
            toolArr.push(ele.id);
          });
          It.tools = JSON.parse(JSON.stringify(toolArr));
        });
      });
      // this.form.riskMeasures = JSON.parse(JSON.stringify(this.newriskMeasures)); //重新赋值
      let params = JSON.parse(JSON.stringify(this.form));
      // console.log('88--------------------',params);
      // return
      this.submitStatus = true;
      let res = {};
      if (this.info.type2 == "add") {
        res = await addRiskPlace(params);
      } else {
        res = await editRiskPlace(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-contacts-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .title-p {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
        }

        .row-box {
          display: flex;
          // align-items: center;
          margin-bottom: 20px;

          .label {
            // min-width: 120px;
            width: 8%;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            width: 90%;
            .el-input__inner {
              white-space: pre-wrap;
              word-break: break-all;
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
          .el-textarea {
            width: 40%;
          }
          .el-select {
            width: 40%;
          }
          .min-select {
            width: 19%;
          }
          .vals-box {
            width: 90%;
            .icon-box {
              i {
                font-size: 30px;
                color: green;
                cursor: pointer;
              }
            }
            .border-box {
              width: 100%;
              // max-height: 200px;
              // overflow-y: auto;
              // padding: 16px 10px;
              // box-sizing: border-box;
              // border: 1px solid #ccc;
              margin-top: 10px;
              .card-box {
                width: 100%;
                // display: flex;
                // align-items: center;
                // justify-content: space-between;
                margin-bottom: 10px;
                padding: 16px 10px;
                box-sizing: border-box;
                border: 1px solid #ccc;
                .label-p {
                  font-size: 16px;
                  font-weight: 600;
                  color: #111;
                  margin-bottom: 10px;
                }
                .main {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 10px;
                  .tool {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .el-icon-remove-outline {
                      font-size: 24px;
                      margin-left: 10px;
                    }
                  }
                }
                .el-textarea {
                  width: 94%;
                  ::v-deep .el-textarea__inner {
                    line-height: 22px !important;
                    letter-spacing: 2px !important;
                  }
                }
                span {
                  font-size: 14px;
                  color: #ccc;
                  white-space: nowrap;
                }
                .ipt1 {
                  width: 30%;
                  margin-right: 20px;
                }
                .ipt2 {
                  width: 50%;
                }
                i {
                  font-size: 30px;
                  color: red;
                  cursor: pointer;
                }
              }
              .card-box2 {
                // flex-direction: column;
                align-items: center;
                margin-bottom: 16px;
                .vals-box {
                  width: 100%;
                  .el-textarea {
                    width: 96%;
                  }
                  .short-ipt {
                    width: 48%;
                    margin-bottom: 5px;
                  }
                }
              }
            }
            .col-boxs {
              margin-top: 5px;
              .head-row {
                .el-col {
                  font-weight: 600;
                  padding: 5px;
                  box-sizing: border-box;
                  background-color: #cce4f5;
                }
              }
              .row-son {
                .el-col {
                  display: flex;
                  align-items: center;
                  padding: 5px;
                  box-sizing: border-box;
                  background-color: #f5f7fa;
                  .span-val {
                    color: #333;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                  }
                  span {
                    color: rgba(0, 148, 255, 0.7);
                    cursor: pointer;
                  }
                  i {
                    font-size: 24px;
                    color: red;
                    cursor: pointer;
                    margin-left: 5px;
                  }
                }
              }
            }

            .add-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              // width: 24%;
              width: 190px;
              height: 115px;
              margin-right: 1%;
              margin-bottom: 14px;
              border-radius: 10px;
              background: #f0f3fb;
              cursor: pointer;
              img {
                width: 30px;
                height: 30px;
                margin-bottom: 10px;
              }
              p {
                font-size: 14px;
                color: #6474c7;
              }
              span {
                font-size: 12px;
                color: #ccc;
                margin-top: 10px;
              }
            }
            .file-box {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              width: 100%;
              .file-card {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 24%;
                padding: 20px;
                box-sizing: border-box;
                border-radius: 18px;
                border: 1px solid #dddfe6;
                margin-right: 1%;
                margin-bottom: 16px;
                p {
                  width: 90%;
                  font-size: 14px;
                  text-align: center;
                  color: #333;
                  margin-top: 10px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                .shadow-box {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  // height: 200px;
                  height: 100%;
                  color: #fff;
                  background-color: rgba(63, 65, 77, 0.5);
                  z-index: -1;
                  border-radius: 18px;
                  overflow: hidden;
                }
                .el-progress {
                  width: 80%;
                }
              }
              .is-file-card {
                .shadow-box {
                  z-index: 9;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  button {
                    width: 80px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 20px;
                    border: none;
                    background-color: #fff;
                    color: #333;
                    font-size: 14px;
                    margin: 0 5px;
                  }
                }
              }
            }
          }
        }
        .vals-box2,
        .vals-box3 {
          width: 100%;
        }
        .row-box2,
        .row-box3 {
          .el-icon-circle-plus-outline {
            font-size: 30px;
            color: green;
          }
          .col-boxs {
            margin-top: 10px;
            width: 100%;
            .head-row {
              .el-col {
                font-weight: 600;
                padding: 5px;
                box-sizing: border-box;
                background-color: #cce4f5;
              }
            }
            .row-son {
              .el-col-4 {
                display: inline-flex !important;
                flex-direction: row !important;
                align-items: center !important;
                justify-content: flex-start !important;
              }
              .el-col {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding: 5px;
                box-sizing: border-box;
                background-color: #f5f7fa;
                line-height: 30px;
                .subClass {
                  width: 80%;
                }
                .span-val {
                  color: #333;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
                span {
                  color: rgba(0, 148, 255, 0.7);
                  cursor: pointer;
                }
                i {
                  font-size: 24px;
                  color: red;
                  cursor: pointer;
                  margin-left: 5px;
                }
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}

// 滚动条整体部分
::-webkit-scrollbar {
  width: 4px; //对垂直方向滚动条
  height: 4px; //对水平方向滚动条
}

//滚动的滑块
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ccc; //滚动条的颜色
}

//内层滚动槽
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
</style>
