<template>
  <div class="labor-laws-calculator">
    <h1>劳动法计算器</h1> 
  </div>
</template>

<script>
export default {
  name: "laborLawsCalculator",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .labor-laws-calculator {
  }
</style>

