<template>
  <div class="risk-tool-dialog">
    <el-dialog
      title="风控数字工具"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="460px">
      <div class="row-box">
        <p class="label">
          <span>* </span>
          工具名称：
        </p>
        <el-input placeholder="请输入工具名称" v-model="form.title"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          工具路由：
        </p>
        <el-input placeholder="请输入工具路由" v-model="form.route"></el-input>
      </div>
      <div class="row-box" v-if="form.status !== ''">
        <p class="label">
          启用状态：
        </p>
        <el-switch
          v-model="form.status"
          active-color="#aaaaaa"
          inactive-color="#13ce66">
        </el-switch>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addRiskTool, editRiskTool } from "@/api/knowledgeData.js"
export default {
  name: "riskToolDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        title: "",
        route: "",
        status: "",
      },
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      if (this.info.type == 'edit') {
        this.form = JSON.parse(JSON.stringify(this.info.row));
        this.form.status = this.form.status ? true:false;
      }
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (this.submitStatus) return;
      if (!this.form.title) {
        this.$message.warning(`工具名称不能为空！`);
        return;
      }
      let params = {
        title: this.form.title,
        route: this.form.route,
      };
      this.submitStatus = true;
      let res = {};
      if (this.info.type == 'add') {
        res = await addRiskTool(params);
      } else {
        params = this.form;
        params.status = params.status ? 1:0;
        res = await editRiskTool(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.init();
        this.cancel();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.risk-tool-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
