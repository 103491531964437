<template>
<!-- 订单管理 -->
  <div class="compliance-order-form-management">
    <router-view class="children-view"></router-view>
  </div>
</template>

<script>
export default {
  name: "complianceOrderFormManagement",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .compliance-order-form-management {
  }
</style>

