<template>
  <div class="add-route-dialog">
    <el-dialog
      title="导航菜单"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="460px">
      <div class="row-box">
        <p class="label">
          <span>* </span>
          菜单名称：
        </p>
        <el-input placeholder="请输入菜单名称" v-model="form.name"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          路由名称：
        </p>
        <el-input placeholder="请输入路由名称" v-model="form.identity"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          文件位置：
        </p>
        <el-input placeholder="请输入文件位置" v-model="form.route"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          排序：
        </p>
        <el-input placeholder="请输入排序" v-model="form.order"></el-input>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addMenu, editMenu } from "@/api/setting.js"
export default {
  name: "addRouteDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        identity: "", // 路由名称
        name: "",  // 菜单名称
        parentId: null,  // 菜单id
        iconUrl: "",  // 菜单图标
        route: "",  // 文件位置
        order: null, // 排序
      },
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // console.log(this.info);
      if (this.info.type == 'edit') {
        this.form = JSON.parse(JSON.stringify(this.info.item));
      } else {
        this.form.parentId = this.info.parentId;
      }
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (this.submitStatus) return;
      let verifyObj = {
        name: '菜单名称',
        identity: '路由名称',
        route: '文件位置',
      }
      for (let key in verifyObj) {
        if (!this.form[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      let params = JSON.parse(JSON.stringify(this.form));
      params.order = params.order || null;
      // console.log(params);return
      this.submitStatus = true;
      let res = {};
      if (this.info.type == 'add') {
        res = await addMenu(params);
      } else {
        res = await editMenu(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-route-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
