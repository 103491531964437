<template>
  <div class="labor-laws-parameters">
    <router-view class="children-view"></router-view>
  </div>
</template>

<script>
export default {
  name: "laborLawsParameters",
  components: {
  },
  data() {
    return {
      showTable: true,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .labor-laws-parameters {
  }
</style>

