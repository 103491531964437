<template>
  <div class="labour-harmonious-law-dialog">
    <el-dialog
      title="关联法规"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      width="960px"
    >
      <div class="row-box">
        <p class="label">
          <span>* </span>
          法规名称：
        </p>
        <el-input
          type="textarea"
          autosize
          placeholder="选择列表法规名称自动带出"
          v-model="form.title"
          disabled
        ></el-input>
      </div>
      <div class="row-box search-box">
        <div class="span">范围：</div>
        <el-cascader
          v-model="regionId"
          :options="scopeList"
          :props="props"
          filterable
          @change="search"
        >
        </el-cascader>
        <div class="span">法规类型：</div>
        <el-select
          v-model="type"
          filterable
          placeholder="选择法规类型"
          clearable
          @change="search"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
             @change="search"
          >
          </el-option>
        </el-select>
        <el-input placeholder="输入法规标题" v-model="keyVal"></el-input>
        <el-button @click="search">搜索</el-button>
      </div>
      <keep-alive>
        <div class="table-container" v-if="!detailsStatus">
          <el-table
            :height="tableHeight"
            border
            :data="tableData"
            style="width: 100%"
            v-loading="loading"
            fit
          >
            <template v-for="(head, index) in headData">
              <el-table-column
                :align="head.align"
                v-if="head.label === '选择'"
                :key="index"
                :prop="head.prop"
                :label="head.label"
                :width="head.width"
              >
                <template slot-scope="scope">
                  <!-- {{scope.$index+1}} -->
                  <el-checkbox
                    v-model="scope.row.check"
                    @change="check(scope.row.id)"
                    >{{ scope.$index + 1 }}</el-checkbox
                  >
                </template>
              </el-table-column>
              <el-table-column
                :align="head.align"
                v-else-if="head.label === '操作'"
                :key="index"
                :prop="head.prop"
                :label="head.label"
                :width="head.width"
              >
                <template slot-scope="scope">
                  <span
                    style="color: #6aa9fb; cursor: pointer"
                    @click="lookDetails(scope.row)"
                  >
                    查看
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                v-else
                :align="head.align"
                :key="index"
                :prop="head.prop"
                :label="head.label"
                :width="head.width"
                show-overflow-tooltip
              ></el-table-column>
            </template>
          </el-table>
          <div>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 15, 20]"
              :page-size="interPage"
              layout="total, sizes, prev, pager, next, jumper"
              background
              :total="dataSize"
            >
            </el-pagination>
          </div>
        </div>
      </keep-alive>
      <div class="details-box" v-if="detailsStatus">
        <el-button @click="goBack">返回</el-button>
        <div class="content-box">
          <template v-if="JSON.stringify(detailsInfo) != '{}'">
            <div class="lawLeft">
              <p>
                <span>文件名称：</span>
                {{ detailsInfo.title }}
              </p>
              <p v-if="detailsInfo.publicationNumber">
                <span>发文字号：</span>
                {{ detailsInfo.publicationNumber }}
              </p>
              <p v-if="detailsInfo.promulgateMechanism">
                <span>发布单位：</span>
                {{ detailsInfo.promulgateMechanism }}
              </p>
              <p v-if="detailsInfo.promulgateDate">
                <span>公布日期：</span>
                {{ detailsInfo.promulgateDate }}
              </p>
              <p v-if="detailsInfo.executeDate">
                <span>施行日期：</span>
                {{ detailsInfo.executeDate }}
              </p>
              <p v-if="detailsInfo.abolishDate">
                <span>废止日期：</span>
                {{ detailsInfo.abolishDate }}
              </p>
              <p v-if="detailsInfo.reviseDate">
                <span>修订日期：</span>
                {{ detailsInfo.reviseDate }}
              </p>
              <p>
                <span>标签：</span>
                {{ detailsInfo.regionName }} &nbsp;&nbsp;&nbsp;
                {{ setLegalEffect(detailsInfo.legalEffect) }}
              </p>
              <p v-if="detailsInfo.url">
                <span>数据来源：</span>
                <a class="source" :href="detailsInfo.url" target="_blank"
                  >查看</a
                >
              </p>
            </div>
            <div class="lawRight">
              <p class="html-p" v-html="detailsInfo.content"></p>
            </div>
          </template>
          <span v-else style="text-align: center">暂无数据</span>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import { getLaborLawsList, getLaborLawsDetail,getLaborLawsRegion } from "@/api/knowledgeData";
export default {
  name: "labourHarmoniousLawDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        title: "", // 标准名称
      },
      regionId: "", // 范围
      type: null, // 法规类型
      keyVal: "",
      dataSize: 0,
      currentPage: 1,
      interPage: 15,
      tableHeight: 500,
      loading: false,
      tableData: [],
      headData: [
        { align: "center", prop: "选择", label: "选择", width: "100px" },
        { align: "left", prop: "title", label: "标题", width: "" },
        { align: "center", prop: "regionName", label: "范围", width: "100px" },
        {
          align: "center",
          prop: "promulgateDate",
          label: "颁布日期",
          width: "100px",
        },
        {
          align: "center",
          prop: "createTime",
          label: "添加日期",
          width: "100px",
        },
        { align: "center", prop: "操作", label: "操作", width: "100px" },
      ],
      detailsStatus: false, // 显示详情页
      detailsInfo: {}, // 详情数据
      legalEffectList: [
        // 法律效力位级数组
        { value: 1, label: "国家法律" },
        { value: 2, label: "国家行政法规" },
        { value: 3, label: "国家部门规章" },
        { value: 4, label: "国家规范性文件" },
        { value: 5, label: "地方行政法规" },
        { value: 6, label: "地方部门规章" },
        { value: 7, label: "地方规范性文件" },
      ],
      scopeList: [], // 范围数组
      props: {
	      label: 'name',
        value: 'id',
        children: 'childs',
	      expandTrigger: 'hover'
      },
      typeList: [ // 法规类型数组
        {value: 1, label: "ISO"},
        {value: 2, label: "GBT"},
        {value: 3, label: "中央企业合规管理办法"},
        {value: 4, label: "和谐关系"},
      ],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      // if (this.info.lawId) {
      //   this.form.title = this.info.lawName;
      //   this.keyVal = this.info.lawName;
      // }
      this.getRegion();
      this.getData();
    },
     // 获取范围
     async getRegion() {
      this.scopeList = [];
      let res = await getLaborLawsRegion();
      if (res.code === 0) {
        this.scopeList = res.result;
        this.estimateArr(this.scopeList);
        console.log('scopeList',this.scopeList);
      }
    },
    estimateArr(arr) {
      // console.log(arr,'arr');
      if (arr.length < 1)return
      arr.forEach(item => {
        let str = JSON.stringify(item.childs)
        // console.log(str,'strstrstrs');
        if (str === "[]") {
          item.childs = null;
        } else if (str != "null" && str != "[]") {
          this.estimateArr(item.childs)
        }
      })
    },
    // 获取法规列表
    async getData() {
      let params = `${this.currentPage}/${this.interPage}`;
      if (this.keyVal || this.type || this.regionId) {
        params += "?";
        if (this.keyVal) {
          params = `${params}queryString=${this.keyVal}&`;
        }
        if (this.type) {
          params = `${params}type=${this.type}&`;
        }
        if (this.regionId) {
          let key = this.regionId[this.regionId.length - 1]
          params = `${params}regionId=${key}&`;
        }
        params= params.slice(0, -1);
      }
      // console.log(params,'paramsparamsparams');
      this.loading = true;
      let res = await getLaborLawsList(params);
      this.loading = false;
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        data.map((item) => {
          item.promulgateDate =
            item.promulgateDate == null
              ? ""
              : item.promulgateDate.split("T")[0];
          item.createTime = item.createTime.split("T")[0];
          item.check = false;
          // if (this.info.lawId) {
          //   item.check = item.id == this.info.lawId ? true:false;
          // }
          return item;
        });
        this.tableData = res.result.items;
      }
    },
    // 搜索
    search() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;

      this.getData();
    },
    // 选择法规
    check(id) {
      this.tableData.forEach((item) => {
        if (id != item.id) {
          item.check = false;
        } else {
          this.form = item;
        }
      });
    },
    // 返回列表
    goBack() {
      this.detailsStatus = false;
    },
    // 查看法规详情
    lookDetails(row) {
      this.detailsStatus = true;
      this.getDetail(row.id);
    },
    async getDetail(id) {
      this.detailsInfo = {};
      let loadingInstance = Loading.service({ fullscreen: true });
      let res = await getLaborLawsDetail(id);
      loadingInstance.close();
      if (res.code === 0) {
        let data = res.result;
        data.promulgateDate = data.promulgateDate
          ? data.promulgateDate.split("T")[0]
          : "";
        data.executeDate = data.executeDate
          ? data.executeDate.split("T")[0]
          : "";
        data.abolishDate = data.abolishDate
          ? data.abolishDate.split("T")[0]
          : "";
        data.reviseDate = data.reviseDate ? data.reviseDate.split("T")[0] : "";
        this.detailsInfo = data;
      }
    },
    setLegalEffect(num) {
      let str = "";
      for (let i = 0; i < this.legalEffectList.length; i++) {
        let item = this.legalEffectList[i];
        if (item.value == num) {
          str = item.label;
          break;
        }
      }
      return str;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    cancel() {
      this.$emit("cancelLaw");
    },
    async submit() {
      let status = false;
      this.tableData.map((item) => {
        if (item.check) {
          status = true;
        }
      });
      if (!status) {
        this.$message.warning(`请选择法规！`);
        return;
      }
      this.$emit("selectLaw", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.labour-harmonious-law-dialog {
  .el-dialog__wrapper {
    margin-top: -100px;
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
          .law-p {
            color: #0094ff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
        .search-box {
          display: flex;
          align-items: center;
          justify-content: center;
          .span {
            white-space: nowrap;
          }
          .el-select,.el-cascader {
            margin: 0 10px;
          }
          .selects {
            max-width: 350px;
          }
          .el-input {
            width: 48%;
          }
          // .el-input {
          //   width: 30%;
          // }
          .el-button {
            font-weight: 600;
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            margin-left: 10px;
          }
        }
        .table-container {
          height: 400px;
          :v-deep .el-table__body {
            height: 400px;
          }
        }
        .details-box {
          .el-button {
            height: 32px;
            border-radius: 16px;
            color: rgba(0, 148, 255, 0.7);
            border: 1px solid rgba(0, 148, 255, 0.7);
            margin-bottom: 5px;
          }
          .content-box {
            display: flex;
            justify-content: space-between;
            height: 400px;
            overflow-y: auto;
            margin: 0 32px;
            .lawLeft {
              width: 25%;
              background-color: #f5f7fa;
              padding: 20px;
              box-sizing: border-box;
              border-radius: 10px;
              p {
                font-size: 14px;
                color: #000;
                line-height: 36px;
                margin-bottom: 10px;
                span {
                  font-size: 14px;
                  font-weight: 600;
                }
                .source {
                  font-size: 14px;
                  font-weight: 400;
                  color: #000;
                  cursor: pointer;
                }
              }
            }
            .lawRight {
              width: 73%;
              background-color: #f5f7fa;
              padding: 80px;
              box-sizing: border-box;
              border-radius: 10px;
              p {
                font-size: 14px;
                color: #000;
                line-height: 36px;
                margin-bottom: 10px;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
