<template>
  <div class="addComplianceMeasures">
    <el-dialog
      title="合规措施"
      :visible.sync="ShowcomplianceMeasuresDialog"
      width="60%"
      center
      :show-close="false"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
    >
      <div class="content">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
          label-position="top"
        >
          <el-form-item label="合规措施标题" prop="title">
            <el-input
              type="textarea"
              autosize
              v-model="ruleForm.title"
              placeholder="请输入合规措施标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="合规措施描述" prop="description">
            <el-input
              type="textarea"
              autosize
              v-model="ruleForm.description"
              placeholder="请输入合规措施"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="icon-box">
          <i class="el-icon-circle-plus-outline" @click="addTask"></i>
        </div>
        <div class="border-box" v-if="taskList.length > 0">
          <div class="cardBox" v-for="(item, index) in taskList" :key="index">
            <div class="left">
              <div class="left1">
                <div class="title">任务：</div>
                <el-input
                  class="textbox"
                  type="textarea"
                  placeholder="请输入合规任务"
                  autosize
                  v-model="item.content"
                ></el-input>
              </div>
              <div class="left2">
                <div class="title">合规工具：</div>
                <!--  @change="((val)=>{getSelected(val,index)})" -->
                <el-select
                  v-model="item.tools"
                  multiple
                  placeholder="请选择合规工具"
                  value-key="title"
                >
                  <el-option
                    v-for="item in ToolList"
                    :disabled="item.status"
                    :key="item.id"
                    :label="item.title"
                    :value="item"
                  >
                  </el-option>
                </el-select>
                <!-- 下拉多选组件调用 -->
                <!-- <selectInput :liData="liData" @change="((val)=>{change(val,index)})" />
                <div>
                  当前选中：<span
                    v-for="(item, index) in selectName[index]"
                    :key="item.id"
                    >{{ item.title }}</span
                  >
                </div> -->
              </div>
            </div>
            <div class="right">
              <i class="el-icon-remove-outline" @click="delTask(index)"></i>
            </div>
          </div>
        </div>
      </div>

      <!-- <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button @click="close">取 消</el-button>
        <el-button type="primary" @click="confirm('ruleForm')">保存</el-button>
      </span> -->
      <div class="btns-box">
        <button class="btn" @click="close">取 消</button>
        <button class="btn2" @click="confirm('ruleForm')">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import selectInput from "./multipleSelection.vue";
export default {
  name: "addComplianceMeasures",
  components: {
    selectInput,
  },

  props: {
    complianceMeasuresDialog: {
      type: Boolean,
      default: false,
    },
    ToolList: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
    },
  },
  data() {
    return {
      liData: [
        {
          Id: 1,
          Name: "孙小胖1",
          Check: false,
        },
        {
          Id: 2,
          Name: "孙小胖2",
          Check: false,
        },
      ],
      selectName: "",

      _idx: null, //tools下标
      ShowcomplianceMeasuresDialog: false,
      ruleForm: {
        title: "",
        description: "",
      },
      rules: {
        title: [{ required: true, message: "请输入合规措施标题", trigger: "blur" }],
        description: [{ required: true, message: "请输入合规措施描述", trigger: "blur" }],
      },
      taskList: [
        {
          content: "",
        tools: [],
        id: null,
        }
      ],
      MeasuresInfo: {},
      labelList: [],
      showTools: [],
    };
  },
  watch: {
    complianceMeasuresDialog(newVal) {
      this.ShowcomplianceMeasuresDialog = newVal;
    },
    info(newVal) {
      console.log("newValibfo", JSON.parse(JSON.stringify(newVal)));
      this.MeasuresInfo = JSON.parse(JSON.stringify(newVal));
      this.init();
    },
  },
  created() {
    console.log("created");
  },
  mounted() {
    console.log("mounted", this.taskList);
    console.log("ToolList", this.ToolList);
  },
  methods: {
    // 下拉多选传值展示
    change(val, index) {
      console.log("change", val, index);
      this.selectName = val;
    },

    partTypesClick(e) {
      console.log("333", e);
      this._idx = e;
    },
    init() {
      if (this.MeasuresInfo.type == "edit") {
        console.log("this.MeasuresInfo", this.MeasuresInfo);
        this.ruleForm.title = this.MeasuresInfo.row.title;
        this.ruleForm.description = this.MeasuresInfo.row.description;
        this.taskList = JSON.parse(JSON.stringify(this.MeasuresInfo.row.subs));
        return;
        if (this.MeasuresInfo.row.subs.length > 0) {
          this.MeasuresInfo.row.subs.forEach((item) => {
            if (item.tools.length > 0) {
              let Arr = [];
              item.tools.forEach((ele) => {
                Arr.push(ele.title);
              });
              item.tools = Arr;
              console.log("))))", item.tools);
            }
          });
          this.taskList = JSON.parse(
            JSON.stringify(this.MeasuresInfo.row.subs)
          );
          console.log("  this.taskList", this.taskList);
        }
      }
    },

    //重置弹窗
    resetData() {
      this.ruleForm = {
        title: "",
        description: "",
      };
      this.taskList = [];
      this.showTools = [];
    },
    //取消
    close() {
      this.$emit("closecomplianceMeasures", false);
      this.resetData(); //重置表单
    },
    //获取工具
    getSelected(val, index) {
      console.log("33", val, index);
      let label_arr = []; //要放入的label数组
      let list = JSON.parse(JSON.stringify(this.ToolList)); //你所有的选项数组
      val.forEach((item) => {
        // console.log("itemeeee", item);
        list.forEach((Iitem) => {
          // console.log("Iitem", Iitem);
          if (item == Iitem.value) {
            //选中的label
            label_arr.push({
              id: Iitem.value,
              title: Iitem.label,
            });
          }
        });
      });
      console.log("label_arr", label_arr);
      this.labelList = label_arr;
      this.taskList[index].tools = this.labelList;
    },

    // 确定新增
    confirm(ruleForm) {
      // return
      let verify = false;
      if (this.taskList.length > 0) {
        this.taskList.forEach((item) => {
          if (item.content == "") {
            this.$message({
              message: "请填写任务",
              type: "warning",
            });
            verify = true;
          }
        });
      }
      if (verify) return
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          console.log("合规措施3>>>", this.taskList, this.ruleForm);
          let data = {
            title: JSON.parse(JSON.stringify(this.ruleForm.title)),
            description: this.ruleForm.description,
            id: null,
            subs: JSON.parse(JSON.stringify(this.taskList)),
          };
          let idx =
            this.MeasuresInfo.type == "edit" ? this.MeasuresInfo.id : "";
          this.$emit("confirmcomplianceMeasures", false, data, idx);
          this.resetData(); //重置表单
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //新增合规任务
    addTask() {
      this.taskList.push({
        content: "",
        tools: [],
        id: null,
      });
      console.log(this.taskList);
    },
    //删除合规任务
    delTask(idx) {
      console.log("idx", idx);
      this.taskList.splice(idx, 1);
      // this.resetData(); //重置表单
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  .cardBox {
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-icon-remove-outline {
      font-size: 24px;
      color: red;
    }
    .left {
      width: 430px;
      ::v-deep .el-textarea__inner {
        min-height: 40px !important;
        line-height: 2;
        font-size: 14px;
      }
      ::v-deep .el-input__inner {
        font-size: 14px;
      }
    }
    .left1,
    .left2 {
      margin-bottom: 10px;
      .title {
        width: 120px;
        font-size: 14px;
        margin-bottom: 10px;
      }
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
    }
  }
  .el-icon-circle-plus-outline {
    font-size: 28px;
    color: green;
  }
  .border-box {
    margin-top: 20px;
  }
}
.btns-box {
  display: flex;
  justify-content: flex-end !important;
  button {
    width: 110px;
    height: 46px;
    font-size: 16px;
    font-weight: 600;
    color: #bbb;
    background: #f1f2f1;
    border-radius: 23px;
    border: none;
    margin-left: 20px;
    cursor: pointer;
  }
  .btn2 {
    color: #fff;
    background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
    border: none;
  }
}
</style>