<template>
  <div>
    <div class="head-box">
      <div class="head-left">
        <el-button @click="add">新增劳动法</el-button>
        <el-checkbox v-model="allChecked" @change="allCheck">全选</el-checkbox>
        <button class="del-btn" @click="dels">删除</button>
        <el-switch
          v-model="wipeRepeat"
          active-text="去重"
          inactive-text="不去重"
          @change="init">
        </el-switch>
      </div>
      <div class="head-right" v-show="!wipeRepeat">
        <span>范围：</span>
        <el-cascader
          v-model="scopeVal"
          :options="scopeList"
          :props="props"
          filterable
          clearable
          @change="handleChange">
        </el-cascader>
        <span>时效性：</span>
        <el-select v-model="timelinessVal" clearable placeholder="请选择时效性">
          <el-option
            v-for="item in timelinessList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入搜索内容"
          v-model="keyVal">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <el-button @click="init">查询</el-button>
      </div>
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        :height="tableHeight"
        border
        :data="tableData"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '序号'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-checkbox v-model="scope.row.check" @change="check">{{scope.$index+1}}</el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span
                style="color: #6aa9fb; cursor: pointer"
                @click="lookDetails(scope.row)">
                修改
              </span>
              <span
                style="color: red; cursor: pointer; margin-left: 10px;"
                @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
            show-overflow-tooltip
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <addLaborLawsDialog v-if="showDialog" :info="detailsInfo"></addLaborLawsDialog>
  </div>
</template>

<script>
import { getLaborLawsRegion, getLaborLawsList, delLaborLaws, getLaborLawsListRepeat  } from '@/api/knowledgeData'
import addLaborLawsDialog from '@/views/Dialog/addLaborLawsDialog.vue';
export default {
  name: "laborLaws",
  components: {
    addLaborLawsDialog,
  },
  data() {
    return {
      wipeRepeat: false, // 是否去重
      loading: false,
      keyVal: "",
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 15, //每页显示条数
      tableData: [],
      headData: [
        { align: "center", prop: "序号", label: "序号", width: "100px" },
        { align: "left", prop: "title", label: "标题", width: "" },
        { align: "center", prop: "regionName", label: "范围", width: "100px" },
        { align: "center", prop: "promulgateDate", label: "颁布日期", width: "160px" },
        { align: "center", prop: "createTime", label: "添加日期", width: "160px" },
        { align: "center", prop: "操作", label: "操作", width: "150px" },
      ],
      scopeList: [], // 范围数组
      scopeVal: [], 
      props: {
	      label: 'name',
        value: 'id',
        children: 'childs',
	      expandTrigger: 'hover'
      },
      timelinessList: [ // 时效性数组
        {value: 1, label: "尚未生效"},
        {value: 2, label: "有效"},
        {value: 3, label: "废止"},
        {value: 4, label: "修订"},
      ],
      timelinessVal: "",
      arrTableLength: {},
      showDialog: false,
      detailsInfo: { // 查看数据
        type: 'add',
      },
      allChecked: false, // 全选删除
    }
  },
  created() {
  },
  mounted() {
    this.getRegion();
    this.init();
  },
  methods: {
    init() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      this.getData();
    },
    // 获取范围
    async getRegion() {
      this.scopeList = [];
      let res = await getLaborLawsRegion();
      if (res.code === 0) {
        // let data = res.result[0];
        // this.scopeList.push(
        //   {
        //     childs: null,
        //     id: data.id,
        //     name: data.name,
        //     order: data.order,
        //     parentId: data.parentId,
        //   }
        // );
        // let childs = data.childs;
        // childs.forEach(item => {
        //   this.scopeList.push(item);
        // });
        this.scopeList = res.result;
        this.estimateArr(this.scopeList)
        // console.log(this.scopeList);
      }
    },
    estimateArr(arr) {
      if (arr.length < 1)return
      arr.forEach(item => {
        let str = JSON.stringify(item.childs)
        if (str === "[]") {
          item.childs = null;
        } else if (str != "null" && str != "[]") {
          this.estimateArr(item.childs)
        }
      })
    },
    // 获取列表
    async getData() {
      // let params = `${this.currentPage}/${this.interPage}?regionId=${this.scopeVal}&queryString=${this.keyVal}&Timeliness=${this.timelinessVal}`;
      let params = `${this.currentPage}/${this.interPage}`;
      // console.log(this.scopeVal);
      this.loading = true;
      let res = {};
      if (!this.wipeRepeat) {  // 没有去重
        if (this.scopeVal.length > 0) {
            let key = this.scopeVal[this.scopeVal.length - 1]
          params = `${params}?regionId=${key}`;
        }
        if (this.keyVal) {
          if (this.scopeVal.length > 0) {
            params = `${params}&queryString=${this.keyVal}`;
          } else {
            params = `${params}?queryString=${this.keyVal}`;
          }
        }
        if (this.timelinessVal) {
          if (this.scopeVal.length > 0 || this.keyVal) {
            params = `${params}&Timeliness=${this.timelinessVal}`;
          } else {
            params = `${params}?Timeliness=${this.timelinessVal}`;
          }
        } 
        res = await getLaborLawsList(params);
      } else {
        res = await getLaborLawsListRepeat(params);
      }
      this.loading = false;
      // console.log(res,'劳动法列表数据');
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        data.map(item => {
          item.promulgateDate = item.promulgateDate == null ? '':item.promulgateDate.split("T")[0];
          item.createTime = item.createTime.split("T")[0];
          item.check = false;
          return item;
        })
        this.tableData = res.result.items;
        // this.tableData.map(item => {
        //   item.auditTime = item.auditTime || '';
        //   item.expireTime = item.expireTime || '';
        //   return item;
        // })
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 选择范围
    handleChange(value) {
      console.log(value);
    },
    // 新增劳务法
    add() {
      this.detailsInfo = {
        type: "add",
      }
      this.showDialog = true;
    },
    // 编辑
    lookDetails(row) {
      // console.log(row);
      this.detailsInfo = row;
      this.detailsInfo.type = "edit";
      this.$nextTick(() => {
        this.showDialog = true;
      })
    },
    // 删除
    del(row) {
      this.$confirm(`请确认是否删除【 ${row.title} 】？`)
        .then((_) => {
          this.delTask({ids: [row.id]});
        })
        .catch((_) => {});
    },
    async delTask(id) {
      let res = await delLaborLaws(id);
      if (res.code === 200) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.init();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 多删
    dels() {
      let arr = [];
      this.tableData.forEach(item => {
        if (item.check) {
          arr.push(item.id);
        }
      })
      if (arr.length != 0) {
        this.$confirm(`请确认是否删除选中的${arr.length}条数据？`)
        .then((_) => {
          this.delTask({ids: arr});
        })
        .catch((_) => {});
      }
    },
    // 全选
    allCheck() {
      if (this.allChecked) {
        this.tableData.map(item => {
          return item.check = true;
        })
      } else {
        this.tableData.map(item => {
          return item.check = false;
        })
      }
    },
    // 单选
    check() {
      let totalNum = 0;
      this.tableData.forEach(item => {
        if (item.check) {
          totalNum ++;
        }
      })
      if (this.tableData.length == totalNum) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    //获取表格裂宽
    getTableCellWidth() {
      this.tableHeight = this.$getTableHeight(70, "table-container");
      let headData = JSON.parse(JSON.stringify(this.headData));
      // console.log(headData)
      headData = headData.filter((val) => {
        return val.label !== "操作";
      });

      this.arrTableLength = this.$getTableCellLength({
        W: $(".table-container")[0].clientWidth,
        W2: 80,
        head: headData,
        data: this.tableData,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
    .head-box{
      display: flex;
      justify-content: space-between;
      height: 7%;
      margin-bottom: 1%;
      .head-left {
        display: flex;
         align-items:  center;
        .el-button {
          height: 40px;
          border-radius: 20px;
          color: rgba(0, 148, 255,.7);
          border: 1px solid rgba(0, 148, 255,.7);
        }
        .el-checkbox {
          margin: 0 10px;
        }
        .del-btn {
          width: 60px;
          height: 20px;
          border-radius: 15px;
          border: 1px solid rgba(0, 148, 255, 0.7);
          background-color: #fff;
          color: rgba(0, 148, 255, 0.7);
        }
        ::v-deep .el-switch {
          margin-left: 20px;
          .el-switch__label {
            span {
              font-size: 12px;
              white-space: nowrap;
            }
          }
        }
      }
      .head-right {
        display: flex;
        align-items: center;
        span {
          white-space: nowrap;
        }
        .el-cascader {
          width: 200px;
          margin-right: 10px;
        }
        .el-select {
          margin: 0 10px;
        }
        .el-input {
          width: 240px;
          margin-right: 10px;
        }
      }
    }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
</style>

