<template>
  <div class="ComDoc-operation">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "ComDocOperation",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .ComDoc-operation {
  }
</style>

