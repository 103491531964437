
// import { getToken } from './token'

//dateNum为空，获取日期，type =1;Y-MM-DD;type=2;Y-MM-DD HH:mm:ss;type=3,Y年MM月DD日 星期几;
//dateNum格式为Y-MM-DD或者时间戳，返回格式化的日期。
export function getDate(type, dateNum) {
  var date;
  if (dateNum === '') {
    return '';
  }
  if (dateNum != null) {
    // date = new Date(dateNum);
    var currentDate = new Date(dateNum);
    if (currentDate.toString() === "Invalid Date") {
      var dateStringList = String(dateNum).split(/[- : \/]/);
      if (dateStringList.length >= 2) {
        //字符串月份需要-1才能获得真正的月份
        dateStringList[1] = String(Number(dateStringList[1]) - 1);
      }
      date = new Date(...dateStringList.slice(0, 6));
    } else {
      date = currentDate;
    }
  } else {
    date = new Date();
  }
  var Y = date.getFullYear();
  var M = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + parseInt(date.getMonth() + 1);
  var D = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  var week = ['日', '一', '二', '三', '四', '五', '六'][date.getDay()];
  var H = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
  var m = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
  var s = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
  var time = '';
  if (type == 1) {
    time = Y + '-' + M + '-' + D;
  } else if (type == 2) {
    time = Y + '-' + M + '-' + D + ' ' + H + ':' + m + ':' + s;
  } else if (type == 3) {
    time = Y + '-' + M;
  } else if (type == 4) {
    time = M + '/' + D;
  } else if (type == 5) {
    time = Number(M) + '月' + Number(D) + '日';
  } else if (type == 'week') {
    time = week;
  } else {
    time = Y + ' 年 ' + M + ' 月 ' + D + ' 日 &nbsp;&nbsp;&nbsp; 星期' + week;
  }
  return time;
}

// 获取一周的时间  对应日期及周几 date:周内某个时间  initDate:周起始时间(日或一)
export function setWeekDate(date, initDate) {
  let newData = date || new Date();
  initDate = initDate || '一';
  let weekList = ["一", "二", "三", "四", "五", "六"];
  // if (initDate == '一') {
  //   weekList.push('日')
  // } else if (initDate == '日') {
  //   weekList.unshift('日')
  // }
  if (initDate == '日') {
    weekList.unshift('日')
  } else {
    weekList.push('日')
  }
  let weekDateList = [];
  let is_date = newData.getTime();
  let newWeek = getDate("week", newData);
  let weekIdx = weekList.findIndex((item) => {
    return item == newWeek;
  });
  let weekIdx2 = 7 - weekIdx;
  for (var i = 0; i < weekIdx; i++) {
    is_date -= 1000 * 60 * 60 * 24;
    weekDateList.unshift({
      label: "周" + getDate("week", is_date),
      date: getDate(1, is_date),
      showDate: getDate(4, is_date),
      showDate2: getDate(5, is_date),
    });
  }
  is_date = newData.getTime();
  weekDateList.push({
    label: "周" + getDate("week", is_date),
    date: getDate(1, is_date),
    showDate: getDate(4, is_date),
    showDate2: getDate(5, is_date),
  });
  for (var i = 1; i < weekIdx2; i++) {
    is_date += 1000 * 60 * 60 * 24;
    weekDateList.push({
      label: "周" + getDate("week", is_date),
      date: getDate(1, is_date),
      showDate: getDate(4, is_date),
      showDate2: getDate(5, is_date),
    });
  }
  return weekDateList;
}
//elment 日期插件限制不能选未来时间
export function set_PickerOption(date) {
  // disabledDate 为true表示不可选,false表示可选
  if (date !== undefined) {
    date = new Date(date).getTime() - 24 * 60 * 60 * 1000;
  } else {
    date = 0;
  }
  let obj = {};
  obj.disabledDate = function (time) {
    var num = time.getTime();
    if (num > Date.now() - 8.64e6 || num < date) {
      return true;
    }
  };
  return obj;
}
//elment 日期插件限制不能选过去时间
export function set_PickerOption1(date) {
  // disabledDate 为true表示不可选,false表示可选
  let obj = {};
  let date1 = new Date().getTime() - 24 * 60 * 60 * 1000;
  if (date !== undefined) {
    date = new Date(date).getTime();
  } else {
    date = '9999-12-31'
  }
  // console.log(date)
  obj.disabledDate = function (time) {
    var num = time.getTime();
    if (num < date1 || num > date) {
      return true;
    }
  };
  return obj;
}
//elment 日期插件限制只能选择区间内时间
export function set_PickerOption2(date, date2) {
  // disabledDate 为true表示不可选,false表示可选
  let obj = {};
  date1 = date1 ? new Date(date1).getTime() - 24 * 60 * 60 * 1000 : new Date().getTime() - 24 * 60 * 60 * 1000;
  date2 = date2 ? new Date(date2).getTime() : new Date('9999-12-31').getTime();
  // console.log(date)
  obj.disabledDate = function (time) {
    var num = time.getTime();
    if (num < date1 || num > date2) {
      return true;
    }
  };
  return obj;
}

//计算一段文本的长度px.
export function getstrLength(str) {
  var a = document.createElement('A');
  if (str !== undefined) {
    a.innerText = str;
  } else {
    a.innerText = '';
  }

  $(document.body).append(a);
  var w = a.offsetWidth;
  $(a).remove();
  return w;
}

//计算表格内容宽度
export function getTableCellLength(obj) {
  var W = obj.W;//容器宽度
  var otherWidth = obj.hasOwnProperty('W2') ? obj.W2 : 0;//循环之外的列宽。
  var head = obj.head;//表头数据
  var currentData = obj.data;//表体数据

  var lengthArr = [];
  var arrhead = {};
  var arrtotal = {};

  for (var i = 0; i < head.length; i++) {
    var text = head[i]['label'];
    let prop = head[i]['prop'];
    arrhead[prop] = getstrLength(text)
  }
  var keys = Object.keys(arrhead);
  // console.log(arrhead)
  lengthArr.push(arrhead);//计算表头长度放入数组
  for (var i = 0; i < currentData.length; i++) {
    var obj = {};
    for (var k in currentData[i]) {
      var text = currentData[i][k];
      if (keys.indexOf(k) > -1) {
        obj[k] = getstrLength(text)
      }
    }
    lengthArr.push(obj);//计算表体长度放入数组内
  }
  for (var i = 0; i < keys.length; i++) {
    arrtotal[keys[i]] = [];
    for (var j = 0; j < lengthArr.length; j++) {
      for (var k in lengthArr[j]) {
        if (k === keys[i]) {
          arrtotal[keys[i]].push(lengthArr[j][k])
        }
      }
    }
  }

  for (var i in arrtotal) {
    arrtotal[i] = Math.max.apply(Math, arrtotal[i]);
  }

  if (W !== undefined) {//根据容器宽度补全多余部分。
    function sum(obj) {
      var s = 0;
      for (var i in obj) {
        s += parseInt(obj[i]);
      }
      return s;
    }
    var w = sum(arrtotal);
    var len = head.length;
    var aver = 20;
    if ((W - otherWidth - w) > 0) {
      aver = Math.ceil((W - otherWidth - w) / len);
    }
    aver = aver < 20 ? 20 : aver;
    for (var i in arrtotal) {
      arrtotal[i] = arrtotal[i] + aver;
    }
  }
  return arrtotal;
}
