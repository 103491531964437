
// 引入request
import requests from "../utils/request";
// 获取手机验证码
export const getPhoneCode = (data) =>
  requests({
    url: "/api/auth/verifycode",
    method: "post",
    data: data,
  });
// 手机验证码登录
export const verifyCodeLogin = (data) =>
  requests({
    url: "/api/auth/login/code",
    method: "post",
    data: data,
  });

// 登录
export const login = (data) =>
  requests({
    url: "/api/login",
    method: "post",
    data: data,
  });

//获取上传Policy
export const getPolicyToken = (data) =>
  requests({
    url: "/api/oss/policy/token",
    method: "post",
    data,
  });

// 获取oss临时地址
export const getFileUrl = (data) =>
  requests({
    url: "/api/OSS/file/uri",
    method: "post",
    data,
  });

//上传文件 后台返回oss路径
export const upFile = (data) =>
  requests({
    url: "/api/complianceguide/risk/uploadfile",
    method: "post",
    data,
  });
// 省市地区数据
export const getAddress = () =>
  requests({
    url: `/api/common/address/`,
    method: "get",
  });