<template>
  <div class="interviewer">
    <h1>面试官</h1> 
  </div>
</template>

<script>
export default {
  name: "interviewer",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .interviewer {
  }
</style>

