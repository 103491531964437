<template>
  <div class="democratic-flow-path">
    <h1>民主流程</h1> 
  </div>
</template>

<script>
export default {
  name: "democraticFlowPath",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .democratic-flow-path {
  }
</style>

