<template>
  <div class="set-hrpp-file-dialog">
    <el-dialog
      :title="title"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="80%">
      <template v-if="fileUrl">
        <iframe 
          :src="fileUrl"
          frameborder="0"
          type="application/x-google-chrome-pdf"
          scrolling="auto"
          :style="`width: 100%; height: 80vh`">
        </iframe>
      </template>
      <i class="el-icon-circle-close close-icon" @click="cancel"></i>
      <!-- <button class="btn" @click="cancel">取 消</button> -->
      <!-- <div class="row-box">
        <p class="label">
          <span>* </span>
          联系人：
        </p>
        <el-input placeholder="请输入联系人" v-model="form.name"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          联系方式：
        </p>
        <el-input placeholder="请输入联系方式" v-model="form.phone"></el-input>
      </div>
      <div class="row-box">
        <p class="label">联系人邮箱：</p>
        <el-input placeholder="请输入联系人邮箱" v-model="form.email"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          公司职务：
        </p>
        <el-input placeholder="请输入公司职务" v-model="form.job"></el-input>
      </div> -->
      <!-- <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import { getPolicyToken, getFileUrl } from "@/api/index.js"
var OSS = require("ali-oss");
export default {
  name: "setHRPPFileDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      ossInfo: { // oss参数
        BucketName: "ilaas-files",
        expireTime: 120,
        uploadDir: "ilaas_cms/conformGuide/file/",
      },
      ossSignature: {}, //oss签名
      fileUrl: "",
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // console.log(this.info);
      this.title = this.info.name;
      this.ossInfo.BucketName = this.info.buketName;
      this.getOss();
    },
    // 获取oss签名
    async getOss() {
      let res = await getPolicyToken(this.ossInfo);
      if (res.code === 200) {
        let data = JSON.parse(res.result);
        this.ossSignature = data;
        this.getUrl();
      }
    },
    async getUrl() {
      let params = {
        expireTime: 3600,
        objectName: this.info.handledFilePath,
        BucketName: this.info.buketName,
        EndPoint: 'https://oss-cn-shenzhen.aliyuncs.com',
      };
      let res = await getFileUrl(params);
      if (res.code == 200) {
        this.fileUrl = res.result;
      }
    },
    cancel() {
      this.$parent.showDialog4 = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.set-hrpp-file-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .close-icon {
          position: absolute;
          top: 8px;
          right: 4px;
          font-size: 40px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
