// 对axios进行二次封装
import axios from 'axios'
// import { getToken } from '@/Utils/auth'
import { getToken, removeToken } from '../utils/token'
import store from '@/store/index'
import router from '@/router/index'
import { Message } from 'element-ui'

const requests = axios.create({
  // 配置对象
  baseURL: process.env.VUE_APP_ILAAS_API, // 基础路径，发送请求的时候，路径当中会出现api
  timeout: 100000, // 请求超时的时间5s
})
// requests.defaults.headers.post['Content-Type'] = 'application/json'; //配置请求头
// 请求拦截器：在发送请求之前，请求拦截器可以检测到，在请求发送之前处理一些事情
requests.interceptors.request.use(
  config => {
    // config.headers['Content-Type'] = 'application/json';
    if (getToken()) {
      config.headers['Authorization'] = 'Bearer ' + getToken();
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 响应拦截器：
requests.interceptors.response.use(
  response => {
    // response.headers['content-type'] = 'application/json';
    const res = response.data;
    if (res.code != 200 && res.code) {
      Message({
        message: res.msg || res.message || 'Error',
        type: 'error',
        duration: 3 * 1000,
      });
    }
    return res;
  },
  error => {
    if (error.response.status == 401) {
      Message({
        message: '登录超时，请重新登录！',
        type: 'error',
        duration: 3 * 1000,
      });
      removeToken();
      store.dispatch('index/setRouterInfo', []);
      router.push({name: 'login'})
      // setTimeout(() => {
      // }, 1500)
      return;
    }
    const message = error.response.data.message || '网络异常';
    Message({
      message: message,
      type: 'error',
      duration: 3 * 1000,
    });
    return Promise.reject(error);
  }
)
export default requests

