<template>
  <div class="add-user-dialog">
    <el-dialog
      title="用户"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="460px"
    >
      <!-- <div class="row-box">
        <p class="label">
          <span>* </span>
          名称：
        </p>
        <el-input placeholder="请输入名称" v-model="form.identity"></el-input>
      </div> -->
      <div class="row-box">
        <p class="label">
          <span>* </span>
          用户名称：
        </p>
        <el-input
          placeholder="请输入用户名称"
          v-model="form.userName"
        ></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          用户角色：
        </p>
        <el-select v-model="form.roleId">
          <el-option
            v-for="item in roleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="row-box">
        <p class="label">
          <span>* </span>
          密码：
        </p>
        <el-input placeholder="请输入密码" v-model="form.password" show-password></el-input>
      </div> -->
      <div class="row-box">
        <p class="label">
          <span>* </span>
          真实姓名：
        </p>
        <el-input
          placeholder="请输入真实姓名"
          v-model="form.realName"
        ></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          手机号：
        </p>
        <el-input
          placeholder="请输入手机号"
          v-model="form.telephone"
        ></el-input>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addAccount, editAccount } from "@/api/setting.js";
export default {
  name: "addUserDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        userName: "", // 用户名称
        roleId: 1, // 用户角色
        password: "abc123456", // 密码
        realName: "", // 姓名
        telephone: "", // 手机号
      },
      roleList: [
        { value: 0, label: "超级管理员" },
        { value: 1, label: "运维" },
        { value: 2, label: "渠道" },
      ],
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // console.log(this.info);
      if (this.info.type == "edit") {
        this.form = JSON.parse(JSON.stringify(this.info.item));
      }
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (this.submitStatus) return;
      this.form.userName = this.form.userName.trim();
      // this.form.password = this.form.password.trim();
      if (!this.form.userName) {
        this.$message.warning(`请输入用户名称！`);
        return;
      }
      if (!this.form.realName) {
        this.$message.warning(`请输入真实姓名！`);
        return;
      }
      let isPhone = /^1[3456789]\d{9}$/;
      if (!this.form.telephone || !isPhone.test(this.form.telephone)) {
        this.$message.warning(`请正确输入手机号！`);
        return;
      }
      // if (!this.form.password || this.form.password.length < 6) {
      //   this.$message.warning(`密码不能为空且长度不小于6！`);
      //   return;
      // }
      let params = JSON.parse(JSON.stringify(this.form));
      // console.log(params);return
      this.submitStatus = true;
      let res = {};
      if (this.info.type == "add") {
        res = await addAccount(params);
      } else {
        res = await editAccount(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-user-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
