<template>
  <div class="edit-parameters">
    <el-dialog
      title="修改参数"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="50%">
      <div class="row-box">
        <p class="label">省份：</p>
        <el-input v-model="form.provinceName" disabled></el-input>
      </div>
      <div class="row-box">
        <p class="label">城市：</p>
        <el-input v-model="form.cityName" disabled></el-input>
      </div>
      <div class="row-box">
        <p class="label">地区：</p>
        <el-input v-model="form.area" disabled></el-input>
      </div>
      <div class="row-box">
        <p class="label">年份：</p>
        <el-input v-model="form.year" disabled></el-input>
      </div>
      <div class="row-box">
        <p class="label">参数类型：</p>
        <el-input v-model="form.targetName" disabled></el-input>
      </div>
      <div class="row-box">
        <p class="label">参数名称：</p>
        <el-input v-model="form.subTargetName" disabled></el-input>
      </div>
      <div class="row-box">
        <p class="label">参数内容：</p>
        <el-input type="textarea" :autosize="{ minRows: 1}" placeholder="请输入参数内容" v-model="form.value"></el-input>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { editParameter } from '@/api/knowledgeData'
export default {
  name: "editParameters",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {},
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // console.log(this.info);
      if (!this.info.cityName) {
        let type = this.info.provinceName.substr(-1);
        this.info.cityName = `全${type}`
      }
      this.form = this.info;
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (this.submitStatus) return;
      if (!this.form.value.trim()) {
        this.$message.warning(`请输入参数内容！`);
        return;
      }
      let params = {
        id: this.info.id,
        value: this.form.value,
      }
      // console.log(params);
      // return
      this.submitStatus = true;
      let res = await editParameter(params);
      this.submitStatus = false;
      // console.log(res);
      if (res.code == 200) {
        this.$parent.getData();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-parameters {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
