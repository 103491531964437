<template>
  <div class="details-page">
    <template v-if="breadcrumbList.length == 1">
      <p class="btn-p" @click="goBack">返回</p>
      <div class="tabs-box">
        <div
          :class="`tabs-p ${activeId == item.id ? 'is-tabs-p' : ''}`"
          @mouseenter="isTabsId = item.id"
          @mouseleave="isTabsId = null"
          @click="checkTabs(item)"
          v-for="item in tabsList"
          :key="item.id"
        >
          {{ item.projectName }}
          <!-- <div class="icon-box" v-show="isTabsId == item.id" @mouseenter="popId = item.id"
          @mouseleave="popId = null">
            <i class="el-icon-more"></i>
            <div class="pop-box" v-show="popId == item.id">99</div>
          </div> -->
          <i class="el-icon-edit edit-icon" v-show="isTabsId == item.id" @click.stop="editProject(item)"></i>
          <i
            class="el-icon-circle-close del-icon"
            v-show="isTabsId == item.id"
            @click.stop="delProject(item)"
          ></i>
        </div>
        <i class="el-icon-circle-plus-outline" @click="addProject"></i>
      </div>
    </template>
    <div class="breadcrumb-box" v-else>
      <div v-for="(item2, index2) in breadcrumbList" :key="item2.id">
        <p
          @click="checkBreadcrumb(index2)"
          :style="`${
            index2 == breadcrumbList.length - 1
              ? 'color: #606266;cursor:default;'
              : ''
          }`"
        >
          {{ item2.name }}
        </p>
        <span v-if="index2 != breadcrumbList.length - 1">/</span>
      </div>
    </div>
    <p class="btn-p2" @click="add">新增</p>
    <div class="table-container">
      <el-table
        v-loading="loading"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '序号'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '名称'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              <p
                style="
                  color: #6aa9fb;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  cursor: pointer;
                "
                @click="details(scope.row)"
                :title="scope.row.name"
              >
                {{ scope.row.name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '关联风险点'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              <p
                style="
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                "
              >
                {{ scope.row.riskName }}
              </p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              <span
                style="color: #6aa9fb; cursor: pointer; margin-right: 15px"
                @click="edit(scope.row)"
              >
                编辑
              </span>
              <span style="color: red; cursor: pointer" @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <addProject v-if="showDialog" :id="info.id" :info="detailsInfo2"></addProject>
    <addIndexLevel v-if="showDialog2" :info="detailsInfo"></addIndexLevel>
  </div>
</template>

<script>
import addProject from "./addProject.vue";
import addIndexLevel from "./addIndexLevel.vue";
import {
  getHarmoniousProject2,
  delHarmoniousProject2,
  getIndexLevel,
  delIndexLevel,
  getTopic,
  delTopic,
} from "@/api/knowledgeData";
export default {
  name: "detailsPage",
  components: {
    addProject,
    addIndexLevel,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      activeId: null,
      tabsList: [],
      isTabsId: null,
      popId: null,
      showDialog: false, // 选择项目弹框
      detailsInfo2: {},
      loading: false,
      headData: [
        { align: "center", prop: "序号", label: "序号", width: "100px" },
        { align: "center", prop: "name", label: "名称", width: "" },
        { align: "center", prop: "riskName", label: "关联风险点", width: "" },
        { align: "center", prop: "order", label: "排序", width: "100px" },
        { align: "center", prop: "操作", label: "操作", width: "200px" },
      ],
      tableData: [],
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 15, //每页显示条数
      showDialog2: false, // 指标答题弹框
      detailsInfo: {
        type: 1,
        type2: null, // 指标 题目
        projectId: null, // 项目id
        parentId: null, // 当前父级id
      },
      breadcrumbList: [
        // 面包屑
      ],
    };
  },
  created() {
    // console.log(this.info);
    // this.detailsInfo.projectId = this.info.id;
    this.breadcrumbList.push({ name: "总表", id: null });
    this.getData();
  },
  mounted() {},
  methods: {
    init(num) {
      this.dataSize = 0;
      this.currentPage = 1;
      this.tableData = [];
      if (num == 1) {
        this.getData2();
      } else if (num == 2) {
        this.getData3();
      }
      // console.log(this.detailsInfo,'this.detailsInfo');
    },
    // 获取项目列表
    async getData() {
      let res = await getHarmoniousProject2(this.info.id);
      if (res.code == 0) {
        this.tabsList = res.result;
        if (this.tabsList.length) {
          if (!this.activeId) {
            this.activeId = this.tabsList[0].id;
            this.detailsInfo.projectId = this.tabsList[0].id;
            this.init(1);
          }
        } else {
          this.setHead(1);
          this.activeId = null;
          this.detailsInfo.projectId = null;
          this.dataSize = 0;
          this.currentPage = 1;
          this.tableData = [];
        }
      }
    },
    // 获取指标列表
    async getData2() {
      this.setHead(2);
      let params = `/${this.currentPage}/${this.interPage}`;
      if (this.detailsInfo.parentId) {
        params += `?parentId=${this.detailsInfo.parentId}`;
      } else {
        params += `?projectId=${this.detailsInfo.projectId}`;
      }
      let res = await getIndexLevel(params);
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        // this.setHead(data);
        data.forEach((item) => {
          item.typeName = item.type == 1 ? "指标" : "题目";
          if (item.type == 2) {
            this.setHead(3);
            item.answerWayName = item.answerWay == 1 ? "打分" : "选择";
            if (item.answerWay == 2 || !item.maxScore) {
              item.maxScore = null;
            }
          }
          return item;
        });
        this.tableData = data;
      }
    },
    // 获取题目列表
    async getData3() {
      this.setHead(3);
      // let params = `/${this.currentPage}/${this.interPage}/${this.detailsInfo.parentId}`;
      let params = `/${this.currentPage}/${this.interPage}`;
      if (this.detailsInfo.parentId) {
        params += `?gradeId=${this.detailsInfo.parentId}`;
      } else {
        params += `?projectId=${this.detailsInfo.projectId}`;
      }
      let res = await getTopic(params);
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        // this.setHead(data);
        data.forEach((item) => {
          item.typeName = item.type == 1 ? "指标" : "题目";
          item.answerWayName = item.answerWay == 1 ? "打分" : "选择";
          if (item.answerWay == 2 || !item.maxScore) {
            item.maxScore = null;
          }
          return item;
        });
        this.tableData = data;
      }
    },
    // 设置列表头
    setHead(num) {
      if (num == 1) {
        this.headData = [
          { align: "center", prop: "序号", label: "序号", width: "100px" },
          { align: "center", prop: "name", label: "名称", width: "" },
        ];
      } else if (num == 2) {
        this.headData = [
          { align: "center", prop: "序号", label: "序号", width: "100px" },
          { align: "center", prop: "name", label: "名称", width: "" },
          { align: "center", prop: "typeName", label: "类型", width: "100px" },
          { align: "center", prop: "riskName", label: "关联风险点", width: "" },
          { align: "center", prop: "totalScore", label: "总分", width: "100px" },
          { align: "center", prop: "childCount", label: "子项数", width: "100px" },
          { align: "center", prop: "order", label: "排序", width: "100px" },
          { align: "center", prop: "操作", label: "操作", width: "200px" },
        ];
      } else if (num == 3) {
        this.headData = [
          { align: "center", prop: "序号", label: "序号", width: "100px" },
          { align: "center", prop: "content", label: "内容", width: "" },
          { align: "center", prop: "typeName", label: "类型", width: "100px" },
          {
            align: "center",
            prop: "answerWayName",
            label: "答题方式",
            width: "100px",
          },
          {
            align: "center",
            prop: "maxScore",
            label: "最大分值",
            width: "100px",
          },
          { align: "center", prop: "limit", label: "分数限制", width: "100px" },
          { align: "center", prop: "order", label: "排序", width: "100px" },
          { align: "center", prop: "操作", label: "操作", width: "200px" },
        ];
      }
    },
    // 切换面包屑
    checkBreadcrumb(index2) {
      // console.log(this.breadcrumbList,index2);
      if (!index2) {
        this.detailsInfo.parentId = null;
        this.breadcrumbList = [this.breadcrumbList[0]];
        this.init(1);
      } else if (index2 == this.breadcrumbList.length - 1) {
        return;
      } else {
        let newArr = this.breadcrumbList.slice(0, index2 + 1);
        this.breadcrumbList = newArr;
        this.detailsInfo.parentId = newArr[newArr.length - 1].id;
        this.init(1);
      }
    },
    // 详情
    details(item) {
      // console.log(item);
      this.detailsInfo.parentId = item.id;
      this.dataSize = 0;
      this.currentPage = 1;
      this.breadcrumbList.push({ id: item.id, name: item.name });
      if (!item.isHasChild) {
        this.setHead(1);
        this.tableData = [];
      } else {
        if (item.isHasDetail) {
          this.getData3();
        } else {
          this.getData2();
        }
      }
    },
    // 切换tabs
    checkTabs(item) {
      // console.log(item);
      if (item.id == this.activeId) return;
      this.activeId = item.id;
      this.detailsInfo.projectId = item.id;
      this.init(1);
    },
    // 添加项目
    addProject() {
      this.detailsInfo2 = {};
      this.showDialog = true;
    },
    // 编辑项目
    editProject(item) {
      this.detailsInfo2 = JSON.parse(JSON.stringify(item));
      this.showDialog = true;
    },
    // 删除项目
    delProject(item) {
      this.$confirm(`是否确认删除项目【${item.projectName}】`)
        .then((_) => {
          this.delProject2(item);
        })
        .catch((_) => {});
    },
    async delProject2(item) {
      let res = await delHarmoniousProject2(item.id);
      if (res.code == 200) {
        if (this.activeId == item.id) {
          this.activeId = null;
        }
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getData();
      }
    },
    // 新增指标题目
    add() {
      if (!this.tabsList.length) {
        this.$message.warning(`项目不能为空，请通过上方图标添加项目！`);
        return;
      }
      let type = null;
      if (!this.tableData.length) {
        type = 1;
      } else {
        if (this.tableData[0].type == 1) {
          type = 2;
        } else {
          type = 3;
        }
      }
      this.detailsInfo.type = type;
      this.detailsInfo.type2 = null;
      this.detailsInfo.item = null;
      this.showDialog2 = true;
    },
    // 编辑指标题目
    edit(item) {
      let type = null;
      if (item.type == 1) {
        type = 1;
      } else {
        type = 2;
      }
      this.detailsInfo.type2 = type;
      this.detailsInfo.item = JSON.parse(JSON.stringify(item));
      this.showDialog2 = true;
    },
    // 删除指标题目
    del(item) {
      let str = "";
      if (item.type == 1) {
        str = `是否确认删除指标【${item.name}】`;
      } else {
        str = `是否确认删除题目【${item.content}】`;
      }
      this.$confirm(`${str}`)
        .then((_) => {
          this.delData(item);
        })
        .catch((_) => {});
    },
    async delData(item) {
      let res = {};
      if (item.type == 1) {
        res = await delIndexLevel(item.id);
      } else {
        res = await delTopic(item.id);
      }
      if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.init(item.type);
      }
    },
    handleSizeChange(val) {
      console.log(val);
      this.currentPage = 1;
      this.interPage = val;
      if (this.tableData.length) {
        if (this.tableData[0].type == 1) {
          this.getData2();
        } else {
          this.getData3();
        }
      }
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      if (this.tableData[0].type == 1) {
          this.getData2();
        } else {
          this.getData3();
        }
    },
    // 返回
    goBack() {
      this.$parent.showDetails = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.details-page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  p {
    margin-bottom: 0 !important;
  }
  .btn-p {
    position: absolute;
    top: 20px;
    right: 20px;
    width: fit-content;
    font-size: 14px;
    color: #4cb4ff;
    border-radius: 20px;
    border: 1px solid #4cb4ff;
    padding: 0 18px;
    margin-bottom: 6px !important;
    cursor: pointer;
  }
  .tabs-box {
    display: flex;
    align-items: center;
    width: 90%;
    overflow-x: auto;
    overflow-y: none;
    .tabs-p {
      position: relative;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ccc;
      padding: 6px 14px;
      margin-right: 4px;
      cursor: pointer;
      .edit-icon {
        position: absolute;
        top: 0;
        left: 0;
          color: green;
          font-size: 18px;
          margin-left: 0;
          cursor: pointer;
      }
      .del-icon {
        position: absolute;
        top: 0;
        right: 0;
          color: red;
          font-size: 18px;
          margin-left: 0;
          cursor: pointer;
        }
      // .icon-box {
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   width: fit-content;
      //   padding: 0 3px;
      //   background-color: rgba(204, 204, 204, .6);
      //   i {
      //     color: #606266;
      //     font-size: 12px;
      //     margin-left: 0;
      //     cursor: pointer;
      //   }
      //   .pop-box {
      //     position: absolute;
      //     top: 10px;
      //     right: 0;
      //     border: 1px solid #111;
      //     border-radius: 4px;
      //     padding: 4px;
      //     z-index: 90;
      //   }
      // }
    }
    .is-tabs-p {
      color: #4cb4ff;
      border: 1px solid #4cb4ff;
    }
    i {
      font-size: 26px;
      color: #4cb4ff;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .breadcrumb-box {
    display: flex;
    align-items: center;
    overflow-x: auto;
    & > div {
      display: flex;
      align-items: center;
      p {
        color: #6ab1fc;
        cursor: pointer;
      }
      span {
        display: inline-block;
        margin: 0 6px !important;
      }
    }
  }
  .btn-p2 {
    width: fit-content;
    color: #4cb4ff;
    border-radius: 20px;
    border: 1px solid #4cb4ff;
    padding: 0 18px;
    margin: 10px 0 !important;
    cursor: pointer;
  }
}
</style>