import getters from './getters'
const state = {
  routerInfo: [], // 动态路由
};

const mutations = {
  SET_ROUTER_INFO(state, info) {
    state.routerInfo = info;
  },
};
const actions = {
  setRouterInfo({ commit }, info) {
    commit('SET_ROUTER_INFO', info);
  },
};

export default {
  namespaced: true,
  state,
  // getters,
  mutations,
  actions,
};
