<template>
<!-- 已到期 -->
  <div class="iLaaS-have-expire">
    <div v-show="!showDetails">
      <div class="head-box">
        <div class="head-left">
        <el-date-picker
          v-model="filterInfo.dates"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="init">
        </el-date-picker>
        <el-select
          v-model="filterInfo.accountStatus"
          @change="init"
          clearable
          placeholder="请选择账号状态"
        >
          <el-option
            v-for="item in accountStatusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input placeholder="请输入搜索内容" v-model="filterInfo.keyVal">
          <i slot="suffix" @click="init" class="el-input__icon el-icon-search"></i>
        </el-input>
        <!-- <el-button class="btn" @click="exportData">导出</el-button> -->
      </div>
      <div class="head-right">
        <div class="popover-box">
          <el-popover
            popper-class="item-popover"
            placement="bottom"
            width="416"
            trigger="click"
          >
            <el-checkbox-group v-model="headFilter">
              <el-checkbox
                v-for="(item, index) in headFilterList"
                :label="index"
                :key="index"
                @change="filtrate"
                class="checkbox-line">
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
            <el-button type="text" slot="reference">
              <img class="icon-img" src="@/assets/img/hmc_shaixuan.png" />
              <span class="c-span">筛选</span></el-button>
          </el-popover>
        </div>
       </div>
      </div>
      <div class="table-container">
        <el-table
          :height="tableHeight"
          border
          :data="tableData"
          style="width: 100%"
          fit
        >
          <template v-for="(head, index) in headData">
            <el-table-column
              :align="head.align"
              v-if="head.label === '编号'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="arrTableLength[head.prop] + 'px'"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              :align="head.align"
              v-else-if="head.label === '用户头像'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="arrTableLength[head.prop] + 'px'"
            >
              <template slot-scope="scope">
                <img style="width: 100%;" :src="scope.row.avatar" alt="" />
              </template>
            </el-table-column>
            <el-table-column
              :align="head.align"
              v-else-if="head.label === '会员身份'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="arrTableLength[head.prop] + 'px'"
            >
              <template slot-scope="scope">
                {{ scope.row.isVip ? "会员" : "非会员" }}
              </template>
            </el-table-column>
            <el-table-column
              :align="head.align"
              v-else-if="head.label === '操作'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="arrTableLength[head.prop] + 'px'"
            >
              <template slot-scope="scope">
                <span
                  style="color: #6aa9fb; cursor: pointer"
                  @click="lookDetails(scope.row)">
                  查看详情
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :align="head.align"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="arrTableLength[head.prop] + 'px'"
            ></el-table-column>
          </template>
        </el-table>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="interPage"
            layout="total, sizes, prev, pager, next, jumper"
            background
            :total="dataSize"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- <userDetails v-if="showDetails" ref="userDetails"></userDetails> -->
  </div>
</template>

<script>
// import userDetails from "./userDetails.vue";
import { exportUser, getBestComHaveExpireUser } from "@/api/clientOperation";
import { Loading } from "element-ui";
export default {
  name: "iLaaSHaveExpire",
  components: {
    // userDetails,
  },
  data() {
    return {
      filterInfo: {
        dates: null,
        accountStatus: null,
        keyVal: "",
      },
      accountStatusList: [
        { label: "正常", value: 0 },
        { label: "待验证", value: 1 },
        { label: "待审核", value: 2 },
        { label: "锁定", value: 3 },
      ],
      sexVal: 0,
      sexList: [
        { label: "全部", value: 0 },
        { label: "男性", value: 1 },
        { label: "女性", value: 2 },
        { label: "未知", value: 3 },
      ],
      areaVal: "",
      areaList: [{ label: "北京", value: "北京" }],
      keyVal: "",
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      headFilter: [0,1,2,3,4,5,6,7,8,9],
      headFilterList: [
        { align: "center", prop: "userName", label: "用户名" },
        // { align: "center", prop: "用户头像", label: "用户头像" },
        { align: "center", prop: "nickName", label: "姓名" },
        { align: "center", prop: "phoneNumber", label: "手机" },
        { align: "center", prop: "email", label: "邮箱" },
        { align: "center", prop: "createTime", label: "注册时间" },
        { align: "center", prop: "enterprise", label: "公司" },
        { align: "center", prop: "registerIP", label: "注册IP" },
        { align: "center", prop: "address", label: "地区" },
        { align: "center", prop: "会员身份", label: "会员身份" },
        { align: "center", prop: "expiredTime", label: "会员到期时间" },
        // { align: "center", prop: "area", label: "地区" },
        // { align: "center", prop: "remark", label: "操作" },
      ],
      tableData: [],
      headData: [],
      arrTableLength: {},
      showDetails: false, // 显示详情
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      this.filtrate();
    },
    // 过滤表头
    filtrate() {
      this.headData = [];
      let newArr = [];
      if (this.headFilter.length > 0) {
        this.headFilter.sort(function (a,b) {
            return a-b;
        })
        this.headFilter.forEach(item => {
          newArr.push(this.headFilterList[item])
        })
      }
      // newArr.push({ align: "center", prop: "操作", label: "操作", width: "100px" });
      this.$nextTick(()=> {
        this.headData = newArr;
        this.getUserInfo();
      })
    },
    async getUserInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = {
        info: {
          queryString: this.filterInfo.keyVal,
          startDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[0]):null,
          endDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[1]):null,
          status: this.filterInfo.accountStatus == "" ? null:this.filterInfo.accountStatus,
        },
        params: `${this.currentPage}/${this.interPage}`,
      }
      let res = await getBestComHaveExpireUser(params);
      loadingInstance.close();
      if (res.code == 0) {
        this.tableData = res.result.items;
        this.dataSize = res.result.totalCount;
        this.tableData.map((item) => { 
          item.province = item.province !=null ? item.province:"";
          item.city = item.city !=null ? item.city:"";
          item.area = `${item.province}  ${item.city}`;
          item.createTime = item.createTime != null ? item.createTime.split("T")[0]:"";
          item.expiredTime = item.expiredTime != null ? item.expiredTime.split("T")[0]:"";
          // item.imgUrl = `https://apitest.ilaas.cn${item.avatar}`
          return item;
        });
        // console.log(this.tableData);
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 导出 exportUser
    async exportData() {
      let params = {
        queryString: this.filterInfo.keyVal,
        startDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[0]):null,
        endDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[1]):null,
        status: this.filterInfo.accountStatus,
      };
      let res = await exportUser(params);
      // console.log(res.status);
      if (res.status != undefined) {

      } else {
        let data = res;
        let fileName = "用户列表.xlsx";
        const blob =
          data instanceof Blob
            ? data
            : new Blob([data], {
                type: "application/vnd.ms-excel;charset=utf-8",
              });
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      }
    },
    // 查看用户详情
    lookDetails(row) {
      // console.log(row);return
      this.showDetails = true;
      this.$nextTick(() => {
        this.$refs.userDetails.getDetailInfo(row.id);
      });
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getUserInfo();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getUserInfo();
    },
    //获取表格裂宽
    getTableCellWidth() {
      this.tableHeight = this.$getTableHeight(70, "table-container");
      let headData = JSON.parse(JSON.stringify(this.headData));
      // console.log(headData)
      headData = headData.filter((val) => {
        return val.label !== "操作";
      });

      this.arrTableLength = this.$getTableCellLength({
        W: $(".table-container")[0].clientWidth,
        W2: 80,
        head: headData,
        data: this.tableData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.iLaaS-have-expire {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  .head-box {
    display: flex;
    justify-content: space-between;
    height: 7%;
    margin-bottom: 1%;
    .head-left {
      display: flex;
      align-items: center;
      .el-date-editor {
        width: 300px;
        margin-right: 10px;
      }
      .el-select {
        width: 200px;
        margin-right: 20px;
      }
      .el-input {
        width: 330px;
        margin-right: 10px;
      }
      .btn {
        height: 30px;
        border-radius: 15px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
      }
    }
    .head-right {
      .popover-box {
        margin-left: 30px;
        .icon-img {
          width: 14px !important;
          height: 14px !important;
          margin-right: 8px;
        }
        span {
          color: #333;
        }
      }
    }
  }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  // .img-box {
  //   width: 140px;
  img {
    width: 140px;
  }
  // }
}
</style>

