<template>
  <div class="parameter-type">
    <el-button @click="$parent.showTable = true" style="margin-bottom: 10px"
      >返回</el-button
    >
    <div class="head-box">
      <div class="btns-box">
        <el-button @click="add">新增</el-button>
        <el-button @click="dowTemplate">下载模板</el-button>
        <el-button @click="uploading">文件导入</el-button>
        <input class="inputFile" ref="file" type="file" />
      </div>
      <div class="head-right">
        <el-select
          v-model="typeVal"
          @change="selectType"
          placeholder="请选择参数类型"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <!-- <el-input
          placeholder="请输入搜索内容"
          v-model="keyVal">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input> -->
      </div>
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        :height="tableHeight"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in allHeadData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '城市'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <span>{{ setCityName(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <!-- <span
                style="color: #6aa9fb; cursor: pointer;margin: 0 15px;"
                @click="edit(scope.row)">
                编辑
              </span> -->
              <span style="color: red; cursor: pointer" @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <addParameters v-if="showDialog" :info="dialogInfo"></addParameters>
  </div>
</template>

<script>
import addParameters from "@/views/Dialog/addParameters.vue";
import {
  getTargetParameter,
  delTargetParameter2,
  getTemplate,
  uploadParameter,
  getParameterHead,
  getTarget,
} from "@/api/knowledgeData";
import { Loading } from "element-ui";
export default {
  name: "parameterType",
  components: {
    addParameters,
  },
  data() {
    return {
      loading: false,
      typeVal: "",
      typeList: [],
      keyVal: "",
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      tableData: [],
      allHeadData: [
        { align: "center", prop: "编号", label: "省份" },
        { align: "center", prop: "name", label: "城市" },
        { align: "center", prop: "phoneNumber", label: "地区" },
        { align: "center", prop: "报名类型", label: "年份" },
      ],
      arrTableLength: {},
      showDialog: false,
      dialogInfo: {
        type: "add",
        targetId: "",
        head: [], // 动态表头
      },
      throttle: true, //节流
      formData: null, // 选择的文件
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  computed: {},
  methods: {
    init() {
      this.getTargetData();
    },
    // 获取指标列表
    async getTargetData() {
      this.loading = true;
      let res = await getTarget();
      // console.log(res);
      this.loading = false;
      if (res.code == 0) {
        this.typeList = [];
        let data = res.result;
        data.forEach((item) => {
          this.typeList.push({
            label: item.name,
            value: item.id,
          });
        });
        this.typeVal = data[0].id;
        this.getHeadData();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
    // 获取动态表头
    async getHeadData() {
      this.dialogInfo.targetId = this.typeVal;
      let res = await getParameterHead(this.typeVal);
      // console.log(res);
      if (res.code == 0) {
        this.allHeadData = [
          { align: "center", prop: "country", label: "国家" },
          { align: "center", prop: "provinceName", label: "省份" },
          { align: "center", prop: "cityName", label: "城市" },
          { align: "center", prop: "area", label: "地区" },
          { align: "center", prop: "year", label: "年份" },
        ];
        let data = res.result;
        this.dialogInfo.head = data;
        data.forEach((item) => {
          this.allHeadData.push({
            align: "center",
            prop: item.code,
            label: item.name,
          });
        });
        this.allHeadData.push({
          align: "center",
          prop: "操作",
          label: "操作",
        });
      }
      this.getData();
    },
    // 选择参数类型
    selectType() {
      this.getHeadData();
    },
    // 获取列表数据
    async getData() {
      let params = `/${this.currentPage}/${this.interPage}/${this.typeVal}`;
      let res = await getTargetParameter(params);
      if (res.code == 0) {
        let data = res.result.items;
        this.dataSize = res.result.totalCount;
        this.tableData = [];
        data.forEach((item) => {
          let newObj = {
            targetId: item.targetId,
            id: item.id,
            country: "中国",
            provinceId: item.provinceId,
            provinceName: item.provinceName,
            cityId: item.cityId,
            cityName: item.cityName,
            area: item.area,
            year: item.year,
          };
          item.datas.forEach((item2) => {
            newObj[item2.code] = item2.value;
          });
          this.tableData.push(newObj);
        });
      }
    },
    // 下载模板
    async dowTemplate() {
      if (!this.throttle) return;
      this.throttle = false;
      let fileName = "";
      for (let i = 0; i < this.typeList.length; i++) {
        let item = this.typeList[i];
        if (item.value == this.typeVal) {
          fileName = `${item.label}模板.xlsx`;
          break;
        }
      }
      let res = await getTemplate(this.typeVal);
      this.throttle = true;
      let loadingInstance = Loading.service({ fullscreen: true });
      loadingInstance.close();
      let data = res;
      const blob =
        data instanceof Blob
          ? data
          : new Blob([data], {
              type: "application/vnd.ms-excel;charset=utf-8",
            });
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },
    // 上传参数
    uploading() {
      // uploadParameter
      this.$refs.file.value = null;
      let input = document.querySelector(".inputFile");
      let newInput = input.cloneNode(true);
      newInput.setAttribute("type", "file");
      newInput.setAttribute("accept", "xlsx/*");
      newInput.click();
      document.body.appendChild(newInput);
      newInput.addEventListener("change", () => {
        const inputFile = newInput.files[0];
        let fileType = inputFile.name.split(".");
        fileType = fileType[fileType.length - 1];
        if (fileType == "xlsx" || fileType == "xls") {
          const formData = new FormData();
          formData.append("file", inputFile);
          formData.append("TargetId", this.typeVal);
          let parent = newInput.parentElement;
          parent.removeChild(newInput);
          uploadParameter(formData).then((res) => {
            if (res.code == 200) {
              this.$message.success("上传成功");
              this.getData();
            }
          });
        } else {
          let parent = newInput.parentElement;
          parent.removeChild(newInput);
          this.$message.warning("上传文件只能是Excel格式!");
        }
      });
      let timeIdTwo = setTimeout(function () {
        let parent = newInput.parentElement;
        parent.removeChild(newInput);
        clearTimeout(timeIdTwo);
      }, 20000);
    },
    // 设置城市名称
    setCityName(row) {
      // console.log(row);
      if (!row.cityName && row.provinceName) {
        let type = row.provinceName.substr(-1);
        return `全${type}`;
      } else if (row.cityName) {
        return row.cityName;
      } else {
        return;
      }
    },
    // 编辑
    edit(row) {},
    // 删除
    del(row) {
      this.$confirm(`是否确认删除当前行数据？`)
        .then((_) => {
          this.delParameter(row);
        })
        .catch((_) => {});
    },
    async delParameter(row) {
      let res = await delTargetParameter2({ ids: [row.id] });
      if (res.code == 200) {
        this.getData();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
    // 新增
    add() {
      this.showDialog = true;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    //获取表格裂宽
    getTableCellWidth() {
      this.tableHeight = this.$getTableHeight(70, "table-container");
      let headData = JSON.parse(JSON.stringify(this.headData));
      // console.log(headData)
      headData = headData.filter((val) => {
        return val.label !== "操作";
      });

      this.arrTableLength = this.$getTableCellLength({
        W: $(".table-container")[0].clientWidth,
        W2: 80,
        head: headData,
        data: this.tableData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.parameter-type {
  // width: 100%;
  // padding: 20px;
  // height: 100%;
  // box-sizing: border-box;
  padding-bottom: 20px;
  .el-button {
    height: 40px;
    border-radius: 20px;
    color: rgba(0, 148, 255, 0.7);
    border: 1px solid rgba(0, 148, 255, 0.7);
  }
  .btns-box {
    display: flex;
    position: relative;
    .inputFile {
      position: absolute;
      top: -50px;
      left: 0;
      width: 2px;
      height: 2px;
      border-radius: 80px;
    }
  }
  .head-box {
    display: flex;
    justify-content: space-between;
    height: 7%;
    margin-bottom: 1%;
    .head-right {
      display: flex;
      align-items: center;
      .el-select {
        margin-right: 20px;
      }
    }
  }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
}
</style>
