<template>
  <div class="add-compliance-user-dialog">
    <el-dialog
      title="合规指引用户"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%"
    >
      <div class="row-box">
        <p class="label"><span>* </span>姓名：</p>
        <el-input placeholder="请输入姓名" v-model="form.userName"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          手机：
        </p>
        <el-input placeholder="请输入手机" v-model="form.telephone"></el-input>
      </div>
      <div class="row-box">
        <p class="label"><span>* </span>邮箱：</p>
        <el-input placeholder="请输入邮箱" v-model="form.email"></el-input>
      </div>
      <div class="row-box">
        <p class="label"><span>* </span>公司：</p>
        <el-input placeholder="请输入公司" v-model="form.name"></el-input>
      </div>
      <div class="row-box">
        <p class="label"><span>* </span>用户身份：</p>
        <el-select v-model="form.type" placeholder="请选择用户身份">
          <el-option
            v-for="item in identityList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row-box" v-if="isAmmount">
        <p class="label"><span></span>渠道来源：</p>
        <el-select v-model="form.manageUserId" placeholder="请选择渠道来源">
          <el-option
            v-for="item in qudaoList"
            :key="item.id"
            :label="item.userName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">启用状态：</p>
        <el-switch
          v-model="form.isEnabled"
          active-color="#13ce66"
          inactive-color="#ff3737"
        >
        </el-switch>
      </div>
      <div class="row-box">
        保存后将
        <span :style="`color: ${form.status ? '#13ce66' : '#ff3737'}`">
          {{ form.status ? "启用" : "禁用" }}
        </span>
        用户状态，请谨慎操作
      </div>
      <div class="row-box" v-if="isAmmount">
        <p class="label"><span></span>追加余额：</p>
        <el-input
          placeholder="请输入将要追加给当前账户的账户额度"
          v-model="form.amount"
        ></el-input>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import axiosa from "axios";
import {
  addComplianceUser,
  editComplianceUser,
  detailComplianceUser,
  getQudaoUsers,
} from "@/api/clientOperation.js";
export default {
  name: "addComplianceUserDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
    isAmmount: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isEdit: false,
      dialogVisible: true,
      form: {
        // nickName: "", // 昵称
        userName: "", // 姓名
        telephone: "", // 手机号
        email: "", // 邮箱
        name: "", // 公司
        type: null, // 身份
        status: 2,
        amount: 0, //追加给账户的额度
        manageUserId: null, //绑定用户来源渠道
        registerIP: "",
        isEnabled: true,
        // position: "", // 职务
        // provinceId: null, // 省份
        // cityId: null, // 城市
        // isVip: false, // 是否为会员
        // useCount: null, // 会员体验天数
        // expiredTime:null,//会员体验时间
        // address:"",//会员地址
      },
      submitStatus: false,
      showOperation: false,
      identityList: [
        { value: 1, label: "企业" },
        { value: 2, label: "审计师事务所" },
        { value: 3, label: "律师事务所" },
      ],
      qudaoList: [
        {
          id: 0,
          userName: "不设置",
          isAdmin: false,
          realName: "不设置",
          telephone: "",
          roleId: 0,
        },
      ],
      ipAddress: "",
    };
  },
  created() {
    //获取客户端IP
    this.fetchIPAddress();
    this.init();
  },
  mounted() {},
  methods: {
    async fetchIPAddress() {
      try {
        // 发送GET请求
        const response = await axiosa.get("https://api.ipify.org/?format=json");
        //console.log('ipAddress-response', JSON.stringify(response))
        // 将响应数据中的IP地址赋值给data中的ipAddress
        if (response.status == 200) {
          this.ipAddress = response.data.ip;
          console.log("ipAddress-ipAddress", this.ipAddress);
          this.form.registerIP = this.ipAddress;
        }
      } catch (error) {
        // 捕获请求过程中的任何错误
        console.error("获取IP地址失败:", error);
        // 可以设置一些错误处理逻辑，比如显示错误信息
        this.ipAddress = "";
      }
    },
    init() {
      // this.getAddressData();
      if (this.info.type == "edit") {
        this.getData();
        this.isEdit = true;
      }
      console.log(this.info);
      //获取渠道数据
      this.getQudao();
    },
    async getQudao() {
      //获取渠道用户列表
      var qudao = await getQudaoUsers();
      console.log("qudao", JSON.stringify(qudao));
      var that = this;
      if (qudao.code == 0) {
        qudao.result.items.forEach(function (item, index) {
          that.qudaoList.push(item);
        });
      }
    },
    async getData() {
      let res = await detailComplianceUser(this.info.item.id);
      if (res.code == 0) {
        let data = res.result;
        if (data.status == 2) {
          data.isEnabled = true;
        } else {
          data.isEnabled = false;
        }
        this.form = {
          // nickName: data.nickName ,
          userName: data.userName,
          telephone: data.phoneNumber,
          email: data.email,
          name: data.enterprise,
          status: data.status,
          type: data.type,
          id: data.id,
          amount: 0, //追加给账户的额度
          manageUserId: data.manageUserId,
          isEnabled: data.status == 2 ? true : false,
        };
      }
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (!this.form.userName) {
        this.$message.warning("请输入姓名!");
        return;
      }
      var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      if (!re.test(this.form.telephone)) {
        this.$message.warning("请正确输入手机号吗!");
        return;
      }
      if (!this.form.email) {
        this.$message.warning("请输入邮箱!");
        return;
      }
      // this.form.email = this.form.email||null;
      if (!this.form.name) {
        this.$message.warning("请填写公司名称!");
        return;
      }
      if (!this.form.type) {
        this.$message.warning("请选择用户身份!");
        return;
      }
      //let params = JSON.parse(JSON.stringify(this.form));
      let params = this.form;
      if (params.isEnabled) {
        params.status = 2;
      } else {
        params.status = 3;
      }
      // console.log(params);return
      this.submitStatus = true;
      let res = {};
      if (this.info.type == "add") {
        res = await addComplianceUser(params);
      } else {
        res = await editComplianceUser(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.$parent.init();
        this.cancel();
      }
      // else {
      //   this.$message({
      //     message: res.message,
      //     type: "warning",
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-compliance-user-dialog {
  .el-dialog__wrapper {
    ::v-deep .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      margin-top: 5vh !important;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #0094ff;
        span {
          color: #fff;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
          .add-box {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 190px;
            height: 152px;
            margin-bottom: 14px;
            border-radius: 10px;
            background: #f0f3fb;
            cursor: pointer;
            img {
              width: 30px;
              height: 30px;
              margin-bottom: 10px;
            }
            .inputFile {
              position: absolute;
              top: -50px;
              left: 0;
              width: 2px;
              height: 2px;
              border-radius: 80px;
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
