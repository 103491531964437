<template>
  <div class="risk-tool">
    <div class="head-box">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="item in tabsList" :key="item.id" :label="item.label" :name="item.name"></el-tab-pane>
      </el-tabs>
      <numberTool v-if="activeName == '风控数字工具管理'"></numberTool>
      <specialClass v-else-if="activeName == '专项类管理'"></specialClass>
      <suitedReport v-else-if="activeName == '匹配报告管理'"></suitedReport>
    </div>
  </div>
</template>

<script>
import numberTool from "./modules/numberTool.vue"
import specialClass from "./modules/specialClass.vue"
import suitedReport from "./modules/suitedReport.vue"
export default {
  name: "riskTool",
  components: {
    numberTool,
    specialClass,
    suitedReport,
  },
  data() {
    return {
      activeName: "风控数字工具管理",
      tabsList: [
        {id: 1, label: "风控数字工具管理", name: "风控数字工具管理"},
        {id: 2, label: "专项类管理", name: "专项类管理"},
        {id: 3, label: "匹配报告管理", name: "匹配报告管理"},
      ],
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
    },
}
}
</script>

<style lang="scss" scoped>
  .risk-tool {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
  }
</style>

