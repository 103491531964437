<template>
  <div class="relevance-risk">
    <el-dialog
      title="关联风险点"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      width="960px"
    >
      <div class="row-box">
        <p class="label">
          <span>* </span>
          风险点名称：
        </p>
        <el-input
          type="textarea"
          autosize
          placeholder="选择列表风险点名称自动带出"
          v-model="form.name"
          disabled
        ></el-input>
      </div>
      <div class="row-box search-box">
        <div class="span">职业阶段：</div>
        <el-select
          v-model="postCycle"
          clearable
          @change="search"
          placeholder="请选择职业阶段"
        >
          <el-option
            v-for="item in postCycleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div class="span">专项类：</div>
        <el-select
          v-model="businessModule"
          clearable
          @change="search"
          placeholder="请选择专项类"
        >
          <el-option
            v-for="item in businessModuleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input placeholder="输入风险点标题" v-model="keyVal"></el-input>
        <p class="btn-p" @click="search">搜索</p>
      </div>
      <div class="table-container">
        <el-table
          :height="tableHeight"
          border
          :data="tableData"
          style="width: 100%"
          v-loading="loading"
          fit
        >
          <template v-for="(head, index) in headData">
            <el-table-column
              :align="head.align"
              v-if="head.label === '选择'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
            >
              <template slot-scope="scope">
                <!-- {{scope.$index+1}} -->
                <el-checkbox
                  class="checkbox"
                  v-model="scope.row.check"
                  @change="check(scope.row.id)"
                ></el-checkbox>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :align="head.align"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
              show-overflow-tooltip
            ></el-table-column>
          </template>
        </el-table>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="interPage"
            layout="total, sizes, prev, pager, next, jumper"
            background
            :total="dataSize"
          >
          </el-pagination>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import {
  getLaborLawsList,
  getLaborLawsDetail,
  getRiskBusinessModule,
} from "@/api/knowledgeData";
import { getRiskPlace } from "@/api/knowledgeData";
export default {
  name: "relevanceRisk",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        title: "", // 标准名称
      },
      postCycle: null,
      businessModule: null,
      keyVal: "",
      dataSize: 0,
      currentPage: 1,
      interPage: 15,
      tableHeight: 500,
      loading: false,
      tableData: [],
      headData: [
        { align: "center", prop: "选择", label: "选择", width: "100px" },
        { align: "left", prop: "code", label: "风险点编码", width: "100px" },
        { align: "center", prop: "name", label: "风险点名称", width: "" },
        {
          align: "center",
          prop: "postCycleName",
          label: "职业阶段",
          width: "100px",
        },
        {
          align: "center",
          prop: "businessModuleName",
          label: "专项类",
          width: "100px",
        },
        {
          align: "center",
          prop: "isEnable",
          label: "启用状态",
          width: "100px",
        },
      ],
      postCycleList: [
        // 职业阶段数组
        { value: 1, label: "职前" },
        { value: 2, label: "入职" },
        { value: 3, label: "在职" },
        { value: 4, label: "离职" },
      ],
      businessModuleList: [
        // 业务流程数组
        // { value: 1, label: "招聘甄选" },
        // { value: 2, label: "培训开发" },
        // { value: 3, label: "绩效管理" },
        // { value: 4, label: "薪酬管理" },
        // { value: 5, label: "人事规章" },
        // { value: 6, label: "劳动关系" },
        // { value: 7, label: "组织发展" },
      ],
    };
  },
  created() {
    this.init();
  },
  mounted() {
    this.getBusinessModule();
  },
  methods: {
    init() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      if (this.info.lawId) {
        // this.form.title = this.info.lawName;
        // this.keyVal = this.info.lawName;
      }
      this.getData();
    },
    // 获取法规列表
    async getData() {
      let params = {
        info: {
          postCycle: this.postCycle || null,
          businessModule: this.businessModule || null,
          lawName: this.keyVal,
        },
        params: `${this.currentPage}/${this.interPage}`,
      };
      this.loading = true;
      let res = await getRiskPlace(params);
      this.loading = false;
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        console.log(data);
        data.forEach((item) => {
          item.check = false;
          return item;
        });
        this.tableData = data;
      }
    },
    // 获取业务类型 getRiskBusinessModule
    async getBusinessModule() {
      this.businessModuleList = [];
      let res = await getRiskBusinessModule();
      if (res.code == 0) {
        let data = res.result;
        data.forEach((item) => {
          this.businessModuleList.push({
            label: item.name,
            value: item.id,
          });
        });
      }
    },
    // 搜索
    search() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      this.getData();
    },
    // 选择法规
    check(id) {
      this.tableData.forEach((item) => {
        if (id != item.id) {
          item.check = false;
        } else {
          this.form = item;
        }
      });
    },

    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    cancel() {
      this.$emit("cancelRisk");
    },
    async submit() {
      let status = false;
      this.tableData.map((item) => {
        if (item.check) {
          status = true;
        }
      });
      if (!status) {
        this.$message.warning(`请选择风险点！`);
        return;
      }
      this.$emit("selectRisk", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
.relevance-risk {
  .el-dialog__wrapper {
    margin-top: -100px;
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        p {
          margin-bottom: 0 !important;
        }
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
          .law-p {
            color: #0094ff;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
        .search-box {
          display: flex;
          align-items: center;
          justify-content: center;
          .span {
            white-space: nowrap;
          }
          .el-select {
            margin: 0 10px;
          }
          .selects {
            max-width: 350px;
          }
          .el-input {
            width: 48%;
          }
          .btn-p {
            font-weight: 600;
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            margin-left: 10px;
            padding: 0 20px;
            border-radius: 8px;
            width: 80px;
            cursor: pointer;
            text-align: center;
          }
        }
        .table-container {
          height: 400px;
          :v-deep .el-table__body {
            height: 400px;
            // .el-checkbox {
            //   .el-checkbox__input {
            //     line-height: 20px !important;
            //   }
            // }
          }
        }
        :v-deep .checkbox {
          line-height: 14px !important;
          .el-checkbox__input {
            line-height: 14px !important;
            margin-bottom: 0 !important;
          }
        }
        .details-box {
          .el-button {
            height: 32px;
            border-radius: 16px;
            color: rgba(0, 148, 255, 0.7);
            border: 1px solid rgba(0, 148, 255, 0.7);
            margin-bottom: 5px;
          }
          .content-box {
            display: flex;
            justify-content: space-between;
            height: 400px;
            overflow-y: auto;
            margin: 0 32px;
            .lawLeft {
              width: 25%;
              background-color: #f5f7fa;
              padding: 20px;
              box-sizing: border-box;
              border-radius: 10px;
              p {
                font-size: 14px;
                color: #000;
                line-height: 36px;
                margin-bottom: 10px;
                span {
                  font-size: 14px;
                  font-weight: 600;
                }
                .source {
                  font-size: 14px;
                  font-weight: 400;
                  color: #000;
                  cursor: pointer;
                }
              }
            }
            .lawRight {
              width: 73%;
              background-color: #f5f7fa;
              padding: 80px;
              box-sizing: border-box;
              border-radius: 10px;
              p {
                font-size: 14px;
                color: #000;
                line-height: 36px;
                margin-bottom: 10px;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
:v-deep .el-pager {
  margin: 0 10px !important;
}
</style>
