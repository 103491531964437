<template>
  <div class="add-labour-harmonious-project-dialog">
    <el-dialog
      title="项目"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="460px"
    >
      <div class="row-box">
        <p class="label">
          <span>* </span>
          项目名称：
        </p>
        <el-input placeholder="请输入项目名称" v-model="form.name"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          计分方式：
        </p>
        <el-select v-model="form.way" placeholder="请选择">
          <el-option
            v-for="item in wayList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="row-box">
        <p class="label">
          <span>* </span>
          排序：
        </p>
        <el-input placeholder="请输入排序" v-model="form.sort"></el-input>
      </div> -->
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addHarmoniousProject, editHarmoniousProject } from "@/api/knowledgeData";
export default {
  name: "addLabourHarmoniousProjectDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        name: "", // 项目名称
        way: null, // 计分方式
        // sort: null, // 排序
      },
      submitStatus: false,
      wayList: [
        { value: 1, label: "加分" },
        { value: 2, label: "减分" },
        { value: 3, label: "清零" },
      ],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // console.log(this.info);
      if (this.info.type == "edit") {
        this.form = JSON.parse(JSON.stringify(this.info.item));
      } else {
        this.form.parentId = this.info.parentId;
      }
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (this.submitStatus) return;
      let verifyObj = {
        name: "项目名称",
        way: "计分方式",
      };
      for (let key in verifyObj) {
        if (!this.form[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      let params = JSON.parse(JSON.stringify(this.form));
      // console.log(params);return
      this.submitStatus = true;
      let res = {};
      if (this.info.type == "add") {
        res = await addHarmoniousProject(params);
      } else {
        res = await editHarmoniousProject(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-labour-harmonious-project-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
