<template>
  <div class="labor-laws-precedent">
    <div class="head-box"> 
      <div class="head-left">
        <el-button @click="addUser">新增判例</el-button>
        <span>采集来源：</span>
        <el-select v-model="isTask" class="selects" clearable placeholder="请选择采集来源">
          <el-option
            v-for="item in taskList"
            :key="item.taskId"
            :label="item.taskName"
            :value="item.taskId">
          </el-option>
        </el-select>
        <el-button @click="gather">采集</el-button>
      </div>
      <div class="head-right">
        <span>来源：</span>
        <el-select v-model="filterInfo.source" class="selects" clearable @change="init" placeholder="请选择来源">
          <el-option
            v-for="item in sourceList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <span>案由：</span>
        <el-cascader
          v-model="causeOfActionList"
          clearable
          @change="setCascader"
          :options="postCycleList"
          :props="{ value: 'id', label: 'name', children: 'childs', expandTrigger: 'hover' }">
        </el-cascader>
        <span>地域：</span>
        <el-select v-model="filterInfo.region" clearable @change="init" placeholder="请选择地域">
          <el-option
            v-for="item in areaList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <span>年份：</span>
        <el-select v-model="filterInfo.year" class="selects" clearable @change="init" placeholder="请选择年份">
          <el-option
            v-for="item in yearList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入搜索内容"
          @keyup.enter.native="init"
          v-model="filterInfo.name">
          <i @click="init" slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
        <div class="popover-box">
          <el-popover
            popper-class="item-popover"
            placement="bottom"
            width="416"
            trigger="click"
          >
            <el-checkbox-group v-model="headFilter">
              <el-checkbox
                v-for="(item, index) in headFilterList"
                :label="index"
                :key="index"
                @change="filtrate"
                class="checkbox-line">
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
            <el-button type="text" slot="reference"
              ><img src="@/assets/img/hmc_shaixuan.png" />
              <span class="c-span">筛选</span></el-button
            >
          </el-popover>
        </div>
      </div>
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        height="650"
        border
        :data="tableData"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '是否启用'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <span v-if="scope.row.isEnable" style="color: green;">是</span>
              <span v-else style="color: #ccc;">否</span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '来源'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <span v-if="scope.row.source === 1">平台</span>
              <span v-else>采集</span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <span
                style="color: #6aa9fb; cursor: pointer; margin-right: 20px;"
                @click="editUser(scope.row)">
                修改
              </span>
              <span
                style="color: red; cursor: pointer;"
                @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <addCaseLawDialog v-if="showDialog" :info="detailsInfo"></addCaseLawDialog>
    <setJurisdictionDialog v-if="showDialog2" :info="jurisdictionInfo"></setJurisdictionDialog>
  </div>
</template>

<script>
import addCaseLawDialog from '@/views/Dialog/addCaseLawDialog.vue';
import setJurisdictionDialog from '@/views/Dialog/setJurisdictionDialog.vue';
import { getCauseofaction, delPrecedent, getPrecedenttList, gatherBazhuayuTask, gatherPrecedenttList } from "@/api/knowledgeData.js"
export default {
  name: "laborLawsPrecedent",
  components: {
    addCaseLawDialog,
    setJurisdictionDialog,
  },
  data() {
    return {
      loading: false,
      keyVal: "",
      taskList: [], // 采集任务列表
      isTask: "", // 选择的采集任务
      filterInfo: {
        causeOfAction: null,
        region: "",
        year: null,
        name: "",
        source: null,
      },
      causeOfActionList: [],
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 15, //每页显示条数
      headFilter: [0,1,2,3,4,5,6,7,8],
      headData: [],
      headFilterList: [
        { align: "center", prop: "name", label: "判例名称", width: "" },
        { align: "center", prop: "caseNo", label: "案号", width: "200px" },
        { align: "center", prop: "causeOfActionName", label: "案由", width: "140px" },
        { align: "center", prop: "caseType", label: "案件类型", width: "100px" },
        { align: "center", prop: "source", label: "来源", width: "100px" },
        { align: "center", prop: "trialProcedure", label: "审理程序", width: "120px" },
        // { align: "center", prop: "rank", label: "地域", width: "80px" },
        { align: "center", prop: "trialCourt", label: "审理法院", width: "" },
        { align: "center", prop: "refereeTime", label: "裁判日期", width: "120px" },
        { align: "center", prop: "isEnable", label: "是否启用", width: "80px" },
      ],
      tableData: [],
      areaList: [ // 地域列表
        {label: "最高人民法院", value: "最高人民法院"},
        {label: "北京市", value: "北京市"},
        {label: "天津市", value: "天津市"},
        {label: "河北省", value: "河北省"},
        {label: "山西省", value: "山西省"},
        {label: "内蒙古自治区", value: "内蒙古自治区"},
        {label: "辽宁省", value: "辽宁省"},
        {label: "吉林省", value: "吉林省"},
        {label: "黑龙江省", value: "黑龙江省"},
        {label: "上海市", value: "上海市"},
        {label: "江苏省", value: "江苏省"},
        {label: "浙江省", value: "浙江省"},
        {label: "安徽省", value: "安徽省"},
        {label: "福建省", value: "福建省"},
        {label: "江西省", value: "江西省"},
        {label: "山东省", value: "山东省"},
        {label: "河南省", value: "河南省"},
        {label: "湖北省", value: "湖北省"},
        {label: "广东省", value: "广东省"},
        {label: "广西壮族自治区", value: "广西壮族自治区"},
        {label: "海南省", value: "海南省"},
        {label: "重庆市", value: "重庆市"},
        {label: "四川省", value: "四川省"},
        {label: "贵州省", value: "贵州省"},
        {label: "云南省", value: "云南省"},
        {label: "西藏自治区", value: "西藏自治区"},
        {label: "陕西省", value: "陕西省"},
        {label: "甘肃省", value: "甘肃省"},
        {label: "青海省", value: "青海省"},
        {label: "宁夏回族自治区", value: "宁夏回族自治区"},
        {label: "新疆维吾尔自治区", value: "新疆维吾尔自治区"},
      ],
      yearList: [
        {label: "2023", value: 2023},
        {label: "2022", value: 2022},
        {label: "2021", value: 2021},
        {label: "2020", value: 2020},
        {label: "2019", value: 2019},
        {label: "2018", value: 2018},
        {label: "2017", value: 2017},
        {label: "2016", value: 2016},
        {label: "2015", value: 2015},
        {label: "2014", value: 2014},
        {label: "2013", value: 2013},
        {label: "2012", value: 2012},
        {label: "2011", value: 2011},
        {label: "2010", value: 2010},
      ],
      sourceList: [
        {label: "全部", value: null},
        {label: "平台", value: 1},
        {label: "采集", value: 2},
      ],
      postCycleList: [],
      detailsInfo: {
        type: "add",
      },
      showDialog: false, // 用户弹框
      showDialog2: false, // 权限弹框
      jurisdictionInfo: {},
    }
  },
  created() {
    this.init();
    this.getCauseData();
    // this.gatherTask();   采集账号被封，暂时注释
  },
  mounted() {
  },
  methods: {
    init() {
      // this.currentPage = 1;
      // this.interPage = 15;
      this.dataSize = 0;
      this.filtrate();
    },
    async getCauseData() {
      let res = await getCauseofaction();
      if (res.code == 0) {
        let data = res.result;
        data.forEach(item => {
          if (item.childs.length > 0){
            let newArr = item.childs;
            newArr.forEach(item2 => {
              if (item2.childs.length == 0) {
                item2.childs = null;
              }
            })
          } else {
            item.childs = null;
          }
        });
        this.postCycleList = res.result;
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    setCascader() {
      // console.log(this.causeOfActionList);
      let len = this.causeOfActionList.length;
      if (len > 0) {
        this.filterInfo.causeOfAction = this.causeOfActionList[len - 1];
      } else {
        this.filterInfo.causeOfAction = null;
      }
      this.init();
    },
    // 获取采集任务列表
    async gatherTask() {
      let res = await gatherBazhuayuTask();
      if (res.code == 200) {
        let data = JSON.parse(res.result);
        // console.log(data);
        this.taskList = data.data;
      }
    },
    // 采集
    async gather() {
      if (!this.isTask) return;
      let params = {
        taskId: this.isTask,
        count: 100,
      }
      let res = await gatherPrecedenttList(params);
      // console.log(res);
      if (res.code == 200) {
        this.$message({
          message: res.message,
          type: 'success'
        });
        this.init();
      }
    },
    filtrate() {
      this.headData = [];
      let newArr = [];
      // return
      if (this.headFilter.length > 0) {
        this.headFilter.sort(function (a,b) {
            return a-b;
        })
        this.headFilter.forEach(item => {
          newArr.push(this.headFilterList[item])
        })
      }
      newArr.push({ align: "center", prop: "操作", label: "操作", width: "140px" });
      this.$nextTick(()=> {
        this.headData = newArr;
        this.getData();
      })
    },
    // 获取判例列表
    async getData() { 
      this.filterInfo.year = this.filterInfo.year ? this.filterInfo.year:null;
      this.filterInfo.source = this.filterInfo.source ? this.filterInfo.source:null;
      this.filterInfo.causeOfAction = this.filterInfo.causeOfAction ? this.filterInfo.causeOfAction:null;
      let params = {
        info: this.filterInfo,
        params: `${this.currentPage}/${this.interPage}`,
      }
      this.loading = true;
      let res = await getPrecedenttList(params);
      this.loading = false;
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        this.tableData = res.result.items;
        this.tableData.map(item => {
          return item.refereeTime = item.refereeTime.substring(0,10);
        })
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    // 新增判例
    addUser() {
      this.detailsInfo = {
        type: "add",
      }
      this.showDialog = true;
    },
    // 编辑判例
    editUser(row) {
      this.detailsInfo = {
        type: "edit",
        item: row,
      }
      this.showDialog = true;
    },
    // 删除判例
    del(row) {
      this.$confirm(`请确认是否删除判例【 ${row.name} 】？`)
        .then((_) => {
          this.delData({ids: [row.id]});
        })
        .catch((_) => {});
    },
    async delData(id) {
      let res = await delPrecedent(id);
      if (res.code === 200) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.init();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 权限
    setJurisdiction(row) {
      this.jurisdictionInfo = row;
      this.showDialog2 = true;
    },
  }
}
</script>

<style lang="scss" scoped>
  .labor-laws-precedent {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    .head-box{
      display: flex;
      justify-content: space-between;
      height: 7%;
      margin-bottom: 10px;
      .head-left {
        display: flex;
        align-items:  center;
        span {
          white-space: nowrap;
        }
        .el-select {
          width: 120px;
          margin: 0 10px;
        }
        .el-button {
          height: 40px;
          border-radius: 20px;
          color: rgba(0, 148, 255,.7);
          border: 1px solid rgba(0, 148, 255,.7);
          margin-right: 10px;
        }
      }
      .head-right {
        display: flex;
        align-items: center;
        span {
          white-space: nowrap;
        }
        .el-select,
        .el-cascader {
          width: 130px;
          margin: 0 10px;
        }
        .selects {
          max-width: 200px;
        }
        .el-input {
          max-width: 200px;
          margin-right: 10px;
        }
        .btn {
          height: 40px;
          border-radius: 20px;
          color: rgba(0, 148, 255,.7);
          border: 1px solid rgba(0, 148, 255,.7);
          // margin-right: 10px;
        }
        .popover-box {
          margin-left: 30px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 8px;
          }
          span {
            color: #333;
          }
        }
      }
    }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  }
</style>

