<template>
  <div class="hr-rules">
    <div class="tabs-box">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="总表" name="1">
          <!-- <keep-alive> -->
            <tablePage v-if="activeName == '1'" ref="tablePage"></tablePage>
          <!-- </keep-alive> -->
        </el-tab-pane>
        <el-tab-pane label="仪表盘" name="2">
          <!-- <keep-alive> -->
            <statisticsPage v-if="activeName == '2'"></statisticsPage>
          <!-- </keep-alive> -->
        </el-tab-pane>
      </el-tabs>
      <el-button v-if="activeName == '1'" class="btn" @click="add">新增</el-button>
      <!-- <div class="right-box">
        <el-date-picker
          v-model="value1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div> -->
    </div>
  </div>
</template>

<script>
import tablePage from './modules/tablePage.vue'
import statisticsPage from './modules/statisticsPage.vue'
export default {
  name: "HRrules",
  components: {
    tablePage,
    statisticsPage
  },
  data() {
    return {
      activeName: "1",
    };
  },
  watch: {
  },
  created() {
  },
  mounted() {},
  methods: {
    // 切换tab
    handleClick() {},
    add() {
      this.$refs.tablePage.add();
    },
  }
}
</script>

<style lang="scss" scoped>
.hr-rules {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 20px;
  height: 100%;
  box-sizing: border-box;
  .tabs-box {
    position: relative;
    width: 100%;
    ::v-deep .el-tabs__item {
      padding: 0 30px !important;
    }
    .btn {
      position: absolute;
      top: 2px;
      right: 0;
      // transform: translate(-50%, -50%);
      height: 30px;
      border-radius: 20px;
      color: rgba(0, 148, 255,.7);
      border: 1px solid rgba(0, 148, 255,.7);
      margin-right: 20px;
    }
  }
}
</style>

