<template>
<!-- 合同管理 -->
  <div class="iLaaS-contract-management">
  <h1>合同管理</h1>
    <!-- <router-view class="children-view"></router-view> -->
  </div>
</template>

<script>
export default {
  name: "iLaaSContractManagement",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .iLaaS-contract-management {
  }
</style>

