<template>
  <div class="add-file-dialog">
    <el-dialog
      :title="title"
      center
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div class="center-box">
        <div class="row-box">
          <p class="label">文件名称：</p>
          <el-input
            v-model="myInfo.title"
            placeholder="请输入文件名称（重命名）"
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">文件归属：</p>
          <el-input v-model="myInfo.categoryName" disabled></el-input>
        </div>
        <div class="row-box">
          <p class="label">排序：</p>
          <el-input
            v-model="myInfo.sort"
            placeholder="请输入排序，数值为正整数"
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">上传：</p>
          <div class="add-box" v-show="!fileInfo.name">
            <el-upload
              class="avatar-uploader"
              :action="objData.host"
              :data="objData"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-progress="uploadVideoProcess"
              :on-success="upSuccess"
            >
              <img src="@/assets/img/add-icon.png" alt="" />
              <p>点击上传</p>
            </el-upload>
          </div>
          <div
            v-if="fileInfo.name"
            class="file-card"
            :class="showOperation ? 'is-file-card':''"
            @mouseenter="showOperation = true"
            @mouseleave="showOperation = false">
            <img :src="selectImg(fileInfo.name)" alt="" />
            <p :title="fileInfo.name">{{fileInfo.name}}</p>
            <el-progress v-if="progressFlag" :percentage="loadProgress"></el-progress>
            <div class="shadow-box" v-else>
              <button @click="delFile(fileInfo)">删除</button>
            </div>
          </div>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">关 闭</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPolicyToken } from "@/api/index.js";
import { addLaborcontract } from "@/api/knowledgeData.js";
var OSS = require('ali-oss');
export default {
  name: "addFileDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      title: "新增文件",
      dialogVisible: true,
      myInfo: {
        title: "",
        categoryId: 0,
        categoryName: "",
        filePath: "",
        sort: 0,
      },
      fileInfo: {},
      isFile: {},
      ossParams: {
        // oss参数
        expireTime: 120,
        uploadDir: "laborcontract/",
      },
      ossInfo: {},
      filePathStr: '', // 文件上传成功的路径
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "",
        dir: "",
      },
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 关闭进度条
      showOperation: false, // 鼠标移入显示操作按钮
      submitStatus: false,
    };
  },
  created() {
    // console.log(this.info);
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.myInfo = {
        title: "",
        categoryId: this.info.id,
        categoryName: `${this.info.treeName}`,
        filePath: "",
        sort: "",
      };
      let dates = this.$getDate(3).split('-');
      this.ossParams.uploadDir = `laborcontract/${dates[0]}/${dates[1]}`;
      this.getPolicyTokenInfo();
      // console.log(this.ossParams);
    },
    // 获取阿里云oss签名
    async getPolicyTokenInfo() {
      let params = JSON.parse(JSON.stringify(this.ossParams));
      params.BucketName = 'ilaas-template';
      let res = await getPolicyToken(params);
      if (res.code == 200) {
        this.ossInfo = JSON.parse(res.result);
        console.log(this.ossInfo);
      }
    },
    // 删除文件
    delFile() {
      this.$confirm(`请确认是否删除文件`)
        .then((_) => {
          this.delOssFile();
        })
        .catch((_) => {});
    },
    delOssFile() {
        this.fileInfo = {};
        this.myInfo.filePath = '';
      let client = new OSS({
          region: 'oss-cn-shenzhen',
          accessKeyId: this.ossInfo.accessid,
          accessKeySecret: '5ZUDWAJ0Gz4o52kubRVYCaJRRQRims',
          bucket: 'ilaas-template',
        })
        client.delete(this.filePathStr);
        this.filePathStr = '';
        // console.log(this.fileInfo);
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      let newArr = file.name.split(".");
      let fileType = newArr[newArr.length - 1];
      let newFileName = file.name;
      this.myInfo.title = this.myInfo.title.trim();
      if (this.myInfo.title) {
        newFileName = `${this.myInfo.title}.${fileType}`;
      }
      // console.log(fileType);
      // console.log(file,file.type);return
      let imgType = [
        "doc",
        "docx", // Word
        "xls",
        "xlsx", // Excel
        "ppt", // PPT
        "pdf", // PDF
      ];
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!imgType.includes(fileType)) {
        this.$message.warning("上传文件只能是 Word、Excel、PPT、PDF格式!");
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 50MB!");
        return;
      }
        let data = this.ossInfo;
        // console.log(data);
        this.objData = {
          OSSAccessKeyId: data.accessid,
          policy: data.policy,
          signature: data.signature,
          expire: data.expire,
          host: data.host,
          dir: data.dir,
          key: `${this.ossParams.uploadDir}/${new Date().getTime()}-${newFileName}`,
        };
        console.log(this.objData);
        this.filePathStr = this.objData.key;
        this.myInfo.title = newFileName;
        this.myInfo.filePath = this.objData.key;
        this.isFile = {
          name: newFileName,
          filePath: this.objData.key,
        }
    },
    // 上传成功事件
    upSuccess() {
      this.fileInfo = this.isFile;
    },
    // 上传文件进度
    uploadVideoProcess(event, file, fileList) {
      // console.log(event.percent);
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 1000); // 一秒后关闭进度条
      }
    },
    // 设置文件图片
    selectImg(info) {
      // console.log(info);
      let imgUrl = '';
      let valList = info.split('.');
      let fileType = valList[valList.length -1];
      if (fileType == 'doc' || fileType == 'docx') {
        imgUrl = require('@/assets/img/file-word.png')
      } else if (fileType == 'xls' || fileType == 'xlsx') {
        imgUrl = require('@/assets/img/file-excel.png')
      } else if (fileType == 'ppt') {
        imgUrl = require('@/assets/img/file-ppt.png')
      } else if (fileType == 'pdf') {
        imgUrl = require('@/assets/img/file-pdf.png')
      }
      return imgUrl;
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (this.submitStatus) return;
      if (!this.myInfo.sort || !(/(^[1-9]\d*$)/.test(this.myInfo.sort.trim()))) {
        this.$message.warning(`请正确输入排序（正整数）！`);
        this.myInfo.sort = '';
        return;
      }
      if (!this.myInfo.filePath) {
        this.$message.warning(`请上传文件！`);
        return;
      }
      let params = {
        title: this.myInfo.title,
        categoryId: Number(this.myInfo.categoryId),
        filePath: this.myInfo.filePath,
        sort: Number(this.myInfo.sort.trim()),
      };
      // console.log(JSON.stringify(params));
      this.submitStatus = true;
      let res = await addLaborcontract(params);
      this.submitStatus = false;
      // console.log(res);
      if (res.code == 200) {
        this.$parent.getTableInfo();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-file-dialog {
  ::v-deep .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .center-box {
          .row-box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .label {
              min-width: 72px;
              font-size: 14px;
              color: #606266;
              margin-right: 18px;
              white-space: nowrap;
            }
            .el-input {
              .el-input__inner {
                border-radius: 10px;
                border: 1px solid #d7dbda;
              }
            }
            .add-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 24%;
              height: 152px;
              margin-right: 1%;
              margin-bottom: 14px;
              border-radius: 10px;
              background: #f0f3fb;
              cursor: pointer;
              img {
                width: 30px;
                height: 30px;
                margin-bottom: 10px;
              }
              p {
                font-size: 16px;
                color: #6474c7;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
