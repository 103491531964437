<template>
  <div class="project-type">
      <div class="head-box">
        <el-button @click="add">新增项目</el-button>
      </div>
      <div class="table-container">
        <el-table
          v-loading="loading"
          border
          :data="tableData"
          style="width: 100%"
          fit
        >
          <template v-for="(head, index) in headData">
            <el-table-column
              :align="head.align"
              v-if="head.label === '序号'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              :align="head.align"
              v-else-if="head.label === '操作'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
            >
              <template slot-scope="scope">
                <span
                  style="color: #6aa9fb; cursor: pointer; margin: 0 15px"
                  @click="details(scope.row)"
                >
                  编辑
                </span>
                <span
                  style="color: red; cursor: pointer"
                  @click="del(scope.row)"
                >
                  删除
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :align="head.align"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
            ></el-table-column>
          </template>
        </el-table>
        <!-- <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="interPage"
            layout="total, sizes, prev, pager, next, jumper"
            background
            :total="dataSize"
          >
          </el-pagination>
        </div> -->
      <addLabourHarmoniousProjectDialog
        v-if="showDialog"
        :info="detailsInfo"
      ></addLabourHarmoniousProjectDialog>
    </div>
  </div>
</template>

<script> 
import addLabourHarmoniousProjectDialog from "@/views/Dialog/addLabourHarmoniousProjectDialog.vue";
import { getHarmoniousProject, delHarmoniousProject } from "@/api/knowledgeData";
export default {
  name: "projectType",
  components: {
    addLabourHarmoniousProjectDialog,
  },
  data() {
    return {
      loading: false,
      headData: [
        { align: "center", prop: "序号", label: "序号", width: "120px" },
        { align: "center", prop: "name", label: "项目名称", width: "" },
        { align: "center", prop: "wayName", label: "计分方式", width: "200px" },
        { align: "center", prop: "操作", label: "操作", width: "200px" },
      ],
      tableData: [
        {
          id: 1,
          name: "标准一",
        },
      ],
      showDialog: false, // 新增弹框
      detailsInfo: {
        type: "add",
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getData();
    },
    async getData() {
      let res = await getHarmoniousProject();
      if (res.code == 0) {
        let data = res.result;
        data.forEach(item => {
          item.wayName = item.way == 1 ? "加分":item.way == 2 ? "减分":"清零";
          return item
        });
        this.tableData = data;
      }
    },
    // 新增项目
    add() {
      this.detailsInfo = {
        type: "add",
      }
      this.showDialog = true;
    },
    // 查看详情
    details(item) {
      this.detailsInfo = {
        type: "edit",
        item: JSON.parse(JSON.stringify(item)),
      }
      this.showDialog = true;
    },
    // 删除
    del(item) {
      this.$confirm(`是否确认删除项目【${item.name}】`)
        .then((_) => {
          this.delQuestionnaire(item);
        })
        .catch((_) => {});
    },
    async delQuestionnaire(item) {
      let res = await delHarmoniousProject(item.id);
      if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.getData();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.project-type {
  .head-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .el-button {
      height: 40px;
      color: #4cb4ff;
      border-radius: 20px;
      border: 1px solid #4cb4ff;
    }
  }
}
</style>

