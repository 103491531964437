<template>
  <div class="add-contacts-dialog">
    <el-dialog
      :title="title"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="60%">
      <div class="row-box">
        <p class="label">
          <span>* </span>
          法规名称：
        </p>
        <el-input type="textarea" autosize placeholder="选择列表法规名称自动带出" v-model="form.name" disabled></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          条款：
        </p>
        <el-input type="textarea" autosize placeholder="条款" v-model="form.clause"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          条款内容：</p>
          <!-- :autosize="{ minRows: 2, maxRows: 6}" -->
        <el-input
          type="textarea"
          autosize
          placeholder="请输入条款内容"
          v-model="form.content">
        </el-input>
      </div>
      <div class="row-box search-box">
        <el-input placeholder="输入法规标题" v-model="keyVal"></el-input>
        <el-button @click="search">搜索</el-button>
      </div>
      <keep-alive>
        <div class="table-container" v-if="!detailsStatus">
          <el-table
            :height="tableHeight"
            border
            :data="tableData"
            style="width: 100%"
            v-loading="loading"
            fit
          >
            <template v-for="(head, index) in headData">
              <el-table-column
                :align="head.align"
                v-if="head.label === '选择'"
                :key="index"
                :prop="head.prop"
                :label="head.label"
                :width="head.width"
              >
                <template slot-scope="scope">
                  <!-- {{scope.$index+1}} -->
                  <el-checkbox v-model="scope.row.check" @change="check(scope.row.id)">{{scope.$index+1}}</el-checkbox>
                </template>
              </el-table-column>
              <el-table-column
                :align="head.align"
                v-else-if="head.label === '操作'"
                :key="index"
                :prop="head.prop"
                :label="head.label"
                :width="head.width"
              >
                <template slot-scope="scope">
                  <span
                    style="color: #6aa9fb; cursor: pointer"
                    @click="lookDetails(scope.row)">
                    查看
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                v-else
                :align="head.align"
                :key="index"
                :prop="head.prop"
                :label="head.label"
                :width="head.width"
                show-overflow-tooltip></el-table-column>
            </template>
          </el-table>
          <div>
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage"
              :page-sizes="[10, 15, 20]"
              :page-size="interPage"
              layout="total, sizes, prev, pager, next, jumper"
              background
              :total="dataSize"
            >
            </el-pagination>
          </div>
        </div>
      </keep-alive>
      <div class="details-box" v-if="detailsStatus">
        <el-button @click="goBack">返回</el-button>
        <div class="content-box">
          <template v-if="JSON.stringify(detailsInfo) != '{}'">
        <div class="lawLeft">
          <p>
            <span>文件名称：</span>
            {{detailsInfo.title}}
          </p>
          <p v-if="detailsInfo.publicationNumber">
            <span>发文字号：</span>
            {{detailsInfo.publicationNumber}}
          </p>
          <p v-if="detailsInfo.promulgateMechanism">
            <span>发布单位：</span>
            {{ detailsInfo.promulgateMechanism }}
          </p>
          <p v-if="detailsInfo.promulgateDate">
            <span>公布日期：</span>
            {{detailsInfo.promulgateDate}}
          </p>
          <p v-if="detailsInfo.executeDate">
            <span>施行日期：</span>
            {{detailsInfo.executeDate}}
          </p>
          <p v-if="detailsInfo.abolishDate">
            <span>废止日期：</span>
            {{detailsInfo.abolishDate}}
          </p>
          <p v-if="detailsInfo.reviseDate">
            <span>修订日期：</span>
            {{detailsInfo.reviseDate}}
          </p>
          <p>
            <span>标签：</span>
            {{ detailsInfo.regionName }} &nbsp;&nbsp;&nbsp; {{ setLegalEffect(detailsInfo.legalEffect) }}
          </p>
          <p v-if="detailsInfo.url">
            <span>数据来源：</span>
            <a class="source" :href="detailsInfo.url" target="_blank">查看</a>
          </p>
        </div>
        <div class="lawRight">
          <p class="html-p" v-html="detailsInfo.content"></p>
        </div>
        </template>
        <span v-else style="text-align: center;">暂无数据</span>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import { getLaborLawsList, getLaborLawsDetail } from '@/api/knowledgeData'
export default {
  name: "addContactsDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      title: "劳动法规",
      dialogVisible: true,
      form: {
        name: '',
        clause: '',
        content: '',
        sourceId: null,
        id: null,
      },
      keyVal: "",
      dataSize: 0,
      currentPage: 1,
      interPage: 15,
      tableHeight: 500,
      loading: false,
      tableData: [],
      headData: [
        { align: "center", prop: "选择", label: "选择", width: "100px" },
        { align: "left", prop: "title", label: "标题", width: "" },
        { align: "center", prop: "regionName", label: "范围", width: "100px" },
        { align: "center", prop: "promulgateDate", label: "颁布日期", width: "100px" },
        { align: "center", prop: "createTime", label: "添加日期", width: "100px" },
        { align: "center", prop: "操作", label: "操作", width: "100px" },
      ],
      detailsStatus: false, // 显示详情页
      detailsInfo: {}, // 详情数据
      legalEffectList: [ // 法律效力位级数组
        {value: 1, label: "国家法律"},
        {value: 2, label: "国家行政法规"},
        {value: 3, label: "国家部门规章"},
        {value: 4, label: "国家规范性文件"},
        {value: 5, label: "地方行政法规"},
        {value: 6, label: "地方部门规章"},
        {value: 7, label: "地方规范性文件"},
      ],
      submitStatus: false,
      type: "",
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.title = this.info.name;
      if (this.title == "劳动法规") {
        this.type = "";
      } else if (this.title == "ISO37301") {
        this.type = 1;
      } else if (this.title == "GBT/T") {
        this.type = 2;
      } else if (this.title == "中央企业合规管理办法") {
        this.type = 3;
      } else if (this.title == "和谐关系标准") {
        this.type = 4;
      }
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      if (this.info.type == 'edit') {
        this.form = {
          id: this.info.row.id,
          name: this.info.row.name,
          clause: this.info.row.clause,
          content: this.info.row.content,
          sourceId: this.info.row.sourceId,
        }
      }
      this.getData(this.form.sourceId);
    },
    // 获取法规列表
    async getData(id) {
      let params = `${this.currentPage}/${this.interPage}`;
      if (id || this.keyVal || this.type) {
        params += "?"
        if (id) {
          params = `${params}id=${id}&`;
        }
        if (this.keyVal) {
          params = `${params}queryString=${this.keyVal}&`;
        }
        if (this.type) {
          params = `${params}type=${this.type}&`;
        }
        params = params.substring(0,params.length-1)
      }
      // console.log(params,'paramsparamsparams');
      this.loading = true;
      let res = await getLaborLawsList(params);
      this.loading = false;
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        data.map(item => {
          item.promulgateDate = item.promulgateDate == null ? '':item.promulgateDate.split("T")[0];
          item.createTime = item.createTime.split("T")[0];
          item.check = id ? true:false;
          return item;
        })
        this.tableData = res.result.items;
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.$filterCache(`${this.page}`);
      this.getData();
    },
    // 搜索
    search() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      this.getData();
    },
    // 选择法规
    check(id) {
      this.tableData.forEach(item => {
        if (id != item.id) {
          item.check = false;
        } else {
          this.form.name = item.title;
          this.form.sourceId = item.id;
        }
      })
    },
    // 返回列表
    goBack() {
      this.detailsStatus = false;
    },
    // 查看法规详情
    lookDetails(row) {
      this.detailsStatus = true;
      this.getDetail(row.id);
    },
    async getDetail(id) {
      this.detailsInfo = {};
      let loadingInstance = Loading.service({ fullscreen: true });
      let res = await getLaborLawsDetail(id);
      loadingInstance.close();
      if (res.code === 0) {
        let data = res.result;
        data.promulgateDate = data.promulgateDate ? data.promulgateDate.split("T")[0] : '';
        data.executeDate = data.executeDate ? data.executeDate.split("T")[0] : '';
        data.abolishDate = data.abolishDate ? data.abolishDate.split("T")[0] : '';
        data.reviseDate = data.reviseDate ? data.reviseDate.split("T")[0] : '';
        this.detailsInfo = data;
      }
    },
    setLegalEffect(num) {
      let str = "";
      for (let i = 0; i < this.legalEffectList.length; i++) {
        let item = this.legalEffectList[i];
        if (item.value == num) {
          str = item.label;
          break;
        }
      }
      return str;
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    submit() {
      let verifyObj = {
        name: '法规名称',
        clause: '条款',
        content: '条款内容',
      }
      for (let key in verifyObj) {
        if (!this.form[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      let data = JSON.parse(JSON.stringify(this.form));
      let idx = this.info.type == 'edit' ? this.info.idx:"";
      this.$emit("setLawBasis", data,idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.add-contacts-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          ::v-deep .el-textarea__inner {
            white-space: pre-wrap;
            word-break: break-all;
            line-height: 22px !important;
            letter-spacing: 2px !important;
          }
        }
        .search-box {
          display: flex;
          align-items: center;
          justify-content: center;
          .el-input {
            width: 30%;
          }
          .el-button {
            font-weight: 600;
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            margin-left: 10px;
          }
        }
        .details-box {
          .el-button {
            height: 32px;
            border-radius: 16px;
            color: rgba(0, 148, 255, 0.7);
            border: 1px solid rgba(0, 148, 255, 0.7);
            margin-bottom: 5px;
          }
          .content-box {
            display: flex;
            justify-content: space-between;
            height: 400px;
            overflow-y: auto;
            margin: 0 32px;
            .lawLeft {
              width: 25%;
              background-color: #f5f7fa;
              padding: 20px;
              box-sizing: border-box;
              border-radius: 10px;
              p {
                font-size: 14px;
                color: #000;
                line-height: 36px;
                margin-bottom: 10px;
                span {
                  font-size: 14px;
                  font-weight: 600;
                }
                .source {
                  font-size: 14px;
                  font-weight: 400;
                  color: #000;
                  cursor:pointer;
                }
              }
            }
            .lawRight {
              width: 73%;
              background-color: #f5f7fa;
              padding: 80px;
              box-sizing: border-box;
              border-radius: 10px;
              p {
                font-size: 14px;
                color: #000;
                line-height: 36px;
                margin-bottom: 10px;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    text-align: center;
    margin-top: 30px;
  }
</style>
<style lang="scss">
.details-page {
/* table 样式 */
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}
p,
span {
  font-size: 14px !important;
  line-height: 34px !important;
  margin-bottom: 10px !important;
}
}
</style>
