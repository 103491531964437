<template>
  <div class="labor-laws-parameter-indicator">
    <div class="head-box">
      <el-button @click="add">新增指标</el-button>
      <!-- <div class="head-right">
        <el-input
          placeholder="请输入搜索内容"
          v-model="keyVal">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div> -->
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        :height="tableHeight"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '编号'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '参数'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              <p v-for="item in scope.row.childs" :key="item.id">{{item.name}}</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.isEnable"
                active-color="#13ce66"
                @change="selectStatus(scope.row)"
                inactive-color="#aaaaaa">
              </el-switch>
              <span
                style="color: #6aa9fb; cursor: pointer;margin: 0 15px;"
                @click="edit(scope.row)">
                编辑
              </span>
              <span
                style="color: red; cursor: pointer"
                @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          ></el-table-column>
        </template>
      </el-table>
      <div v-if="false">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <addTargetDialog v-if="showDialog" :info="dialogInfo"></addTargetDialog>
  </div>
</template>

<script>
import addTargetDialog from '@/views/Dialog/addTargetDialog.vue';
import { getTarget, delTargetParameter, editEnable } from '@/api/knowledgeData'
export default {
  name: "laborLawsParameterIndicator",
  components: {
    addTargetDialog,
  },
  data() {
    return {
      loading: false,
      keyVal: "",
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      tableData: [],
      value: true,
      headData: [
        { align: "center", prop: "编号", label: "编号", width: "120px" },
        { align: "center", prop: "name", label: "指标名称", width: "" },
        { align: "center", prop: "childs", label: "参数", width: "" },
        // { align: "center", prop: "phoneNumber", label: "创建时间" },
        { align: "center", prop: "remark", label: "操作", width: "240px" },
      ],
      arrTableLength: {
      },
      showDialog: false,
      dialogInfo: {
        type: 'add',
      },
    }
  },
  created() {
    this.getTargetInfo();
  },
  mounted() {
  },
  methods: {
    // 获取指标列表
    async getTargetInfo() {
      this.loading = true;
      let res = await getTarget();
      // console.log(res);
      this.loading = false;
      if (res.code == 0) {
        this.tableData = res.result;
      }
    },
    // 编辑
    edit(row) {
      console.log(row);
      this.dialogInfo.type = 'edit';
      this.dialogInfo.row = row;
      this.showDialog = true;
    },
    // 删除指标
    del(row) {
      this.$confirm(`是否确认删除指标【${row.name}】`)
      .then((_) => {
        this.delTarget(row);
      })
      .catch((_) => {});
    },
    async delTarget(row) {
      let res = await delTargetParameter({ids: [row.id]});
      if (res.code == 200) {
        this.getTargetInfo();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 设定启用状态
    async selectStatus(row) {
      // console.log(row);
      let params = {
        targetId: row.id,
        isEnable: row.isEnable,
      }
      let res = await editEnable(params);
      // console.log(res);
      if (res.code == 200) {
        this.getTargetInfo();
      }
    },
    // 新增劳务法
    add() {
      this.dialogInfo.type = 'add';
      this.dialogInfo.row = {};
      this.showDialog = true;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getPageData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.$filterCache(`${this.page}`);
      this.getPageData();
    },
    //获取表格裂宽
    getTableCellWidth() {
      this.tableHeight = this.$getTableHeight(70, "table-container");
      let headData = JSON.parse(JSON.stringify(this.headData));
      // console.log(headData)
      headData = headData.filter((val) => {
        return val.label !== "操作";
      });

      this.arrTableLength = this.$getTableCellLength({
        W: $(".table-container")[0].clientWidth,
        W2: 80,
        head: headData,
        data: this.tableData,
      });
    },
}
}
</script>

<style lang="scss" scoped>
  .labor-laws-parameter-indicator {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    .head-box{
      display: flex;
      justify-content: space-between;
      height: 7%;
      // margin-bottom: 1%;
      .el-button {
        height: 40px;
        border-radius: 20px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
      }
      .head-right {
        display: flex;
      }
    }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  }
</style>

