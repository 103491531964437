<template>
  <div class="labour-harmonious">
    <div v-show="!showDetails">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane v-for="item in tabsList" :key="item.id" :label="item.label" :name="item.name"></el-tab-pane>
      </el-tabs>
      <questionnaireTable v-if="activeName == '劳动和谐关系标准列表'" @showDetailsPage="details"></questionnaireTable>
      <projectType v-if="activeName == '项目管理'"></projectType>
    </div>
    <div class="details-box" v-if="showDetails">
      <detailsPage :info="detailsInfo"></detailsPage>
    </div>
  </div>
</template>

<script>
import questionnaireTable from "./questionnaireTable.vue"
import projectType from "./projectType.vue"
import detailsPage from "./detailsPage.vue"
export default {
  name: "labourHarmonious",
  components: {
    questionnaireTable,
    projectType,
    detailsPage,
  },
  data() {
    return {
      activeName: "劳动和谐关系标准列表",  
      tabsList: [
        {id: 1, label: "劳动和谐关系标准列表", name: "劳动和谐关系标准列表"},
        {id: 2, label: "项目管理", name: "项目管理"},
      ],
      detailsInfo: {
        type: "add",
      },
      showDetails: false, // 详情
      detailsInfo: {},
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 查看详情
    details(item) {
      this.detailsInfo = item;
      this.showDetails = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.labour-harmonious {
  position: relative;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  .details-box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 2;
  }
}
</style>

