<template>
  <div class="add-ilaas-user-dialog">
    <el-dialog
      title="iLaaS用户"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%">
      <!-- <div class="row-box">
        <p class="label">
          <span>* </span>
          名称：
        </p>
        <el-input placeholder="请输入名称" v-model="form.identity"></el-input>
      </div> -->
      <div class="row-box">
        <p class="label">
          昵称：
        </p>
        <el-input placeholder="请输入昵称" v-model="form.nickName"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          姓名：
        </p>
        <el-input placeholder="请输入姓名" v-model="form.realName"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          手机：
        </p>
        <el-input placeholder="请输入手机" v-model="form.phoneNumber"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          邮箱：
        </p>
        <el-input placeholder="请输入邮箱" v-model="form.email"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          公司：
        </p>
        <el-input placeholder="请输入公司" v-model="form.enterprise"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          职务：
        </p>
        <el-input placeholder="请输入职务" v-model="form.position"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          地区：
        </p>
        <el-select
          v-model="provinceVal"
          filterable
          placeholder="省/直辖市/自治区"
          @change="selectProvince"
          style="margin-right: 20px;"
        >
          <el-option
            v-for="item in provinceList"
            :key="item.id"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="cityVal"
          filterable
          placeholder="市"
          @change="selectCity"
        >
          <el-option
            v-for="item in cityInfo[provinceVal]"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="row-box">
        <p class="label">
          会员身份：
        </p>
        <el-select v-model="identityVal">
          <el-option
            v-for="item in identityList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div> -->
      <div class="row-box">
        <p class="label">
          <!-- <span>* </span> -->
          会员体验天数：（ 输入天数即为用户设置体验会员 ）
        </p>
        <el-input placeholder="请输入会员体验天数" type="number" v-model="form.days"></el-input>
      </div>
      <div class="row-box" v-if="expiredTime!=''">
        <p class="label">
          体验会员到期时间：
        </p>
        <span>{{expiredTime}}</span>
      </div>
      <!-- <div class="row-box">
        <p class="label">
          头像：
        </p>
        <div class="add-box"  v-if="!form.imgUrl" @click="upoadBox">
          <input class="inputFile" ref="file" type="file"/>
          <img src="@/assets/img/add-icon.png" alt="" />
        </div>
        <div
          v-else
          class="file-card"
          @mouseenter="showOperation = true"
          @mouseleave="showOperation = false">
          <div class="img-box">
            <img v-if="form.imgUrl" src="form.imgUrl" alt="">
            <div class="btn-box" v-if="showOperation">
              <button @click="delFile(fileInfo)">删除</button>
            </div>
          </div>
        </div>
      </div> -->
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addIlaasUser, editIlaasUser, detailIlaasUser } from "@/api/clientOperation.js"
import { getAddress } from "@/api/iLaaSindex.js"
export default {
  name: "addIlaaSUserDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        nickName: "", // 昵称
        realName: "", // 姓名
        phoneNumber: "", // 手机号
        email: "", // 邮箱
        enterprise: "", // 公司
        position: "", // 职务
        provinceId: null, // 省份
        cityId: null, // 城市
        // isVip: false, // 是否为会员
        days: null, // 会员体验天数
      },
      submitStatus: false,
      showOperation: false,
      identityVal: "普通用户",
      identityList: [
        { value: "普通用户", label: "普通用户" },
        { value: "会员", label: "会员" },
      ],
      provinceList: [], // 省下拉框数据
      cityInfo: {}, // 市下拉框数据
      provinceVal: "", //省下拉框选中的数据
      cityVal: "", //市下拉框选中的数据
      provinceCityInfo: {}, // 选中的省市具体数据
      // inputStatus: false,
      expiredTime: "",
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getAddressData();
      console.log(this.info);
    },
    async getData() {
      let res = await detailIlaasUser(this.info.item.id);
      if (res.code == 0) {
        let data = res.result;
        console.log(data);
        // let days = null;
        // if (data.isVip) {
        //   let day = new Date().getTime();
        //   let day2 = new Date(data.expiredTime).getTime();
        //   let second = day2 - day;
        //   second = Math.floor(second/1000);
        //   days = parseInt(second/60/60/24);
        //   let day4 = parseInt(second/60/60%24);
        //   if (day4 > 0) days+1;
        // }
        let date = "";
        if (data.expiredTime != null) {
          date = data.expiredTime.split("T");
          date = `${date[0]} ${date[1]}`
        }
        this.form = {
          nickName: data.nickName,
          realName: data.realName,
          phoneNumber: data.phoneNumber,
          email: data.email,
          enterprise: data.enterprise,
          position: data.position,
          provinceId: data.provinceId,
          cityId: data.cityId,
          // isVip: data.isVip,
          expiredTime: data.expiredTime,
          days: null,
          id: data.id,
        }
        this.expiredTime = date;
        // if (this.form.isVip) {
        //   this.identityVal = "会员";
        // }
        for (let i = 0; i < this.provinceList.length; i++) {
          let item = this.provinceList[i];
          if (item.id == this.form.provinceId) {
            this.provinceVal = item.value;
            let newCity = this.cityInfo[item.value];
            for (let y = 0; y < newCity.length; y++) {
              let item2 = newCity[y];
              if (item2.id == this.form.cityId) {
                this.cityVal = item2.name;
                break;
              }
            }
            break
          }
        }
      }
    },
    async getAddressData() {
      let res = await getAddress();
      // console.log(res);
      if (res.code == 0) {
        res.items.map((item) => {
          this.provinceList.push({
            id: item.id,
            label: item.name,
            value: item.name,
          });
          this.cityInfo[item.name] = item.cities;
        });
        if (this.info.type == 'edit') {
          this.getData();
        }
      }
    },
    // 选择省
    selectProvince() {
      // console.log(this.provinceList);
      for (var i = 0; i < this.provinceList.length; i++) {
        let newItem = this.provinceList[i];
        if (newItem.label == this.provinceVal) {
          // console.log(this.cityInfo[this.provinceVal]);
          this.provinceCityInfo.provinceId = newItem.id;
          this.provinceCityInfo.province = newItem.value;
          this.provinceCityInfo.cityId = this.cityInfo[this.provinceVal][0].id;
          this.provinceCityInfo.city = this.cityInfo[this.provinceVal][0].name;
          this.cityVal = this.cityInfo[this.provinceVal][0].name;
          break;
        }
      }
      // console.log(this.provinceCityInfo);
    },
    // 选择市
    selectCity() {
      let newArr = this.cityInfo[this.provinceVal];
      for (var i = 0; i < newArr.length; i++) {
        let newItem = newArr[i];
        if (newItem.name == this.cityVal) {
          this.provinceCityInfo.cityId = newItem.id;
          this.provinceCityInfo.city = newItem.name;
          break;
        }
      }
      // console.log(this.provinceCityInfo);
    },
    // upoadBox() {
    //   this.$refs.file.value = null;
    //   this.inputStatus = true;
    //   // 苹果系统 兼容问题 无法直接获取change事件 需要在DOM上加入input节点 上传完成后去除节点
    //   let input = document.querySelector('.inputFile');
    //   let newInput = input.cloneNode(true);
    //   newInput.setAttribute('type','file');
    //   newInput.setAttribute('accept','image/*');
    //   newInput.click();
    //   document.body.appendChild(newInput);
    //   newInput.onchange = () => {
    //     const inputFile = newInput.files[0];
    //     console.log(inputFile,'inputFile');
    //     const formData = new FormData();
    //     formData.append('file', inputFile);
    //     let parent = newInput.parentElement;
    //     parent.removeChild(newInput);
    //     upload(formData).then(res => {
    //       if (res.code == 0) {
    //         this.$message.success("上传成功");
    //         this.getUser();
    //       }
    //     });
    //   }
    //   let that = this;
    //   let timeIdTwo = setTimeout(function(){
    //     if (that.inputStatus) {
    //       let parent = newInput.parentElement;
    //       parent.removeChild(newInput);
    //     }
    //     clearTimeout(timeIdTwo);
    //   },30000);
    // },
    delFile() {},
    cancel() {
      this.$parent.showDetails = false;
    },
    async submit() {
      if (this.submitStatus) return;
      // console.log(this.provinceCityInfo);return
      var re = /^1[3,4,5,6,7,8,9][0-9]{9}$/;
      if (!re.test(this.form.phoneNumber)) {
        this.$message.warning("请正确输入手机号吗!");
        return;
      }
      if (this.provinceCityInfo.city) {
        this.form.provinceId = this.provinceCityInfo.provinceId;
        this.form.cityId = this.provinceCityInfo.cityId;
      }
      // console.log(this.form);return
      // if (this.identityVal == "会员") this.form.isVip = true;
      this.form.days = Number(this.form.days)
      let params = JSON.parse(JSON.stringify(this.form));
      // console.log(params);return
      this.submitStatus = true;
      let res = {};
      if (this.info.type == 'add') {
        res = await addIlaasUser(params);
      } else {
        res = await editIlaasUser(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-ilaas-user-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
          .add-box {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 190px;
            height: 152px;
            margin-bottom: 14px;
            border-radius: 10px;
            background: #f0f3fb;
            cursor: pointer;
            img {
              width: 30px;
              height: 30px;
              margin-bottom: 10px;
            }
            .inputFile {
              position: absolute;
              top: -50px;
              left: 0;
              width: 2px;
              height: 2px;
              border-radius: 80px;
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
