import { render, staticRenderFns } from "./qudao.vue?vue&type=template&id=23c2bfcf&scoped=true"
import script from "./qudao.vue?vue&type=script&lang=js"
export * from "./qudao.vue?vue&type=script&lang=js"
import style0 from "./qudao.vue?vue&type=style&index=0&id=23c2bfcf&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c2bfcf",
  null
  
)

export default component.exports