<template>
  <div class="iLaaS">
    <h1>iLaaS</h1>
  </div>
</template>

<script>
export default {
  name: "iLaaS",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .iLaaS {
  }
</style>

