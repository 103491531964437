<template>
  <div class="labor-laws">
    <div class="tabs-box">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="总表" name="1">
          <tablePage ref="tablePage"></tablePage>
        </el-tab-pane>
        <el-tab-pane label="仪表盘" name="2">
          <statisticsPage
            ref="statisticsPage"
            @showBtn="showBtn"
          ></statisticsPage>
        </el-tab-pane>
      </el-tabs>
      <el-button v-if="activeName == '2' && btnStatus" class="btn" @click="back"
        >返回</el-button
      >
    </div>
  </div>
</template>

<script>
import tablePage from "./modules/tablePage.vue";
import statisticsPage from "./modules/statisticsPage.vue";
export default {
  name: "laborLaws",
  components: {
    tablePage,
    statisticsPage,
  },
  data() {
    return {
      activeName: "1",
      btnStatus: false,
    };
  },
  created() {},
  mounted() {},
  methods: {
    back() {
      this.btnStatus = false;
      this.$refs.statisticsPage.setAllData();
    },
    showBtn() {
      this.btnStatus = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.labor-laws {
  padding: 20px;
  box-sizing: border-box;
  .tabs-box {
    position: relative;
    width: 100%;
    ::v-deep .el-tabs__item {
      padding: 0 30px !important;
    }
    .btn {
      position: absolute;
      top: 2px;
      right: 0;
      height: 30px;
      border-radius: 20px;
      color: rgba(0, 148, 255, 0.7);
      border: 1px solid rgba(0, 148, 255, 0.7);
      margin-right: 20px;
    }
  }
}
</style>
