<template>
  <div class="page-neaten">
    <div class="head-box">
      <el-button @click="addRoute(null)">新增菜单</el-button>
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        ref="multipleTable"
        @select-all="selectAll"
        @select="selectTr"
        :data="tableData"
        style="width: 100%"
        row-key="id"
        border
        default-expand-all
        :tree-props="{ children: 'childs', hasChildren: 'hasChildren' }">
        <el-table-column prop="name" label="菜单名称" align="left" width="300px"> </el-table-column>
        <el-table-column prop="identity" label="路由名" align="center" width="180px"> </el-table-column>
        <el-table-column prop="route" label="文件位置" align="left"> </el-table-column>
        <el-table-column prop="操作" label="操作" align="center" width="200px">
          <template slot-scope="scope">
            <span style="color: green; cursor: pointer;" @click="addRoute(scope.row.id)">添加子级</span>
            <span
              style="color: #6aa9fb; cursor: pointer; margin: 0 10px;"
              @click="lookDetails(scope.row)">
              修改
            </span>
            <span
              style="color: red; cursor: pointer;"
              @click="del(scope.row)">
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <addRouteDialog v-if="showDialog" :info="detailsInfo"></addRouteDialog>
  </div>
</template>

<script>
import addRouteDialog from '@/views/Dialog/addRouteDialog.vue';
import { getMenuList, delMenu } from "@/api/setting.js"
export default {
  name: "pageNeaten",
  components: {
    addRouteDialog,
  },
  data() {
    return {
      loading: false,
      tableData: [],
      detailsInfo: {
        type: "add",
      },
      showDialog: false,
    }
  },
  created() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getData();
    },
    async getData() {
      this.loading = true;
      let res = await getMenuList();
      this.loading = false;
      // console.log(res,'res');
      if (res.code == 0) {
        this.tableData = res.result;
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 新增菜单
    addRoute(id) {
      this.detailsInfo = {
        type: "add",
        parentId: id,
      }
      this.showDialog = true;
    },
    // 编辑菜单
    lookDetails(item) {
      this.detailsInfo = {
        type: "edit",
        item,
      }
      this.showDialog = true;
    },
    // 删除菜单
    del(row) {
      let str = "";
      if (row.childs.length > 0) {
        str = `菜单 ${row.name} 包含子级菜单，是否确认删除？`;
      } else {
        str = `请确认是否删除菜单【 ${row.name} 】？`;
      }
      this.$confirm(str)
        .then((_) => {
          this.delTask({ids: [row.id]});
        })
        .catch((_) => {});
    },
    async delTask(id) {
      let res = await delMenu(id);
      if (res.code === 200) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.init();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    selectAll() {
      this.isAllSelect = !this.isAllSelect;
      let data = this.tableData;
      this.toggleSelect(data, this.isAllSelect, "all");
    },
    //选择某行
    selectTr(selection, row) {
      this.$set(row, "isChecked", !row.isChecked);
      this.$nextTick(() => {
        this.isAllSelect = row.isChecked;
        this.toggleSelect(row, row.isChecked, "tr");
      });
    },
    //递归子级
    toggleSelect(data, flag, type) {
      if (type === "all") {
        if (data.length > 0) {
          data.forEach((item) => {
            this.toggleSelection(item, flag);
            if (item.children && item.children.length > 0) {
              this.toggleSelect(item.children, flag, type);
            }
          });
        }
      } else {
        if (data.children && data.children.length > 0) {
          data.children.forEach((item) => {
            item.isChecked = flag;
            this.$refs.multipleTable.toggleRowSelection(item, flag);
            this.toggleSelect(item, flag, type);
          });
        }
      }
    },
    //改变选中
    toggleSelection(row, flag) {
      this.$set(row, "isChecked", flag);
      this.$nextTick(() => {
        if (flag) {
          this.$refs.multipleTable.toggleRowSelection(row, flag);
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .page-neaten {
    padding: 20px;
    box-sizing: border-box;
    .head-box {
        .el-button {
          height: 40px;
          border-radius: 20px;
          color: rgba(0, 148, 255,.7);
          border: 1px solid rgba(0, 148, 255,.7);
          margin-bottom: 10px;
        }
    }
    ::v-deep .table-container {
      th,
      td {
        padding: 5px 0;
      }
    }
  }
</style>

