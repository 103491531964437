<template>
  <div class="questionnaire-table">
      <div class="head-box">
        <el-button @click="add">新增标准</el-button>
      </div>
      <div class="table-container">
        <el-table
          v-loading="loading"
          border
          :data="tableData"
          style="width: 100%"
          fit
        >
          <template v-for="(head, index) in headData">
            <el-table-column
              :align="head.align"
              v-if="head.label === '序号'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              :align="head.align"
              v-else-if="head.label === '关联法规'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
            >
              <template slot-scope="scope">
                <p style="overflow: hidden;  white-space: nowrap; text-overflow: ellipsis;" :title="scope.row.lawName">{{scope.row.lawName}}</p>
              </template>
            </el-table-column>
            <el-table-column
              :align="head.align"
              v-else-if="head.label === '状态'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
            >
              <template slot-scope="scope">
                <span v-if="!scope.row.isEnable" style="color: #ccc"
                  >未启用</span
                >
                <span v-else style="color: green">启用</span>
              </template>
            </el-table-column>
            <el-table-column
              :align="head.align"
              v-else-if="head.label === '操作'"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
            >
              <template slot-scope="scope">
                <span
                  style="color: #6aa9fb; cursor: pointer; margin: 0 15px"
                  @click="details(scope.row)"
                >
                  详情
                </span>
                <span
                  style="color: #6aa9fb; cursor: pointer; margin-right: 15px"
                  @click="edit(scope.row)"
                >
                  编辑
                </span>
                <span
                  style="color: red; cursor: pointer"
                  @click="del(scope.row)"
                >
                  删除
                </span>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :align="head.align"
              :key="index"
              :prop="head.prop"
              :label="head.label"
              :width="head.width"
            ></el-table-column>
          </template>
        </el-table>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="interPage"
            layout="total, sizes, prev, pager, next, jumper"
            background
            :total="dataSize"
          >
          </el-pagination>
        </div>
      <addLabourHarmoniousDialog
        v-if="showDialog"
        :info="detailsInfo"
      ></addLabourHarmoniousDialog>
    </div>
  </div>
</template>

<script>
import { getHarmoniousList, delHarmonious } from "@/api/knowledgeData";
import addLabourHarmoniousDialog from "@/views/Dialog/addLabourHarmoniousDialog.vue";
export default {
  name: "questionnaireTable",
  components: {
    addLabourHarmoniousDialog,
  },
  data() {
    return {
      loading: false,
      headData: [
        { align: "center", prop: "序号", label: "序号", width: "100px" },
        { align: "center", prop: "title", label: "标准名称", width: "" },
        { align: "center", prop: "provinceName", label: "省份/直辖市/自治区", width: "160px" },
        { align: "center", prop: "cityName", label: "城市", width: "120px" },
        { align: "center", prop: "lawName", label: "关联法规", width: "200px" },
        { align: "center", prop: "totalScore", label: "总分", width: "100px" },
        { align: "center", prop: "isEnable", label: "状态", width: "100px" },
        { align: "center", prop: "操作", label: "操作", width: "200px" },
      ],
      tableData: [],
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 15, //每页显示条数
      showDialog: false, // 新增弹框
      detailsInfo: {
        type: "add",
      },
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.dataSize = 0;
      this.currentPage = 1;
      this.interPage = 15;
      this.getData();
    },
    async getData() {
      let params = `/${this.currentPage}/${this.interPage}`;
      let res = await getHarmoniousList(params);
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        this.tableData = data;
      }
    },
    // 新增标准
    add() {
      this.detailsInfo = {
        type: "add",
      }
      this.showDialog = true;
    },
    // 查看详情
    details(item) {
      this.$emit("showDetailsPage",item)
    },
    // 编辑
    edit(item) {
      this.detailsInfo = {
        type: "edit",
        item: JSON.parse(JSON.stringify(item))
      }
      this.showDialog = true;
    },
    // 删除
    del(item) {
      this.$confirm(`是否确认删除标准【${item.title}】`)
        .then((_) => {
          this.delQuestionnaire(item);
        })
        .catch((_) => {});
    },
    async delQuestionnaire(item) {
      let res = await delHarmonious(item.id);
      if (res.code == 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.init();
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.questionnaire-table {
  .head-box {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .el-button {
      height: 40px;
      color: #4cb4ff;
      border-radius: 20px;
      border: 1px solid #4cb4ff;
    }
  }
}
</style>

