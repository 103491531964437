<template>
  <div class="iHraaS">
    <h1>iHraaS</h1>
  </div>
</template>

<script>
export default {
  name: "iHraaS",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .iHraaS {
  }
</style>

