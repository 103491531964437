<template>
  <div class="model-case-dialog">
    <el-dialog
      title="典型案例"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%">
      <div class="row-box">
        <p class="label">
          标题：
        </p>
        <el-input type="textarea" autosize placeholder="请输入标题" v-model="form.title"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          地址链接：</p>
        <el-input
          type="textarea"
          autosize
          placeholder="请输入地址链接"
          v-model="form.url">
        </el-input>
      </div>
        <div class="vals-box">
          <template v-if="!progressFlag">
          <div class="add-box" v-show="fileList.length <1">
            <el-upload
              class="avatar-uploader"
              :action="objData2.host"
              :data="objData2"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload2"
              :on-progress="uploadVideoProcess2"
              :on-success="upSuccess"
            >
              <img src="@/assets/img/add-icon.png" alt="" />
              <p>上传文件</p>
            </el-upload>
            <span style="text-align: center;">（ 上传文件格式：Word、PDF、PNG、JPG ）</span>
          </div>
          </template>
          <div class="file-box">
            <div
              class="file-card"
              :class="isFileIdx === index ? 'is-file-card' : ''"
              @mouseenter="mouseOver(index)"
              @mouseleave="mouseLeave"
              v-for="(item, index) in fileList"
              :key="item.fileName"
            >
              <img :src="selectImg(item.fileName)" alt="" />
              <p :title="item.fileName">{{ item.fileName }}</p>
              <el-progress
                v-if="progressFlag && index == fileList.length - 1"
                :percentage="loadProgress"
              ></el-progress>
              <div class="shadow-box" v-else>
                <button @click="download(item)">下载</button>
                <button @click="delFile(item, index)">删除</button>
              </div>
            </div>
          </div>
        </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPolicyToken } from '@/api/index'
var OSS = require("ali-oss");
export default {
  name: "modelCaseDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        title: "",
        url: "",
        fileName: "",
        filePath: "",
        buketName: "",
      },
      ossInfo: { // oss参数
        BucketName: "ilaas-files",
        expireTime: 120,
        uploadDir: "ilaas_cms/conformGuide/file/",
      },
      objData2: {
        host: "*",
      },
      imageUrl: "",
      isFileIdx: "", // 鼠标悬浮哪个文件上
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 是否显示进度条
      isFile: {}, // 上传中的文件
      fileList: [], // 上传的文件
      filePathList: [], // 文件上传成功的路径
      delFileList: [], // 删除的文件暂存数组
      ossSignature2: {}, //oss签名
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.getOss();
      if (this.info.type == 'edit') {
        this.form = this.info.row;
        if (this.info.row.filePath) {
          this.fileList.push(
            {
              fileName: this.info.row.fileName,
              filePath: this.info.row.filePath,
              buketName: this.info.row.buketName,
            }
          )
        }
      }
    },
    // 获取oss签名
    async getOss() {
      let res = await getPolicyToken(this.ossInfo);
      if (res.code === 200) {
        let data = JSON.parse(res.result);
        this.ossSignature2 = data;
      }
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload2(file) {
      let newArr = file.name.split(".");
      let fileType = newArr[newArr.length - 1];
      let imgType = [
        "doc",
        "docx", // Word
        "pdf",
        "jpg", "jpeg", "png", "pnge",
      ];
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!imgType.includes(fileType)) {
        this.$message.warning(
          "上传文件只能是 Word、PDF、JPG、PNG 格式文件!"
        );
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 50MB!");
        return;
      }
        this.objData2 = {
          OSSAccessKeyId: this.ossSignature2.accessid,
          policy: this.ossSignature2.policy,
          signature: this.ossSignature2.signature,
          expire: this.ossSignature2.expire,
          host: this.ossSignature2.host,
          dir: this.ossSignature2.dir,
          key: `ilaas_cms/conformGuide/file/yw-0/${new Date().getTime()}-${file.name}`,
        };
        this.filePathList.push(this.objData2.key);
        this.isFile = {
          fileName: file.name,
          filePath: this.objData2.key,
          buketName: "ilaas-files",
        }
    },
    // 上传成功事件
    upSuccess() {
      this.fileList.push(this.isFile);
    },
    // 上传文件进度
    uploadVideoProcess2(event, file, fileList) {
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 1000); // 一秒后关闭进度条
      }
    },
    // 鼠标悬浮文件上
    mouseOver(idx) {
      this.isFileIdx = idx;
    },
    // 离开文件
    mouseLeave() {
      this.isFileIdx = "";
    },
    // 下载客户文件
    download(item) {
      let client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossSignature2.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-files",
      });
      let filename = item.fileName;
      let response = {
        "content-disposition": `attachment; filename=${encodeURIComponent(
          filename
        )};expires: 7200`,
      };
      let url = client.signatureUrl(item.filePath, {
        response,
      });
      window.location.href = url;
    },
    // 删除客户文件
    delFile(item, idx) {
      this.$confirm(`请确认是否删除文件【 ${item.fileName} 】`)
        .then((_) => {
          this.fileList.splice(idx, 1);
          this.ossDelFile(item);
        })
        .catch((_) => {});
    },
    ossDelFile(item) {
      let client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossSignature2.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-files",
      });
      client.delete(item.filePath);
    },
    // 设置文件图片
    selectImg(info) {
      // console.log(info);
      let imgUrl = "";
      let valList = info.split(".");
      let fileType = valList[valList.length - 1];
      if (fileType == "doc" || fileType == "docx") {
        imgUrl = require("@/assets/img/file-word.png");
      } else if (fileType == "xls" || fileType == "xlsx") {
        imgUrl = require("@/assets/img/file-excel.png");
      } else if (fileType == "pdf") {
        imgUrl = require("@/assets/img/file-pdf.png");
      } else {
        imgUrl = require("@/assets/img/file-img.png");
      }
      return imgUrl;
    },
    cancel() {
      this.$parent.showDialog2 = false;
    },
    submit() {
      if (this.fileList.length > 0) {
        let fileInfo = this.fileList[this.fileList.length - 1];
        this.form.fileName = fileInfo.fileName || "";
        this.form.filePath = fileInfo.filePath || "";
        this.form.buketName = fileInfo.buketName || "";
      }
      if (!this.form.title && !this.form.url && !this.form.fileName) {
        this.cancel();
        return;
      }
      let data = JSON.parse(JSON.stringify(this.form));
      let idx = this.info.type == 'edit' ? this.info.idx:"";
      this.$emit("setTypicalCases", data,idx);
    },
  },
};
</script>

<style lang="scss" scoped>
.model-case-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          ::v-deep .el-textarea__inner {
            line-height: 22px !important;
            letter-spacing: 2px !important;
          }
        }
        .vals-box {
          
      .add-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // width: 24%;
        width: 190px;
        height: 130px;
        margin-right: 1%;
        margin-bottom: 14px;
        border-radius: 10px;
        background: #f0f3fb;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          color: #6474c7;
        }
        span {
          font-size: 12px;
          color: #ccc;
          margin-top: 10px;
        }
      }
      .file-box {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
      .file-card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 24%;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 18px;
        border: 1px solid #dddfe6;
        margin-right: 1%;
        margin-bottom: 16px;
        p {
          width: 90%;
          font-size: 14px;
          text-align: center;
          color: #333;
          margin-top: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .shadow-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          // height: 200px;
          height: 100%;
          color: #fff;
          background-color: rgba(63, 65, 77, 0.5);
          z-index: -1;
          border-radius: 18px;
          overflow: hidden;
        }
        .el-progress {
          width: 80%;
        }
      }
      .is-file-card {
        .shadow-box {
          z-index: 9;
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            width: 60px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            border: none;
            background-color: #fff;
            color: #333;
            font-size: 14px;
            margin: 0 5px;
          }
        }
      }
      }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    text-align: center;
    margin-top: 30px;
  }
</style>
<style lang="scss">
.details-page {
/* table 样式 */
table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
table td,
table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
}
table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
}

/* blockquote 样式 */
blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}

/* code 样式 */
code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
pre code {
  display: block;
}

/* ul ol 样式 */
ul, ol {
  margin: 10px 0 10px 20px;
}
p,
span {
  font-size: 14px !important;
  line-height: 34px !important;
  margin-bottom: 10px !important;
}
}
</style>
