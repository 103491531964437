import Vue from 'vue'
import VueRouter from 'vue-router'
// 导入子路由规则
import children from './childrenRoutes.js'
import { getAccountMenuList } from "@/api/index.js"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    component: (resolve) => require(['@/views/login/index.vue'],resolve)
  },
  // {
  //   path: '*',
  //   name: '404',
  //   component: () => import('@/views/404.vue'),
  // },
  // {
  //   path: '/home',
  //   name: 'home',
  //   component: () => import('@/views/home/index.vue'),
  //   children
  // },
]

// const originalPush = Router.prototype.push;
// Router.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject)
//     return originalPush.call(this, location, onResolve, onReject);
//   return originalPush.call(this, location).catch((err) => err);
// }

// const originalPush = VueRouter.prototype.push;
// const originalReplace = VueRouter.prototype.replace;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject)
//     return originalPush.call(this, location, onResolve, onReject);
//   return originalPush.call(this, location).catch(err => err);
// };
// VueRouter.prototype.replace = function push(location, onResolve, onReject) {
//     if (onResolve || onReject)
//         return originalReplace.call(this, location, onResolve, onReject);
//     return originalReplace.call(this, location).catch(err => err);
// };

const router = new VueRouter({
  mode: 'history',
  routes
})

// function setRouter(data,arr) {
//   data.forEach( item => {
//     console.log(item);
//     let list = [];
//     if (item.childs.length > 0) {
//       list = setRouter(item.childs,[]);
//     }
//     let route = item.route;
//     let obj = {
//       path: `/${item.identity}`,
//       name: item.identity,
//       component: (resolve) => require([`@/views/home/${route}`],resolve),
//       meta: {
//         title: item.name,
//         icon: "",
//       }
//     }
//     if (list.length > 0) {
//       obj.children = list;
//     }
//     arr.push(obj);
//   })
//   return arr;
// }
// 路由守卫
router.beforeEach(async(to, from, next) => {
  // next();
  // console.log(to, from);
  if (to.path === "/") {
    next();
  } else if (to.path === "*") {
    // let res = await getAccountMenuList();
    // if (res.code === 0) {
    //   let obj = {
    //     path: '/home',
    //     name: 'home',
    //     component: () => import('@/views/home/index.vue'),
    //     children: await setRouter(res.result,[]),
    //   }
    //   // let newRouter = await [
    //   //   {
    //   //     path: '/',
    //   //     name: 'login',
    //   //     component: () => import('@/views/login/index.vue')
    //   //   },
    //   //   {
    //   //     path: '/home',
    //   //     name: 'home',
    //   //     component: () => import('@/views/home/index.vue'),
    //   //     children: await setRouter(res.result,[]),
    //   //   }
    //   // ]
    //   await router.addRoute(obj);
      next("/");
    // }
    // next();
  } else {
    // let routerInfo = store.state.index.routerInfo;
    // if (routerInfo === "") {
    //   let res = await getAccountMenuList();
    //   if (res.code === 0) {
    //     let obj = {
    //       path: '/home',
    //       name: 'home',
    //       component: (resolve) => require(['@/views/home/index.vue'],resolve),
    //       children: setRouter(res.result,[]),
    //     }
    //     router.addRoute(obj);
    //     router.push()
    //   }
    // }
    next();
  }
})

export default router
