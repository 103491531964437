// 引入request
import requests from "../utils/request";

//emails信息
// 获取email列表
export const getEmailPaging = (data) =>
  requests({
    url: `/api/user/email/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
  // 导出用户列表
  export const exportEmail = (data) =>
    requests({
      url: `/api/user/email/export`,
      method: "post",
      data,
      responseType: "blob",
    });

// 编辑email
export const editEmail = (data) =>
  requests({
    url: `/api/user/email/edit`,
    method: "post",
    data: data,
  });

//email详情
export const detailEmail = (id) =>
  requests({
    url: `/api/user/email/detail/${id}`,
    method: "get",
  });

// 1 --------------- ComDoc
// 1-1 ------------- 用户管理
// 获取用户列表
export const getComDocUserPaging = (data) =>
  requests({
    url: `/api/user/comdoc/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
  // 导出用户列表
  export const exportComDocUser = (data) =>
    requests({
      url: `/api/user/comdoc/export`,
      method: "post",
      data,
      responseType: "blob",
    });
// 获取今日新增用户列表
export const getComDocTodayUser = (data) =>
  requests({
    url: `/api/user/comdoc/today/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 获取会员过期用户列表
export const getComDocHaveExpireUser = (data) =>
  requests({
    url: `/api/user/comdoc/expired/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 1-2 ------------- 访问管理
// 登录日志
export const getComDocLoginLog = (data) =>
  requests({
    url: `/api/operationLog/comdoc/login/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 操作日志
export const getComDocOperationLog = (data) =>
  requests({
    url: `/api/operationLog/comdoc/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 1-3 ------------- 订单管理
export const getComDocOrder = (data) =>
  requests({
    url: `/api/order/comdoc/paging/${data.params}`,
    method: "post",
    data: data.info,
  });

// 1-4 ------------------------用户管理
// 新增用户
export const addComDocUser = (data) =>
  requests({
    url: `/api/user/comdoc/add`,
    method: "post",
    data: data,
  });
// 编辑用户
export const editComDocUser = (data) =>
  requests({
    url: `/api/user/comdoc/edit`,
    method: "post",
    data: data,
  });

//用户详情
export const detailComDocUser = (id) =>
  requests({
    url: `/api/user/comdoc/detail/${id}`,
    method: "get",
  });

//1-5--------------
//ComDoc今日订单
export const getComDocTodayOrder = (data) =>
  requests({
    url: `/api/order/comdoc/today/paging/${data.params}`,
    method: "post",
    data: data.info,
  });

// 2 --------------- iLaaS
// 2-1 ------------- 用户管理
// 新增用户
export const addIlaasUser = (data) =>
  requests({
    url: `/api/user/ilaas/add`,
    method: "post",
    data: data,
  });
// 编辑用户
export const editIlaasUser = (data) =>
  requests({
    url: `/api/user/ilaas/edit`,
    method: "post",
    data: data,
  });
// 用户详情
export const detailIlaasUser = (id) =>
  requests({
    url: `/api/user/detail/${id}`,
    method: "get",
  });
// 获取用户列表
export const getUserPaging = (data) =>
  requests({
    url: `/api/user/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 导出用户列表
export const exportUser = (data) =>
  requests({
    url: `/api/user/export`,
    method: "post",
    data,
    responseType: "blob",
  });
// 获取今日新增用户列表
export const getTodayUser = (data) =>
  requests({
    url: `/api/user/today/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 获取会员过期用户列表
export const getHaveExpireUser = (data) =>
  requests({
    url: `/api/user/expired/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 获取会员试用列表
export const getVipApply = (params) =>
  requests({
    url: `/api/vip/experience/apply/paging/${params}`,
    method: "get",
  });
// 审核试用会员
export const auditVipApply = (data) =>
  requests({
    url: `/api/vip/experience/apply/audit`,
    method: "post",
    data,
  });
// 导出会员试用列表
export const exportApplyVip = () =>
  requests({
    url: `/api/vip/experience/apply/export`,
    method: "get",
    responseType: "blob",
  });
// 2-2 ------------- 访问管理
// 登录日志
export const getLoginLog = (data) =>
  requests({
    url: `/api/operationLog/login/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 操作日志
export const getOperationLog = (data) =>
  requests({
    url: `/api/operationLog/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 2-3 ------------- 订单管理
export const getIlaasOrder = (data) =>
  requests({
    url: `/api/order/ilaas/today/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
  export const getIlaasAllOrder = (data) =>
  requests({
    url: `/api/order/ilaas/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 2-4 ------------- 课程管理
// 课程报名列表
export const getApplyPaging = (params) =>
  requests({
    url: `/api/apply/paging${params}`,
    method: "get",
  });
// 导出报名列表
export const exportApply = (params) =>
  requests({
    url: `/api/apply/export/${params}`,
    method: "get",
    responseType: "blob",
  });

// 3 --------------- BestCom
// 3-1 ------------- 用户管理
// 获取用户列表
export const getBestComUserPaging = (data) =>
requests({
  url: `/api/user/BestCom/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 导出用户列表
export const exportBestComUser = (data) =>
  requests({
    url: `/api/user/bestcom/export`,
    method: "post",
    data,
    responseType: "blob",
  });
// 获取今日新增用户列表
export const getBestComTodayUser = (data) =>
requests({
  url: `/api/user/BestCom/today/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 获取会员过期用户列表
export const getBestComHaveExpireUser = (data) =>
requests({
  url: `/api/user/BestCom/expired/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 3-2 ------------- 访问管理
// 登录日志
export const getBestComLoginLog = (data) =>
requests({
  url: `/api/operationLog/BestCom/login/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 操作日志
export const getBestComOperationLog = (data) =>
requests({
  url: `/api/operationLog/BestCom/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 3-3 ------------- 订单管理
export const getBestComOrder = (data) =>
requests({
  url: `/api/order/BestCom/paging/${data.params}`,
  method: "post",
  data: data.info,
});

// 3-4 ------------------------用户管理
// 新增用户
export const addBestComUser = (data) =>
requests({
  url: `/api/user/BestCom/add`,
  method: "post",
  data: data,
});
// 编辑用户
export const editBestComUser = (data) =>
requests({
  url: `/api/user/BestCom/edit`,
  method: "post",
  data: data,
});

//用户详情
export const detailBestComUser = (id) =>
requests({
  url: `/api/user/BestCom/detail/${id}`,
  method: "get",
});

//3-5--------------
//BestCom今日订单
export const getBestComTodayOrder = (data) =>
requests({
  url: `/api/order/BestCom/today/paging/${data.params}`,
  method: "post",
  data: data.info,
});

// 4 ------------------ C端用户
// 4-1 ---------------- 用户管理
// 获取用户列表
export const getComDocEmployeeUser = (data) =>
  requests({
    url: `/api/user/comdocemployee/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
  // 导出用户列表
  export const exportComDocEmployeeUser = (data) =>
    requests({
      url: `/api/user/comdocemployee/export`,
      method: "post",
      data,
      responseType: "blob",
    });
// 获取今日新增用户列表
export const getComDocEmployeeTodayUser = (data) =>
  requests({
    url: `/api/user/comdocemployee/today/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
// 获取会员过期用户列表
export const getComDocEmployeeHaveExpireUser = (data) =>
  requests({
    url: `/api/user/comdocemployee/expired/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
  // 4-2 ------------- 访问管理
  // 登录日志
  export const getComDocEmployeeLoginLog = (data) =>
    requests({
      url: `/api/operationLog/comdocemployee/login/paging/${data.params}`,
      method: "post",
      data: data.info,
    });
  // 操作日志
  export const getComDocEmployeeOperationLog = (data) =>
    requests({
      url: `/api/operationLog/comdocemployee/paging/${data.params}`,
      method: "post",
      data: data.info,
    });


    
// 1 --------------- 合规指引
// 1-1 ------------- 用户管理
// 获取用户列表
export const getComplianceUserPaging = (data) =>
requests({
  url: `/api/user/complianceguide/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 导出用户列表
export const exportComplianceUser = (data) =>
  requests({
    url: `/api/user/complianceguide/export`,
    method: "post",
    data,
    responseType: "blob",
  });
// 获取今日新增用户列表
export const getComplianceTodayUser = (data) =>
requests({
  url: `/api/user/complianceguide/today/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 获取会员过期用户列表
export const getComplianceHaveExpireUser = (data) =>
requests({
  url: `/api/user/complianceguide/expired/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 1-2 ------------- 访问管理
// 登录日志
export const getComplianceLoginLog = (data) =>
requests({
  url: `/api/operationLog/complianceguide/login/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 操作日志
export const getComplianceOperationLog = (data) =>
requests({
  url: `/api/operationLog/complianceguide/paging/${data.params}`,
  method: "post",
  data: data.info,
});
// 1-3 ------------- 订单管理
export const getComplianceOrder = (data) =>
requests({
  url: `/api/order/complianceguide/paging/${data.params}`,
  method: "post",
  data: data.info,
});

// 1-4 ------------------------用户管理
// 新增用户
export const addComplianceUser = (data) =>
requests({
  url: `/api/user/complianceguide/add`,
  method: "post",
  data: data,
});
// 编辑用户
export const editComplianceUser = (data) =>
requests({
  url: `/api/user/complianceguide/edit`,
  method: "post",
  data: data,
});

//用户详情
export const detailComplianceUser = (id) =>
requests({
  url: `/api/user/complianceguide/detail/${id}`,
  method: "get",
});

//1-5--------------
//合规指引今日订单
export const getComplianceTodayOrder = (data) =>
requests({
  url: `/api/order/complianceguide/today/paging/${data.params}`,
  method: "post",
  data: data.info,
});

// 获取用户列表
export const getQudaoUsers = () =>
  requests({
    url: `/api/account/qudao`,
    method: "get"
  });

  
// 获取用户列表-渠道
export const getQudaoComplianceUserPaging = (data) =>
  requests({
    url: `/api/user/complianceguide/qudao/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
  // 导出用户列表
  export const exportQudaoComplianceUser = (data) =>
    requests({
      url: `/api/user/complianceguide/qudao/export`,
      method: "post",
      data,
      responseType: "blob",
    });

    
// 登录日志
export const getQudaoComplianceLoginLog = (data) =>
  requests({
    url: `/api/operationLog/complianceguide/qudao/login/paging/${data.params}`,
    method: "post",
    data: data.info,
  });
  // 操作日志
  export const getQudaoComplianceOperationLog = (data) =>
  requests({
    url: `/api/operationLog/complianceguide/qudao/paging/${data.params}`,
    method: "post",
    data: data.info,
  });