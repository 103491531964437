<template>
<!-- 会员申请 -->
  <div class="operation">
    <div class="head-box" v-if="false">
      <!-- <el-button @click="add">新增案例库</el-button> -->
      <span></span>
      <div class="head-right">
        <el-input
          placeholder="请输入搜索内容"
          v-model="keyVal">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div>
    <div class="table-container">
      <el-table
        :height="tableHeight"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '编号'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '体验周期'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <span class="btn-text">
                {{ scope.row.expireTime.split("T")[0] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '申请时间'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <span class="btn-text">
                {{ scope.row.createTime.split("T")[0] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '审批时间'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <span class="btn-text">
                {{ scope.row.auditTime.split("T")[0] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '状态'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <span class="btn-text" v-if="scope.row.status === 0">待审核</span>
              <span class="btn-text" v-if="scope.row.status === 1">审核通过</span>
              <span class="btn-text" v-if="scope.row.status === 2">审核驳回</span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <template v-if="scope.row.status === 0">
              <span
                style="color: #95f204; cursor: pointer"
                @click="audit(scope.row,'通过')">
                通过
              </span>
              <span
                style="color: red; cursor: pointer; margin-left: 20px;"
                @click="audit(scope.row,'拒绝')">
                拒绝
              </span>
              </template>
              <span v-else>已审核</span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <addCaseDialog v-if="showDialog"></addCaseDialog>
  </div>
</template>

<script>
import addCaseDialog from '@/views/Dialog/addCaseDialog.vue';
import { getVipApply, auditVipApply } from '@/api/clientOperation'
export default {
  name: "operation",
  components: {
    addCaseDialog,
  },
  data() {
    return {
      keyVal: "",
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      tableData: [],
      headData: [
        { align: "center", prop: "编号", label: "编号" },
        { align: "center", prop: "userName", label: "姓名" },
        { align: "center", prop: "phoneNumber", label: "联系电话" },
        { align: "center", prop: "companyName", label: "单位" },
        { align: "center", prop: "source", label: "来源" },
        { align: "center", prop: "createTime", label: "体验周期" },
        { align: "center", prop: "createTime", label: "申请时间" },
        { align: "center", prop: "auditTime", label: "审批时间" },
        { align: "center", prop: "status", label: "状态" },
        { align: "center", prop: "remark", label: "操作" },
      ],
      arrTableLength: {},
      showDialog: false,
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
      this.getVipApplyInfo();
    },
    // 获取申请列表
    async getVipApplyInfo() {
      let params = `${this.currentPage}/${this.interPage}`;
      let res = await getVipApply(params);
      // console.log(res);
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        this.tableData = res.result.items;
        this.tableData.map(item => {
          item.auditTime = item.auditTime || '';
          item.expireTime = item.expireTime || '';
          return item;
        })
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 审核
    audit(row,status) {
      console.log(row);
      this.$confirm(`是否确认${status}【${row.userName}】的会员申请？`)
      .then((_) => {
        this.operationAudit(row,status);
      })
      .catch((_) => {});
    },
    async operationAudit(row,status) {
      let params = {
        id: row.id,
        status: status == '通过' ? 1:2,
      }
      let res = await auditVipApply(params);
      // console.log(res);
      if (res.code == 200) {
        this.getVipApplyInfo();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getVipApplyInfo();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getVipApplyInfo();
    },
    //获取表格裂宽
    getTableCellWidth() {
      this.tableHeight = this.$getTableHeight(70, "table-container");
      let headData = JSON.parse(JSON.stringify(this.headData));
      // console.log(headData)
      headData = headData.filter((val) => {
        return val.label !== "操作";
      });

      this.arrTableLength = this.$getTableCellLength({
        W: $(".table-container")[0].clientWidth,
        W2: 80,
        head: headData,
        data: this.tableData,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .operation {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    .head-box{
      display: flex;
      justify-content: space-between;
      height: 7%;
      margin-bottom: 1%;
      .el-button {
        height: 40px;
        border-radius: 20px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
      }
      .head-right {
        display: flex;
      }
    }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  }
</style>

