<template>
  <div class="editFilename">
    <el-dialog
      title="文件重命名"
      :visible.sync="showRenameDialogVisible"
      width="40%"
      :show-close="false"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      center
    >
      <el-form
        :model="showFileForm"
        ref="FileFormRef"
        label-width="100px"
        label-position="left"
      >
        <!-- <el-form-item label="当前文件名: ">
          {{ showFileForm.name }}
        </el-form-item> -->
        <el-form-item label="新文件名: ">
          <el-input v-model="showFileForm.name"></el-input>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <el-button @click="Reback">取 消</el-button>
        <el-button type="primary" @click="SaveFileInfo">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    RenameDialogVisible: {
      type: Boolean,
      default: false,
    },
    Filename: {
      type: String,
      default: "",
    },
    fileId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showRenameDialogVisible: false,
      showFileForm: {
        name: "",
      },
    //   extName: "", //文件后缀
    };
  },
  watch: {
    RenameDialogVisible: {
      handler(newVal) {
        // console.log("newVal1", newVal);
        this.showRenameDialogVisible = newVal;
      },
    },
    Filename: {
      handler(newVal) {
        // console.log("newVal", newVal);
        this.showFileForm.name = newVal;
        // this.extName = newVal.split(".")[1];
        // console.log("this.extName", this.extName);
      },
      deep: true
    },
  },
  methods: {
    async SaveFileInfo() {
      if (this.showFileForm.name === "") {
        return this.$message.error("新文件名不能为空");
      }
      this.$message.success("重命名成功");
      let data = {
        FileName: this.showFileForm.name,
        id: this.fileId,
      };
      this.$emit("filenameConfirm", false, data);
      //   this.RenameDialogVisible = false;
    },

    //重置form
    clearForm() {
      this.showFileForm = {
        name: "",
      };
    },
    //取消
    Reback() {
      this.$emit("Reback", false);
      this.clearForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.editFilename {

}
</style>