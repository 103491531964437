import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/commonStyle/main.scss'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/base.css';
Vue.use(ElementUI);
import jquery from 'jquery'
Vue.prototype.$ = jquery
import 'lib-flexible'

// el-table x轴滚动条固定在可视区底部  <el-table :data="data"  v-horizontal-scroll="'always'"></el-table>
import horizontalScroll from 'el-table-horizontal-scroll'
Vue.use(horizontalScroll)

Vue.config.productionTip = false
import {
  getstrLength,
  getTableCellLength,
  getDate,
} from '@/utils/index'
Vue.prototype.$getstrLength = getstrLength;
Vue.prototype.$getTableCellLength = getTableCellLength;
Vue.prototype.$getDate = getDate;

new Vue({
  router,
  store,
  render: h => h(App),
  // created() {
  //   store
  // }
}).$mount('#app')
