<template>
  <div class="add-target-dialog">
    <el-dialog
      :title="info.type == 'add' ? '添加指标':'修改指标'"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="40%">
      <div class="row-box">
        <p class="label">
          指标名称：
        </p>
        <el-input placeholder="请输入联系人" v-model="form.name"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          参数：
          <i class="el-icon-circle-plus-outline" @click="addParameter"></i>
        </p>
        <div class="cards-box">
          <div class="card" v-for="(item,index) in parameterList" :key="Math.random()">
            <!-- <span v-if="item.id" style="font-size: 30px;">.</span> -->
            <el-input placeholder="请输入参数名称" v-model="item.name"></el-input>
            <i class="el-icon-circle-close" @click="delParameter(index)"></i>
          </div>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addTarget, editTarget, delTargetParameter } from '@/api/knowledgeData'
export default {
  name: "addTargetDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        name: '',
        phone: '',
        email: '',
        job: '',
      },
      parameterList: [],
      delParameterList: [],
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // console.log(this.info);
      if (this.info.type == 'edit') {
        this.form = JSON.parse(JSON.stringify(this.info.row))
        this.parameterList = this.form.childs;
        console.log(this.parameterList);
      }
    },
    // 添加参数
    addParameter() {
      this.parameterList.push(
        {
          childs: [],
          code: '',
          id: '',
          level: 2,
          name: '',
          parentId: '',
        }
      )
    },
    // 删除参数
    delParameter(idx) {
      if (this.parameterList[idx].id) {
        this.delParameterList.push(this.parameterList[idx].id)
      }
      console.log(idx);
      this.parameterList.splice(idx,1);
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    submit() {
      if (this.info.type == 'add') {
        this.addSubmit();
      } else {
        this.editSubmit();
      }
    },
    async addSubmit() {
      if (this.submitStatus) return;
      if (!this.form.name.trim()) {
        this.$message.warning(`指标名称不能为空！`);
        return;
      }
      let childs = [];
      this.parameterList.forEach(item => {
        let name = item.name.trim();
        if (name) {
          childs.push(
            {
              name: name
            }
          )
        }
      })
      let params = {
        name: this.form.name,
        childs: childs,
      }
      this.submitStatus = true;
      let res = await addTarget(params);
      this.submitStatus = false;
      if (res.code == 200) {
        this.$parent.getTargetInfo();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    async editSubmit() {
      if (this.submitStatus) return;
      if (!this.form.name.trim()) {
        this.$message.warning(`指标名称不能为空！`);
        return;
      }
      if (this.delParameterList.length != 0) {
        // 删除参数
        let res = await delTargetParameter({ids: this.delParameterList});
        if (res.code != 200) {
          this.$message.warning(`${res.message}！`);
          return
        }
      }
      let childs = [];
      this.parameterList.forEach(item => {
        let name = item.name.trim();
        if (name) {
          let newObj = {
            name: name,
          }
          if (item.id) {
            newObj.id = item.id;
          }
          childs.push(newObj);
        }
      })
      let params = {
        name: this.form.name,
        childs: childs,
        id: this.form.id,
      }
      this.submitStatus = true;
      let res2 = await editTarget(params);
      this.submitStatus = false;
      // console.log(res2);
      if (res2.code == 200) {
        this.$parent.getTargetInfo();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-target-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            display: flex;
            justify-content: space-between;
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            font-size: 18px;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
            i {
              font-size: 34px;
              cursor: pointer;
            }
          }
          .cards-box {
            height: 500px;
            overflow-y: auto;
            padding: 18px;
            border-radius: 20px;
            border: 1px solid #ccc;
            .card {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: 10px;
              .el-input {
                width: 90%;
              }
              i {
                font-size: 34px;
                cursor: pointer;
              }
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
