
// 引入request
import requests from "../utils/request";

// 界面管理
// 新增菜单
export const addMenu = (data) =>
  requests({
  url: `/api/menu/add`,
  method: "post",
  data,
});
//  编辑菜单
export const editMenu = (data) =>
  requests({
  url: `/api/menu/edit`,
  method: "post",
  data,
});
//  删除菜单
export const delMenu  = (data) =>
  requests({
  url: `/api/menu`,
  method: "delete",
  data,
});
//  获取菜单列表
export const getMenuList = () =>
  requests({
  url: `/api/menu/list`,
  method: "get",
});

// 用户管理
// 新增用户
export const addAccount = (data) =>
requests({
url: `/api/account/create`,
method: "post",
data,
});
//  编辑用户
export const editAccount = (data) =>
  requests({
  url: `/api/account/edit`,
  method: "post",
  data,
});
//  删除用户
export const delAccount  = (data) =>
  requests({
  url: `/api/Account`,
  method: "delete",
  data,
});
// 获取用户列表
export const getAccountList = (params) =>
  requests({
  url: `/api/account/paging/${params}`,
  method: "get",
});
// 获取菜单权限列表
export const getAllocationList = (id) =>
  requests({
  url: `/api/account/permission/allocation/list/${id}`,
  method: "get",
});
// 获取菜单有权限列表
export const getAccountMenuList = () =>
  requests({
  url: `/api/account/menu/list`,
  method: "get",
});
// 配置权限
export const setAllocation = (data) =>
  requests({
  url: `/api/account/permission/allocation`,
  method: "post",
  data,
});