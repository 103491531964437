<template>
  <div class="case-warehouse">
    <tablePage ref="tablePage" v-show="!detailsStatus" @editTask="editTask" @look="look"></tablePage>
    <addPage v-if="detailsStatus === 'add' || detailsStatus === 'edit'" :info="detailsInfo"></addPage>
    <detailsPage v-if="detailsStatus == 'look'" :info="detailsInfo"></detailsPage>
  </div>
</template>

<script>
import tablePage from './modules/tablePage.vue'
import addPage from "./modules/addPage.vue"
import detailsPage from "./modules/detailsPage.vue"
export default {
  name: "caseWarehouse",
  components: {
    tablePage,
    addPage,
    detailsPage,
  },
  data() {
    return {
      detailsStatus: "",
      detailsInfo: {}
    }
  },
  created() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init () {
      this.$nextTick(() => {
        this.$refs.tablePage.init();
      })
    },
    // 查看
    look(row) {
      console.log(row);
      this.detailsInfo = row;
      this.detailsStatus = "look";
    },
    // 新增
    add() {
      this.detailsInfo = {};
      this.detailsStatus = "add";
    },
    // 编辑
    editTask(row) {
      console.log(row);
      this.detailsInfo = row;
      this.detailsStatus = "edit";
    },
  }
}
</script>

<style lang="scss" scoped>
  .case-warehouse {
  }
</style>

