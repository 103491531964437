<template>
<!-- 登录记录 -->
  <div class="iLaaS-login-record">
    <div class="head-box">
        <div class="head-left">
        <el-date-picker
          v-model="filterInfo.dates"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="init">
        </el-date-picker>
        <el-input placeholder="请输入搜索内容" v-model="filterInfo.keyVal">
          <i slot="suffix" @click="init" class="el-input__icon el-icon-search"></i>
        </el-input>
        <!-- <el-button class="btn" @click="exportData">导出</el-button> -->
      </div>
      <div class="head-right">
        <div class="popover-box">
          <el-popover
            popper-class="item-popover"
            placement="bottom"
            width="416"
            trigger="click"
          >
            <el-checkbox-group v-model="headFilter">
              <el-checkbox
                v-for="(item, index) in headFilterList"
                :label="index"
                :key="index"
                @change="filtrate"
                class="checkbox-line">
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
            <el-button type="text" slot="reference">
              <img class="icon-img" src="@/assets/img/hmc_shaixuan.png" />
              <span class="c-span">筛选</span></el-button>
          </el-popover>
        </div>
       </div>
      </div>
      <div class="table-container">
        <el-table
          :height="tableHeight"
          border
          :data="tableData"
          style="width: 100%"
          fit
        >
          <template v-for="(head, index) in headData">
            <el-table-column
              :align="head.align"
              :key="index"
              :prop="head.prop"
              :label="head.label"
            ></el-table-column>
          </template>
        </el-table>
        <div>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 15, 20]"
            :page-size="interPage"
            layout="total, sizes, prev, pager, next, jumper"
            background
            :total="dataSize"
          >
          </el-pagination>
        </div>
      </div>
  </div>
</template>

<script>
import { exportUser, getComDocEmployeeLoginLog } from "@/api/clientOperation";
import { Loading } from "element-ui";
export default {
  name: "user",
  components: {
  },
  data() {
    return {
      filterInfo: {
        dates: null,
        // accountStatus: null,
        keyVal: "",
      },
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      headFilter: [0,1,2,3,4,5,6,7,8],
      headFilterList: [
        { align: "center", prop: "userName", label: "用户名" },
        { align: "center", prop: "name", label: "姓名" },
        { align: "center", prop: "phoneNumber", label: "手机" },
        { align: "center", prop: "enterprise", label: "公司" },
        { align: "center", prop: "identity", label: "身份" },
        { align: "center", prop: "createTime", label: "登录时间" },
        { align: "center", prop: "ip", label: "IP" },
        { align: "center", prop: "ipAddress", label: "IP所属地" },
        { align: "center", prop: "description", label: "说明" },
      ],
      tableData: [],
      headData: [],
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      this.filtrate();
    },
    // 过滤表头
    filtrate() {
      this.headData = [];
      let newArr = [];
      if (this.headFilter.length > 0) {
        this.headFilter.sort(function (a,b) {
            return a-b;
        })
        this.headFilter.forEach(item => {
          newArr.push(this.headFilterList[item])
        })
      }
      this.$nextTick(()=> {
        this.headData = newArr;
        this.getData();
      })
    },
    async getData() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = {
        info: {
          queryString: this.filterInfo.keyVal,
          startDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[0]):null,
          endDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[1]):null,
        },
        params: `${this.currentPage}/${this.interPage}`,
      }
      // let params = `/${this.currentPage}/${this.interPage}`;
      let res = await getComDocEmployeeLoginLog(params);
      loadingInstance.close();
      if (res.code == 0) {
        this.tableData = res.result.items;
        this.dataSize = res.result.totalCount;
        this.tableData.map((item) => { 
          item.identity = "员工";
          // item.province = item.province !=null ? item.province:"";
          // item.city = item.city !=null ? item.city:"";
          // item.area = `${item.province}  ${item.city}`;
          let dates = "";
          if (item.createTime != null) {
            dates = item.createTime.split("T");
            dates = `${dates[0]} ${dates[1]}`;
          }
          item.createTime = dates;
          // item.expires = item.expires != null ? item.expires.split("T")[0]:"";
          // item.imgUrl = `https://apitest.ilaas.cn${item.avatar}`
          return item;
        });
        // console.log(this.tableData);
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    setVip(row) {
      let str = "非会员";
      if (row.isVip) {
        if (row.isExperience) {
          str = "体验会员";
        } else {
          str = "会员";
        }
      }
      return str;
    },
    // 导出 exportUser
    async exportData() {
      let params = {
        queryString: this.filterInfo.keyVal,
        startDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[0]):null,
        endDate: this.filterInfo.dates != null ? this.$getDate(1,this.filterInfo.dates[1]):null,
        status: this.filterInfo.accountStatus,
      };
      let res = await exportUser(params);
      // console.log(res.status);
      if (res.status != undefined) {

      } else {
        let data = res;
        let fileName = "登录记录.xlsx";
        const blob =
          data instanceof Blob
            ? data
            : new Blob([data], {
                type: "application/vnd.ms-excel;charset=utf-8",
              });
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(link.href);
          document.body.removeChild(link);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
  .iLaaS-login-record {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  .head-box {
    display: flex;
    justify-content: space-between;
    height: 7%;
    margin-bottom: 1%;
    .head-left {
      display: flex;
      align-items: center;
      .el-date-editor {
        width: 300px;
        margin-right: 10px;
      }
      .el-select {
        width: 200px;
        margin-right: 20px;
      }
      .el-input {
        width: 330px;
        margin-right: 10px;
      }
      .btn {
        height: 30px;
        border-radius: 15px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
      }
    }
    .head-right {
      .popover-box {
        margin-left: 30px;
        .icon-img {
          width: 14px !important;
          height: 14px !important;
          margin-right: 8px;
        }
        span {
          color: #333;
        }
      }
    }
  }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  img {
    width: 140px;
  }
  }
</style>

