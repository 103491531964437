<template>
  <div class="Official-Accounts">
    <router-view class="children-view"></router-view>
  </div>
</template>

<script>
export default {
  name: "OfficialAccounts",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .Official-Accounts {
  }
</style>

