<template>
  <div class="add-case-law-dialog">
    <el-dialog
      title="判例"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="50%">
      <div class="row-box">
        <p class="label">
          <span>* </span>
          判例名称：
        </p>
        <el-input placeholder="请输入判例名称" v-model="form.name"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          案号：
        </p>
        <el-input placeholder="请输入案号" v-model="form.caseNo"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          案由：
        </p>
        <el-cascader
          v-model="form.causeOfAction"
          :options="options"
          :props="{ value: 'id', label: 'name', children: 'childs', expandTrigger: 'hover' }">
        </el-cascader>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          当事人：
          <span style="color: #ccc;">（ 多人用 “，” 隔开 ）</span>
        </p>
        <el-input placeholder="请输入当事人" v-model="form.litigant"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          案件类型：
        </p>
        <el-input placeholder="请输入案件类型" v-model="form.caseType"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          审理程序：
        </p>
        <el-input placeholder="请输入审理程序" v-model="form.trialProcedure"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          审理法院：
        </p>
        <el-input placeholder="请输入审理法院" v-model="form.trialCourt"></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          智能摘要：
        </p>
        <el-input type="textarea" placeholder="请输入智能摘要" v-model="form.reason" autosize></el-input>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          裁判日期：
        </p>
        <el-date-picker
          v-model="form.refereeTime"
          type="date"
          placeholder="选择裁判日期">
        </el-date-picker>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          法律依据：
        </p>
        <div style="border: 1px solid #ccc">
          <Editor
            style="height: 200px; overflow-y: hidden"
            v-model="form.lawBasis"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
          />
        </div>
      </div>
      <div class="row-box">
        <p class="label">
          <span>* </span>
          内容：
        </p>
        <div style="border: 1px solid #ccc">
          <Toolbar
            style="border-bottom: 1px solid #ccc"
            :editor="editor"
            :defaultConfig="toolbarConfig"
            :mode="mode"
          />
          <Editor
            style="height: 500px; overflow-y: hidden"
            v-model="form.content"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
          />
        </div>
      </div>
      <div class="row-box">
        <p class="label">
          是否启用：
        </p>
        <el-select v-model="form.isEnable">
          <el-option
            v-for="item in enableList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import { getCauseofaction, addPrecedent, editPrecedent, getPrecedentDetail } from "@/api/knowledgeData.js"
export default {
  name: "addCaseLawDialog",
  components: {
    Editor,
    Toolbar,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default", // or 'simple'
      form: {
        name: "",  // 判例名称
        caseNo: "", // 案号
        causeOfAction: [], // 案由
        causeOfActionStract: "",
        litigant: "", // 当事人
        caseType: "", // 案件类型
        trialProcedure: "", // 审理程序
        trialCourt: "", // 审理法院
        reason: "", //智能摘要
        refereeTime: "", // 裁判日期
        lawBasis: "", // 法律依据
        content: "", // 内容
        isEnable: true, // 是否启用
        url: "", // 来源
      },
      options: [],
      enableList: [
        {label: "是", value: true},
        {label: "否", value: false},
      ],
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    async init() {
      // console.log(this.info);
      await this.getData();
      if (this.info.type == 'edit') {
        this.getDetailData();
      }
    },
    async getData() {
      let res = await getCauseofaction();
      if (res.code == 0) {
        let data = res.result;
        data.forEach(item => {
          if (item.childs.length > 0){
            let newArr = item.childs;
            newArr.forEach(item2 => {
              if (item2.childs.length == 0) {
                item2.childs = null;
              }
            })
          } else {
            item.childs = null;
          }
        });
        this.options = res.result;

      }
    },
    async getDetailData() {
      let res = await getPrecedentDetail(this.info.item.id);
      if (res.code == 0) {
        let data = res.result;
        let newList = [];
        if (data.causeOfActionStract != null) {
          data.causeOfAction = data.causeOfActionStract.split(',');
          data.causeOfAction.forEach(item => {
            newList.push(Number(item));
          })
        }
        data.causeOfAction = newList;
        this.form = data;
      }
    },
    handleChange(value) {
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      let verifyStatus = false;
      if (
        !this.form.name ||
        !this.form.caseNo ||
        this.form.causeOfAction.length == 0 ||
        !this.form.litigant ||
        !this.form.trialCourt ||
        !this.form.refereeTime ||
        !this.form.lawBasis ||
        !this.form.content
      ) {
        verifyStatus = true;
      }
      if (verifyStatus) {
        this.$confirm(`部分必填项为空，是否继续保存？保存后启用状态将被设定为不启用！`)
        .then((_) => {
          this.form.isEnable = false;
          this.confirmSubmit();
        })
        .catch((_) => {});
      } else {
        this.confirmSubmit();
      }
    },
    async confirmSubmit() {
      if (this.submitStatus) return;
      let params = JSON.parse(JSON.stringify(this.form));
      params.causeOfActionStract = params.causeOfAction.join(',');
      params.causeOfAction = params.causeOfAction[params.causeOfAction.length - 1];
      params.refereeTime = this.$getDate(1,params.refereeTime);
      // console.log(params);return  this.$getDate(3)
      this.submitStatus = true;
      let res = {};
      if (this.info.type == 'add') {
        res = await addPrecedent(params);
      } else {
        res = await editPrecedent(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style>
  @import url("@wangeditor/editor/dist/css/style.css");
</style>
<style lang="scss" scoped>
.add-case-law-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
