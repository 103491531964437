<template>
  <div class="add-index-level">
    <el-dialog
      :title="title"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      width="460px"
    >
      <div class="row-box" v-if="title == '新增'">
        <p class="label">请选择新增类型：</p>
        <div class="radio-box">
          <p :class="raradioId == 1 ? 'is-p' : ''" @click="raradioId = 1">
            指标
          </p>
          <p :class="raradioId == 2 ? 'is-p' : ''" @click="raradioId = 2">
            题目
          </p>
        </div>
      </div>
      <template v-if="raradioId == 1">
        <div class="row-box">
          <p class="label">
            <span>* </span>
            指标名称：
          </p>
          <el-input
            placeholder="请输入指标名称"
            v-model="form.indexName"
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">关联风险点：</p>

          <div class="law-div">
            <p class="law-p" :title="form.riskName" @click="seleteRisk">
              {{ form.riskName }}
            </p>
            <div class="del" @click="delRiskName" v-if="form.riskName!='关联'">删除</div>
          </div>
        </div>
      </template>
      <template v-if="raradioId == 2">
        <div class="row-box">
          <p class="label">
            <span>* </span>
            题目内容：
          </p>
          <el-input
            type="textarea"
            :autosize="{ minRows: 1 }"
            placeholder="请输入题目内容"
            v-model="form.content"
          ></el-input>
        </div>
        <div class="row-box">
          <p class="label">
            <span>* </span>
            答题方式：
          </p>
          <el-select v-model="form.answerWay" placeholder="请选择">
            <el-option
              v-for="item in answerList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <p class="label">评价规则：</p>
          <el-input
            type="textarea"
            :autosize="{ minRows: 1 }"
            placeholder="请输入评价规则"
            v-model="form.rule"
          ></el-input>
        </div>
        <div class="row-box" v-if="form.answerWay == 1">
          <p class="label">最大分值：</p>
          <el-input
            placeholder="请输入最大分值"
            v-model="form.maxScore"
          ></el-input>
        </div>
        <div class="row-box" v-if="form.answerWay == 1">
          <p class="label">分数限制（多个分值用中文，隔开）：</p>
          <el-input
            type="textarea"
            :autosize="{ minRows: 1 }"
            placeholder="请输入分数限制"
            v-model="form.limit"
          ></el-input>
        </div>
      </template>
      <div class="row-box">
        <p class="label">排序：</p>
        <el-input placeholder="请输入排序" v-model="form.order"></el-input>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
      <relevanceRisk
        v-if="showDialog"
        :info="detailsInfo"
        @cancelRisk="cancelRisk"
        @selectRisk="selectRisk"
      ></relevanceRisk>
    </el-dialog>
  </div>
</template>

<script>
import {
  addIndexLevel,
  editIndexLevel,
  addTopic,
  editTopic,
} from "@/api/knowledgeData";
import relevanceRisk from "./relevanceRisk.vue";
export default {
  name: "addIndexLevel",
  components: {
    relevanceRisk,
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      title: "指标",
      dialogVisible: true,
      form: {
        projectId: null, // 父级id
        parentId: null, // 项目id
        indexName: "", // 指标名称
        order: null, // 排序
        riskId: null, // 关联风险点
        riskName: "关联", // 关联风险点
        gradeId: null, // 父级id
        content: "", // 题目内容
        answerWay: 1, // 答题方式
        rule: "", // 评价规则
        maxScore: null, // 最大分值
        limit: "", // 分值限制
      },
      projectList: [],
      submitStatus: false,
      raradioId: 1, // 1 指标 2 题目
      showDialog: false,
      detailsInfo: {},
      answerList: [
        { value: 1, label: "打分" },
        { value: 2, label: "选择" },
      ],
    };
  },
  created() {
    this.form.projectId = this.info.projectId;
    this.form.parentId = this.info.parentId;
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      console.log(this.info);
      if (this.info.type == 1) {
        this.title = "新增";
      } else if (this.info.type == 2) {
        this.title = "新增指标";
        this.raradioId = 1;
      } else if (this.info.type == 3) {
        this.title = "新增题目";
        this.raradioId = 2;
      }
      if (this.info.type2 == 1) {
        this.title = "编辑指标";
        let info = this.info.item;
        this.form.id = info.id;
        this.form.indexName = info.name;
        this.form.order = info.order;
        this.form.riskId = info.riskId;
        this.form.riskName = info.riskName || "关联";
        this.raradioId = 1;
      } else if (this.info.type2 == 2) {
        this.title = "编辑题目";
        let info = this.info.item;
        this.form.id = info.id;
        this.form.content = info.content;
        this.form.rule = info.rule;
        this.form.answerWay = info.answerWay;
        this.form.maxScore = info.maxScore;
        this.form.limit = info.limit;
        this.form.order = info.order;
        this.raradioId = 2;
      }
    },
    // 风险点弹框
    seleteRisk() {
      this.showDialog = true;
    },
    // 关闭风险点弹框
    cancelRisk() {
      this.showDialog = false;
    },
    // 关联风险点
    selectRisk(item) {
      this.form.riskId = item.id;
      this.form.riskName = item.name;
      this.showDialog = false;
    },
    cancel() {
      this.$parent.showDialog2 = false;
      // this.$emit("cancel");
    },
    delRiskName() {
      this.form.riskId =null;
      this.form.riskName = "关联";
    },
    async submit() {
      if (this.raradioId == 1) {
        this.submit2();
      } else {
        this.submit3();
      }
    },
    // 新增指标
    async submit2() {
      if (this.submitStatus) return;
      if (!this.form.indexName) {
        this.$message.warning(`指标名称不能为空！`);
        return;
      }
      let params = {
        name: this.form.indexName,
        order: Number(this.form.order),
        riskId: this.form.riskId,
      };
      if (this.form.parentId) {
        params.parentId = this.form.parentId;
      } else {
        params.projectId = this.form.projectId;
      }
      // console.log(params);return
      this.submitStatus = true;
      let res = {};
      if (!this.info.type2) {
        res = await addIndexLevel(params);
      } else {
        params.id = this.form.id;
        res = await editIndexLevel(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.$parent.init(1);
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
    // 新增题目
    async submit3() {
      if (this.submitStatus) return;
      if (!this.form.content) {
        this.$message.warning(`题目内容不能为空！`);
        return;
      }
      let params = {
        // gradeId: this.form.parentId,
        content: this.form.content,
        rule: this.form.rule,
        answerWay: Number(this.form.answerWay),
        maxScore: Number(this.form.maxScore),
        limit: this.form.limit,
        order: Number(this.form.order),
      };
      if (this.form.parentId) {
        params.gradeId = this.form.parentId;
      } else {
        params.projectId = this.form.projectId;
      }
      // console.log(params);return
      this.submitStatus = true;
      let res = {};
      if (!this.info.type2) {
        res = await addTopic(params);
      } else {
        params.id = this.form.id;
        res = await editTopic(params);
      }
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.$parent.init(2);
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-index-level {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        p {
          margin-bottom: 0 !important;
        }
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
          .law-div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .law-p {
              color: #0094ff;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              cursor: pointer;
            }
            .del {
              width: 60px;
              height: 26px;
              font-size: 12px;
              font-weight: 600;
              color: #bbb;
              background: #f1f2f1;
              border-radius: 12px;
              border: none;
              cursor: pointer;
              text-align: center;
              line-height: 26px;
            }
          }
          .radio-box {
            display: flex;
            align-items: center;
            p {
              margin-right: 10px;
              cursor: pointer;
            }
            .is-p {
              color: #4cb4ff;
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
