<template>
  <div class="add-parameters">
    <el-dialog
      :title="info.type == 'add' ? '添加参数':'修改参数'"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="50%">
      <!-- <div class="row-box">
        <p class="label">
          <span>*</span>
          国家：
        </p>
        <el-select v-model="countryVal" value-key="id" @change="selectCountry" placeholder="请选择国家">
          <el-option
            v-for="item in countryList"
            :key="item.id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </div> -->
      <div class="row-box">
        <p class="label">
          <!-- <span>*</span> -->
          省份：
        </p>
        <el-select v-model="provinceVal" value-key="id" @change="selectProvince" placeholder="请选择省份">
          <el-option
            v-for="item in provinceList"
            :key="item.id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">
          <!-- <span>*</span> -->
          城市：
        </p>
        <el-select v-model="cityVal" value-key="id" @change="selectCity" placeholder="请选择城市">
          <el-option
            v-for="item2 in cityList"
            :key="item2.id"
            :label="item2.name"
            :value="item2">
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">地区：</p>
        <el-input placeholder="请输入地区" v-model="region"></el-input>
      </div>
      <div class="row-box">
        <p class="label">年份：</p>
        <el-date-picker
          v-model="year"
          type="year"
          placeholder="选择年">
        </el-date-picker>
      </div>
      <div class="row-box" v-for="item3 in myHeadData" :key="item3.id">
        <p class="label">
          {{item3.name}}：
        </p>
        <el-input type="textarea" :autosize="{ minRows: 1}" :placeholder="`请输入${item3.name}`" v-model="item3.value"></el-input>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRegion, addParameter } from '@/api/knowledgeData'
export default {
  name: "addParameters",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      country: "",
      region: '',
      year: new Date(),
      myHeadData: [],
      scopeList: [], // 适用范围
      provinceVal: null,
      provinceList: null,
      cityVal: null,
      cityList: null,
      submitStatus: false,
      // countryVal: null, // 国家
      // countryList: null, // 国家数组
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // console.log(this.info);
      let headData = this.info.head;
      headData.forEach(item => {
        this.myHeadData.push(
          {
            subTargetId: item.id,
            name: item.name,
            value: '',
          }
        )
      });
      this.getRegionInfo();
      return
      // console.log(this.info);
      if (this.info.type == 'edit') {
      }
    },
    // 获取地区
    async getRegionInfo() {
      let res = await getRegion();
      // console.log(res,'地区');
      if (res.code == 0) {
        // this.countryList = res.result;
        this.provinceList = res.result;
      }
    },
    // // 选择国家
    // selectCountry() {
    //   this.provinceVal = null;
    //   this.cityVal = null;
    //   let newArr = [];
    //   for (let i = 0; i < this.countryList.length; i++) {
    //     let item = this.countryList[i];
    //     if (item.id == this.countryVal.id) {
    //       if (item.childs.length > 0) {
    //         this.provinceList = newArr.concat(item.childs);
    //         this.provinceVal = this.provinceList[0];
    //         this.selectProvince();
    //       }
    //       break;
    //     }
    //   }
    // },
    // 选择省份
    selectProvince() {
      this.cityVal = null;
      let type = this.provinceVal.name.substr(-1);
      let newArr = [];
      newArr = newArr.concat(this.provinceVal.childs);
        newArr.unshift(
          {
            id: '',
            isEnable: true,
            level: 2,
            name: `全${type}`,
          }
        )
        this.cityList = newArr;
        this.cityVal = this.cityList[0];
    },
    // 选择城市
    selectCity() {
      console.log(this.cityVal);
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (this.submitStatus) return;
      // console.log(this.myHeadData);return
      // if (!this.countryVal || !this.provinceVal || !this.cityVal) {
      // if (!this.countryVal) {
      //   this.$message.warning(`请选择国家！`);
      //   return;
      // }
      let provinceId = null;
      let cityId = null;
      if (this.provinceVal != null) {
        provinceId = this.provinceVal.id || null;
      }
      if (this.cityVal != null) {
        cityId = this.cityVal.id || null;
      }
      console.log(this.cityVal);
      let params = {
        targetId: this.info.targetId,
        provinceId: provinceId,
        cityId: cityId,
        area: this.region,
        year: this.year.getFullYear(),
        data: this.myHeadData,
      }
      // console.log(params);
      // return
      this.submitStatus = true;
      let res = await addParameter(params);
      this.submitStatus = false;
      // console.log(res);
      if (res.code == 200) {
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-parameters {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
