<template>
  <div class="shift-hrpp-dialog">
    <el-dialog
      title="转移"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%">
      <div class="row-box">
        <p class="label">
          转移位置：
        </p>
        <el-cascader
          style="margin-bottom: 10px;"
          v-model="value"
          @active-item-change="handleItemChange"
          :options="myList"
          :props="prop">
        </el-cascader><br/>
        <span v-if="info.fileType === 1">（ 文件夹与文件不能同时存在同一级，请确认目标文件夹是否存在文件 ）</span>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import { getHrpp, moveFile } from "@/api/knowledgeData.js";
export default {
  name: "shiftHRPPDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      value: [],
      myList: [],
      levelList: [],
      prop: {
        lazy: false,
        value: 'id',
        label: 'name',
        children: 'child',
        // checkStrictly: true,
        // expandTrigger: 'hover'
      },
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    async init() {
      // console.log(this.info,'this.info');
      if (this.info.fileType === 1) {
        this.prop.checkStrictly = true;
      }
      // let arr =  [].concat(this.info.lists);
      // this.myList = await this.setData(arr,[]);
      this.getPlatCategory();
    },
    async getPlatCategory() {
      let params = "/0";
      if (this.levelList.length > 0) {
        params = `/${this.levelList[this.levelList.length - 1].id}`
      }
      let res = await getHrpp(params);
      if (res.code == 0) {
        let data = res.result;
        data.map(item => {
          item.child = null;
          if (item.fileType === 2) {
            let nameArr = item.name.split(".");
            if (nameArr.length > 1) {
              nameArr.pop();
              item.name = nameArr.join('');
            }
          }
          if (item.hasChild && item.childFileType === 1) {
            item.child = [];
          }
          return item
        })
        console.log(data,'datadata');
        this.myList = data;
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    handleItemChange(value) {
      let newArr = this.myList;
      value.map(async (item,index) => {
        if (newArr instanceof Array == false) {
          newArr = newArr.child
        }
        for (let i = 0; i < newArr.length; i++) {
          let item2 = newArr[i];
          if (item == item2.id) {
            newArr = item2;
            break;
          }
        }
        if (index == value.length -1) {
          let params = `/${item}`
          this.prop.lazy = true;
          let res = await getHrpp(params);
          this.prop.lazy = false;
            if (res.code == 0) {
              let data = res.result;
              data.map(item => {
                item.child = null;
                if (item.fileType === 2) {
                  let nameArr = item.name.split(".");
                  if (nameArr.length > 1) {
                    nameArr.pop();
                    item.name = nameArr.join('');
                  }
                }
                if (item.hasChild && item.childFileType === 1) {
                  item.child = [];
                }
                return item
              })
              newArr.child = data;
            }
        }
      })
    },
    cancel() {
      this.$parent.showDialog3 = false;
    },
    async submit() {
      if (this.submitStatus) return;
      if (this.value.length == 0) {
        this.$message.warning(`请选择文件夹！`);
        return;
      }
      let params = {
        ids: [this.info.id],
        parentId: this.value[this.value.length - 1],
      }
      if (params.ids[0] == params.parentId) {
        this.$message.warning(`请选择其他文件夹！`);
        return;
      }
      if (this.info.fileType === 1) {
        let newList = this.myList;
        let info = null;
        this.value.map(item => {
          for (let y = 0; y < newList.length; y++) {
            let item3 = newList[y];
            if (item == item3.id) {
              newList = item3.child;
              info = item3;
              break;
            }
          }
        })
        if (info.childFileType === 2) {
          this.$message.warning(`文件夹 ${info.name} 下面存在文件，请选择其他文件夹！`);
          return;
        }
      }
      this.submitStatus = true;
      let res = await moveFile(params);
      this.submitStatus = false;
      if (res.code == 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.shift-hrpp-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #EAF5FF;
        span {
          color: #6474C7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #D7DBDA;

            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #F1F2F1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
