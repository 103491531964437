<template>
  <div class="details-page">
    <template v-if="myInfo.customerName">
      <p class="title-p">基本信息</p>
      <div class="basics-box">
        <div class="line-box">
          <div class="row-box">
            <span class="label">客户名称</span>
            <span class="val">{{myInfo.customerName}}</span>
          </div>
          <div class="row-box">
            <span class="label">企业类型</span>
            <span class="val">{{myInfo.customerType === 1? '个人':'企业'}}</span>
          </div>
          <div class="row-box">
            <span class="label">所在城市</span>
            <span class="val">{{myInfo.provinceName}} — {{myInfo.cityName}}</span>
          </div>
        </div>
        <div class="line-box line-box2">
          <div class="row-box">
            <span class="label">联系人</span>
            <span class="val">{{myInfo.customerContact}}</span>
          </div>
          <div class="row-box">
            <span class="label">联系方式</span>
            <span class="val">{{myInfo.customerContactTel}}</span>
          </div>
          <div class="row-box">
            <span class="label">任务类型</span>
            <span class="val">{{myInfo.assignmentTypeName}}</span>
          </div>
        </div>
      </div>
      <p class="title-p">问题描述</p>
      <p class="textarea">{{myInfo.description}}</p>
      <p class="title-p">案件文件</p>
      <div class="file-box">
        <div
          class="file-card"
          v-for="(item, index) in myInfo.files"
          :key="index"
        >
          <img :src="selectImg(item.name)" alt="" />
          <p :title="item.name">{{ item.name }}</p>
        </div>
      </div>
      <div class="btns-box">
        <el-button @click="toBack">返回</el-button>
      </div>
    </template>
  </div>
</template>

<script>
import { getOrderDetail } from "@/api/knowledgeData.js"
export default {
  name: "detailsPage",
  components: {
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      myInfo: {
        customerName: "",
      },
      showDialog: false,
      showDialog2: false,
      orderType: 0, // 当前订单类型 1：案件源 2：发布案件 3：领取案件
    }
  },
  created() {
    console.log(this.$parent.isTabes,'this.$parent.orderType');
    this.orderType = this.$parent.isTabes;
  },
  mounted() {
    // console.log(this.info);
    this.getDetail();
  },
  methods: {
    async getDetail() {
      let res = await getOrderDetail(this.info.id)
      console.log(res,'详情');
      if (res.code === 0) {
        this.myInfo = res.result;
      }
    },
    // 设置文件图片
    selectImg(info) {
      // console.log(info);
      let imgUrl = "";
      let valList = info.split(".");
      let fileType = valList[valList.length - 1];
      if (fileType == "doc" || fileType == "docx") {
        imgUrl = require("@/assets/img/file-word.png");
      } else if (fileType == "xls" || fileType == "xlsx") {
        imgUrl = require("@/assets/img/file-excel.png");
      } else if (fileType == "ppt") {
        imgUrl = require("@/assets/img/file-ppt.png");
      } else if (fileType == "pdf") {
        imgUrl = require("@/assets/img/file-pdf.png");
      }
      return imgUrl;
    },
    // 返回
    toBack() {
      this.$parent.detailsStatus = "";
    },
  }
}
</script>

<style lang="scss" scoped>
  .details-page {
    padding: 20px;
    .title-p {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      text-align: left;
      margin-bottom: 20px !important;
    }
    .basics-box {
      display: flex;
      margin-bottom: 40px;
      .line-box {
        .row-box {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .label {
            display: block;
            min-width: 90px;
            font-size: 14px;
            color: #909399;
            white-space:nowrap;
          }
          .val {
            font-size: 14px;
            color: #333333;
          }
        }
      }
      .line-box2 {
        margin: 0 200px;
      }
    }
      .textarea {
        display: block;
        width: 50%;
        min-height: 200px;
        padding: 16px;
        box-sizing: border-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-break: break-all;
        border-radius: 4px;
        border: 1px solid #D7DBDA;
        font-size: 14px;
        color: #333333;
        text-align: left;
        margin-bottom: 60px !important;
      }
      .file-box {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
      .file-card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 24%;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 18px;
        border: 1px solid #dddfe6;
        margin-right: 1%;
        margin-bottom: 16px;
        p {
          width: 90%;
          font-size: 14px;
          text-align: center;
          color: #333;
          margin-top: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .shadow-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          // height: 200px;
          height: 100%;
          color: #fff;
          background-color: rgba(63, 65, 77, 0.5);
          z-index: -1;
          border-radius: 18px;
          overflow: hidden;
        }
        .el-progress {
          width: 80%;
        }
      }
      }
      .btns-box {
        display: flex;
        align-items: center;
        margin-top: 60px;
        span {
          font-size: 14px;
          color: #333;
          font-weight: 600;
          margin-right: 40px;
        }
        .el-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
          color: #6474C7;
          background: #FFFFFF;
          border-radius: 25px;
          margin-right: 40px;
        }
        .btn {
          color: #fff;
          background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
        }
      }
  }
</style>

