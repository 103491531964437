<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { getToken } from '@/utils/token'
import { getAccountMenuList } from '@/api/setting'
export default {
  async created() {
    // console.log(getToken());
    if (JSON.stringify(getToken()) != "null") {
      if (this.$store.state.index.routerInfo.length == 0) {
        let res = await getAccountMenuList();
        if (res.code === 0) {
          let routerList = this.setRouter(res.result,[]);
          let obj = {
            path: '/home',
            name: 'home',
            component: (resolve) => require(['@/views/home/index.vue'],resolve),
            children: routerList,
          }
          this.$store.dispatch('index/setRouterInfo', routerList);
          this.$router.addRoute(obj);
        }
      }
    }
  },
  methods: {
    setRouter(data,arr) {
      data.forEach( item => {
        let list = [];
        if (item.childs.length > 0) {
          list = this.setRouter(item.childs,[]);
        }
        let route = item.route;
        let obj = {
          path: `/${item.identity}`,
          name: item.identity,
          component: (resolve) => require([`@/views/home/${route}`],resolve),
          meta: {
            title: item.name,
            icon: "",
          }
        }
        if (list.length > 0) {
          obj.children = list;
        }
        arr.push(obj);
      })
      return arr;
    },
  }
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // width: 100vw;
  height: 100vh;
}
</style>
