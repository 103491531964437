<template>
  <div class="user-manage">
    <div class="head-box">
      <el-button @click="addUser">新增账号</el-button>
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        height="650"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '序号'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            width="200px"
          >
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template slot-scope="scope">
              <span
                style="color: green; cursor: pointer"
                @click="setJurisdiction(scope.row)"
              >
                权限
              </span>
              <span
                style="color: #6aa9fb; cursor: pointer; margin: 0 20px"
                @click="editUser(scope.row)"
              >
                修改
              </span>
              <span style="color: red; cursor: pointer" @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <addUserDialog v-if="showDialog" :info="detailsInfo"></addUserDialog>
    <setJurisdictionDialog
      v-if="showDialog2"
      :info="jurisdictionInfo"
    ></setJurisdictionDialog>
  </div>
</template>

<script>
import addUserDialog from "@/views/Dialog/addUserDialog.vue";
import setJurisdictionDialog from "@/views/Dialog/setJurisdictionDialog.vue";
import { getAccountList, delAccount } from "@/api/setting";
export default {
  name: "userManage",
  components: {
    addUserDialog,
    setJurisdictionDialog,
  },
  data() {
    return {
      loading: false,
      keyVal: "",
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 15, //每页显示条数
      tableData: [],
      headData: [
        { align: "center", prop: "序号", label: "序号" },
        { align: "center", prop: "userName", label: "用户名称" },
        { align: "center", prop: "roleName", label: "用户角色" },
        { align: "center", prop: "telephone", label: "手机号" },
        { align: "center", prop: "realName", label: "真实姓名" },
        { align: "center", prop: "remark", label: "操作" },
      ],
      detailsInfo: {
        type: "add",
      },
      showDialog: false, // 用户弹框
      showDialog2: false, // 权限弹框
      jurisdictionInfo: {},
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // this.currentPage = 1;
      // this.interPage = 15;
      this.dataSize = 0;
      this.getAccount();
    },
    // 获取用户列表
    async getAccount() {
      this.loading = true;
      let params = `${this.currentPage}/${this.interPage}`;
      let res = await getAccountList(params);
      this.loading = false;
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        data.map((item) => {
          return (item.roleName =
            item.roleId == 0
              ? "超级管理员"
              : item.roleId == 1
              ? "运维"
              : "渠道");
        });
        this.tableData = data;
        // this.tableData.map(item => {
        //   item.auditTime = item.auditTime || '';
        //   item.expireTime = item.expireTime || '';
        //   return item;
        // })
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getAccount();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAccount();
    },
    // 新增用户
    addUser() {
      this.detailsInfo = {
        type: "add",
      };
      this.showDialog = true;
    },
    // 编辑用户
    editUser(row) {
      this.detailsInfo = {
        type: "edit",
        item: row,
      };
      this.showDialog = true;
    },
    // 删除用户
    del(row) {
      this.$confirm(`请确认是否删除账号【 ${row.userName} 】？`)
        .then((_) => {
          this.delUser({ ids: [row.id] });
        })
        .catch((_) => {});
    },
    async delUser(id) {
      let res = await delAccount(id);
      if (res.code === 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.init();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
    // 权限
    setJurisdiction(row) {
      this.jurisdictionInfo = row;
      this.showDialog2 = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-manage {
  padding: 20px;
  height: 100%;
  box-sizing: border-box;
  .head-box {
    display: flex;
    justify-content: space-between;
    height: 7%;
    margin-bottom: 10px;
    .el-button {
      height: 40px;
      border-radius: 20px;
      color: rgba(0, 148, 255, 0.7);
      border: 1px solid rgba(0, 148, 255, 0.7);
    }
    .head-right {
      display: flex;
    }
  }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
}
</style>
