<template>
  <div class="add-page">
    <p class="title-p">基本信息</p>
    <div class="basics-box">
      <div class="line-box">
        <div class="row-box">
          <span class="label">客户名称</span>
          <el-input class="valIpt" v-model="myInfo.name" placeholder="请输入客户名称"></el-input>
        </div>
        <div class="row-box">
          <span class="label">企业类型</span>
          <el-select
            v-model="myInfo.enterpriseType"
            filterable
            placeholder="选择企业类型"
          >
            <el-option
              v-for="item in clientList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="row-box">
          <span class="label">所在城市</span>
          <el-select
            v-model="provinceVal"
            filterable
            placeholder="省/直辖市/自治区"
            @change="selectProvince"
          >
            <el-option
              v-for="item in provinceList"
              :key="item.id"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="cityVal"
            filterable
            placeholder="市"
            @change="selectCity"
          >
            <el-option
              v-for="item in cityInfo[provinceVal]"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="line-box line-box2">
        <div class="row-box">
          <span class="label">联系人</span>
          <el-input class="valIpt" v-model="myInfo.linkman" placeholder="请输入联系人"></el-input>
        </div>
        <div class="row-box">
          <span class="label">联系方式</span>
          <el-input class="valIpt" v-model="myInfo.linkmanPhone" placeholder="请输入联系方式"></el-input>
        </div>
        <div class="row-box">
          <span class="label">任务类型</span>
          <el-select
            v-model="myInfo.issueType"
            filterable
            placeholder="选择任务类型"
          >
            <el-option
              v-for="item in issueTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="line-box">
      </div>
    </div>
    <p class="title-p">问题描述</p>
    <div class="textarea">
      <el-input
        type="textarea"
        :autosize="{ minRows: 6 }"
        placeholder="请输入问题描述"
        v-model="myInfo.issueDescribe">
      </el-input>
    </div>
    <p class="title-p">案件文件</p>
    <div class="add-box" v-if="!progressFlag">
      <el-upload
        class="avatar-uploader"
        :action="objData.host"
        :data="objData"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
        :on-progress="uploadVideoProcess"
        :on-success="upSuccess"
      >
        <img src="@/assets/img/add-icon.png" alt="" />
        <p>上传文件</p>
      </el-upload>
    </div>
      <div class="file-box">
        <div
          class="file-card"
          :class="isFileIdx === index ? 'is-file-card' : ''"
          @mouseenter="mouseOver(index)"
          @mouseleave="mouseLeave"
          v-for="(item, index) in fileList"
          :key="item.name"
        >
          <img :src="selectImg(item.name)" alt="" />
          <p :title="item.name">{{ item.name }}</p>
          <el-progress
            v-if="progressFlag && index == fileList.length - 1"
            :percentage="loadProgress"
          ></el-progress>
          <div class="shadow-box" v-else>
            <!-- <button @click="download(item)">下载</button> -->
            <button
              @click="delFile(item, index)"
            >
              删除
            </button>
          </div>
        </div>
      </div>
    <div class="btns-box">
      <el-button class="btn" @click="submit">保存</el-button>
      <el-button @click="toBack">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getPolicyToken,  } from "@/api/index.js"
import { getAddress, addOrder, editOrder, getOrderDetail } from "@/api/knowledgeData.js"
// import { mapGetters } from "vuex";
var OSS = require("ali-oss");
export default {
  name: "addPage",
  components: {
  },
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      myInfo: {
        name: "",
        enterpriseType: "",
        area: "",
        linkman: "",
        linkmanPhone: "",
        type: "",
        issueSource: "",
        issueType: "",
        issueDescribe: "",
      },
      provinceList: [], // 省份集合
      cityInfo: {}, // 城市对象
      provinceVal: "", //省下拉框选中的数据
      cityVal: "", //市下拉框选中的数据
      provinceCityInfo: {}, // 选中的省市具体数据
      issueTypeList: [], // 问题类型
      clientList: [
        { label: "个人", value: 1 },
        { label: "企业", value: 2 },
      ],
      ossInfo: { // oss参数
        BucketName: "ilaas-files",
        expireTime: 120,
        uploadDir: "business/client/file/",
      },
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "*",
        dir: "",
      },
      imageUrl: "",
      isFileIdx: "", // 鼠标悬浮哪个文件上
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 是否显示进度条
      isFile: {}, // 上传中的文件
      fileList: [], // 上传的文件
      filePathList: [], // 文件上传成功的路径
      delFileList: [], // 删除的文件暂存数组
      ossSignature: {}, //oss签名
      detailsStatus: "", // 当前订单详情状态
    }
  },
  // computed: {
  //   ...mapGetters({
  //     userInfo: "index/userInfoState",
  //     // ossInfo2: "index/ossInfoState",
  //   }),
  // },
  created() {
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      // console.log(this.ossInfo2,'ossInfo2');
      this.getType();
      this.getAddressInfo();
      this.getOss();
      this.detailsStatus = this.$parent.detailsStatus;
      if (this.detailsStatus === 'edit') {
        this.getDetail();
      }
    },
    // 获取订单详情
    async getDetail() {
      let res = await getOrderDetail(this.info.id)
      // console.log(res,'详情');
      if (res.code === 0) {
        let data = res.result;
        this.provinceVal = data.provinceName;
        this.cityVal = data.cityName;
        this.myInfo = {
          id: data.id,
          name: data.customerName,
          enterpriseType: data.customerType,
          area: "省市",
          linkman: data.customerContact,
          linkmanPhone: data.customerContactTel,
          issueType: data.assignmentTypeName,
          issueDescribe: data.description,
        };
        this.fileList = data.files;
        this.provinceCityInfo = {
          provinceId: data.provinceId,
          province: data.provinceName,
          cityId: data.cityId,
          city: data.cityName,
        }
      }
    },
    // 获取问题类型  写死
    async getType() {
      this.issueTypeList = [
        {value: "劳动法律法规政策咨询", label: "劳动法律法规政策咨询"},
        {value: "上门法律服务", label: "上门法律服务"},
        {value: "人事文件制度审核修订", label: "人事文件制度审核修订"},
        {value: "出具法律意见书或律师函", label: "出具法律意见书或律师函"},
        {value: "人力资源尽职调查", label: "人力资源尽职调查"},
        {value: "规章制度审查", label: "规章制度审查"},
        {value: "人力资源合规审计", label: "人力资源合规审计"},
        {value: "人力资源合规全流程", label: "人力资源合规全流程"},
        {value: "经济性裁员", label: "经济性裁员"},
        {value: "搬迁人员安置", label: "搬迁人员安置"},
        {value: "并购人员安置", label: "并购人员安置"},
        {value: "破产人员安置", label: "破产人员安置"},
        {value: "法律意见书", label: "法律意见书"},
        {value: "劳动争议", label: "劳动争议"},
        {value: "人事争议", label: "人事争议"},
        {value: "其他问题", label: "其他问题"},
      ]
    },
    // 获取地区
    async getAddressInfo() {
      let res = await getAddress();
      if (res.code == 0) {
        res.result.map((item) => {
          this.provinceList.push({
            id: item.id,
            label: item.name,
            value: item.name,
          });
          this.cityInfo[item.name] = item.cities;
        });
      }
    },
    // 获取oss签名
    async getOss() {
      let res = await getPolicyToken(this.ossInfo);
      if (res.code === 200) {
        let data = JSON.parse(res.result);
        this.ossSignature = data;
      }
    },
    // 选择省
    selectProvince() {
      for (var i = 0; i < this.provinceList.length; i++) {
        let newItem = this.provinceList[i];
        if (newItem.label == this.provinceVal) {
          this.provinceCityInfo.provinceId = newItem.id;
          this.provinceCityInfo.province = newItem.value;
          this.provinceCityInfo.cityId = this.cityInfo[this.provinceVal][0].id;
          this.provinceCityInfo.city = this.cityInfo[this.provinceVal][0].name;
          this.cityVal = this.cityInfo[this.provinceVal][0].name;
          break;
        }
      }
    },
    // 选择市
    selectCity() {
      let newArr = this.cityInfo[this.provinceVal];
      for (var i = 0; i < newArr.length; i++) {
        let newItem = newArr[i];
        if (newItem.name == this.cityVal) {
          this.provinceCityInfo.cityId = newItem.id;
          this.provinceCityInfo.city = newItem.name;
          break;
        }
      }
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      // console.log(this.ossSignature);return
      let newArr = file.name.split(".");
      let fileType = newArr[newArr.length - 1];
      let imgType = [
        "doc",
        "docx", // Word
        "xls",
        "xlsx", // Excel
        "ppt", // PPT
        "pdf", // PDF
      ];
      const isLt5M = file.size / 1024 / 1024 < 50;
      if (!imgType.includes(fileType)) {
        this.$message.warning(
          "上传文件只能是 Word、Excel、PPT、PDF 格式文件!"
        );
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 50MB!");
        return;
      }
        this.objData = {
          OSSAccessKeyId: this.ossSignature.accessid,
          policy: this.ossSignature.policy,
          signature: this.ossSignature.signature,
          expire: this.ossSignature.expire,
          host: this.ossSignature.host,
          dir: this.ossSignature.dir,
          key: `business/client/file/yw-0/${new Date().getTime()}-${file.name}`,
        };
        this.filePathList.push(this.objData.key);
        this.isFile = {
          fileName: file.name,
          filePath: this.objData.key,
          bucketName: "ilaas-files",
        }
    },
    // 上传成功事件
    upSuccess() {
      this.fileList.push(this.isFile);
    },
    // 上传文件进度
    uploadVideoProcess(event, file, fileList) {
      // console.log(event.percent);
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 1000); // 一秒后关闭进度条
      }
    },
    // 鼠标悬浮文件上
    mouseOver(idx) {
      this.isFileIdx = idx;
    },
    // 离开文件
    mouseLeave() {
      this.isFileIdx = "";
    },
    // 下载客户文件
    download(item) {
      let client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossInfo2.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-files",
      });
      let filename = item.name;
      let response = {
        "content-disposition": `attachment; filename=${encodeURIComponent(
          filename
        )};expires: 7200`,
      };
      let url = client.signatureUrl(item.filePath, {
        response,
      });
      window.location.href = url;
    },
    // 删除客户文件
    delFile(item, idx) {
      this.$confirm(`请确认是否删除文件【 ${item.name} 】`)
        .then((_) => {
          this.fileList.splice(idx, 1);
          this.ossDelFile(item);
        })
        .catch((_) => {});
    },
    ossDelFile(item) {
      let client = new OSS({
        region: "oss-cn-shenzhen",
        accessKeyId: this.ossSignature.accessid,
        accessKeySecret: "5ZUDWAJ0Gz4o52kubRVYCaJRRQRims",
        bucket: "ilaas-files",
      });
      client.delete(item.filePath);
    },
    // 设置文件图片
    selectImg(info) {
      // console.log(info);
      let imgUrl = "";
      let valList = info.split(".");
      let fileType = valList[valList.length - 1];
      if (fileType == "doc" || fileType == "docx") {
        imgUrl = require("@/assets/img/file-word.png");
      } else if (fileType == "xls" || fileType == "xlsx") {
        imgUrl = require("@/assets/img/file-excel.png");
      } else if (fileType == "ppt") {
        imgUrl = require("@/assets/img/file-ppt.png");
      } else if (fileType == "pdf") {
        imgUrl = require("@/assets/img/file-pdf.png");
      }
      return imgUrl;
    },
    // 保存
    async submit() {
      // console.log(this.provinceCityInfo);return
      if (this.cityVal) {
        this.myInfo.area = '省市';
      }
      let verifyObj = {
        name: "客户名称",
        enterpriseType: "企业类型",
        area: "所在城市",
        linkman: "联系人",
        issueType: "问题类型",
        issueDescribe: "问题描述",
        // address: "详细地址",
        // label: "客户标签",
      };
      for (let key in verifyObj) {
        if (!this.myInfo[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      let isPhone = /^1[3456789]\d{9}$/;
      var isMob = /^((0\d{2,3})-)?(\d{7,8})$/;
      if (
        isPhone.test(this.myInfo.linkmanPhone) ||
        isMob.test(this.myInfo.linkmanPhone)
      ) {
        let params = {
          customerName: this.myInfo.name,
          provinceId: this.provinceCityInfo.provinceId,
          cityId: this.provinceCityInfo.cityId,
          customerType: this.myInfo.enterpriseType,
          customerContact: this.myInfo.linkman,
          customerContactTel: this.myInfo.linkmanPhone,
          assignmentTypeName: this.myInfo.issueType,
          description: this.myInfo.issueDescribe,
          files: this.fileList,
        }
        if (this.detailsStatus === 'edit') {
          params.id = this.myInfo.id;
        }
        // console.log(params,'params');
        let res = {};
        if (this.detailsStatus === 'add') {
          res = await addOrder(params);
        } else {
          res = await editOrder(params);
        }
        // let res = await addOrder(params);
        console.log(res,"新增返回");
        if (res.code === 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          });
          this.$parent.detailsStatus = "";
          this.$parent.init();
        }
      } else {
        this.$message.warning(`请正确输入联系方式（手机号或座机号）！`);
      }
    },
    // 返回
    toBack() {
      this.$parent.detailsStatus = "";
    },
  }
}
</script>

<style lang="scss" scoped>
  .add-page {
    padding: 20px;
    .title-p {
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      text-align: left;
      margin-bottom: 20px !important;
    }
    .basics-box {
      display: flex;
      margin-bottom: 40px;
      .line-box {
        margin-right: 150px;
        .row-box {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          .label {
            display: block;
            min-width: 90px;
            font-size: 14px;
            color: #909399;
            white-space:nowrap;
          }
          .valIpt {
            width: 240px;
            font-size: 14px;
            color: #333333;
          }
          .el-select {
            width: 240px;
            margin-right: 10px;
          }
        }
      }
      // .line-box2 {
      //   margin: 0 200px;
      // }
    }
      .textarea {
        width: 50%;
        margin-bottom: 60px !important;
      }
      .add-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // width: 24%;
        width: 240px;
        height: 152px;
        margin-right: 1%;
        margin-bottom: 14px;
        border-radius: 10px;
        background: #f0f3fb;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 10px;
        }
        p {
          font-size: 16px;
          color: #6474c7;
        }
      }
      .file-box {
        display: flex;
        flex-wrap: wrap;
        width: 50%;
      .file-card {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 24%;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 18px;
        border: 1px solid #dddfe6;
        margin-right: 1%;
        margin-bottom: 16px;
        p {
          width: 90%;
          font-size: 14px;
          text-align: center;
          color: #333;
          margin-top: 10px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .shadow-box {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          // height: 200px;
          height: 100%;
          color: #fff;
          background-color: rgba(63, 65, 77, 0.5);
          z-index: -1;
          border-radius: 18px;
          overflow: hidden;
        }
        .el-progress {
          width: 80%;
        }
      }
      .is-file-card {
        .shadow-box {
          z-index: 9;
          display: flex;
          align-items: center;
          justify-content: center;
          button {
            width: 60px;
            height: 30px;
            line-height: 30px;
            border-radius: 15px;
            border: none;
            background-color: #fff;
            color: #333;
            font-size: 14px;
            margin: 0 5px;
          }
        }
      }
      }
      .btns-box {
        display: flex;
        margin-top: 60px;
        .el-button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 120px;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
          color: #6474C7;
          background: #FFFFFF;
          border-radius: 25px;
          margin-right: 40px;
        }
        .btn {
          color: #fff;
          background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%);
        }
      }
  }
</style>

