<template>
  <div class="allParameter">
    <div class="head-box">
      <el-button @click="add">新增参数</el-button>
      <div class="head-right">
        <el-select v-model="provinceVal" value-key="id" @change="selectProvince" placeholder="请选择省份">
          <el-option
            v-for="item in provinceList"
            :key="item.id"
            :label="item.name"
            :value="item">
          </el-option>
        </el-select>
        <el-select v-model="cityVal" value-key="id" @change="selectCity" placeholder="请选择城市">
          <el-option
            v-for="item2 in cityList"
            :key="item2.id"
            :label="item2.name"
            :value="item2">
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入搜索内容"
          @keyup.enter.native="getData"
          v-model="keyVal">
          <i slot="suffix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </div>
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        :height="tableHeight"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '城市'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <span>{{setCityName(scope.row)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <span
                style="color: #6aa9fb; cursor: pointer;margin: 0 15px;"
                @click="edit(scope.row)">
                编辑
              </span>
              <span
                style="color: red; cursor: pointer"
                @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <editParameters v-if="showDialog" :info="dialogInfo"></editParameters>
  </div>
</template>

<script>
import editParameters from '@/views/Dialog/editParameters.vue';
import { getRegion, getParameter, delParameter } from '@/api/knowledgeData'
export default {
  name: "allParameter",
  components: {
    editParameters,
  },
  data() {
    return {
      loading: false,
      keyVal: "",
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      tableData: [],
      headData: [
        { align: "center", prop: "country", label: "国家" },
        { align: "center", prop: "provinceName", label: "省份" },
        { align: "center", prop: "cityName", label: "城市" },
        { align: "center", prop: "areaName", label: "地区" },
        { align: "center", prop: "targetName", label: "参数类型" },
        { align: "center", prop: "subTargetName", label: "参数名称" },
        { align: "center", prop: "value", label: "参数内容" },
        { align: "center", prop: "year", label: "年份" },
        // { align: "center", prop: "address", label: "创建时间" },
        { align: "center", prop: "address", label: "操作" },
      ],
      arrTableLength: {},
      showDialog: false,
      dialogInfo: {},
      provinceVal: null,
      provinceList: null,
      cityVal: null,
      cityList: null,
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
      this.getData();
      this.getRegionInfo();
    },
    // 获取列表
    async getData() {
      let params = `/${this.currentPage}/${this.interPage}`;
      // if (this.keyVal) {
        params += `?queryString=${this.keyVal}`
      // }
      if (this.provinceVal) {
        params += `&provinceId=${this.provinceVal.id}`
      }
      if (this.cityVal) {
        params += `&cityId=${this.cityVal.id}`
      }
      this.loading = true;
      let res = await getParameter(params);
      this.loading = false;
      // console.log(res); country
      if (res.code == 0) {
        let data = res.result.items;
        data.map(item => {
          return item.country = "中国";
        })
        this.tableData = data;
        this.dataSize = res.result.totalCount;
      }
    },
    // 获取地区
    async getRegionInfo() {
      let res = await getRegion();
      // console.log(res,'地区');
      if (res.code == 0) {
        // this.countryList = res.result;
        this.provinceList = res.result;
        // this.countryList.unshift(
        //   {
        //     childs: [],
        //     id: '',
        //     isEnable: '',
        //     level: 1,
        //     name: '全部',
        //   }
        // )
      }
    },
    // // 选择国家
    // selectCountry() {
    //   // console.log(this.countryVal);
    //   this.provinceVal = null;
    //   let newArr = [];
    //   for (let i = 0; i < this.countryList.length; i++) {
    //     let item = this.countryList[i];
    //     if (item.id == this.countryVal.id) {
    //       if (item.childs.length > 0) {
    //         this.provinceList = newArr.concat(item.childs);
    //         this.provinceVal = this.provinceList[0];
    //         this.selectProvince();
    //       } else {
    //         this.getData();
    //       }
    //       break;
    //     }
    //   }
    // },
    // 选择省份
    selectProvince() {
      // console.log(this.provinceVal);
      this.cityVal = null;
      let type = this.provinceVal.name.substr(-1);
      let newArr = [];
      newArr = newArr.concat(this.provinceVal.childs);
        newArr.unshift(
          {
            id: '',
            isEnable: true,
            level: 2,
            name: `全${type}`,
          }
        )
        this.cityList = newArr;
        this.cityVal = this.cityList[0];
        this.selectCity();
    },
    // 选择城市
    selectCity() {
      // console.log(this.cityVal);
      this.getData();
    },
    // 新增参数
    add() {
      this.$parent.showTable = false;
    },
    // 设置城市名称
    setCityName(row) {
      // console.log(row);
      if (!row.cityName && row.provinceName) {
        let type = row.provinceName.substr(-1);
        return `全${type}`;
      } else if (row.cityName) {
        return row.cityName
      } else {
        return
      }
    },
    // 编辑
    edit(row) {
      this.dialogInfo = JSON.parse(JSON.stringify(row));
      this.showDialog = true;
    },
    // 删除
    del(row) {
      this.$confirm(`是否确认删除参数【${row.subTargetName}】`)
      .then((_) => {
        this.delParameter(row);
      })
      .catch((_) => {});
    },
    async delParameter(row) {
      let res = await delParameter({ids: [row.id]});
      if (res.code == 200) {
        this.getData();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.$filterCache(`${this.page}`);
      this.getData();
    },
    //获取表格裂宽
    getTableCellWidth() {
      this.tableHeight = this.$getTableHeight(70, "table-container");
      let headData = JSON.parse(JSON.stringify(this.headData));
      // console.log(headData)
      headData = headData.filter((val) => {
        return val.label !== "操作";
      });

      this.arrTableLength = this.$getTableCellLength({
        W: $(".table-container")[0].clientWidth,
        W2: 80,
        head: headData,
        data: this.tableData,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .allParameter {
    // width: 100%;
    // padding: 20px;
    // height: 100%;
    // box-sizing: border-box;
    padding-bottom: 20px;
    .head-box{
      display: flex;
      justify-content: space-between;
      height: 7%;
      margin-bottom: 1%;
      .el-button {
        height: 40px;
        border-radius: 20px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
      }
      .head-right {
        display: flex;
        .el-select {
          margin-right: 20px;
        }
      }
    }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  }
</style>

