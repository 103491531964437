<template>
  <div class="labour-contract">
    <div class="left-box" v-if="defaultChecked.length != 0">
      <el-tree
        ref="tree"
        :data="treeData"
        show-checkbox
        :props="defaultProps"
        node-key="id"
        :indent="6"
        check-strictly
        :default-expanded-keys="[1]"
        :default-checked-keys="defaultChecked"
        @check="selectTreeNode">
        <span slot-scope="{ node }" class="custom-tree-node">
          <span class="title" :title="node.label">{{ node.label }}</span>
        </span>
      </el-tree>
    </div>
    <div class="right-box">
      <div class="head-box">
        <el-button @click="add">新增文件</el-button>
        <div class="head-right">
          <el-input
            placeholder="请输入搜索内容"
            v-model="keyVal">
            <i slot="suffix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
      </div>
        
    <div class="table-container">
      <el-table
        v-loading="loading"
        height="650"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '编号'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '报名时间'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <span class="btn-text">
                {{ scope.row.createTime.split("T")[0] }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          >
            <template slot-scope="scope">
              <!-- <span style="color:#6aa9fb;cursor:pointer;margin-right: 20px;">编辑</span> -->
              <span style="color:red;cursor:pointer;" @click="del(scope.row)">删除</span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="arrTableLength[head.prop] + 'px'"
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    </div>
    <addLaborContractFileDialog v-if="showDialog" :info="dialogInfo"></addLaborContractFileDialog>
  </div>
</template>

<script>
import addLaborContractFileDialog from '@/views/Dialog/addLaborContractFileDialog.vue'
import { getLaborContractType, getLaborcontract, delLaborcontract, } from '@/api/knowledgeData'
// import { Loading } from "element-ui";
export default {
  name: "labourContract",
  components: {
    addLaborContractFileDialog,
  },
  data() {
    return {
      loading: false,
      treeData: [],
      defaultChecked: [], // tree默认选择节点
      defaultProps: {
          children: 'children',
          label: 'label'
        },
      currentNodeData: [],
      keyVal: '',
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      tableData: [],
      headData: [
        { align: "center", prop: "编号", label: "编号" },
        { align: "center", prop: "title", label: "文件名称" },
        { align: "center", prop: "phoneNumber", label: "文件编号" },
        { align: "center", prop: "fileType2", label: "文件类型" },
        { align: "center", prop: "sort", label: "排序" },
        // { align: "center", prop: "address", label: "创建时间" },
        { align: "center", prop: "操作", label: "操作" },
      ],
      arrTableLength: {},
      showDialog: false,
      dialogInfo: {
        id: 0,
        sort: 1,
        treeName: '人社部',
        treeFatherName: '劳动合同文件',
        type: 'add',
      },
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
      this.treeData = [];
      this.getTreeType();
    },
    async getTreeType() {
      // let loadingInstance = Loading.service({ fullscreen: true });
      this.loading = true;
      let res = await getLaborContractType();
      // loadingInstance.close();
      this.loading = false;
      // console.log(res);
      if (res.code == 0) {
        let data = res.result;
        let info = {
          id: 1,
          label: '劳动合同文件',
          disabled: true,
          children: [],
        }
        data.forEach(item => {
          info.children.push(
            {
              id: Number(`1.${item.id}`),
              initId: item.id,
              label: item.name,
              sort: item.sort,
              fatherName: '劳动合同文件',
            }
          )
        });
        this.treeData.push(info);
        this.defaultChecked = [info.children[0].id];
        this.dialogInfo = {
          id: info.children[0].initId,
          sort: info.children[0].sort,
          treeName: info.children[0].label,
          treeFatherName: info.children[0].fatherName,
          type: 'add',
        }
        // console.log(info.children[0]);
        // console.log(this.dialogInfo);
        this.currentPage = 1;
        this.dataSize = 0;
        this.getTableInfo();
      }
    },
    async getTableInfo() {
      let loadingInstance = Loading.service({ fullscreen: true });
      let params = `${this.currentPage}/${this.interPage}?categoryId=${this.dialogInfo.id}`;
      let res = await getLaborcontract(params);
      loadingInstance.close();
      // console.log(res);
      if (res.code == 0) {
        this.tableData = res.result.items;
        this.dataSize = res.result.totalCount;
        this.tableData.forEach(item => {
          item.fileType2 = '';
          if (item.fileType == '.docx' || item.fileType == '.doc') {
            item.fileType2 = 'word'
          } else if (item.fileType == '.xlsx' || item.fileType == '.xls') {
            item.fileType2 = 'excel'
          } else if (item.fileType == '.ppt') {
            item.fileType2 = 'ppt'
          } else if (item.fileType == '.pdf') {
            item.fileType2 = 'pdf'
          }
        })
        // console.log(this.tableData);
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 新增文件
    add() {
      this.showDialog = true;
    },
    selectTreeNode(data, checked, tree) {
      if (checked) {
        this.currentNodeData = data;
        this.$refs.tree.setCheckedNodes([data]);
      }
      let newInfo = this.$refs.tree.getCheckedNodes()[0];
      let newIdList = JSON.stringify(newInfo.id).split('.');
        this.dialogInfo.treeFatherName = this.treeData[Number(newIdList[0])-1].label;
        this.dialogInfo.treeName = newInfo.label;
        this.dialogInfo.id = newInfo.initId;
      this.getTableInfo();
    },
    // 删除范本
    del(row) {
      this.$confirm(`是否确认删除劳动范本【${row.title}】`)
      .then((_) => {
        this.dels(row);
      })
      .catch((_) => {});
    },
    async dels(row) {
      let res = await delLaborcontract({ids: [row.id]});
      if (res.code == 200) {
        this.getTableInfo();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getTableInfo();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableInfo();
    },
    //获取表格裂宽
    getTableCellWidth() {
      this.tableHeight = this.$getTableHeight(70, "table-container");
      let headData = JSON.parse(JSON.stringify(this.headData));
      // console.log(headData)
      headData = headData.filter((val) => {
        return val.label !== "操作";
      });

      this.arrTableLength = this.$getTableCellLength({
        W: $(".table-container")[0].clientWidth,
        W2: 80,
        head: headData,
        data: this.tableData,
      });
    },
  }
}
</script>

<style lang="scss" scoped>
  .labour-contract {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    .left-box {
      width: 15%;
      height: 97%;
      overflow-y: auto;
      border-radius: 14px;
      border: 1px solid #0094ff;
      padding: 14px;
      box-sizing: border-box;
    }
    .right-box {
      width: 83%;
      .head-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1%;
        .el-button {
          height: 40px;
          border-radius: 20px;
          color: rgba(0, 148, 255,.7);
          border: 1px solid rgba(0, 148, 255,.7);
        }
      }
      // .table-container {
      //   ::v-deep .el-table {
      //     overflow-y: auto;
      //   }
      // }
    }
  ::v-deep .el-table__body-wrapper {
    height: 600px !important;
    overflow-y: auto;
  }
  .el-pagination {
    margin-top: 30px;
  }
  }
</style>

