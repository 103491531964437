<template>
  <div class="tablePage">
    <div class="head-box">
      <el-button @click="add">新增案源</el-button>
      <div class="file-box"></div>
    </div>
    <div class="table-container">
        <el-table v-loading="loading" :data="tableData" ref="table" style="width: 100%">
          <template v-for="(item, index) in headData">
            <el-table-column
              :align="item.align"
              v-if="item.label === '序号'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
               width="120px"
            >
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '地区'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="scope">
                {{ scope.row.provinceName }} — {{scope.row.cityName}}
              </template>
            </el-table-column>
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '来源方式'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.source === 1">ComDoc平台</span>
                <span v-if="scope.row.source === 2">官方发布</span>
                <span v-if="scope.row.source === 3">合作渠道</span>
              </template>
            </el-table-column>
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '问题描述'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              show-overflow-tooltip
              width="300px"
            >
            </el-table-column>
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '领取状态'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.status === 0" style="color: green;">未被领取</span>
                <span v-else>已被领取</span>
              </template>
            </el-table-column>
              <!-- fixed="right" -->
            <el-table-column
              :align="item.align"
              v-else-if="item.label === '操作'"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              width="220px"
            >
              <template slot-scope="scope">
                <div class="btn-box">
                  <div>
                    <img class="img1" src="@/assets/img/btn-icon-1.png" alt="" @click="look(scope.row)">
                    <span class="span1" @click="look(scope.row)">查看</span>
                  </div>
                    <template v-if="scope.row.status === 0 && scope.row.source === 2">
                      <div>
                      <img class="img2" src="@/assets/img/btn-icon-3.png" alt="" @click="editTask(scope.row)">
                      <span class="span3" @click="editTask(scope.row)">编辑</span>
                      </div>
                      <i class="el-icon-delete del-icon" @click="del(scope.row)"></i>
                    </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :align="item.align"
              :key="index"
              :prop="item.prop"
              :label="item.label"
            ></el-table-column>
          </template>
        </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <addCaseDialog v-if="showDialog"></addCaseDialog>
  </div>
</template>

<script>
import addCaseDialog from '@/views/Dialog/addCaseDialog.vue';
import { getOrderList, delOrder } from '@/api/knowledgeData'
export default {
  name: "tablePage",
  components: {
    addCaseDialog,
  },
  data() {
    return {
      loading: false,
      keyVal: "",
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 10, //每页显示条数
      tableData: [],
      headData: [
        { align: "center", prop: "序号", label: "序号", width: "100px" },
        { align: "center", prop: "customerName", label: "客户名称" },
        { align: "center", prop: "地区", label: "地区" },
        { align: "center", prop: "source", label: "来源方式" },
        { align: "center", prop: "assignmentTypeName", label: "任务类型" },
        { align: "center", prop: "description", label: "问题描述" },
        {align: "center", prop: "status", label: "领取状态"},
        { align: "center", prop: "操作", label: "操作" },
      ],
      arrTableLength: {},
      showDialog: false,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    init() {
      // this.currentPage = 1;
      // this.interPage = 15;
      this.dataSize = 0;
      this.getData();
    },
    // 获取
    async getData() {
      this.loading = true;
      let params = `${this.currentPage}/${this.interPage}`;
      let res = await getOrderList(params);
      this.loading = false;
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        this.tableData = res.result.items;
        this.tableData.map(item => {
          item.auditTime = item.auditTime || '';
          item.expireTime = item.expireTime || '';
          return item;
        })
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    // 查看
    look(row) {
      this.$parent.look(row);
    },
    // 新增
    async add() {
      this.$parent.add();
    },
    // 编辑任务
    editTask(row) {
      // console.log(row);
      this.$emit('editTask',row);
    },
    // 删除
    del(row) {
      this.$confirm(`请确认是否删除客户【 ${row.customerName} 】案源？`)
        .then((_) => {
          this.delTask(row.id);
        })
        .catch((_) => {});
    },
    async delTask(id) {
      let res = await delOrder({ids: [id]});
      if (res.code === 200) {
        this.$message({
          message: '删除成功',
          type: 'success'
        });
        this.init();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .tablePage {
    padding: 20px;
    height: 100%;
    box-sizing: border-box;
    .head-box{
      display: flex;
      justify-content: space-between;
      height: 7%;
      margin-bottom: 1%;
      .el-button {
        height: 40px;
        border-radius: 20px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
      }
      .head-right {
        display: flex;
        
      }
    }
  .table-container {
    flex: 1;
    ::v-deep .el-table {
      border-radius: 18px 18px 0 0;
      .el-table__header-wrapper {
        .el-table__header {
          // width: 100%;
          .has-gutter {
            th {
              font-size: 14px;
              font-weight: bold;
              color: #6474C7;
              padding: 12px 16px;
              background: #EAF5FF;
            }
          }
        }
      }
      .el-table__body-wrapper {
        min-height: 470px;
        .el-table__body {
          td {
            font-size: 14px;
            color: #333;
            padding: 12px 16px;
            .cell {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              .btn-box {
                display: flex;
                  align-items: center;
                justify-content: space-around;
                  cursor:pointer;
                // div {
                //   display: flex;
                //   align-items: center;
                //   cursor:pointer;
                  .img1 {
                    width: 20px;
                    height: 14px;
                    margin-right: 6px;
                  }
                  .img2 {
                    width: 17px;
                    height: 17px;
                    margin-right: 6px;
                  }
                  span {
                    font-size: 14px;
                  }
                  .span1 {
                    color: #6474C7;
                    margin-right: 10px;
                  }
                  .span2 {
                    color: #45CFA0;
                  }
                  .span3 {
                    color: #FCC647;
                  }
                  .del-icon {
                    font-size: 20px;
                    font-weight: 600;
                    color: red;
                    margin-left: 10px;
                  }
                  .span4 {
                    color: #333;
                    cursor:default;
                  }
                }
              }
            // }
          }
        }
      }
      .el-table__fixed-right {
        thead {
          .is-center {
            color: #fff;
            background: linear-gradient(90deg, #6878C8 0%, #79BAF2 100%) !important;
          }
        }
          .in-column {
            .cell {
              .btn-box {
                display: flex;
                justify-content: space-between;
                p {
                  font-size: 14px;
                  color: #6474C7;
                  cursor:pointer;
                }
                .btn-p {
                  color: #FCC647;
                }
              }
            }
          }
      }
    }
  }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  }
</style>

