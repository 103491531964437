<template >
  <div class="multipleSelection">
    <div class="title">
      <input
        type="text"
        placeholder="请选择.."
        v-model="val"
        @click.stop="liShow"
      />
      <i class="icon" @click.stop="liShow">∨</i>
    </div>
    //下拉列表
    <ul v-show="isShow" @click.stop="liShow">
      <li v-for="item in liData" :key="item.Id">
        <label :id="item.Id">
          <input type="checkbox" v-model="item.Check" />
          {{ item.Name }}
        </label>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkedData: [], //选中的数据
      isShow: false, //下拉列表是否显示
      selectCon: "", //选中的内容
      val:''
    };
  },
  props: ["liData"],
  mounted() {
    let that = this;
    //点击页面空白处隐藏下拉列表
    document.addEventListener("click", function () {
      that.isShow = false;
    });
  },
  methods: {
    //控制下拉列表的显示隐藏
    liShow() {
      this.isShow = true;
    },
  },
  watch: {
    liData: {
      handler(newVal, oldVal) {
        //选中数据
        this.checkedData = newVal.filter(function (item) {
          return item.Check == true;
        });
        //在页面打印出的数据
        this.selectCon = []; //点击的当前项的展示
        for (var i = 0; i < this.checkedData.length; i++) {
          this.selectCon.push({title:this.checkedData[i].Name + "  ",id:this.checkedData[i].Id});
        }
        this.val=this.selectCon.map(ele => {
            return ele.title
        });
        // 给父组件传值
        this.$emit("change", this.selectCon);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.selectInput .title {
  width: 300px;
  position: relative;
}
.selectInput input[type="text"] {
  width: 300px;
  height: 40px;
  padding: 0 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 3px;
  -moz-border-radius: 3px; /* Firefox */
  -webkit-border-radius: 3px; /* Safari 和 Chrome */
  border-radius: 3px; /* Opera 10.5+, 以及使用了IE-CSS3的IE浏览器 */
}
.selectInput i {
  position: absolute;
  width: 30px;
  height: 40px;
  line-height: 38px;
  right: -12px;
  top: 1px;
  text-align: center;
  cursor: pointer;
}
.selectInput ul {
  border-radius: 3px;
  -moz-border-radius: 3px; /* Firefox */
  -webkit-border-radius: 3px; /* Safari 和 Chrome */
  border-radius: 3px; /* Opera 10.5+, 以及使用了IE-CSS3的IE浏览器 */
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2); /* Firefox */
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2); /* Safari 和 Chrome */
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2); /* Opera 10.5+, 以及使用了IE-CSS3的IE浏览器 */
  width: 253px;
  /* border: 1px solid #ccc; */
  padding: 10px 30px;
}
.selectInput li {
  line-height: 30px;
}
</style>