<template>
  <div class="add-case-dialog">
    <el-dialog
      :title="myTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      center
      :show-close="false"
      width="70%"
    >
      <div class="content-box">
        <div class="row-box">
          <div class="row-item">
            <p class="label">
              <span>* </span>
              案由：
            </p>
            <el-input
              type="text"
              v-model="clientForm.name"
              placeholder="请输入案由"
            />
          </div>
          <div class="row-item">
            <p class="label">
              <span>* </span>
              案号：
            </p>
            <el-input
              type="text"
              v-model="clientForm.name"
              placeholder="请输入案号"
            />
          </div>
        </div>
        <div class="row-box">
          <div class="row-item">
            <p class="label">
              <span>* </span>
              案件类型：
            </p>
            <el-select
              v-model="clientForm.type"
              filterable
              placeholder="请选择案件类型"
            >
              <el-option
                v-for="item in typeList2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row-item">
            <p class="label">
              <span>* </span>
              发布日期：
            </p>
            <el-select
              v-model="clientForm.type"
              filterable
              placeholder="请选择发布日期"
            >
              <el-option
                v-for="item in typeList2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-box">
          <div class="row-item">
            <p class="label">
              <span>* </span>
              审理流程：
            </p>
            <el-select
              v-model="clientForm.type"
              filterable
              placeholder="请选择审理流程"
            >
              <el-option
                v-for="item in typeList2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="row-item">
            <p class="label">
              <span>* </span>
              审理法院：
            </p>
            <el-select
              v-model="clientForm.type"
              filterable
              placeholder="请选择审理法院"
            >
              <el-option
                v-for="item in typeList2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-box">
          <div class="row-item row-item2">
            <p class="label">
              <span>* </span>
              案件地区：
            </p>
            <el-select
              v-model="clientForm.type"
              filterable
              placeholder="请选择发文单位"
            >
              <el-option
                v-for="item in typeList2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="row-box">
          <div class="row-item row-item2">
            <p class="label">
              <span>* </span>
              内容详情：
            </p>
          </div>
        </div>
        <div class="row-box">
          <div style="border: 1px solid #ccc">
            <Toolbar
              style="border-bottom: 1px solid #ccc"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              :mode="mode"
            />
            <Editor
              style="height: 500px; overflow-y: hidden"
              v-model="html"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
            />
          </div>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import { addCustomer } from "@/FackApi/api/project.js";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  name: "addCaseDialog",
  components: {
    Editor,
    Toolbar,
  },
  props: {},
  data() {
    return {
      myTitle: "新增案例库",
      dialogVisible: true,
      clientForm: {
        type: 1,
        name: "",
        phone: "",
        num: "",
      },
      typeList2: [
        { label: "个人类", value: 1 },
        { label: "企业类", value: 2 },
      ],
      throttleNum: 0,
      flag: null, //
      ossInfo: {
        // oss参数
        expireTime: 120,
        uploadDir: "project/client/file/",
      },
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "*",
        dir: "",
      },
      imageUrl: "",

      editor: null,
      html: "<p>hello</p>",
      toolbarConfig: {},
      editorConfig: { placeholder: "请输入内容..." },
      mode: "default", // or 'simple'
      submitStatus: false,
    };
  },
  created() {},
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    setTimeout(() => {
      this.html = "<p>模拟 Ajax 异步设置内容 HTML</p>";
    }, 1500);
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    uploadVideoProcess() {},
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      let newArr = file.name.split(".");
      let fileType = newArr[newArr.length - 1];
      // console.log(fileType);
      // console.log(file,file.type);return
      let imgType = [
        "jpg",
        "jpeg",
        "dds",
        "psd",
        "pdt",
        "webp",
        "xmp",
        "gif",
        "bmp",
        "svg",
        "tiff",
        "ico", // 图片
      ];
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!imgType.includes(fileType)) {
        this.$message.warning("上传文件只能是图片!");
        return;
      }
      if (!isLt5M) {
        this.$message.warning("上传文件大小不能超过 10MB!");
        return;
      }
      let res3 = await getPolicyToken(this.ossInfo);
      if (res3.code == 0) {
        // console.log(res3);
        let data = JSON.parse(res3.content);
        this.objData = {
          OSSAccessKeyId: data.accessid,
          policy: data.policy,
          signature: data.signature,
          expire: data.expire,
          host: data.host,
          dir: data.dir,
          key: `project/client/file/${
            this.userInfo.userId
          }/${new Date().getTime()}-${file.name}`,
        };
        this.filePathList.push(this.objData.key);
        // console.log(this.objData);
        this.fileList.push({
          name: file.name,
          filePath: this.objData.key,
        });
      } else {
        return;
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    cancel() {
      this.$parent.showDialog = false;
      // this.$parent.init();
    },
    // 弹框保存
    async submit() {
      // if (this.submitStatus) return;
      // this.submitStatus = true;
      return
      if (this.throttleNum != 0) return;
      this.throttleNum = 1;
      let that = this;
      this.flag = setInterval(() => {
        that.throttleNum++;
        if (that.throttleNum == 2) {
          that.throttleNum = 0;
          clearInterval(this.flag);
        }
      }, 1000);
      let verifyObj = {
        name: "客户名称",
        phone: "联系方式",
      };
      for (let key in verifyObj) {
        if (!this.clientForm[key]) {
          this.$message.warning(`${verifyObj[key]}不能为空！`);
          return;
        }
      }
      let isPhone = /^1[3456789]\d{9}$/;
      var isMob = /^((0\d{2,3})-)?(\d{7,8})$/;
      if (
        isPhone.test(this.clientForm.phone) ||
        isMob.test(this.clientForm.phone)
      ) {
        let params = {
          code: this.clientForm.num,
          name: this.clientForm.name,
          type: this.clientForm.type,
          address: "",
          email: "",
          nature: "",
          tags: "",
          provinceId: "",
          province: "",
          city: "",
          cityId: "",
          contact: this.clientForm.phone,
          industry: "",
          remark: "",
          contacts: [],
          files: [],
        };
        // let res = await addCustomer(params);
        // if (res.code == 0) {
        //   this.cancel2();
        // }
      } else {
        this.$message.warning(`请正确输入联系方式（手机号或座机号）！`);
      }
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style>
  @import url("@wangeditor/editor/dist/css/style.css");
</style>
<style lang="scss" scoped>
.add-case-dialog {
  .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__body {
        padding: 20px;
        .content-box {
          .row-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 16px;
            .row-item {
              display: flex;
              align-items: center;
              width: 48%;
              .label {
                width: 15%;
                white-space: nowrap;
                span {
                  color: red;
                }
              }
              .el-input,
              .el-textarea {
                width: 85%;
              }
              .el-select {
                width: 85%;
              }
              .avatar-uploader {
                border: 1px dashed #d9d9d9 !important;
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
              }
              .avatar-uploader .el-upload:hover {
                border-color: #409eff;
              }
              .avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 178px;
                height: 178px;
                line-height: 178px;
                text-align: center;
              }
              .avatar {
                width: 178px;
                height: 178px;
                display: block;
              }
            }
            .row-item2 {
              width: 100%;
              .label {
                width: 7.9%;
              }
              .el-input {
                width: 92.1%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
