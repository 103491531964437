
// 引入request
import requests from "../utils/request";

//新增 标准文书
//新增文书
export const addFilePlace = (data) =>
  requests({
  url: `/api/standarddocs/file/add`,
  method: "post",
  data,
});
//获取列表文书
export const getFilePlace = (params) =>
  requests({
  url: `/api/standarddocs/file/list/paging/${params}`,
  method: "get",
});
//删除文书
export const delFilePlace  = (data) =>
  requests({
  url: `/api/standarddocs/file/delete`,
  method: "delete",
  data,
});
//查询文书
export const detailFilePlace  = (params) =>
  requests({
  url: `/api/standarddocs/file/detail?id=`+params,
  method: "get",
});
//修改文书
export const updateFilePlace  = (data) =>
  requests({
  url: `/api/standarddocs/file/modify`,
  method: "post",
  data,
});
//下载文书
export const downFilePlace  = (params) =>
  requests({
  url: `/api/standarddocs/file/down?filepath=`+params,
  method: "get",
});
//新增 标准文书
//新增标签
export const addTagsPlace = (data) =>
  requests({
  url: `/api/standarddocs/tags/add`,
  method: "post",
  data,
});
//编辑标签
export const editTagsPlace = (data) =>
  requests({
  url: `/api/standarddocs/tags/edit`,
  method: "post",
  data,
});
//获取列表标签
export const getTagsPlace = () =>
  requests({
  url: `/api/standarddocs/tags/list`,
  method: "get",
});
//获取列表标签2
export const getTagsSumPlace = () =>
  requests({
  url: `/api/standarddocs/tags/sum`,
  method: "get",
});
//删除标签
export const delTagsPlace  = (data) =>
  requests({
  url: `/api/standarddocs/tags/delete`,
  method: "delete",
  data,
});
//排序标签
export const sortTagsPlace  = (data) =>
  requests({
  url: `/api/standarddocs/tags/sort`,
  method: "post",
  data,
});

// 1 ----------------- 合规指引
// 1-1 --------------- 合规指引列表
//  获取业务类型
export const getBusinessModuleList = () =>
  requests({
  url: `/api/complianceguide/businessModule/list`,
  method: "get",
});
// 新增风险点
export const addRiskPlace = (data) =>
  requests({
  url: `/api/complianceguide/risk/add`,
  method: "post",
  data,
});
//  编辑风险点
export const editRiskPlace = (data) =>
  requests({
  url: `/api/complianceguide/risk/edit`,
  method: "post",
  data,
});
// 获取风险点列表
export const getRiskPlace = (data) =>
  requests({
  url: `/api/complianceguide/risk/paging/${data.params}`,
  method: "post",
  data: data.info,
});
//  获取风险点详情
export const getRiskPlaceDetail = (id) =>
  requests({
  url: `/api/complianceguide/risk/detail/${id}`,
  method: "get",
});
//  删除风险点
export const delRiskPlace  = (data) =>
  requests({
  url: `/api/complianceguide/risk`,
  method: "delete",
  data,
});
// 导出风险点
export const exportRiskPlace = (data) =>
  requests({
  url: `/api/complianceguide/risk/export`,
  method: "post",
  data,
  responseType: 'blob',
});
// 1-2 --------------- 合规指南数据管理
// 新增风控数字工具
export const addRiskTool = (data) =>
  requests({
  url: `/api/complianceguide/riskDigitalTool/add`,
  method: "post",
  data,
});
//  编辑风控数字工具
export const editRiskTool = (data) =>
  requests({
  url: `/api/complianceguide/riskDigitalTool/edit`,
  method: "post",
  data,
});
// 获取风控数字工具列表
export const getRiskTool = (params) =>
  requests({
  url: `/api/complianceguide/riskDigitalTool/paging/${params}`,
  method: "get",
});
//  删除风控数字工具
export const delRiskTool  = (data) =>
  requests({
  url: `/api/complianceguide/riskDigitalTool`,
  method: "delete",
  data,
});
// 新增匹配报告
export const addRiskMatchingReport = (data) =>
  requests({
  url: `/api/complianceguide/riskMatchingReport/add`,
  method: "post",
  data,
});
//  编辑匹配报告
export const editRiskMatchingReport = (data) =>
  requests({
  url: `/api/complianceguide/riskMatchingReport/edit`,
  method: "post",
  data,
});
// 获取匹配报告列表
export const getRiskMatchingReport = (params) =>
  requests({
  url: `/api/complianceguide/riskMatchingReport/paging/${params}`,
  method: "get",
});
//  删除匹配报告
export const delRiskMatchingReport  = (data) =>
  requests({
  url: `/api/complianceguide/riskMatchingReport`,
  method: "delete",
  data,
});
// 新增业务类
export const addRiskBusinessModule = (data) =>
  requests({
  url: `/api/complianceguide/businessModule/add`,
  method: "post",
  data,
});
//  编辑业务类
export const editRiskBusinessModule = (data) =>
  requests({
  url: `/api/complianceguide/businessModule/edit`,
  method: "post",
  data,
});
// 获取业务类列表
export const getRiskBusinessModule = (params) =>
  requests({
    // url: `/api/complianceguide/businessModule/paging/${params}`,
    url: `/api/complianceguide/businessModule/list`,
    method: "get",
});
//  删除业务类
export const delRiskBusinessModule  = (data) =>
  requests({
  url: `/api/complianceguide/businessModule/delete`,
  method: "delete",
  data,
});

// 2 ----------------- 劳动法律库
// 2-1 --------------- 劳动法规
//  获取范围
export const getLaborLawsRegion = () =>
requests({
url: `/api/LawsRegulations/region/list`,
method: "get",
});
// 获取劳动法列表
export const getLaborLawsList = (params) =>
requests({
  url: `/api/LawsRegulations/paging/${params}`,
  method: "get",
});
//  获取劳动法详情
export const getLaborLawsDetail = (id) =>
  requests({
  url: `/api/LawsRegulations/detail/${id}`,
  method: "get",
});
//  新增劳动法
export const addLaborLaws = (data) =>
  requests({
  url: `/api/LawsRegulations/add`,
  method: "post",
  data,
});
//  编辑劳动法
export const editLaborLaws = (data) =>
  requests({
  url: `/api/LawsRegulations/edit`,
  method: "post",
  data,
});
//  删除劳动法
export const delLaborLaws  = (data) =>
  requests({
  url: `/api/LawsRegulations/delete`,
  method: "delete",
  data,
});
// 获取重复劳动法列表
export const getLaborLawsListRepeat = (params) =>
requests({
  url: `/api/LawsRegulations/repeat/paging/${params}`,
  method: "get",
});
// 获取劳动法仪表盘
export const getLaborLawsListDashboard = (params) =>
requests({
  url: `/api/LawsRegulations/dashboard/statistic/count?parentId=${params}`,
  method: "get",
});
// 2-2 --------------- 劳动法判例
// 获取判例案由
export const getCauseofaction = () =>
requests({
  url: `/api/precedent/causeofaction/list`,
  method: "get",
});
// 新增判例
export const addPrecedent = (data) =>
requests({
url: `/api/precedent/add`,
method: "post",
data,
});
//  编辑判例
export const editPrecedent = (data) =>
  requests({
  url: `/api/precedent/edit`,
  method: "post",
  data,
});
//  删除判例
export const delPrecedent  = (data) =>
  requests({
  url: `/api/precedent/delete`,
  method: "delete",
  data,
});
// 获取判例列表
export const getPrecedenttList = (data) =>
  requests({
  url: `/api/precedent/paging/${data.params}`,
  method: "post",
  data: data.info,
});
//  获取采集任务列表
export const gatherBazhuayuTask = () =>
  requests({
  url: `/api/common/bazhuayu/task/list`,
  method: "get",
});
// 采集判例列表
export const gatherPrecedenttList = (data) =>
  requests({
  url: `/api/precedent/synchronize`,
  method: "post",
  data: data,
});
//  获取判例详情
export const getPrecedentDetail = (id) =>
  requests({
  url: `/api/precedent/detail/${id}`,
  method: "get",
});

// 3 ----------------- 劳动法参数 
// 3-1 --------------- 指标管理
// 获取省市区地址
export const getRegion = () =>
  requests({
    url: '/api/laborlawparams/region/list',
    method: "get",
  });
// 获取参数列表
export const getParameter = (params) =>
  requests({
    url: `/api/laborlawparams/data/manage/paging${params}`,
    method: "get",
  });
// 编辑参数列表
export const editParameter = (data) =>
  requests({
    url: `/api/laborlawparams/data/single/edit`,
    method: "post",
    data,
  });
// 删除参数列表
export const delParameter = (data) =>
  requests({
    url: `/api/laborlawparams/data/single`,
    method: "delete",
    data,
  });
// 获取指标参数列表
export const getTargetParameter = (params) =>
  requests({
    url: `/api/laborlawparams/data/paging${params}`,
    method: "get",
  });
// 删除指标参数
export const delTargetParameter2 = (data) =>
  requests({
    url: `/api/laborlawparams/data/group`,
    method: "delete",
    data,
  });
// 新增参数、数值
export const addParameter = (data) =>
  requests({
    url: `/api/laborlawparams/data/add`,
    method: "post",
    data,
  });
// 下载模板
export const getTemplate = (params) =>
  requests({
    url: `/api/laborlawparams/data/import/template/${params}`,
    method: "get",
    responseType: 'blob',
  });
// 导入参数
export const uploadParameter = (data) =>
  requests({
    url: `/api/laborlawparams/data/import`,
    method: "post",
    data,
  });
  // 获取参数列表动态表头
  export const getParameterHead = (params) =>
  requests({
    url: `/api/laborlawparams/target/headlist/${params}`,
    method: "get",
  });
// 获取劳动法指标列表
export const getTarget = () =>
  requests({
    url: '/api/laborlawparams/target/list',
    method: "get",
  });
// 新增指标
export const addTarget = (data) =>
  requests({
    url: '/api/laborlawparams/target/add',
    method: "post",
    data,
  });
// 编辑指标
export const editTarget = (data) =>
  requests({
    url: '/api/laborlawparams/target/edit',
    method: "post",
    data,
  });
// 删除指标或参数
export const delTargetParameter = (data) =>
  requests({
    url: '/api/laborlawparams/target',
    method: "delete",
    data,
  });
// 修改指标启用状态
export const editEnable = (data) =>
  requests({
    url: '/api/laborlawparams/target/enable/edit',
    method: "post",
    data,
  });

// 4 ----------------- 劳动合同生成器
// 获取劳动合同类别
export const getLaborContractType = () =>
  requests({
    url: `/api/template/laborcontract/category/list`,
    method: "get",
  });
// 新增劳动合同范本
export const addLaborcontract = (data) =>
  requests({
    url: `/api/template/laborcontract/add`,
    method: "post",
    data
  });
// 获取劳动合同范本列表
export const getLaborcontract = (params) =>
  requests({
    url: `/api/template/laborcontract/paging/${params}`,
    method: "get",
  });
// 删除劳动合同范本
export const delLaborcontract = (data) =>
  requests({
    url: `/api/template/laborcontract`,
    method: "delete",
    data,
  });

// 5 ----------------- 职位说明书大师
// 获取JD列表
export const getJD = (params) =>
  requests({
  url: `/api/JobDescription/list${params}`,
  method: "get",
});
// 获取JD仪表盘初始数据
export const getJDInitial = () =>
  requests({
  url: `/api/JobDescription/dashboard/initial`,
  method: "get",
});
// 获取JD仪表盘周数据
export const getJDWeekgroup = () =>
  requests({
  url: `/api/JobDescription/dashboard/weekgroup`,
  method: "get",
});
// 获取JD仪表盘指定范围数据数据
export const getJDBetween = (params) =>
  requests({
  url: `/api/JobDescription/dashboard/between/${params}`,
  method: "get",
});
// 新增文件夹
export const addJDFolder = (data) =>
requests({
url: `/api/JobDescription/addfolder`,
method: "post",
data,
});
//  编辑文件夹
export const editJDfolder = (data) =>
  requests({
  url: `/api/JobDescription/editfolder`,
  method: "post",
  data,
});
//  删除文件夹或文件
export const delJDfolder  = (data) =>
  requests({
  url: `/api/JobDescription/delete`,
  method: "delete",
  data,
});
// 新增文件
export const addJDFile = (data) =>
  requests({
  url: `/api/JobDescription/add`,
  method: "post",
  data,
});
// 移动文件或文件夹
export const moveJDFile = (data) =>
  requests({
  url: `/api/JobDescription/move`,
  method: "post",
  data,
});

// 6 ----------------- HRPP
// 获取hrpp列表
export const getHrpp = (params) =>
  requests({
  url: `/api/hrpp/list${params}`,
  method: "get",
});
// 获取hrpp仪表盘初始数据
export const getHrppInitial = () =>
  requests({
  url: `/api/hrpp/dashboard/initial`,
  method: "get",
});
// 获取hrpp仪表盘周数据
export const getHrppWeekgroup = () =>
  requests({
  url: `/api/hrpp/dashboard/weekgroup`,
  method: "get",
});
// 获取hrpp仪表盘指定范围数据数据
export const getHrppBetween = (params) =>
  requests({
  url: `/api/hrpp/dashboard/between/${params}`,
  method: "get",
});
// 新增文件夹
export const addFolder = (data) =>
requests({
url: `/api/hrpp/addfolder`,
method: "post",
data,
});
//  编辑文件夹
export const editfolder = (data) =>
  requests({
  url: `/api/hrpp/editfolder`,
  method: "post",
  data,
});
//  删除文件夹或文件
export const delfolder  = (data) =>
  requests({
  url: `/api/hrpp/delete`,
  method: "delete",
  data,
});
// 新增文件
export const addFile = (data) =>
  requests({
  url: `/api/hrpp/add`,
  method: "post",
  data,
});
// 移动文件或文件夹
export const moveFile = (data) =>
  requests({
  url: `/api/hrpp/move`,
  method: "post",
  data,
});
// 旧接口
// // 获取hr规章分类
// export const getHRcate = () =>
//   requests({
//     url: "/api/template/hrregulation/category/list",
//     method: "get",
//   });
// // 获取hr规章列表
// export const getHRegulations = (params) =>
//   requests({
//     url: `/api/template/hrregulation/paging/${params}`,
//     method: "get",
//   });
// // 删除hrr
// export const delHRegulations = (data) =>
//   requests({
//     url: `/api/template/hrregulation`,
//     method: "delete",
//     data,
//   });
// // 新增hrr文件
// export const uploadHrrFile = (data) =>
//   requests({
//     url: '/api/template/hrregulation/upload',
//     method: "post",
//     data,
//   });

// 7 ----------------- 律师案源库
// 获取省市
export const getAddress = () =>
requests({
  url: `/api/common/address`,
  method: "get",
});
// 获取列表
export const getOrderList = (params) =>
requests({
  url: `/api/caserepository/order/paging/${params}`,
  method: "get",
});
//  新增订单任务 
export const addOrder = (data) =>
  requests({
  url: `/api/caserepository/order/add`,
  method: "post",
  data,
});
//  编辑订单任务 
export const editOrder = (data) =>
  requests({
  url: `/api/caserepository/order/edit`,
  method: "post",
  data,
});
//  获取订单详情
export const getOrderDetail = (id) =>
  requests({
  url: `/api/caserepository/order/detail/${id}`,
  method: "get",
});
//  删除订单
export const delOrder = (data) =>
  requests({
  url: `/api/caserepository/order/delete`,
  method: "delete",
  data,
});

// 8 ----------------- 公众号菜单
// 保存iLaaS公众号菜单
export const addWechatILaaS = (data) =>
  requests({
  url: `/api/wechat/officalaccounts/ilaas/menu/update`,
  method: "post",
  data,
});
// 获取iLaaS公众号菜单
export const getWechatILaaS = () =>
  requests({
  url: `/api/wechat/officalaccounts/ilaas/menu/list`,
  method: "get",
});
// 保存ComDoc公众号菜单
export const addWechatComDoc = (data) =>
  requests({
  url: `/api/wechat/officalaccounts/comdoc/menu/update`,
  method: "post",
  data,
});
// 获取ComDoc公众号菜单
export const getWechatComDoc = () =>
  requests({
  url: `/api/wechat/officalaccounts/comdoc/menu/list`,
  method: "get",
});

// 8 ----------------- 劳动和谐关系标准
// 获取和谐关系标准列表
export const getHarmoniousList = (params) =>
  requests({
  url: `/api/laborRelationsEvaluation/paging/${params}`,
  method: "get",
});
// 新增和谐关系标准
export const addHarmonious = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/add`,
    method: "post",
    data,
});
// 编辑和谐关系标准
export const editHarmonious = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/edit`,
    method: "post",
    data,
});
// 删除和谐关系标准
export const delHarmonious = (id) =>
  requests({
    url: `/api/laborRelationsEvaluation/delete/${id}`,
    method: "delete",
});
// 获取和谐项目列表
export const getHarmoniousProject = () =>
  requests({
  url: `/api/laborRelationsEvaluation/projectdic/list`,
  method: "get",
});
// 新增和谐项目
export const addHarmoniousProject = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/projectdic/add`,
    method: "post",
    data,
});
// 编辑和谐项目
export const editHarmoniousProject = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/projectdic/edit`,
    method: "post",
    data,
});
// 删除和谐项目
export const delHarmoniousProject = (id) =>
  requests({
    url: `/api/laborRelationsEvaluation/projectdic/delete/${id}`,
    method: "delete",
});
// 获取标准项目列表
export const getHarmoniousProject2 = (id) =>
  requests({
  url: `/api/laborRelationsEvaluation/project/list/${id}`,
  method: "get",
});
// 新增标准项目
export const addHarmoniousProject2 = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/project/add`,
    method: "post",
    data,
});
// 编辑标准项目
export const editHarmoniousProject2 = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/project/edit`,
    method: "post",
    data,
});
// 删除标准项目
export const delHarmoniousProject2 = (id) =>
  requests({
    url: `/api/laborRelationsEvaluation/project/delete/${id}`,
    method: "delete",
});
// 获取指标层级列表
export const getIndexLevel = (params) =>
  requests({
  url: `/api/laborRelationsEvaluation/grade/paging/${params}`,
  method: "get",
});
// 新增指标层级
export const addIndexLevel = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/grade/add`,
    method: "post",
    data,
});
// 编辑指标层级
export const editIndexLevel = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/grade/edit`,
    method: "post",
    data,
});
// 删除指标层级
export const delIndexLevel = (id) =>
  requests({
    url: `/api/laborRelationsEvaluation/grade/delete/${id}`,
    method: "delete",
});
// 获取题目列表
export const getTopic = (params) =>
  requests({
  url: `/api/laborRelationsEvaluation/detail/paging/${params}`,
  method: "get",
});
// 新增题目
export const addTopic = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/detail/add`,
    method: "post",
    data,
});
// 编辑题目
export const editTopic = (data) =>
  requests({
    url: `/api/laborRelationsEvaluation/detail/edit`,
    method: "post",
    data,
});
// 删除题目
export const delTopic = (id) =>
  requests({
    url: `/api/laborRelationsEvaluation/detail/delete/${id}`,
    method: "delete",
});