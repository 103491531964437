<template>
  <div class="number-tool">
    <div class="head-box">
      <el-button @click="add">新增工具</el-button>
    </div>
    <div class="table-container">
      <el-table
        v-loading="loading"
        :height="tableHeight"
        border
        :data="tableData"
        style="width: 100%"
        fit
      >
        <template v-for="(head, index) in headData">
          <el-table-column
            :align="head.align"
            v-if="head.label === '序号'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              {{scope.$index+1}}
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '启用状态'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              <p v-for="item in scope.row.childs" :key="item.id">{{item.name}}</p>
              <span v-if="scope.row.status == 1" style="color: #ccc;">未启用</span>
              <span v-else style="color: green;">启用</span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          >
            <template slot-scope="scope">
              <span
                style="color: #6aa9fb; cursor: pointer;margin: 0 15px;"
                @click="edit(scope.row)">
                编辑
              </span>
              <span
                style="color: red; cursor: pointer"
                @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            :width="head.width"
          ></el-table-column>
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <riskToolDialog v-if="showDialog" :info="dialogInfo"></riskToolDialog>
  </div>
</template>

<script>
import riskToolDialog from '@/views/Dialog/riskToolDialog.vue';
import { getRiskTool, delRiskTool } from '@/api/knowledgeData'
export default {
  name: "numberTool",
  components: {
    riskToolDialog,
  },
  data() {
    return {
      loading: false,
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 15, //每页显示条数
      tableData: [],
      value: true,
      headData: [
        { align: "center", prop: "序号", label: "序号", width: "200px" },
        { align: "center", prop: "title", label: "工具名称", width: "" },
        { align: "center", prop: "route", label: "工具路径", width: "" },
        { align: "center", prop: "status", label: "启用状态", width: "200px" },
        { align: "center", prop: "操作", label: "操作", width: "200px" },
      ],
      arrTableLength: {
      },
      showDialog: false,
      dialogInfo: {
        type: 'add',
      },
    }
  },
  created() {
    this.init();
  },
  mounted() {
  },
  methods: {
    init() {
      // this.currentPage = 1;
      // this.interPage = 15;
      this.dataSize = 0;
      this.getData();
    },
    // 获取指标列表
    async getData() {
      this.loading = true;
      let params = `${this.currentPage}/${this.interPage}`;
      let res = await getRiskTool(params);
      this.loading = false;
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        this.tableData = res.result.items;
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 编辑
    edit(row) {
      this.dialogInfo.type = 'edit';
      this.dialogInfo.row = row;
      this.showDialog = true;
    },
    // 删除指标
    del(row) {
      this.$confirm(`是否确认删除工具【${row.title}】`)
      .then((_) => {
        this.delTarget(row);
      })
      .catch((_) => {});
    },
    async delTarget(row) {
      let res = await delRiskTool({ids: [row.id]});
      if (res.code == 200) {
        this.getData();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    // 新增劳务法
    add() {
      this.dialogInfo.type = 'add';
      this.dialogInfo.row = {};
      this.showDialog = true;
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
}
}
</script>

<style lang="scss" scoped>
  .number-tool {
    .head-box{
      display: flex;
      justify-content: space-between;
      height: 7%;
      margin-bottom: 1%;
      .el-button {
        height: 40px;
        border-radius: 20px;
        color: rgba(0, 148, 255,.7);
        border: 1px solid rgba(0, 148, 255,.7);
      }
      .head-right {
        display: flex;
      }
    }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
  }
</style>

