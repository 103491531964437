import { render, staticRenderFns } from "./setJDFileDialog.vue?vue&type=template&id=06611180&scoped=true"
import script from "./setJDFileDialog.vue?vue&type=script&lang=js"
export * from "./setJDFileDialog.vue?vue&type=script&lang=js"
import style0 from "./setJDFileDialog.vue?vue&type=style&index=0&id=06611180&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06611180",
  null
  
)

export default component.exports