<template>
  <div class="best-com">
    <h1>BestCom</h1>
  </div>
</template>

<script>
export default {
  name: "BestCom",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .best-com {
  }
</style>

