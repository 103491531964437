<template>
  <div class="add-project">
    <el-dialog
      title="项目"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      width="460px"
    >
      <div class="row-box">
        <p class="label">
          <span>* </span>
          选择项目：
        </p>
        <el-select v-model="form.projectId" :disabled="info.id" placeholder="请选择">
          <el-option
            v-for="item in projectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="row-box">
        <p class="label">
          说明：
        </p>
        <el-input type="textarea" :autosize="{ minRows: 1}" placeholder="请输入说明" v-model="form.instructions"></el-input>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getHarmoniousProject,
  addHarmoniousProject2,
  editHarmoniousProject2,
} from "@/api/knowledgeData";
export default {
  name: "addProject",
  components: {},
  props: {
    id: {
      type: Number,
    },
    info: {
      type: Object,
    }
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        projectId: null,
        instructions: "",
      },
      projectList: [],
    };
  },
  created() {
    console.log(this.info);
    if (this.info.id) {
      this.form = {
        id: this.info.id,
        projectId: this.info.projectId,
        instructions: this.info.instructions,
      }
    }
    this.getData();
  },
  mounted() {},
  methods: {
    async getData() {
      let res = await getHarmoniousProject();
      if (res.code == 0) {
        this.projectList = res.result;
      }
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    async submit() {
      if (!this.form.projectId) {
        this.$message.warning(`请选择项目！`);
        return;
      }
      let params = {
        masterId: this.id,
        projectId: this.form.projectId,
        instructions: this.form.instructions,
      };
      let res = {};
      if (this.info.id) {
        params = {
          id: this.form.id,
          instructions: this.form.instructions,
        };
        res = await editHarmoniousProject2(params);
      } else {
        res = await addHarmoniousProject2(params);
      }
      if (res.code == 200) {
        this.$message({
          message: "保存成功",
          type: "success",
        });
        this.$parent.getData();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-project {
  .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          // display: flex;
          // align-items: center;
          margin-bottom: 20px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            margin-bottom: 6px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
