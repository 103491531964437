<template>
  <div class="add-hrpp-dialog">
    <el-dialog
      :title="title"
      center
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div class="center-box">
        <div class="radio-box" v-if="info.type == 'add' && info.status === 1" style="margin-bottom: 10px;">
          <el-radio v-model="radio" label="文件夹">文件夹</el-radio>
          <el-radio v-model="radio" label="文件">文件</el-radio>
        </div>

        <div class="radio1" v-if="radio == '文件夹'">
          <div class="row-box">
            <p class="label">文件夹名称：</p>
            <el-input
              v-model="myInfo.name"
              placeholder="请输入文件夹名称"
            ></el-input>
          </div>
        </div>

        <div class="radio2" v-if="radio == '文件'">
          <div class="row-box">
            <p class="label">上传封面：</p>
            <div class="add-box" v-show="!showImg">
              <el-upload
                class="avatar-uploader"
                :action="objData.host"
                :data="objData"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-progress="uploadVideoProcess"
                :on-success="handleSuccess"
              >
                <img src="@/assets/img/add-icon.png" alt="" />
                <p>点击上传</p>
              </el-upload>
            </div>
            <div
              v-if="showImg"
              class="file-card"
              @mouseenter="showOperation = true"
              @mouseleave="showOperation = false">
              <div class="img-box">
                <img :src="showImg" alt="" />
                <div class="btn-box" v-if="showOperation">
                  <button @click="delFile(fileInfo)">删除</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row-box">
            <p class="label">上传文件：</p>
            <input v-if="!formData" class="inputFile" ref="file" type="file" @change="outClick"/>
            <div class="file-name" v-else>
              <span>{{fileName}}</span>
              <i class="el-icon-circle-close" @click="delFile2"></i>
            </div>
          </div>
          <div class="row-box">
            <p class="label">文件名称：</p>
            <el-input
              v-model="myInfo.title"
              placeholder="请输入文件名称"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">关 闭</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPolicyToken } from "@/api/index.js";
import { addFolder, editfolder, addFile } from "@/api/knowledgeData.js";
var OSS = require('ali-oss');
export default {
  name: "addHRPPDialog",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      title: "文件夹",
      dialogVisible: true,
      radio: "文件夹",
      myInfo: {
        name: "", // 文件夹名称
        parentId: null, // 父级id

        title: "", // 文件名称
        coverPath: "", // 文件封面
        buketName: "ilaas-template", // oss桶名称
      },
      isFileInfo: {}, // 上传封面中
      fileInfo: {},
      showImg: '',
      ossParams: {
        // oss参数
        expireTime: 120,
        uploadDir: "hrregulations/",
      },
      ossInfo: {},
      filePathStr: '', // 文件上传成功的路径
      objData: {
        OSSAccessKeyId: "",
        policy: "",
        Signature: "",
        key: "",
        host: "",
        dir: "",
      },
      formData: null, // 选择的文件
      fileName: '',
      loadProgress: 0, // 动态显示进度条
      progressFlag: false, // 关闭进度条
      showOperation: false, // 鼠标移入显示操作按钮
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      submitStatus: false,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      console.log(this.info);
      // if (this.info.item.id && this.info.type == "add") {
      //   this.myInfo.parentId = this.info.item.id;
      // } else if (this.info.type == "edit") {
      //   this.myInfo = JSON.parse(JSON.stringify(this.info.item));
      // }
      if (this.info.status === 1) {
        this.title = "文件夹或文件";
      } else if (this.info.status === 2) {
        this.title = "文件夹";
        this.radio = "文件夹";
      } else if (this.info.status === 3) {
        this.title = "文件";
        this.radio = "文件";
      }
      if (this.info.type == "add") {
        if (this.info.item.id) {
          this.myInfo.parentId = this.info.item.id;
        }
      } else {
        this.myInfo = JSON.parse(JSON.stringify(this.info.item));
      }
      let dates = this.$getDate(3).split('-');
      this.ossParams.uploadDir = `hrregulations/${dates[0]}/${dates[1]}`;
      this.getPolicyTokenInfo();
    },
    
    // 获取阿里云oss签名
    async getPolicyTokenInfo() {
      let params = JSON.parse(JSON.stringify(this.ossParams));
      params.BucketName = 'ilaas-public';
      let res = await getPolicyToken(params);
      if (res.code == 200) {
        this.ossInfo = JSON.parse(res.result);
        // console.log(this.ossInfo);
      }
    },
    // 删除文件
    delFile(info) {
      this.$confirm(`请确认是否删除文件封面`)
        .then((_) => {
          this.delOssFile();
        })
        .catch((_) => {});
    },
    async delOssFile() {
      this.fileInfo = {};
      this.myInfo.coverPath = '';
      let client = new OSS({
          region: 'oss-cn-shenzhen',
          accessKeyId: this.ossInfo.accessid,
          accessKeySecret: '5ZUDWAJ0Gz4o52kubRVYCaJRRQRims',
          bucket: 'ilaas-public',
        })
      let res = await client.delete(this.filePathStr);
      this.filePathStr = '';
      this.showImg = '';
    },
    // 上传客户文件前的操作
    async beforeAvatarUpload(file) {
      let newArr = file.name.split(".");
      let fileType = newArr[newArr.length - 1];
      let newFileName = file.name;
      // if (this.myInfo.title) {
      //   newFileName = `${this.myInfo.title}.${fileType}`;
      // }
      let imgType = [
        "jpg", "jpeg", "png", "pnge", "dds", "psd", "pdt", "webp", "xmp", "gif", "bmp", "svg", "tiff", "ico", 
      ];
      const isLt5M = file.size / 1024 / 1024 < 10;
      if (!imgType.includes(fileType)) {
        this.$message.warning("只能上传图片格式!");
        return;
      }
      if (!isLt5M) {
        this.$message.warning("封面图片大小不能超过 10MB!");
        return;
      }
        let data = this.ossInfo;
        this.objData = {
          OSSAccessKeyId: data.accessid,
          policy: data.policy,
          signature: data.signature,
          expire: data.expire,
          host: data.host,
          dir: data.dir,
          key: `${this.ossParams.uploadDir}/${new Date().getTime()}-${newFileName}`,
        };
        this.isFileInfo = {
          name: newFileName,
          coverPath: this.objData.key,
          imgUrl: `https://file-public.ilaas.cn/${this.objData.key}`, // https://file-public.ilaas.cn ： oss公共桶的链接地址
        }
    },
    // 上传成功
    handleSuccess(response, file, fileList, rowInfo) {
      // console.log(response, file, fileList, rowInfo);
      this.filePathStr = this.objData.key;
      this.myInfo.coverPath = this.objData.key;
      this.fileInfo = this.isFileInfo;
      this.showImg = this.fileInfo.imgUrl;
      // console.log(this.showImg);
    },
    // 上传文件进度
    uploadVideoProcess(event, file, fileList) {
      // console.log(event.percent);
      this.progressFlag = true; // 显示进度条
      this.loadProgress = parseInt(event.percent); // 动态获取文件上传进度
      if (this.loadProgress >= 100) {
        this.loadProgress = 100;
        setTimeout(() => {
          this.progressFlag = false;
        }, 1000); // 一秒后关闭进度条
      }
    },
    // 选择文件
    async outClick() {
      let inputFile = this.$refs.file.files[0];
      // console.log(inputFile);
      let name = inputFile.name.split(".");
      name.pop();
      this.myInfo.title = name.join('');
      // this.myInfo.title = inputFile.name;
      this.fileName = inputFile.name;
      this.formData = new FormData();
      this.formData.append('file', inputFile);
    },
    // 删除文件 
    delFile2() {
      this.formData = null;
      this.fileName = '';
      this.myInfo.title = '';
    },
    cancel() {
      this.$parent.showDialog = false;
    },
    submit() {
      if (this.radio == "文件夹") {
        this.submitFolder();
      } else {
        this.submitFile();
      }
    },
    async submitFolder() {
      if (this.submitStatus) return;
      if (!this.myInfo.name) {
        this.$message.warning(`文件夹名称不能为空！`);
        return;
      }
      let params = {
        name: this.myInfo.name,
        parentId: this.myInfo.parentId,
      };
      this.submitStatus = true;
      let res = {};
      if (this.info.type == "add") {
        res = await addFolder(params);
      } else {
        res = await editfolder(params);
      }
      this.submitStatus = false;
      // console.log(res);
      if (res.code == 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    },
    async submitFile() {
      if (this.submitStatus) return;
      // console.log("保存文件");
      if (!this.myInfo.title) {
        this.$message.warning(`请上传文件！`);
        return;
      }
      this.formData.append('name', this.myInfo.title);
      this.formData.append('parentId', this.myInfo.parentId);
      this.formData.append('buketName', this.myInfo.buketName);
      this.formData.append('cover', this.myInfo.coverPath);
      this.submitStatus = true;
      let res = await addFile(this.formData);
      this.submitStatus = false;
      // console.log(res);
      if (res.code == 200) {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$parent.init();
        this.cancel();
      } else {
        this.$message({
          message: res.Message,
          type: 'warning'
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.add-hrpp-dialog {
  ::v-deep .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .center-box {
          .row-box {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .label {
              min-width: 72px;
              font-size: 14px;
              color: #606266;
              margin-right: 18px;
              white-space: nowrap;
            }
            .el-input {
              .el-input__inner {
                border-radius: 10px;
                border: 1px solid #d7dbda;
              }
            }
            .add-box {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 24%;
              height: 152px;
              margin-right: 1%;
              margin-bottom: 14px;
              border-radius: 10px;
              background: #f0f3fb;
              cursor: pointer;
              img {
                width: 30px;
                height: 30px;
                margin-bottom: 10px;
              }
              p {
                font-size: 16px;
                color: #6474c7;
              }
            }
            .file-card {
              .img-box {
                position: relative;
                width: 200px;
                border-radius: 14px;
                overflow: hidden;
                img {
                  width: 100%;
                }
                .btn-box {
                  position: absolute;
                  top: 0;
                  right: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 100%;
                  background-color: rgba(66, 66, 66, .5);
                  cursor:pointer;
                }
              }
            }
            .file-name {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              i {
                font-size: 36px;
                color: #7097dc;
                cursor:pointer;
              }
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: center;
          margin-top: 20px;
          button {
            width: 110px;
            height: 46px;
            font-size: 16px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
