<template>
  <div class="conform-guide">
    <div class="head-box">
      <div class="head-left">
        <el-button @click="add">新增风险点</el-button>
        <!-- <el-checkbox v-model="allChecked" @change="allCheck">全选</el-checkbox>
        <button class="del-btn" @click="dels">删除</button> -->
      </div>
      <div class="head-right">
        <span>职业阶段：</span>
        <el-select
          v-model="filterInfo.postCycle"
          clearable
          @change="init"
          placeholder="请选择职业阶段"
        >
          <el-option
            v-for="item in postCycleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span>专项类：</span>
        <el-select
          v-model="filterInfo.businessModule"
          clearable
          @change="init"
          placeholder="请选择专项类"
        >
          <el-option
            v-for="item in businessModuleList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span>风险责任：</span>
        <el-select
          v-model="filterInfo.type"
          class="selects"
          clearable
          multiple
          @change="init"
          placeholder="请选择风险责任"
        >
          <el-option
            v-for="item in typeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <span>风险等级：</span>
        <el-select
          v-model="filterInfo.rank"
          clearable
          @change="init"
          placeholder="请选择风险等级"
        >
          <el-option
            v-for="item in rankList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入搜索内容"
          @keyup.enter.native="init"
          v-model="filterInfo.lawName"
        >
          <i
            @click="init"
            slot="suffix"
            class="el-input__icon el-icon-search"
          ></i>
        </el-input>
        <el-button class="btn" @click="exportData">导出</el-button>
        <div class="popover-box">
          <el-popover
            popper-class="item-popover"
            placement="bottom"
            width="416"
            trigger="click"
          >
            <el-checkbox-group v-model="headFilter">
              <el-checkbox
                v-for="(item, index) in headFilterList"
                :label="index"
                :key="index"
                @change="filtrate"
                class="checkbox-line"
              >
                {{ item.label }}
              </el-checkbox>
            </el-checkbox-group>
            <el-button type="text" slot="reference"
              ><img src="@/assets/img/hmc_shaixuan.png" />
              <span class="c-span">筛选</span></el-button
            >
          </el-popover>
        </div>
      </div>
    </div>
    <!-- <el-button class="export-btn" @click="exportData">导出</el-button> -->
    <div class="table-container" ref="container">
      <el-table
        height="500"
        border
        ref="tabels"
        :data="tableData"
        style="width: 100%"
        v-horizontal-scroll="'always'"
        v-loading="loading"
      >
        <template v-for="(head, index) in headData">
          <!-- :width="head.width" -->
          <el-table-column
            :align="head.align"
            v-if="head.label === '风险识别'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :align="childrenHead.align"
                v-if="childrenHead.label === '风险源'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <div
                    v-for="(item, index) in scope.row.behaviourDescriptions"
                    :key="index"
                  >
                    <p :title="item.keySentence" v-if="item.keySentence">
                      关键句：{{ item.keySentence }}
                    </p>
                    <p :title="item.content" v-if="item.content">
                      描述：{{ item.content }}
                    </p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '风险点名称'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    :title="scope.row.name"
                    style="color: #6aa9fb; cursor: pointer"
                    @click="lookDetails(scope.row)"
                  >
                    {{ scope.row.name }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '违规后果'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    v-for="(item, index) in scope.row.infractionConsequences"
                    :key="index"
                    :title="item.content"
                  >
                    {{ item.content }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '风险责任'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    v-for="(item, index) in scope.row.type"
                    :key="index"
                    :title="item"
                  >
                    {{ item }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '风险等级'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.rank == 1">低</span>
                  <span v-else-if="scope.row.rank == 2">中</span>
                  <span v-else-if="scope.row.rank == 3">高</span>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '风险点状态'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p v-if="scope.row.status === 1" style="color: #ccc">失效</p>
                  <p v-else style="color: green">有效</p>
                </template>
              </el-table-column>
              <el-table-column
                v-else
                :align="childrenHead.align"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p :title="scope.row[childrenHead.prop]">
                    {{ scope.row[childrenHead.prop] }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '类别'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :align="childrenHead.align"
                v-if="childrenHead.label === '组织类型'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    v-for="item in scope.row.enterpriseNature"
                    :key="item.id"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '匹配报告'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    v-for="(item, index) in scope.row.matchingReports"
                    :key="index"
                    :title="item.title"
                  >
                    {{ item.title }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                v-else
                :align="childrenHead.align"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p :title="scope.row[childrenHead.prop]">
                    {{ scope.row[childrenHead.prop] }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '劳动法规'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :key="childrenHeadIdx"
                :label="childrenHead.label"
                :align="childrenHead.align"
                :prop="childrenHead.prop"
              >
                <template slot-scope="scope">
                  <p
                    class="ps"
                    v-for="(item, index) in scope.row[childrenHead.prop]"
                    :key="index"
                    :title="item"
                  >
                    {{ item }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === 'ISO37301'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :key="childrenHeadIdx"
                :label="childrenHead.label"
                :align="childrenHead.align"
                :prop="childrenHead.prop"
              >
                <template slot-scope="scope">
                  <p
                    class="ps"
                    v-for="(item, index) in scope.row[childrenHead.prop]"
                    :key="index"
                    :title="item"
                  >
                    {{ item }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === 'GBT/T'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :key="childrenHeadIdx"
                :label="childrenHead.label"
                :align="childrenHead.align"
                :prop="childrenHead.prop"
              >
                <template slot-scope="scope">
                  <p
                    class="ps"
                    v-for="(item, index) in scope.row[childrenHead.prop]"
                    :key="index"
                    :title="item"
                  >
                    {{ item }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '中央企业合规管理办法'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :key="childrenHeadIdx"
                :label="childrenHead.label"
                :align="childrenHead.align"
                :prop="childrenHead.prop"
              >
                <template slot-scope="scope">
                  <p
                    class="ps"
                    v-for="(item, index) in scope.row[childrenHead.prop]"
                    :key="index"
                    :title="item"
                  >
                    {{ item }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '和谐关系标准'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :key="childrenHeadIdx"
                :label="childrenHead.label"
                :align="childrenHead.align"
                :prop="childrenHead.prop"
              >
                <template slot-scope="scope">
                  <p
                    class="ps"
                    v-for="(item, index) in scope.row[childrenHead.prop]"
                    :key="index"
                    :title="item"
                  >
                    {{ item }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '合规义务'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :align="childrenHead.align"
                v-if="childrenHead.label === '合规措施'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    v-for="(item, index) in scope.row.riskMeasures"
                    :key="index"
                    :title="item.title"
                  >
                    {{ item.title }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '合规文件'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    v-for="(item, index) in scope.row.riskTexts"
                    :key="index"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '合规制度'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    v-for="(item, index) in scope.row.institutions"
                    :key="index"
                    :title="item.name"
                  >
                    {{ item.name }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '合规工具'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    v-for="(item, index) in scope.row.tools"
                    :key="index"
                    :title="item.title"
                  >
                    {{ item.title }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '合规义务种类'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p>{{ scope.row.dutyType ? "自愿性" : "强制性" }}</p>
                </template>
              </el-table-column>
              <el-table-column
                v-else
                :align="childrenHead.align"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p :title="scope.row[childrenHead.prop]">
                    {{ scope.row[childrenHead.prop] }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '案例'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :key="childrenHeadIdx"
                :label="childrenHead.label"
                :align="childrenHead.align"
                :prop="childrenHead.prop"
              >
                <template slot-scope="scope">
                  <p
                    class="ps"
                    v-for="(item, index) in scope.row[childrenHead.prop]"
                    :key="index"
                    :title="item"
                  >
                    {{ item }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '其他'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template v-for="(childrenHead, childrenHeadIdx) in head.childs">
              <el-table-column
                :align="childrenHead.align"
                v-if="childrenHead.label === '问题'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p
                    v-for="(item, index) in scope.row.questions"
                    :key="index"
                    :title="item.content"
                  >
                    {{ item.content }}
                  </p>
                </template>
              </el-table-column>
              <el-table-column
                :align="childrenHead.align"
                v-else-if="childrenHead.label === '是否启用'"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p v-if="scope.row.isEnable" style="color: green">是</p>
                  <p v-else style="color: #ccc">否</p>
                </template>
              </el-table-column>
              <el-table-column
                v-else
                :align="childrenHead.align"
                :key="childrenHeadIdx"
                :prop="childrenHead.prop"
                :label="childrenHead.label"
              >
                <template slot-scope="scope">
                  <p :title="scope.row[childrenHead.prop]">
                    {{ scope.row[childrenHead.prop] }}
                  </p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            width="80px"
          >
            <template slot-scope="scope">
              <span
                style="color: #6aa9fb; cursor: pointer"
                @click="lookDetails(scope.row)"
              >
                修改
              </span>
              <span
                v-if="scope.row.status == 1"
                style="color: red; cursor: pointer; margin-top: 10px"
                @click="del(scope.row)"
              >
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label"
          >
            <template slot-scope="scope">
              <p :title="scope.row[head.prop]">{{ scope.row[head.prop] }}</p>
            </template>
          </el-table-column>
          <!-- <el-table-column
            :align="head.align"
            v-else-if="head.label === '业务流程'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p :title="`${scope.row.businessModuleCategoryName} — ${scope.row.businessModuleName}`">{{scope.row.businessModuleCategoryName}} — {{scope.row.businessModuleName}}</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '风险源'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p v-for="(item,index) in scope.row.behaviourDescriptions" :key="index" :title="item.content">{{item.content}}</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '违规后果'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p v-for="(item,index) in scope.row.infractionConsequences" :key="index" :title="item.content">{{item.content}}</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '风险类型'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p v-for="(item,index) in scope.row.type" :key="index" :title="item">{{item}}</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '风险等级'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <span v-if="scope.row.rank == 1">低</span>
              <span v-else-if="scope.row.rank == 2">中</span>
              <span v-else-if="scope.row.rank == 3">高</span>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '合规措施'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p v-for="(item,index) in scope.row.riskMeasures" :key="index" :title="item.content">{{item.content}}</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '合规文件'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p v-for="(item,index) in scope.row.riskTexts" :key="index" :title="item.fileName">{{item.fileName}}</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '劳动法规'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template v-for="(childrenHead,childrenHeadIdx) in head.childs">
              <el-table-column
                :key="childrenHeadIdx"
                :label="childrenHead.label"
                :align="childrenHead.align"
                :prop="childrenHead.prop">
                <template slot-scope="scope">
                  <p class="ps" v-for="(item,index) in scope.row[childrenHead.prop]" :key="index" :title="item">{{item}}</p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '案例'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template v-for="(childrenHead,childrenHeadIdx) in head.childs">
              <el-table-column
                :key="childrenHeadIdx"
                :label="childrenHead.label"
                :align="childrenHead.align"
                :prop="childrenHead.prop">
                <template slot-scope="scope">
                  <p class="ps" v-for="(item,index) in scope.row[childrenHead.prop]" :key="index" :title="item">{{item}}</p>
                </template>
              </el-table-column>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '问题'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p v-for="(item,index) in scope.row.questions" :key="index" :title="item.content">{{item.content}}</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '合规工具'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p v-for="(item,index) in scope.row.tools" :key="index" :title="item.title">{{item.title}}</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '风险点状态'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p v-if="scope.row.status === 1" style="color: #ccc;">失效</p>
              <p v-else style="color: green;">有效</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '是否启用'"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p v-if="scope.row.isEnable" style="color: green;">是</p>
              <p v-else style="color: #ccc;">否</p>
            </template>
          </el-table-column>
          <el-table-column
            :align="head.align"
            v-else-if="head.label === '操作'"
            :key="index"
            :prop="head.prop"
            :label="head.label"
            width="80px">
            <template slot-scope="scope">
              <span
                style="color: #6aa9fb; cursor: pointer"
                @click="lookDetails(scope.row)">
                修改
              </span>
              <span
                v-if="scope.row.status == 1"
                style="color: red; cursor: pointer;margin-top: 10px;"
                @click="del(scope.row)">
                删除
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :align="head.align"
            :key="index"
            :prop="head.prop"
            :label="head.label">
            <template slot-scope="scope">
              <p :title="scope.row[head.prop]">{{scope.row[head.prop]}}</p>
            </template>
          </el-table-column> -->
        </template>
      </el-table>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 15, 20]"
          :page-size="interPage"
          layout="total, sizes, prev, pager, next, jumper"
          background
          :total="dataSize"
        >
        </el-pagination>
      </div>
    </div>
    <addRiskPoint v-if="showDialog" :info="detailsInfo"></addRiskPoint>
  </div>
</template>

<script>
import {
  getRiskPlace,
  delRiskPlace,
  exportRiskPlace,
  getRiskBusinessModule,
} from "@/api/knowledgeData";
import addRiskPoint from "@/views/Dialog/addRiskPoint.vue";
export default {
  name: "conformGuide",
  components: {
    addRiskPoint,
  },
  data() {
    return {
      filterInfo: {
        // 列表过滤条件
        postCycle: null,
        businessModule: null,
        type: null,
        rank: null,
        lawName: "",
      },
      loading: false,
      tableHeight: 500,
      dataSize: 0, //总记录条数
      currentPage: 1, //分页默认页数
      interPage: 15, //每页显示条数
      // headFilter: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17],
      headFilter: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      headFilterList: [
        {
          align: "center",
          prop: "风险识别",
          label: "风险识别",
          width: "",
          childs: [
            {
              align: "center",
              prop: "code",
              label: "风险点编码",
              width: "130px",
            },
            {
              align: "center",
              prop: "name",
              label: "风险点名称",
              width: "150px",
            },
            {
              align: "center",
              prop: "behaviourDescriptions",
              label: "风险源",
              width: "300px",
            },
            {
              align: "center",
              prop: "infractionConsequences",
              label: "违规后果",
              width: "180px",
            },
            {
              align: "center",
              prop: "type",
              label: "风险责任",
              width: "120px",
            },
            {
              align: "center",
              prop: "riskValue",
              label: "风险值",
              width: "80px",
            },
            { align: "center", prop: "rank", label: "风险等级", width: "80px" },
            {
              align: "center",
              prop: "status",
              label: "风险点状态",
              width: "80px",
            },
          ],
        },
        {
          align: "center",
          prop: "类别",
          label: "类别",
          width: "",
          childs: [
            {
              align: "center",
              prop: "postCycleName",
              label: "职业阶段",
              width: "80px",
            },
            {
              align: "center",
              prop: "businessModuleName",
              label: "专项类",
              width: "110px",
            },
            {
              align: "center",
              prop: "enterpriseNature",
              label: "组织类型",
              width: "110px",
            },
            {
              align: "center",
              prop: "matchingReports",
              label: "匹配报告",
              width: "110px",
            },
          ],
        },
        {
          align: "center",
          prop: "lawBasis",
          label: "劳动法规",
          width: "",
          childs: [
            {
              align: "center",
              prop: "lawBasisName",
              label: "法规名称",
              width: "180px",
            },
            {
              align: "center",
              prop: "lawBasisClause",
              label: "条款",
              width: "120px",
            },
            {
              align: "center",
              prop: "lawBasisContent",
              label: "条款内容",
              width: "200px",
            },
          ],
        },
        {
          align: "center",
          prop: "standardISO",
          label: "ISO37301",
          width: "",
          childs: [
            {
              align: "center",
              prop: "standardISOName",
              label: "法规名称",
              width: "180px",
            },
            {
              align: "center",
              prop: "standardISOClause",
              label: "条款",
              width: "120px",
            },
            {
              align: "center",
              prop: "standardISOContent",
              label: "条款内容",
              width: "200px",
            },
          ],
        },
        {
          align: "center",
          prop: "standardGBT",
          label: "GBT/T",
          width: "",
          childs: [
            {
              align: "center",
              prop: "standardGBTName",
              label: "法规名称",
              width: "180px",
            },
            {
              align: "center",
              prop: "standardGBTClause",
              label: "条款",
              width: "120px",
            },
            {
              align: "center",
              prop: "standardGBTContent",
              label: "条款内容",
              width: "200px",
            },
          ],
        },
        {
          align: "center",
          prop: "centralEnterprises",
          label: "中央企业合规管理办法",
          width: "",
          childs: [
            {
              align: "center",
              prop: "centralEnterprisesName",
              label: "法规名称",
              width: "180px",
            },
            {
              align: "center",
              prop: "centralEnterprisesClause",
              label: "条款",
              width: "120px",
            },
            {
              align: "center",
              prop: "centralEnterprisesContent",
              label: "条款内容",
              width: "200px",
            },
          ],
        },
        {
          align: "center",
          prop: "harmoniousRelationships",
          label: "和谐关系标准",
          width: "",
          childs: [
            {
              align: "center",
              prop: "harmoniousRelationshipsName",
              label: "法规名称",
              width: "180px",
            },
            {
              align: "center",
              prop: "harmoniousRelationshipsClause",
              label: "条款",
              width: "120px",
            },
            {
              align: "center",
              prop: "harmoniousRelationshipsContent",
              label: "条款内容",
              width: "200px",
            },
          ],
        },
        {
          align: "center",
          prop: "合规义务",
          label: "合规义务",
          width: "",
          childs: [
            {
              align: "center",
              prop: "riskMeasures",
              label: "合规措施",
              width: "150px",
            },
            {
              align: "center",
              prop: "riskTexts",
              label: "合规文件",
              width: "120px",
            },
            {
              align: "center",
              prop: "institutions",
              label: "合规制度",
              width: "120px",
            },
            {
              align: "center",
              prop: "dutyAmount",
              label: "合规义务数量",
              width: "80px",
            },
            {
              align: "center",
              prop: "tools",
              label: "合规工具",
              width: "100px",
            },
            {
              align: "center",
              prop: "dutyType",
              label: "合规义务种类",
              width: "110px",
            },
            {
              align: "center",
              prop: "responsiblePerson",
              label: "责任人",
              width: "80px",
            },
          ],
        },
        {
          align: "center",
          prop: "typicalCases",
          label: "案例",
          width: "",
          childs: [
            {
              align: "center",
              prop: "typicalCasesTitle",
              label: "案例标题",
              width: "120px",
            },
            {
              align: "center",
              prop: "typicalCasesUrl",
              label: "链接地址",
              width: "150px",
            },
            {
              align: "center",
              prop: "typicalCasesFileName",
              label: "文件",
              width: "120px",
            },
          ],
        },
        {
          align: "center",
          prop: "其他",
          label: "其他",
          width: "",
          childs: [
            {
              align: "center",
              prop: "rectificationSuggestion",
              label: "整改建议",
              width: "150px",
            },
            {
              align: "center",
              prop: "questions",
              label: "问题",
              width: "150px",
            },
            {
              align: "center",
              prop: "createTime",
              label: "新增日期",
              width: "100px",
            },
            {
              align: "center",
              prop: "isEnable",
              label: "是否启用",
              width: "80px",
            },
          ],
        },
        // { align: "center", prop: "code", label: "风险点编码", width: "130px" },
        // { align: "center", prop: "name", label: "风险点名称", width: "150px" },
        // { align: "center", prop: "rank", label: "风险等级", width: "80px" },
        // { align: "center", prop: "businessModuleName", label: "业务流程", width: "110px" },
      ],
      tableData: [],
      headData: [],
      showDialog: false,
      detailsInfo: {
        // 查看数据
        type2: "add",
      },
      postCycleList: [
        // 职业阶段数组
        { value: 1, label: "职前" },
        { value: 2, label: "入职" },
        { value: 3, label: "在职" },
        { value: 4, label: "离职" },
        { value: 5, label: "职后" },
      ],
      businessModuleList: [
        // 业务流程数组
        { value: 1, label: "招聘甄选" },
        { value: 2, label: "培训开发" },
        { value: 3, label: "绩效管理" },
        { value: 4, label: "薪酬管理" },
        { value: 5, label: "人事规章" },
        { value: 6, label: "劳动关系" },
        { value: 7, label: "组织发展" },
      ],
      typeList: [
        // 风险责任数组
        { value: "行政责任", label: "行政责任" },
        { value: "民事责任", label: "民事责任" },
        { value: "刑事责任", label: "刑事责任" },
      ],
      rankList: [
        // 风险等级数组
        { value: 1, label: "低" },
        { value: 2, label: "中" },
        { value: 3, label: "高" },
      ],
    };
  },
  created() {},
  mounted() {
    this.init();
    this.getBusinessModule();
  },
  methods: {
    init() {
      this.dataSize = 0;
      // this.currentPage = 1;
      // this.interPage = 15;
      this.filtrate();
      // this.getData();
    },
    // 获取业务类型 getRiskBusinessModule
    async getBusinessModule() {
      this.businessModuleList = [];
      let res = await getRiskBusinessModule();
      if (res.code == 0) {
        let data = res.result;
        data.forEach((item) => {
          this.businessModuleList.push({
            label: item.name,
            value: item.id,
          });
        });
      }
    },
    // 过滤表头
    filtrate() {
      this.headData = [];
      let newArr = [];
      if (this.headFilter.length > 0) {
        this.headFilter.sort(function (a, b) {
          return a - b;
        });
        this.headFilter.forEach((item) => {
          newArr.push(this.headFilterList[item]);
        });
      }
      newArr.push({
        align: "center",
        prop: "操作",
        label: "操作",
        width: "100px",
      });
      this.$nextTick(() => {
        this.headData = newArr;
        // console.log(this.headData);
        this.getData();
      });
    },
    // 获取列表
    async getData() {
      this.filterInfo.postCycle =
        this.filterInfo.postCycle === "" ? null : this.filterInfo.postCycle;
      this.filterInfo.businessModule =
        this.filterInfo.businessModule === ""
          ? null
          : this.filterInfo.businessModule;
      this.filterInfo.type =
        this.filterInfo.type === "" ? null : this.filterInfo.type;
      this.filterInfo.rank =
        this.filterInfo.rank === "" ? null : this.filterInfo.rank;
      let params = {
        info: this.filterInfo,
        params: `${this.currentPage}/${this.interPage}`,
      };
      this.tableData = [];
      this.loading = true;
      let res = await getRiskPlace(params);
      this.loading = false;
      // console.log(res,'风险点列表数据');
      if (res.code == 0) {
        this.dataSize = res.result.totalCount;
        let data = res.result.items;
        data.forEach((item) => {
          item.createTime = item.createTime.split("T")[0];
          item.typicalCasesTitle = [];
          item.typicalCasesUrl = [];
          item.typicalCasesFileName = [];
          item.typicalCases.forEach((item2) => {
            item.typicalCasesTitle.push(item2.title);
            item.typicalCasesUrl.push(item2.url);
            item.typicalCasesFileName.push(item2.fileName);
          });
          item.lawBasisName = [];
          item.lawBasisClause = [];
          item.lawBasisContent = [];
          item.lawBasis.forEach((item3) => {
            item.lawBasisName.push(item3.name);
            item.lawBasisClause.push(item3.clause);
            item.lawBasisContent.push(item3.content);
          });
          item.standardGBTName = [];
          item.standardGBTClause = [];
          item.standardGBTContent = [];
          item.standardGBT.forEach((item3) => {
            item.standardGBTName.push(item3.name);
            item.standardGBTClause.push(item3.clause);
            item.standardGBTContent.push(item3.content);
          });
          item.centralEnterprisesName = [];
          item.centralEnterprisesClause = [];
          item.centralEnterprisesContent = [];
          item.centralEnterprises.forEach((item3) => {
            item.centralEnterprisesName.push(item3.name);
            item.centralEnterprisesClause.push(item3.clause);
            item.centralEnterprisesContent.push(item3.content);
          });
          item.harmoniousRelationshipsName = [];
          item.harmoniousRelationshipsClause = [];
          item.harmoniousRelationshipsContent = [];
          item.harmoniousRelationships.forEach((item3) => {
            item.harmoniousRelationshipsName.push(item3.name);
            item.harmoniousRelationshipsClause.push(item3.clause);
            item.harmoniousRelationshipsContent.push(item3.content);
          });
        });
        this.tableData = data;
        // this.$nextTick(() => {
        //   this.$refs.tabel.doLayout();
        // })
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
    // 设置适合组织名称
    // setOrganization(id) {
    //   let name = '';
    //   let suitOrgList = [
    //     {value: 1, label: "央企和国企"},
    //     {value: 2, label: "民企100人以下"},
    //     {value: 3, label: "民企100人以上"},
    //     {value: 4, label: "民企1000人以上"},
    //     {value: 5, label: "劳务和人力公司"},
    //   ]
    //   for (let i = 0; i < suitOrgList.length; i++) {
    //     let item = suitOrgList[i];
    //     if (item.value === id) {
    //       name = item.label;
    //       break;
    //     }
    //   }
    //   return name;
    // },
    // 导出列表
    async exportData() {
      this.loading = true;
      let params = this.filterInfo;
      let res = await exportRiskPlace(params);
      let data = res;
      let fileName = "合规指引列表.xlsx";
      const blob =
        data instanceof Blob
          ? data
          : new Blob([data], {
              type: "application/vnd.ms-excel;charset=utf-8",
            });
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(link.href);
        document.body.removeChild(link);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
      this.loading = false;
    },
    // 新增劳务法
    add() {
      this.detailsInfo = {
        type2: "add",
      };
      this.showDialog = true;
    },
    // 编辑
    lookDetails(row) {
      this.detailsInfo = row;
      this.detailsInfo.type2 = "edit";
      this.$nextTick(() => {
        this.showDialog = true;
      });
    },
    // 删除
    del(row) {
      this.$confirm(`请确认是否删除【 ${row.name} 】？`)
        .then((_) => {
          this.delTask({ ids: [row.id] });
        })
        .catch((_) => {});
    },
    async delTask(id) {
      let res = await delRiskPlace(id);
      if (res.code === 200) {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.init();
      } else {
        this.$message({
          message: res.Message,
          type: "warning",
        });
      }
    },
    handleSizeChange(val) {
      this.currentPage = 1;
      this.interPage = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
.conform-guide {
  padding: 20px;
  // height: 100%;
  box-sizing: border-box;
  .head-box {
    display: flex;
    justify-content: space-between;
    height: 7%;
    margin-bottom: 10px;
    .head-left {
      display: flex;
      align-items: center;
      .el-button {
        height: 40px;
        border-radius: 20px;
        color: rgba(0, 148, 255, 0.7);
        border: 1px solid rgba(0, 148, 255, 0.7);
        margin-right: 10px;
      }
    }
    .head-right {
      display: flex;
      align-items: center;
      span {
        white-space: nowrap;
      }
      .el-select {
        margin: 0 10px;
      }
      .selects {
        max-width: 350px;
      }
      .el-input {
        max-width: 250px;
        margin-right: 10px;
      }
      .btn {
        height: 40px;
        border-radius: 20px;
        color: rgba(0, 148, 255, 0.7);
        border: 1px solid rgba(0, 148, 255, 0.7);
        // margin-right: 10px;
      }
      .popover-box {
        margin-left: 30px;
        img {
          width: 14px;
          height: 14px;
          margin-right: 8px;
        }
        span {
          color: #333;
        }
      }
    }
  }
  ::v-deep .table-container {
    .el-table__header-wrapper {
      .el-table__header {
        th {
          padding: 5px 0;
          .cell {
            font-size: 12px;
            white-space: nowrap;
            padding: 0;
          }
        }
      }
    }
    .el-table__body-wrapper {
      td {
        padding: 5px 0;
        .cell {
          font-size: 12px;
          white-space: nowrap;
          padding: 0;
        }
      }
      .cell {
        display: flex;
        flex-direction: column;
        padding: 0 !important;
        p {
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding: 0 2px;
          box-sizing: border-box;
        }
      }
    }
  }
  ::v-deep .el-table__body-wrapper {
    height: initial !important;
  }
  .el-pagination {
    margin-top: 30px;
  }
}
.el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  .el-checkbox {
    width: 18%;
    margin-right: 59px !important;
    margin-bottom: 10px;
    // .el-checkbox__input.is-checked .el-checkbox__inner{
    //   background-color: #EA191C;
    //   border-color: #EA191C;
    // }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #666;
    }
  }
}
::v-deep .el-table-horizontal-scrollbar {
  .el-scrollbar__bar {
    height: 14px !important;
  }
}
</style>
